import { Directive, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, HostBinding, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterService } from '../services/filter.service';

@Directive({
  selector: '[sortable-column]'
})
export class SortableColumnDirective implements OnInit, OnDestroy {

  constructor(private filterService: FilterService) { }

  @Input('sortable-column') public columnName: string;
  @Input('sort-direction') public sortDirection: string = '';

  @HostBinding('class.sortable') public sortable: boolean = true;
  @HostBinding('class.asc') public isAsc: boolean;
  @HostBinding('class.desc') public isDesc: boolean;

  private sub: Subscription = new Subscription();

  @HostListener('click')
  public sort() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.isAsc = this.sortDirection === 'asc';
    this.isDesc = this.sortDirection === 'desc';
    this.filterService.updateFilters({ sortColumn: this.columnName, sortDirection: this.sortDirection });
  }

  ngOnInit() {
    this.isAsc = this.sortDirection === 'asc';
    this.isDesc = this.sortDirection === 'desc';
    // subscribe to sort changes so we can react when other columns are sorted
    this.sub.add(this.filterService.tableFiltered$.subscribe(event => this.resetIfNotThisColumn(event)));

    if (this.sortDirection) {
      setTimeout(() => this.filterService.updateFilters({ sortColumn: this.columnName, sortDirection: this.sortDirection }), 0);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private resetIfNotThisColumn(event) {
    // reset this column's sort direction to hide the sort icons
    if (this.columnName != event.sortColumn) {
      this.sortDirection = '';
      this.isAsc = this.isDesc = false;
    } else {
      this.sortDirection = event.sortDirection;
      this.isAsc = this.sortDirection === 'asc';
      this.isDesc = this.sortDirection === 'desc';
    }
  }
}
