import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { LoaderService } from './loader.service';
import { UtilsService } from './utils.service';
import { Category } from '../models';
import { Uris } from '../constants/uri.constants';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class RegistryService {

  /**
   * Source privée utilisée uniquement pour déclencher l'Observable de la liste
   */
  private _registriesSource = new Subject<FormListEvent>();

   /**
    * Observable de la liste. Souscrire à cet observable pour réagir à chaque fois qu'une liste est reçue
    */
  public registries$ = this._registriesSource.asObservable();

  private _cachedLexiques = {};

  constructor(
    private _http: HttpClient,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _util: UtilsService

  ) { }

  /**
   * Lance l'appel serveur pour obtenir une liste particulière
   * @param type - type de donnée demandé
   * @param searchText - Texte à rechercher (optionnel)
   */
   public getLexiques(type: string, searchText: string = "", options: any = {}): void {
    let sortField = 'label';

    options.searchText = searchText;

    this.getLexiqueObs(type, 'fr', options)
      .pipe(
        map(datas => {
            let results = this._searchInList(datas, searchText, type);
            return _.sortBy(results, data => data[sortField]);
        })
      )
      .subscribe(
        datas => this._registriesSource.next({ datas: datas, type: type }),
        error => {
          this._toastr.error("Une erreur est survenue lors de la récupération d'une liste, elle ne peut pas être affichée correctement.");
          this._loader.hide();
        }
      );
  }

  public getLexiqueObs(type: string, language: string, options: any = {}): Observable<any[]> {
    let TypeClass, uri;
    let httpParams: HttpParams = null;
    switch (type) {
      case Constants.REGISTRY_CATEGORY:
        TypeClass = Category;
        uri = Uris.REGISTRY_CATEGORIES;
        break;
      case Constants.REGISTRY_KEYWORD:
        TypeClass = Category;
        uri = Uris.REGISTRY_KEYWORDS;
        break;
    }

    uri = uri.replace('{language}', language);

    if (this._cachedLexiques[type] && this._cachedLexiques[type][language]) {
      return of(this._cachedLexiques[type][language]);
    }
    return this._http.get<any[]>(uri, { params: httpParams })
      .pipe(
        map(datas => datas.map(data => new TypeClass().deserialize(data))),
        tap(datas => {
          if (!this._cachedLexiques[type]) {
            this._cachedLexiques[type] = [];
          }
          this._cachedLexiques[type][language] = datas;
        })
      );
  }

  /**
   * Recherche une donnée dans une liste
   * @param list - Liste dans laquelle chercher
   * @param searchText - Texte à rechercher
   * @param listType - Type de donnée de la liste
   */
  private _searchInList(list: any[], searchText: string, listType: string): any[] {
    if (!searchText) {
      return (listType === 'cities') ? [] : list;
    }

    let searchFields = ["label"];

    let filtered = _.filter(list, el => {
      let addToList = false;
      _.each(searchFields, field => {
        addToList = (addToList || this._util.containsStr(searchText, el[field]));
      });
      return addToList;
    });
    return filtered;
  }

}

export interface FormListEvent {

  /**
   * Liste obtenue
   */
  datas: any[];

  /**
   * Type de liste obtenue
   */
  type: string;

}
