<label for="{{metadata.name}}">
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<input type="number" id="{{metadata.name}}" class="form-control"
  [name]="metadata.name"
  [(ngModel)]="value"
  [readonly]="isReadOnly || metadata.readOnly"
  (ngModelChange)="onChange()"
  #numberField="ngModel">
<div class="alert alert-danger" *ngIf="numberField.invalid">
  <p *ngIf="numberField.errors.required" i18n>Champ requis</p>
  <p *ngIf="numberField.errors.min" i18n>La valeur doit être{{metadata.strictMin ? ' strictement':''}} supérieure à {{metadata.min}}</p>
  <p *ngIf="numberField.errors.max" i18n>La valeur doit être{{metadata.strictMax ? ' strictement':''}} inférieure à {{metadata.max}}</p>
  <p *ngIf="numberField.errors.numberType" i18n>La valeur doit être un entier</p>
</div>
