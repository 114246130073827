import { Injectable, Component } from '@angular/core';

import { CanDeactivate } from '@angular/router';

@Injectable()
export class NeedSaveGuard implements CanDeactivate<Component> {
  constructor() {}

  canDeactivate(component: Component) {
    let comp = component as any;
    if(comp.canDeactivate) {
      return comp.canDeactivate();
    }
    return true;
  }
}
