import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
  /**
   * Titre de la popin
   */
  @Input() title;

  /**
   * Message de la popin
   */
  @Input() message;

  /**
   * Classe du bouton de validation
   */
  @Input() confirmClass = "btn-primary";

  /**
   * Texte dans le bouton de validation
   */
  @Input() confirmText;

  /**
   * Texte dans le bouton d'annulation et quitter (active un troisième bouton)
   */
  @Input() cancelAndQuitText = "";

  /**
   * Classe du bouton d'annulation et quitter
   */
  @Input() cancelAndQuitClass = "btn-default";

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.confirmText) {
      this.confirmText = $localize`Confirmer`;
    }
  }
}