import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { RightPanelAnimation, LinkService, SessionService, LoaderService, ProjectService, UtilsService } from 'src/app/services';
import { Link, Project } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { Constants } from '../../../constants';


@Component({
  templateUrl: './link-detail.component.html',
  animations: RightPanelAnimation
})
export class LinkDetailComponent implements OnInit, OnDestroy {
  /**
   * Lien affiché
   */
  public link: Link;

  /**
   * Étude à laquelle appartient le lien
   */
  public project: Project;

  /**
   * Base d'url vers l'étude
   */
  public projectPath: string;

  /**
   * Type d'objet projet
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Est-on dans le contexte d'une étude ?
   */
  private _isInProject: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _linkService: LinkService,
    private _projectService: ProjectService,
    public session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._linkService.link$.subscribe(link => this._initLink(link)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initLinkFromParams(params)));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprimme le lien
   */
  public deleteLink(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un lien`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce lien : '${this.link.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._linkService.deleteLink(this.link)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de lien",
            properties: {
              category: this.link.name
            }
          });
          this._toastr.success($localize`Le lien '${this.link.name}' a été supprimé avec succès`);
          let returnRoute = '../../';
          if (this._isInProject) {
            returnRoute += '../../../';
          }
          this._router.navigate([returnRoute], { relativeTo: this._route });
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initLinkFromParams(params: any): void {
    this._loader.show();
    let linkId = params.linkId;
    if (params.projectId) {
      this._isInProject = true;
    }
    this._linkService.getLink(linkId);
  }

  /**
   * Affiche le lien reçu
   * @param link - Lien issu du serveur
   */
  private _initLink(link: Link): void {
    this.link = link;
    this._projectService.getProject(link.projectId, false);
  }

  /**
   * Affiche l'étude du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    if (this.session.hasRight(this.project.id, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
      this.projectPath = '/my-projects';
    } else {
      this.projectPath = '/projects';
    }
    this._loader.hide();
  }

}
