import * as _ from 'lodash';

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { NgForm, FormControl } from '@angular/forms';

import { UserService } from "src/app/services/user.service";
import { EntityBusinessObject, Permission, Group } from "src/app/models";
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constants';

@Component({
  templateUrl: './rights-modal.component.html'
})
export class RightsModalComponent implements OnInit, OnDestroy {
  /**
   * Métadonnée dont il faut modifier les droits
   */
  @Input() item: EntityBusinessObject;

  /**
   * Type de métadonnée
   */
  @Input() objectType: string;

  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;
  public objectTypeWorkflow = Constants.OBJECT_TYPE_WORKFLOW;

  /**
   * Permissions d'utilisateurs de la métadonnée
   */
  public individualPermissions: Permission[] = [];

  /**
   * Permissions de groupe de la métadonnée
   */
  public groupPermissions: Permission[] = [];

  /**
   * Liste autocomplétée des emails des utilisateurs
   */
  public emails: string[] = [];

  /**
   * Liste des groupes
   */
  public groups: Group[] = [];

  /**
   * Peut-on modifier les permissions des propriétaires ? (non si un seul propriétaire)
   */
  public canEditOwner: boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _userService: UserService,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.emails$.subscribe(emails => this._setUsersList(emails)));
    this._subs.add(this._userService.groups$.subscribe(groups => this._setGroupList(groups)));

    this._userService.getGroups();

    this.individualPermissions = _.cloneDeep(this.item.individualPermissions);
    this.groupPermissions = _.cloneDeep(this.item.groupPermissions);

    _.each(this.individualPermissions, (p, i) => p.trackId = i);
    _.each(this.groupPermissions, (p, i) => p.trackId = i);
    this.verifyOwners();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Valide le formulaire et ferme la modale avec les permissions modifiées
   * @param rightsForm - Objet formulaire d'angular
   */
  public save(rightsForm: NgForm) {
    if (!this._hasGroupDoublons()) {
      if (rightsForm.valid) {
        this.modal.close({
          individualPermissions: this.individualPermissions,
          groupPermissions: this.groupPermissions
        });
      }
    } else {
      this._toastr.error($localize`Vous ne pouvez pas donner deux fois des droits au même groupe`);
    }
  }

  /**
   * Demande l'autocomplétion des emails d'utilisateurs
   * @param event - événement primeng
   */
  public autocompleteUsers(event: any) {
    this._userService.searchUsers(event.query);
  }

  /**
   * Ajoute une permission
   * @param type - Type de permission (group ou indiv)
   */
  public addRight(type: string) {
    let list = type === 'group' ? this.groupPermissions : this.individualPermissions;

    let newPermission = new Permission();
    newPermission.code = 'readonly';
    newPermission.objectType = this.objectType;
    newPermission.objectId = this.item.id;

    _.each(list, p => {
      if (newPermission.trackId <= p.trackId) {
        newPermission.trackId = p.trackId + 1;
      }
    });

    list.push(newPermission);
  }

  /**
   * Supprime une permission
   * @param type - Type de permission (group ou indiv)
   * @param index - index de la permission dans la liste
   */
  public removeRight(type: string, index: number) {
    let list = type === 'group' ? this.groupPermissions : this.individualPermissions;
    if (list[index]) {
      list.splice(index, 1);
    }
    this.verifyOwners();
  }

  /**
   * Active/désactive la modification des permissions de propriétaire selon le nombre
   */
  public verifyOwners() {
    this.canEditOwner = _.filter(this.individualPermissions, { code: 'owner' }).length > 1;
  }

  /**
   * Définit la liste des groupes à afficher
   * @param groups - Liste des groupes du serveur
   */
  private _setGroupList(groups) {
    this.groups = groups;

    _.each(this.groupPermissions, p => {
      p.group = _.find(groups, { id: p.group.id });
    });
  }

  private _setUsersList(emails: string[]) {
    this.emails = _.filter(emails, e => !_.find(this.individualPermissions, { userId: e }));
  }

  private _hasGroupDoublons(): boolean {
    let hasDoublons = false;
    let foundGroups: any = {};
    for (let i = 0; i < this.groupPermissions.length; i++) {
      if (!foundGroups[this.groupPermissions[i].group.id]) {
        foundGroups[this.groupPermissions[i].group.id] = true;
      } else {
        hasDoublons = true;
        break;
      }
    }
    return hasDoublons;
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(i, item) {
    return item.trackId;
  }
}