import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from '@angular/forms';
import { Observable, of, Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { equals as equalsExtent } from 'ol/extent';

import { Project, Destination, Contact, MetadataEditing } from 'src/app/models';
import { ProjectService, MapService, LoaderService, SessionService, UtilsService, ResourceService, RegistryService } from 'src/app/services';
import { Constants } from 'src/app/constants';
import { Angulartics2 } from 'angulartics2';
import { SelectItem } from 'primeng/api';
import { FormListEvent } from '../../../services';

@Component({
  templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent implements OnInit, OnDestroy {
  /**
   * Étude en cours d'édition
   */
  public project: Project;

  /**
   * Est-ce une nouvelle étude ?
   */
  public isNew: boolean = true;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Liste des catégories
   */
  public projectCategories: string[] = [];

  /**
   * Liste des types de contact
   */
  public contactTypes: { label: string, value: string }[] = [];

  /**
   * Est-il possible de supprimer un contact "pointOfContact" ?
   */
  public canDeletePointOfContact: boolean = false;

  /**
   * Objet geoname actuellement sélectionné
   */
  public chosenGeoname: Destination;

  /**
   * Liste des mots-clés au format string
   */
  public keywords: string = "";

  /**
   * Liste des mots-clés opale au format string
   */
  public opaleKeywords: string = "";

  /**
   * Informations de l'étude en cours d'édition
   */
  public nonEditableInfos: MetadataEditing;

  /**
   * Liste des âges de début pour les dropdowns
   */
  public categories: SelectItem[] = [];

  /**
   * Liste des mots clés pour les dropdowns
   */
  public registryKeywords: SelectItem[] = [];

  /**
   * Liste des langues
   */
  public languages: ({ label: string; value: string, flag: any })[] = [];

  /**
   * Étude avant modification
   */
  private _initialProject: Project;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _projectService: ProjectService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _session: SessionService,
    private _tracker: Angulartics2,
    private _utils: UtilsService,
    private _resourceService: ResourceService,
    private _registryService: RegistryService
  ) { }

  ngOnInit() {
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this.geonames = geonames));
    this._subs.add(this._route.parent.paramMap.subscribe(params => this._initProjectFromParams(params)));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));

    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY);
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD);

    this.projectCategories = Constants.projectCategories;
    this.contactTypes = Constants.contactTypes;
    this.languages = Constants.languages;

  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Lance la requête pour l'autocomplétion des geonames
   * @param event - event primeng
   */
  public searchGeonames(event: any) {
    this.project.extentDescription = event.query || "";
    this._mapService.getSearchList(event.query);
  }

  /**
   * Mise à jour de l'emprise quand un geoname est sélectionné
   */
  public updateExtent() {
    if (this.chosenGeoname) {
      this.project.extentDescription = this.chosenGeoname.label;
      const existentExtent = _.find(this.project.extents, e => equalsExtent(e, this.chosenGeoname.extent));
      if (!existentExtent) {
        // trick pour forcer la maj de la carte
        this.project.extents = [_.clone(this.chosenGeoname.extent)].concat(this.project.extents);
      }
    } else {
      this.project.extentDescription = "";
    }
  }

  /**
   * Demande l'étude à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initProjectFromParams(params: any) {
    this._loader.show();
    this._projectService.getProject(params.get('projectId'));
    this.isNew = (params.get('projectId') === 'new');
  }

  /**
   * Clone l'étude reçu du serveur pour en faire un objet éditable
   * @param project - Étude issu du serveur
   */
  private _initProject(project: Project) {
    this._initialProject = _.cloneDeep(project);
    let isEditableObs: Observable<MetadataEditing>;
    if (this.isNew) {
      this._initialProject.name = "";
      this._initialProject.ownerName = this._session.currentUser.email;
      this._initialProject.ownerOrganisation = "BRGM";

      let firstContact = new Contact();
      firstContact.individualName = this._session.currentUser.email;
      firstContact.organisationName = "BRGM";
      firstContact.role = "pointOfContact";
      this._initialProject.contacts.push(firstContact);
      isEditableObs = of(null);
    } else {
      isEditableObs = this._resourceService.getResourceEditingStatus(project.id, Constants.OBJECT_TYPE_PROJECT);
    }
    isEditableObs.subscribe(me => {
      if (me) {
        this.nonEditableInfos = me;
      } else if (!this.isNew) {
        this._resourceService.setResourceAsEditing(project.id, Constants.OBJECT_TYPE_PROJECT, true);
      }
      this.project = _.cloneDeep(this._initialProject);
      this.chosenGeoname = new Destination().deserialize({
        label: this.project.extentDescription,
        extent: this.project.extents.length > 0 ? this.project.extents[0] : null
      });
      this.keywords = this.project.freeKeywords.join(', ');
      const opaleKeywords = this.project.getClassifiedKeywords(Constants.OPALE_KEYWORD_NAME);
      if (opaleKeywords) {
        this.opaleKeywords = opaleKeywords.join(', ')
      }

      this._loader.hide();
    });
  }

  /**
   * Enregistre l'étude et reviens à la liste des études
   */
  public save(editForm: NgForm) {
    if (editForm.invalid) {
      return;
    }
    this._loader.show();
    if (this.keywords) {
      this.project.freeKeywords = this.keywords.split(',').map(k => k.trim());
    } else {
      this.project.freeKeywords = [];
    }
    if (this.opaleKeywords) {
      this.project.addClassifiedKeywords(Constants.OPALE_KEYWORD_NAME, this.opaleKeywords.split(',').map(k => k.trim()), true);
    } else {
      this.project.removeClassifiedKeywords(Constants.OPALE_KEYWORD_NAME);
    }
    this.project.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.project.thematics, true);
    this.project.addClassifiedKeywords(Constants.PROJECT_CATEGORY_KEYWORD_NAME, [this.project.category], true);
    this.project.addClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME, this.project.keywords, true);

    this._projectService.saveProject(this.project)
      .subscribe(result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'une étude" : "Modification d'une étude",
          properties: {
            category: this.project.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew) {
            this._toastr.success($localize`L'étude ${this.project.name} a été créée avec succès`);
            this._session.setInitPageParams({ id: result.id, type: 'project' });
          } else {
            this._toastr.success($localize`L'étude ${this.project.name} a été modifiée avec succès`);
          }
          let routeToNavigate;
          if (this.isNew) {
            routeToNavigate = ['../../'];
          } else {
            routeToNavigate = ['../']
          }
          this._router.navigate(routeToNavigate, { relativeTo: this._route });
        }
      }, error => console.error(error));
  }

  /**
   * Ajoute un contact
   */
  public addContact() {
    this.project.contacts.push(new Contact());
  }

  /**
   * Supprime un contact
   * @param index emplacement de l'objet
   */
  public removeContact(index: number) {
    this.project.contacts.splice(index, 1);
    this.checkPointsOfContacts();
  }

  /**
   * Vérifie si on peut encore supprimer des "pointOfContact"
   */
  public checkPointsOfContacts() {
    let pointOfContacts = _.filter(this.project.contacts, { role: "pointOfContact" });
    this.canDeletePointOfContact = (pointOfContacts.length > 1);
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByValue(item) {
    return item.value;
  }

  /**
   * Action en quittant la page
   */
  public canDeactivate(): boolean {
    if (!this.nonEditableInfos && !this.isNew) {
      this._resourceService.setResourceAsEditing(this.project.id, Constants.OBJECT_TYPE_PROJECT, false);
    }

    return true;
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public categoriesAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY, event.query, {});
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public keywordsAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD, event.query, {});
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case Constants.REGISTRY_CATEGORY:
        this.categories = event.datas;
        break;
      case Constants.REGISTRY_KEYWORD:
        this.registryKeywords = event.datas;
        break;
    }
  }

}
