import * as _ from 'lodash';

import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BboxMapModalComponent } from 'src/app/components/modals';
import { MapConfig } from 'src/app/models';
import { Constants } from 'src/app/constants';


@Component({
  selector: 'described-extent',
  templateUrl: './described-extent.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DescribedExtentComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: DescribedExtentComponent, multi: true }
  ]
})
export class DescribedExtentComponent implements ControlValueAccessor, Validator {
  /**
   * Le champ est-il requis ?
   */
  @Input() required: boolean = false;

  /**
   * Le champ est-il éditable ?
   */
  @Input() isEditable: boolean = true;

  /**
   * Copie locale de l'emprise
   */
  public extents: [number, number, number, number][] = [];

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor(
    private modalService: NgbModal
  ) { }

  /**
   * Ouvre la carte pour une sélection de l'emprise
   */
  public openMap() {
    const modalRef = this.modalService.open(BboxMapModalComponent, {
      backdrop: "static",
      size: "lg",
      centered: true,
      windowClass: "map-modal"
    });

    let extents = [];
    _.each(this.extents, extent => {
      extents.push({
        minX: extent[0],
        minY: extent[1],
        maxX: extent[2],
        maxY: extent[3]
      })
    });

    modalRef.componentInstance.metadata = {
      type: "bbox",
      isMultiple: true,
      value: extents,
      mapConfig: new MapConfig().deserialize(Constants.DESCRIBED_EXTENT_MAP_CONFIG)
    };

    modalRef.result.then(result => {
      if (result && _.isArray(result)) {
        this.extents = [];
        _.each(result, r => {
          this.extents.push([
            r.minX,
            r.minY,
            r.maxX,
            r.maxY
          ])
        });
        this.onChangeCb(this.extents);
      }

    }, () => {

    });
  }

  // control methods
  writeValue(value) {
    if (!value || !_.isArray(value)) {
      this.extents = [];
    } else {
      this.extents = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations = {};

    this.requiredValidator(validations, control);

    if (_.keys(validations).length > 0) {
      return validations;
    }
    return null;
  }

  requiredValidator(validations, control) {
    if (this.required && (!control.value || control.value.length === 0)) {
      validations.required = true;
    }
  }

}
