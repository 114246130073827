import { EntityMapper } from "./entity-mapper.model";

export class MetadataEditing extends EntityMapper {
  /**
   * Email de l'utilisateur
   */
  userId: string = null;

  /**
   * Identifiant de l'objet
   */
  objectId: string = null;

  /**
   * Type d'objet (étude, workflow, dataset ou lien)
   */
  objectType: string = "";

}