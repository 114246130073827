<section id="projects" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto">
      <ng-container *ngIf="isOnlyMine" i18n>Mes études</ng-container>
      <ng-container *ngIf="!isOnlyMine" i18n>Toutes les études</ng-container>
    </h1>
    <div class="title-button-container">
      <a [routerLink]="['./', 'new', 'edit']" class="btn btn-primary"> 
        <i class="fa fa-plus"></i> 
        <ng-container i18n>Créer une étude</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucune étude</p>
          <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} études</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="projects" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
            <th class="owner" sortable-column="ownerName"><span i18n>Contact</span></th>
            <th class="extentDescription" sortable-column="extentDescription"><span i18n>Emprise</span></th>
            <th class="last-update text-center" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-project>
          <tr>
            <td>
              <a [routerLink]="['./', project.id]">{{project.name}}</a>
            </td>
            <td>{{project.ownerName}} ({{project.ownerOrganisation}})</td>
            <td>{{project.extentDescription}}</td>
            <td class="text-center">{{project.lastUpdate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
              <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                <a class="dropdown-item" [routerLink]="['./', project.id]" i18n>Consulter</a>
                <a *ngIf="session.hasRight(project.id, objectTypeProject, 'editor')" class="dropdown-item" [routerLink]="['./', project.id, 'edit']" i18n>Modifier</a>
                <a *ngIf="session.hasRight(project.id, objectTypeProject, 'owner')" class="dropdown-item item-danger" (click)="deleteProject(project)" i18n>Supprimer</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
