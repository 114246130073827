<section id="workflow-detail" class="h-100" *ngIf="workflow">
  <div class="d-flex workflow-content h-100" *ngIf="workflow">
    <div class="w-100 workflow-drawing h-100 d-flex flex-column">
      <div class="row align-items-start page-header">
        <div class="col-12 col-xl mr-auto">
          <h1>{{workflow.name}}</h1>
          <p class="mr-3 description mb-1" *ngIf="workflow.owners && workflow.owners.length > 0">
            <strong i18n>Propriétaires :</strong>&nbsp;
            <ng-container *ngFor="let user of workflow.owners; let i = index;">
              <ng-container *ngIf="i > 0">, </ng-container>{{user}}
            </ng-container>
          </p>
          <p class="mr-3 description mb-3">
            <strong i18n>Contacts principaux :</strong>&nbsp;{{mainContacts}}
          </p>
          <p class="description">{{workflow.description}}</p>
        </div>
        <div class="ml-3 ml-xl-5">
          <ng-container *ngIf="!isInProject">
            <a *ngIf="isWorkflowOwner" [routerLink]="['./edit']" class="btn btn-default btn-block nowrap mb-2">
              <i class="fa fa-edit"></i>
              <ng-container i18n>Modifier</ng-container>
            </a>
            <button type="button" *ngIf="isAdmin || isWorkflowOwner" class="btn btn-default btn-block nowrap"
              (click)="openEditRightsModal()">
              <i class="fa fa-lock"></i>
              <ng-container i18n>Droits d'accès</ng-container>
            </button>
            <button class="btn btn-danger btn-block nowrap mt-2" (click)="deleteWorkflow()"
              *ngIf="isWorkflowOwner && isWorkflowManager">
              <i class="fa fa-trash-alt"></i>
              <ng-container i18n>Supprimer</ng-container>
            </button>
          </ng-container>
          <button type="button" class="btn btn-default btn-block nowrap" [ngClass]="{'workflow-btn': !isInProject}"
            (click)="linkToProject()">
            <ng-container *ngIf="!isInProject" i18n>Lier à une étude</ng-container>
            <ng-container *ngIf="isInProject" i18n>Lier à une autre étude</ng-container>
          </button>
          <button type="button" *ngIf="isInProject && isProjectOwner && !hasExecutions"
            class="btn btn-danger btn-block nowrap mt-2" (click)="unlinkOfProject()">
            <ng-container  i18n>Retirer de cette étude</ng-container>
          </button>
        </div>
      </div>

      <div class="page-content pt-4">
        <div class="process-floor row justify-content-center align-items-center"
          *ngFor="let process of workflow.processes">
          <div class="col" [ngClass]="{'col-xl-4 col-lg-6': process.type === 'treatment'}">
            <div class="process-box mx-auto" [ngClass]="process.type"
              [class.active]="selectedProcess && selectedProcess.id === process.id">
              <h3>
                <i class="fa" [ngClass]="{
                  'fa-toolbox': process.type == 'treatment',
                  'mult mult-alea': process.type == 'alea',
                  'fa-chart-bar': process.type == 'indicator'
                }"></i>
                {{process.name}}
              </h3>
              <i class="fa fa-asterisk edited-process-icon" *ngIf="!this.currentExecution?.id && process.edited"
                i18n-ngbTooltip ngbTooltip="Un paramétrage a été modifié" placement="top" container="body"></i>
              <div class="row align-items-start">
                <div class="col config-btn" *ngIf="process.metadata.length > 0">
                  <button type="button" class="btn btn-default btn-block" (click)="openProcessConfig(process)">
                    <i class="fa fa-cog"></i>
                    <ng-container i18n>Configurer</ng-container>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details h-100">
      <p-tabView styleClass="h-100 d-flex flex-column">
        <p-tabPanel i18n-header header="Exécution" *ngIf="isInProject || displayExecutions">
            <ng-template pTemplate="content">
              <div class="form-group version-selector">
                <div class="d-flex align-items-center">
                  <label for="current-execution" class="mb-0" i18n>Exécution courante</label>
                  <ng-container *ngIf="isInProject">
                    <button *ngIf="isProjectEditor && currentExecution && currentExecution.status === executionStatus.saved"
                      class="ml-auto btn btn-icon delete-exec-btn px-2" (click)="deleteExecution()" i18n-ngbTooltip
                      ngbTooltip="Supprimer l'exécution" placement="left">
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </ng-container>
                </div>
                <p *ngIf="workflow.executions.length === 0" class="mb-1 alert alert-info">
                  <i class="fa fa-info-circle"></i>
                  <ng-container i18n>Aucune exécution enregistrée</ng-container>
                </p>
                <select *ngIf="workflow.executions.length > 0" class="form-control px-1" [(ngModel)]="currentExecution"
                  id="current-execution" (change)="applyExecutionInputs()">
                  <option *ngFor="let execution of workflow.executions; trackBy: trackById" [ngValue]="execution">
                    <ng-container *ngIf="isInProject && execution.status === executionStatus.completed">*&nbsp;
                    </ng-container>
                    {{execution.name}}
                    <ng-container *ngIf="execution.executionDate">
                      ({{execution.executionDate|date:'dd/MM/yyyy à HH:mm'}})
                    </ng-container>
                    <ng-container *ngIf="!execution.executionDate" i18n>(jamais exécutée)</ng-container>
                  </option>
                </select>
                <div class="d-flex mt-2">
                  <button *ngIf="isProjectEditor" class="btn btn-default btn-block p-1 mr-1" (click)="editExecution()">
                    <i class="fa fa-pen"></i>
                    <ng-container i18n>Modifier</ng-container>
                  </button>
                  <button *ngIf="isProjectEditor" class="btn btn-default btn-block mt-0 p-1 ml-1" (click)="addExecution()">
                    <i class="fa fa-plus"></i>
                    <ng-container i18n>Ajouter</ng-container>
                  </button>
                </div>
              </div>
              <dl class="mb-3 row" *ngIf="currentExecution">
                <div class="col-6">
                  <dt i18n>Créée par</dt>
                  <dd>{{currentExecution.userId}}</dd>
                </div>
                <div class="col-6">
                  <dt i18n>Date d'exécution</dt>
                  <dd>
                    <ng-container *ngIf="currentExecution.executionDate">
                      {{currentExecution.executionDate|date:'dd/MM/yyyy à HH:mm'}}
                    </ng-container>
                    <ng-container *ngIf="!currentExecution.executionDate" i18n>Jamais</ng-container>
                  </dd>
                </div>
              </dl>
              <ng-container *ngIf="isInProject && currentExecution">
                <button type="button" class="execute-btn btn btn-primary btn-block px-1 mb-4"
                  *ngIf="!currentExecution.status || currentExecution.status === executionStatus.submitted || currentExecution.status === executionStatus.running"
                  [disabled]="currentExecution.status || hasRunningExecution" (click)="execWorkflow()">
                  <i class="fa" [ngClass]="{
                    'fa-play': !currentExecution.status,
                    'fa-circle-notch': currentExecution.status === executionStatus.submitted || currentExecution.status === executionStatus.running
                  }"></i>
                  <ng-container i18n>Exécuter le workflow</ng-container>
                  <i class="fa fa-asterisk edited-process-icon" *ngIf="processHasChanged" i18n-ngbTooltip
                    ngbTooltip="Des paramétrages ont été modifiés" placement="top" container="body"></i>
                </button>
                <button *ngIf="isProjectEditor && currentExecution.status === executionStatus.completed" placement="left"
                  ngbTooltip="Enregistrer les paramètres et les résultats de cette exécution" i18n-ngbTooltip
                  type="button" class="btn btn-primary btn-block mb-3" (click)="saveExecution()">
                  <i class="fa fa-save"></i>
                  <ng-container i18n>Enregistrer cette exécution</ng-container>
                </button>
              </ng-container>
              <div *ngIf="data" class="result-container">
                <dl class="mb-0">
                  <ng-container *ngIf="data.error">
                    <dt></dt>
                    <dd>
                      <pre class="method-content">{{data.error}}</pre>
                    </dd>
                  </ng-container>
                  <ng-container *ngIf="data.logs">
                    <dt class="d-flex">
                      <button type="button" class="btn btn-icon logs-btn w-100 d-flex"
                        (click)="displayLogs = !displayLogs">
                        <span i18n>Logs</span>
                        <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: !displayLogs}"></i>
                      </button>
                    </dt>
                    <dd class="logs">
                      <pre class="method-content" [ngClass]="{closed: !displayLogs}">{{data.logs}}</pre>
                    </dd>
                  </ng-container>
                  <ng-container *ngIf="data.results && data.results.length > 0">
                    <dt i18n>Fichiers résultats</dt>
                    <dd>
                      <ul class="result-list">
                        <li *ngFor="let result of data.results">
                          <a [href]="result.url" [ngbTooltip]="result.description" placement="left" target="_blank">
                            {{result.name}} <i class="fa fa-external-link-alt"></i>
                          </a>
                          <button type="button" class="ml-1 btn btn-icon add-to-project" *ngIf="!result.isInProject"
                            i18n-ngbTooltip ngbTooltip="Copier ce fichier dans les données de l'étude" placement="left"
                            (click)="addResultToProject(result)">
                            <i class="fa fa-folder-open"></i>
                            <i class="fa fa-plus"></i>
                          </button>
                        </li>
                      </ul>
                    </dd>

                    <ng-container *ngIf="currentExecution && (
                        currentExecution.status === executionStatus.completed ||
                        currentExecution.status === executionStatus.saved
                        )">

                      <button type="button" class="btn btn-default btn-block mb-3" *ngIf="displayMapButton"
                        (click)="openResultsMap()">
                        <i class="fa fa-map-marker-alt"></i>
                        <ng-container i18n>Voir les résultats sur la carte</ng-container>
                      </button>
                      <button type="button" class="btn btn-default btn-block mb-3" *ngIf="displayTableButton"
                        (click)="openResultsTable()">
                        <i class="fa fa-table"></i>
                        <ng-container i18n>Voir le récapitulatif des résultats</ng-container>
                      </button>
                    </ng-container>
                  </ng-container>
                </dl>
              </div>
              <ng-container *ngIf="isInProject">
                <button *ngIf="isProjectEditor && currentExecution && currentExecution.status === executionStatus.saved"
                  class="btn btn-danger btn-block" (click)="deleteExecution()">
                  <i class="fa fa-trash-alt"></i>
                  <ng-container i18n>Supprimer l'exécution</ng-container>
                </button>
              </ng-container>
            </ng-template>
          </p-tabPanel>

        <p-tabPanel i18n-header header="Informations">
          <ng-template pTemplate="content">
            <p class="alert-info p-3" *ngIf="!isInProject">
              <i class="fa fa-info-circle mr-2"></i>
              <ng-container i18n>Ce workflow ne peut être exécuté que s'il est lié à une étude et que vous vous trouvez
                dans le contexte de cette étude.</ng-container>
            </p>
            <a *ngIf="!isInProject && displayExecutionsLink" routerLink="./executions"
               class="btn btn-default btn-block mb-3 executions-link">
              <ng-container i18n>Voir toutes les exécutions</ng-container>
              <i class="fa fa-chevron-right ml-2"></i>
            </a>
            <dl class="mb-0 meta-detail">
              <h2 i18n>Informations</h2>
              <div class="mb-2">
                <dt class="d-inline" i18n>Langue :</dt>&nbsp;
                <dd class="d-inline"><display-lang [displayLang]="workflow.language"></display-lang></dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Mots-clés :</dt>&nbsp;
                <dd class="d-inline">{{workflow.freeKeywords.join(', ')}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Thématiques :</dt>&nbsp;
                <dd class="d-inline">
                  <ng-container *ngFor="let thematic of workflow.thematics; let isLast=last">
                    {{thematic.label}}{{isLast ? '' : ', '}}
                  </ng-container>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Type :</dt>&nbsp;
                <dd class="d-inline">{{workflow.typeName}}</dd>
              </div>
              <div class="mb-2" *ngIf="workflow.executionTime">
                <dt class="d-inline" i18n>Temps d'exécution :</dt>&nbsp;
                <dd class="d-inline">{{workflow.executionTime}}</dd>
              </div>
              <div class="mb-2" *ngIf="workflow.codeVersion">
                <dt class="d-inline" i18n>Version du code :</dt>&nbsp;
                <dd class="d-inline">{{workflow.codeVersion}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Validation scientifique :</dt>&nbsp;
                <dd class="d-inline">{{workflow.quality}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Qualité de la méta-donnée :</dt>&nbsp;
                <dd class="d-inline">{{workflow.metadataQuality}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Dernière modification :</dt>&nbsp;
                <dd class="d-inline">{{workflow.lastUpdate | date: 'dd/MM/yyyy'}}</dd>
              </div>
              <div class="mb-2" *ngIf="workflow.file">
                <dt class="d-inline" i18n>Url du GIT :</dt>&nbsp;
                <dd class="d-inline">
                  <a [href]="workflow.file.url" class="nowrap" target="_blank">
                    {{workflow.gitUrl}}
                    <i class="fa fa-external-link-alt"></i>
                  </a>
                </dd>
              </div>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideDescription = !hideDescription">
                  <span i18n>Description</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{workflow.description}}</dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideContacts = !hideContacts">
                  <span i18n>Contacts</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContacts}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContacts}">
                <ul class="contacts">
                  <li *ngFor="let contact of workflow.contacts">
                    <em class="d-block">{{contactsRolesNames[contact.role]}}</em>
                    {{contact.individualName}} ({{contact.organisationName}})
                    <a *ngIf="contact.email" class="d-block" [href]="'mailto:' + contact.email">{{contact.email}}</a>
                  </li>
                </ul>
              </dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideComment = !hideComment">
                  <span i18n>Commentaire</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideComment}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideComment}">{{workflow.comment}}</dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideLineage = !hideLineage">
                  <span i18n>Traçabilité (origine)</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLineage}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLineage}">{{workflow.lineage}}</dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideLegals = !hideLegals">
                  <span i18n>Contraintes légales</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLegals}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLegals}">
                {{workflow.legalConstraints}}
              </dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                        (click)="hideGitDesc = !hideGitDesc">
                  <span i18n>Commentaire relatif aux sources (GIT)</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideGitDesc}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideGitDesc}">{{workflow.gitDescription}}
              </dd>
              <hr class="my-3">
              <h2>Références associées</h2>
              <ng-container *ngFor="let resource of workflow.onlineResources">
                <dt>
                  <a class="d-block" [href]="resource.url" target="_blank">
                    {{resource.url}}
                  </a>
                </dt>
                <dd>
                  <em class="d-block">{{resource.protocol}}</em>
                  <p class="nl2br">
                    {{resource.description}}
                  </p>
                </dd>
              </ng-container>
              <hr class="my-3">
              <h2>Emprises</h2>
              <dt>
                <ng-container *ngIf="workflow.extentDescription">{{workflow.extentDescription}}</ng-container>
                <ng-container *ngIf="!workflow.extentDescription" i18n>(aucune description)</ng-container>
              </dt>
              <dd>
                <simple-map [extents]="workflow.extents"></simple-map>
              </dd>
            </dl>
          </ng-template>
        </p-tabPanel>
      </p-tabView>

    </div>
  </div>

  <div class="right-panel" *ngIf="selectedProcess" [@panelAnimation]="(selectedProcess) ? 'open': 'close'">
    <h2>{{selectedProcess.name}}</h2>
    <button type="button" name="close" class="btn btn-icon fa fa-times" title="Quitter l'édition sans enregistrer"
      (click)="closeProcessConfig()"></button>
    <metadatas [list]="selectedProcessMetadatas" (onCancel)="closeProcessConfig()"
      (onSubmit)="saveProcessMetadata($event)" (onMetadatasChange)="metadataHasChanged = true"
      [editable]="!isInProject || (currentExecution && !currentExecution.status)"></metadatas>
  </div>

  <ng-template #alertProcessModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" i18n>Appliquer vos modifications ?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="mb-0" i18n>La configuration de cet élément a été modifiée. Voulez-vous appliquer ces changements ?</p>
    </div>
    <div class="modal-footer d-flex">
      <button type="button" class="btn btn-default mr-2" (click)="modal.close('cancel')" i18n>Annuler</button>
      <button type="button" class="btn btn-default mr-auto" (click)="modal.close('continue')" i18n>
        Continuer sans appliquer
      </button>
      <button type="button" class="btn btn-primary" (click)="modal.close('save')" i18n>Appliquer et continuer</button>
    </div>
  </ng-template>
</section>
