import { Component } from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent {
  constructor() {}


}
