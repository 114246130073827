import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT, MissingTranslationStrategy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <== add the imports!
import { NgbModule, NgbTooltipModule, NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { ToastrModule } from 'ngx-toastr';
import { Angulartics2Module } from 'angulartics2';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import {SliderModule} from 'primeng/slider';
import {TabViewModule} from 'primeng/tabview';

import { FiltrableTableModule } from './modules/filtrableTable/app.module';

import { AppRoutes } from './app.routing';

import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { FileSizePipe } from './pipes/file-size.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SanitizeImagePipe } from './pipes/sanitize-image.pipe';
import { ProjectsComponent, WorkflowsComponent, DatasComponent, LinksComponent, WorkflowExecutionsComponent } from './components/lists';
import { AuthGuard, NeedSaveGuard, AdminGuard } from './guards';
import { BboxMapModalComponent, WorkflowMapModalComponent, ConfirmModalComponent, WorkflowLinkageModalComponent, RightsModalComponent, ExecutionNameModalComponent, ResultsTableModalComponent } from './components/modals';
import {
  MainLayoutComponent, HeaderComponent, MenuComponent, BreadcrumbComponent,
  LoaderComponent, AutocompleteComponent, MapComponent, SimpleMapComponent,
  DisplayLangComponent
} from './components/layout';
import {
  ProjectDetailComponent, WorkflowDetailComponent, DataDetailComponent, LinkDetailComponent,
  ResourcesComponent, HelpComponent, ErrorPageComponent, ExecutionDetailComponent, AccountComponent
} from './components/pages';
import {
  ProjectEditComponent, LinkEditComponent, DataEditComponent, WorkflowEditComponent
} from './components/forms';
import {
  MetadatasComponent, NumberComponent, TextComponent, TextMultilineComponent, ListComponent,
  CheckboxComponent, FileComponent, BboxComponent, CoordComponent, InfoComponent, MultipleCoordComponent, DescribedExtentComponent, LabelInfoMetadataComponent, MultipleCoordUniqueComponent, DropFilesComponent
} from './components/forms/form-parts';

import { GroupsAdminComponent } from './components/admin/lists';
import { AdminLayoutComponent } from './components/admin/layout';
import { GroupManageAdminComponent, GroupEditAdminComponent } from './components/admin/forms';
import { UserNameModalAdminComponent, GroupChoiceModalAdminComponent, AddUserModalAdminComponent } from './components/admin/modals';
import { UserSearchAdminComponent } from './components/admin/pages';

import { AutofocusDirective } from './directives';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {CDK_DRAG_CONFIG, DragDropModule} from '@angular/cdk/drag-drop';


// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

// oblige de la garder ici car il ne peux pas appeler la class Util
function getLocaleFromNavigator(): string {
  const navigatorLocale = navigator.language;
  if (navigatorLocale != null) {
    return navigator.language.substring(0, 2) || 'fr';
  }
  return 'fr';
}

// use the require method provided by webpack
declare const require;

const interceptors = { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true };

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@NgModule({
    declarations: [
        AppComponent,
        FileSizePipe,
        SanitizeHtmlPipe,
        SanitizeImagePipe,
        MainLayoutComponent,
        HeaderComponent,
        MenuComponent,
        BreadcrumbComponent,
        ProjectsComponent,
        ProjectDetailComponent,
        ProjectEditComponent,
        WorkflowsComponent,
        WorkflowDetailComponent,
        WorkflowEditComponent,
        WorkflowExecutionsComponent,
        ExecutionDetailComponent,
        DatasComponent,
        DataDetailComponent,
        DataEditComponent,
        LinksComponent,
        LinkDetailComponent,
        LinkEditComponent,
        ResourcesComponent,
        HelpComponent,
        ErrorPageComponent,
        LoaderComponent,
        MetadatasComponent,
        LabelInfoMetadataComponent,
        NumberComponent,
        TextComponent,
        TextMultilineComponent,
        ListComponent,
        CheckboxComponent,
        FileComponent,
        DropFilesComponent,
        BboxMapModalComponent,
        BboxComponent,
        CoordComponent,
        InfoComponent,
        MultipleCoordComponent,
        MultipleCoordUniqueComponent,
        WorkflowMapModalComponent,
        AutocompleteComponent,
        MapComponent,
        SimpleMapComponent,
        DescribedExtentComponent,
        ConfirmModalComponent,
        WorkflowLinkageModalComponent,
        RightsModalComponent,
        ExecutionNameModalComponent,
        ResultsTableModalComponent,
        AdminLayoutComponent,
        GroupsAdminComponent,
        GroupManageAdminComponent,
        GroupEditAdminComponent,
        UserSearchAdminComponent,
        UserNameModalAdminComponent,
        GroupChoiceModalAdminComponent,
        AddUserModalAdminComponent,
        AccountComponent,
        AutofocusDirective,
        DisplayLangComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbTooltipModule,
        NgbAccordionModule,
        NgbDropdownModule,
        FiltrableTableModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        Angulartics2Module.forRoot(),
        DragDropModule,
        AutoCompleteModule,
        CalendarModule,
        FileUploadModule,
        InputSwitchModule,
        DropdownModule,
        SliderModule,
        TabViewModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
            progressBar: true,
            extendedTimeOut: 2000
        })
    ],
    providers: [
        Title,
        { provide: LOCALE_ID, useValue: getLocaleFromNavigator() },
        // { provide: TRANSLATIONS, useValue: require(`raw-loader!../locale/messages.` + getLocaleFromNavigator() + `.xlf`) },
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        AuthGuard,
        NeedSaveGuard,
        AdminGuard,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        interceptors,
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
