import { Injectable } from '@angular/core';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, switchMap } from 'rxjs/operators';

import { SessionService, AppConfigService } from '../services';
import { User } from '../models';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _session: SessionService,
    private _appConfigService: AppConfigService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._session.currentUser) {
      // logged in so return true
      return true;
    }

    this._session.getCurrentUser();

    return this._session.currentUser$
      .pipe(
        switchMap(user => this._appConfigService.initAppConfig().pipe(map(() => user))),
        map(user => {
          if (user) {
            return true;
          }
          return false;
        })
      );
  }
}
