<div class="loader d-flex justify-content-center align-items-center"
  [ngClass]="{visible: visible, 'menu-collapsed': menuCollapsed}">
  <div class="lds-ripple">
    <div class="brand" i18n>Chargement...</div>
    <div class="round round-1"></div>
    <div class="round middle-round round-2"></div>
    <div class="round middle-round round-3"></div>
    <div class="round middle-round round-4"></div>
    <div class="round middle-round round-5"></div>
    <div class="round round-6"></div>
  </div>
</div>