<form #renameForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': renameForm.submitted}"
  (ngSubmit)="save(renameForm)">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Ajouter un utilisateur au groupe</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div class="d-flex align-items-center">
      <label for="rename-name" class="mb-0 mr-3 nowrap" i18n>Utilisateur</label>
      <p-autoComplete [(ngModel)]="user" id="rename-name" class="w-100"
        name="rename-name" [suggestions]="emails" appendTo="body"
        inputStyleClass="form-control" styleClass="w-100" [forceSelection]="true"
        (completeMethod)="autocompleteUsers($event)" required></p-autoComplete>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
  </div>
</form>