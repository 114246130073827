import * as _ from 'lodash';

import { Process } from './process.model';
import { EntityBusinessObject } from './entity-business-object.model';
import { OnlineResource } from './online-resource.model';
import { Constants } from '../constants/app.constants';
import { Project } from './project.model';
import { Execution } from './execution.model';
import { MapConfig } from './map.model';

export class Workflow extends EntityBusinessObject {
  /**
   * Commentaires / informations supplémentaires
   */
  comment: string = "";

  /**
   * Contraintes d'utilisation
   */
  legalConstraints: string = "";

  /**
   * Liste des resources du workflow
   */
  onlineResources: OnlineResource[] = [];

  /**
   * Url du git du workflow
   */
  gitUrl: string = "";

  /**
   * Description git du workflow
   */
  gitDescription: string = "";

  /**
   * Liste des process
   */
  processes: Process[] = [];

  /**
   * Liste des exécutions
   */
  executions: Execution[] = [];

  /**
   * Url d'exécution des process du workflow
   */
  execUrl: string = "";

  /**
   * Liste des études associées
   */
  projectUris: string[] = [];

  /**
   * Études associées
   */
  projects: Project[] = [];

  /**
   * Traçabilité
   */
  lineage: string = "";

  /**
   * Nom du type de workflow
   */
  typeName: string = null;

  /**
   * Temps indicatif d'exécution
   */
  executionTime: string = null;

  /**
   * Version du code python
   */
  codeVersion: string = null;

  /**
   * Validation scientifique
   */
  quality: string = "Ne fait pas l'objet d'une publication scientifique";

  /**
   * Qualité de la métadonnée
   */
  metadataQuality: string = "Incomplet";

  /**
   * Configuration de la carte pour ce workflow
   */
  mapConfig: MapConfig = null;

  /**
   * Langue
   */
  language: string = "fre";

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "comment", back: "supplementalInformation" },
      { front: "onlineResources", class: OnlineResource },
      { front: "lineage", back: "dataQualityLineage" },
      { front: "mapConfig", class: MapConfig }
    ]);
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.typeName = this.getUniqueClassifiedKeyword(Constants.TYPOLOGIE_KEYWORD_NAME);
    this.executionTime = this.getUniqueClassifiedKeyword(Constants.EXECUTION_TIME_KEYWORD_NAME);
    this.codeVersion = this.getUniqueClassifiedKeyword(Constants.CODE_VERSION_KEYWORD_NAME);

    const quality = this.getUniqueClassifiedKeyword(Constants.WORKFLOW_QUALITY_KEYWORD_NAME);
    const metadataQuality = this.getUniqueClassifiedKeyword(Constants.METADATA_QUALITY_KEYWORD_NAME);

    if (quality) {
      this.quality = quality;
    }
    if (metadataQuality) {
      this.metadataQuality = metadataQuality;
    }

    return this;
  }
}
