<section id="data-detail" class="meta-detail d-flex flex-column h-100" *ngIf="data">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto">{{data.name}}</h1>
    <a [routerLink]="['./edit']" *ngIf="session.hasRight(data.projectId, objectTypeProject, 'editor')" class="btn btn-default">
      <i class="fa fa-edit"></i>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button class="btn btn-danger ml-3" (click)="deleteData()" *ngIf="session.hasRight(data.projectId, objectTypeProject, 'editor')">
      <i class="fa fa-trash-alt"></i>
      <ng-container i18n>Supprimer</ng-container>
    </button>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="subcontent">
      <dl class="mb-0 row">
        <div class="col-12 col-xl-8">
          <h2 i18n>Informations</h2>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <dt class="d-inline" i18n>Langue :</dt>&nbsp;
                <dd class="d-inline"><display-lang [displayLang]="data.language"></display-lang></dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Mots-clés :</dt>&nbsp;
                <dd class="d-inline">{{data.freeKeywords.join(', ')}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Thématiques :</dt>&nbsp;
                <dd class="d-inline">
                  <ng-container *ngFor="let thematic of data.thematics; let isLast=last">
                    {{thematic.label}}{{isLast ? '' : ', '}}
                  </ng-container>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Type :</dt>&nbsp;
                <dd class="d-inline">{{data.typeName}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Qualité de la méta-donnée :</dt>&nbsp;
                <dd class="d-inline">{{data.metadataQuality}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Étude :</dt>&nbsp;
                <dd class="d-inline">
                  <a [routerLink]="[projectPath, data.projectId]">{{project ? project.name : ''}}</a>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Dernière modification :</dt>&nbsp;
                <dd class="d-inline">{{data.lastUpdate | date: 'dd/MM/yyyy'}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Date de création :</dt>&nbsp;
                <dd class="d-inline">{{data.creationDate | date: 'dd/MM/yyyy'}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Date de révision :</dt>&nbsp;
                <dd class="d-inline">{{data.revisionDate | date: 'dd/MM/yyyy'}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Date de publication :</dt>&nbsp;
                <dd class="d-inline">{{data.publicationDate | date: 'dd/MM/yyyy'}}</dd>
              </div>
              <div class="mb-2" *ngIf="data.temporalExtentStart && data.temporalExtentEnd">
                <dt class="d-inline" i18n>Étendue temporelle :</dt>&nbsp;
                <dd class="d-inline">
                  <ng-container>Du</ng-container>
                  {{data.temporalExtentStart | date: 'dd/MM/yyyy'}}
                  <ng-container>au</ng-container>
                  {{data.temporalExtentEnd | date: 'dd/MM/yyyy'}}
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Type de résolution spatiale :</dt>&nbsp;
                <dd class="d-inline">{{data.spatialRepresentationType}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Résolution spatiale :</dt>&nbsp;
                <dd class="d-inline">{{data.spatialResolution}}</dd>
              </div>

              <ng-container *ngIf="data.files && data.files.length > 0">
                <dt class="accordion-title">
                  <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                    (click)="hideFiles = !hideFiles">
                    <span i18n>Liens de téléchargement</span>
                    <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideFiles}"></i>
                  </button>
                </dt>
                <dd class="nl2br accordion-content" [ngClass]="{closed: hideFiles}">
                  <a class="d-block" *ngFor="let file of data.files" [href]="'/back' + file.url" target="_blank">
                    {{file.name}}&nbsp;<i class="fa fa-external-link-alt"></i>
                  </a>
                </dd>
              </ng-container>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideDescription = !hideDescription">
                  <span i18n>Description</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{data.description}}</dd>
            </div>
            <div class="col-6">
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideContacts = !hideContacts">
                  <span i18n>Contacts</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContacts}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContacts}">
                <ul class="contacts">
                  <li *ngFor="let contact of data.contacts">
                    <em class="d-block">{{contactsRolesNames[contact.role]}}</em>
                    {{contact.individualName}} ({{contact.organisationName}})
                    <a *ngIf="contact.email" class="d-block" [href]="'mailto:' + contact.email">{{contact.email}}</a>
                  </li>
                </ul>
              </dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideComment = !hideComment">
                  <span i18n>Commentaire</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideComment}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideComment}">{{data.comment}}</dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideLineage = !hideLineage">
                  <span i18n>Traçabilité (origine)</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLineage}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLineage}">{{data.lineage}}</dd>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideLegals = !hideLegals">
                  <span i18n>Contraintes légales</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLegals}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLegals}">
                {{data.legalConstraints}}
              </dd>
            </div>
          </div>
          <hr class="my-3">
          <h2>Liens et services associés</h2>
          <div class="row">
            <ng-container *ngFor="let resource of data.onlineResources">
              <div class="col-6" *ngIf="resource.type !== FILE_RESOURCE_CODE">
                <dt>
                  <ng-container *ngIf="resource.name">{{resource.name}}</ng-container>
                  <ng-container *ngIf="!resource.name" i18n>(Sans nom)</ng-container>
                </dt>
                <dd>
                  <p>
                    <em class="d-block">{{resource.protocol}}</em>
                    <a class="d-block" [href]="resource.url" target="_blank">
                      {{resource.url}}
                    </a>
                    <span class="nl2br">{{resource.description}}</span>
                  </p>
                </dd>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <hr class="my-3 d-xl-none">
          <h2>Emprises</h2>
          <dt>
            <ng-container *ngIf="data.extentDescription">{{data.extentDescription}}</ng-container>
            <ng-container *ngIf="!data.extentDescription" i18n>(aucune description)</ng-container>
          </dt>
          <dd>
            <simple-map [extents]="data.extents"></simple-map>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</section>
