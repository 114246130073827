import { EntityMapper } from "./entity-mapper.model";
import { Registry } from "./registry.model";

export class ClassifiedKeyword extends EntityMapper {
  /**
   * Liste des mots-clés
   */
  keywords: string[] = [];

  /**
   * Liste des mots-clés provenant d'un registre (id + label)
   */
   keywordsWithLink: Registry[] = [];

  /**
   * (géré par le serveur) URI de la liste référençant la typologie
   */
  typeCode: string = null;

  /**
   * Type de mots-clés
   */
  typeCodeValue: string = "";

  constructor() {
    super();

    this._mapperDefs = [
      { front: "typeCode", back: "type.codeList" },
      { front: "typeCodeValue", back: "type.codeListValue" }
    ]
  }
}