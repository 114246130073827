import { EntityMapper } from "./entity-mapper.model";

export class AppConfig extends EntityMapper {
  /**
   * Titre global de l'application
   */
  title: string = "VigiRisks";

  /**
   * Logo à afficher
   */
  logoName: string = "logo-white.svg";
}