import * as _ from 'lodash';

import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { AppConfig } from "../models";

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _currentConfig: AppConfig;

  constructor(
    private _titleService: Title,
  ) { }

  //https://blog.bitsrc.io/dynamic-page-titles-in-angular-98ce20b5c334
  /**
   * Définit la configuration de l'application en fonction du host
   */
  public initAppConfig(): Observable<AppConfig> {
    let config = new AppConfig();
    switch (window.location.host) {
      case "rec-vigirisks.brgm-rec.fr":
        config.title = $localize`VigiRisks - Recette`;
        config.logoName = "logo-demo.png";
        break;
      case "dev-vigirisks.brgm-rec.fr":
        config.title = $localize`VigiRisks - Dévelopement`;
        config.logoName = "logo-demo.png";
        break;

      default:
        break;
    }

    this._titleService.setTitle(config.title);
    this._currentConfig = config;

    return of(this._currentConfig);
  }

  public getAppConfig(): AppConfig {
    return _.cloneDeep(this._currentConfig);
  }
}