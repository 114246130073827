<section id="project-edit" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1>
      <ng-container *ngIf="isNew" i18n>Créer une étude</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier l'étude</ng-container>
    </h1>
    <p class="alert alert-info" *ngIf="nonEditableInfos">
      <i class="fa fa-info-circle"></i>
      <ng-container i18n>Cette étude est actuellement modifiée par {{nonEditableInfos.userId}}</ng-container>
    </p>
  </div>
  <div class="page-content">
    <form *ngIf="project" class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group row no-gutters align-items-center">
            <label for="project-language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue</ng-container> *
            </label>
            <div class="col">
              <select id="project-language" class="form-control" name="project-language"
                      #languageField="ngModel" [(ngModel)]="project.language" [disabled]="nonEditableInfos" required>
                <option *ngFor="let language of languages" [ngValue]="language.value">
                  {{language.flag}}&nbsp;{{language.label}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="project-name" class="col-4 mr-3 mb-0">
              <ng-container i18n>Titre</ng-container> *
            </label>
            <div class="col">
              <input type="text" id="project-name" class="form-control" name="project-name" [(ngModel)]="project.name"
                #nameField="ngModel" [disabled]="nonEditableInfos" required>
              <div class="alert alert-danger" *ngIf="editForm.submitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-description" class="col-4 mr-3 mb-0 mt-1" i18n>Description</label>
            <div class="col">
              <textarea name="project-description" class="form-control" id="project-description"
                [(ngModel)]="project.description" #descField="ngModel" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="project-keywords" class="col-4 mr-3 mb-0">
              <ng-container i18n>Mots-clés</ng-container>
            </label>
            <div class="col">
              <p-autoComplete [(ngModel)]="project.keywords" [multiple]="true" inputId="project-keywords"
                              name="project-keywords" [suggestions]="registryKeywords" field="label" styleClass="form-control w-100"
                              [disabled]="nonEditableInfos" (completeMethod)="keywordsAutoComplete($event)">
              </p-autoComplete>
              <input type="text" id="project-keywords-old" class="form-control" name="project-keywords-old"
                [(ngModel)]="keywords" [disabled]="true">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-thematics" class="col-4 mr-3 mb-0 mt-2" i18n>Thématiques</label>
            <div class="col">
              <p-autoComplete [(ngModel)]="project.thematics" [multiple]="true" inputId="project-thematics"
                name="project-thematics" [suggestions]="categories" field="label" styleClass="form-control w-100"
                [disabled]="nonEditableInfos" (completeMethod)="categoriesAutoComplete($event)">
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="project-opale-keywords" class="col-4 mr-3 mb-0">
              <ng-container i18n>Projets Opale</ng-container>
              <small i18n>(séparés par une virgule)</small>
            </label>
            <div class="col">
              <input type="text" id="project-opale-keywords" class="form-control" name="project-opale-keywords"
                [(ngModel)]="opaleKeywords" [disabled]="nonEditableInfos">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="project-category" class="col-4 mr-3 mb-0">
              <ng-container i18n>Catégorie</ng-container> *
            </label>
            <div class="col">
              <select id="project-category" class="form-control" name="project-category" #projectCategoryField="ngModel"
                [(ngModel)]="project.category" [disabled]="nonEditableInfos" required>
                <option *ngFor="let category of projectCategories" [ngValue]="category">
                  {{category}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && projectCategoryField.invalid">
                <p *ngIf="projectCategoryField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-6">
          <fieldset class="form-group">
            <legend i18n>Emprises</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="project-extent-description" class="col-4 mb-0" i18n>Description</label>
              <div class="col">
                <p-autoComplete [(ngModel)]="chosenGeoname" id="project-extent-description"
                  name="project-extent-description" [suggestions]="geonames"
                  styleClass="form-control w-100" field="label" [disabled]="nonEditableInfos" (onSelect)="updateExtent()"
                  (onClear)="updateExtent()" (completeMethod)="searchGeonames($event)"></p-autoComplete>
              </div>
            </div>
            <div class="row no-gutters align-items-start">
              <label for="project-extent" class="col-4 mb-0 mt-1" i18n>Emprise</label>
              <div class="col">
                <described-extent name="project-extent" id="project-extent" [isEditable]="!nonEditableInfos"
                  [(ngModel)]="project.extents">
                </described-extent>
              </div>
            </div>
          </fieldset>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-comment" class="col-4 mr-3 mb-0 mt-1" i18n>Commentaire</label>
            <div class="col">
              <textarea name="project-comment" class="form-control" id="project-comment" [disabled]="nonEditableInfos"
                [(ngModel)]="project.comment"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-lineage" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Toute information utile permettant de préciser la création de la donnée et son cycle de vie"
                placement="top" container="body"></i>
              <ng-container i18n>Traçabilité (généalogie)</ng-container>
            </label>
            <div class="col">
              <textarea name="project-lineage" class="form-control" id="project-lineage" [disabled]="nonEditableInfos"
                [(ngModel)]="project.lineage"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="project-constraints" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Les contraintes d'utilisation sont définies en fonction de la communicabilité et de la réutilisation des données. Par défaut, les données du BRGM sont publiques et sous licence Etalab. La communicabilité peut être immédiate, décalée (préciser le délai), différée, ou réservée."
                placement="top" container="body"></i>
              <ng-container i18n>Contraintes d'utilisation</ng-container>
            </label>
            <div class="col">
              <textarea name="project-constraints" class="form-control" id="project-constraints"
                [disabled]="nonEditableInfos" [(ngModel)]="project.legalConstraints"></textarea>
            </div>
          </div>
        </div>

      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>Contacts</h2>
        <button type="button" class="btn btn-default ml-5" (click)="addContact()" *ngIf="!nonEditableInfos">
          <i class="fa fa-plus mr-2"></i>
          <ng-container i18n>Ajouter un contact</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let contact of project.contacts; let index = index">
          <div class="col-12 col-xl-6">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Contact</legend>
              <button type="button"
                *ngIf="!nonEditableInfos && (contact.role !== 'pointOfContact' || canDeletePointOfContact)"
                class="btn btn-close" (click)="removeContact(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-name-' + index" class="col-4 mb-0">
                  <ng-container i18n>Nom</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-name-' + index" class="form-control"
                    [name]="'contact-name-' + index" [(ngModel)]="contact.individualName" #contactNameField="ngModel"
                    [disabled]="nonEditableInfos" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactNameField.invalid">
                    <p *ngIf="contactNameField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-email-' + index" class="col-4 mb-0">
                  <ng-container i18n>Email</ng-container>
                </label>
                <div class="col">
                  <input type="email" [id]="'contact-email-' + index" class="form-control"
                    [name]="'contact-email-' + index" [(ngModel)]="contact.email" #contactEmailField="ngModel"
                    [disabled]="nonEditableInfos">
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-organisation-' + index" class="col-4 mb-0">
                  <ng-container i18n>Organisation</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-organisation-' + index" class="form-control"
                    [name]="'contact-organisation-' + index" [(ngModel)]="contact.organisationName"
                    #contactOrganisationField="ngModel" [disabled]="nonEditableInfos" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactOrganisationField.invalid">
                    <p *ngIf="contactOrganisationField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-center">
                <label [for]="'contact-role-' + index" class="col-4 mb-0">
                  <ng-container i18n>Rôle</ng-container> *
                </label>
                <div class="col">
                  <select [id]="'contact-role-' + index" class="form-control" [name]="'contact-role-' + index"
                    [(ngModel)]="contact.role" #contactRoleField="ngModel" (change)="checkPointsOfContacts()"
                    [disabled]="contact.role === 'pointOfContact' && !canDeletePointOfContact"
                    [disabled]="nonEditableInfos" required>
                    <option *ngFor="let role of contactTypes; trackBy: trackByValue" [ngValue]="role.value">
                      {{role.label}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactRoleField.invalid">
                    <p *ngIf="contactRoleField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>
      <div class="d-flex">
        <a [routerLink]="isNew ? ['../../'] : ['../']" class="btn btn-default" i18n>Annuler</a>
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="nonEditableInfos" i18n>Enregistrer</button>
      </div>
    </form>
  </div>
</section>
