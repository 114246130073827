export class Constants {

  public static OBJECT_TYPE_PROJECT = 'PROJECT';
  public static OBJECT_TYPE_WORKFLOW = 'WORKFLOW';
  public static OBJECT_TYPE_DATASET = 'DATASET';
  public static OBJECT_TYPE_LINK = 'LINK';

  public static linkTypes = [
    "Publication scientifique",
    "Rapport BRGM",
    "Présentation",
    "Autre publication",
    "Site web projet",
    "Site web code source",
    "Site web source de donnée externe",
    "Autre site web",
    "DMP"
  ];

  public static dataTypes = [
    "Donnée référentielle",
    "Grande donnée BRGM",
    "Donnée de projet Opale",
    "Donnée d'étude VigiRisks",
    "Donnée calculée par VigiRisks",
    "Donnée externe non référentielle"
  ];

  public static workflowTypes = [
    "Traitement",
    "Mono-aléa",
    "Mono-aléa et risque",
    "Multi-risque"
  ];

  public static executionTimes = [
    "Moins d'une minute",
    "Moins de 5 minutes",
    "Moins d'une heure",
    "Au-delà d'une heure"
  ];

  public static spatialRepresentationTypes = [
    "vector",
    "grid",
    "raster",
    "textTable",
    "tin"
  ];

  public static dataOnlineResourceProtocols = [
    "Lien bibliographique",
    "Site projet",
    "Service web cartographique (WMS, WFS, WCS)",
    "Service de téléchargement (FTP)",
    "Workflow Vigirisks",
    "Espace réseau",
    "Documentation"
  ];

  public static workflowOnlineResourceProtocols = [
    "Lien bibliographique",
    "Site projet",
    "Documentation",
    "Code"
  ];

  public static thematics = [
    "Après mine",
    "CO2",
    "Eau",
    "Géologie",
    "Géothermie",
    "Métrologie",
    "Ressources minérales",
    "Risques naturels",
    "Sols pollués et déchets",
    "Systèmes d'information"
  ];

  public static workflowQualities = [
    "Fait l'objet d'une publication scientifique",
    "Ne fait pas l'objet d'une publication scientifique"
  ];

  public static metadataQualities = [
    "Incomplet",
    "Complet",
    "Validé"
  ];

  public static projectCategories = [
    "Bac-à-sable",
    "En cours",
    "Livré",
    "Archivé"
  ];

  public static contactTypes = [
    {label: "À l'origine de", value: "originator"},
    {label: "Auteur", value: "author"},
    {label: "Distributeur", value: "distributor"},
    {label: "Éditeur (publication)", value: "publisher"},
    {label: "Exécutant", value: "processor"},
    {label: "Fournisseur", value: "resourceProvider"},
    {label: "Gestionnaire", value: "custodian"},
    {label: "Point de contact", value: "pointOfContact"},
    {label: "Point de recherche", value: "principalInvestigator"},
    {label: "Propriétaire", value: "owner"},
    {label: "Utilisateur", value: "user"}
  ];

  /**
   * Statuts :
   * Submitted => En cours
   * Success => Terminé et réussi
   * Saved => Terminé et enregistré
   * Failed => Terminé et en erreur
   */
  public static executionStatus = {
    submitted: 'Submitted',
    running: 'Running',
    completed: 'Succeeded',
    saved: 'Saved',
    failed: 'Failed',
    deleted: 'Deleted'
  };

  public static userRoles = {
    admin: 'vigirisks_admin',
    expert: 'vigirisks_expert',
    workflowManager: 'vigirisks_workflow_manager'
  };

  public static localeCalendarConfig = {
    en: {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    },
    fr: {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Jui", "Juil", "Aou", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Vider',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Wk'
    }
  }

  public static searchDestinations = [
    {label: "France métropolitaine", extent: [-4.965820, 42.261049, 8.305664, 51.645294]},
    {label: "Martinique", extent: [-61.2291951, 14.3886707, -60.8097044, 14.8787327]},
    {label: "Guadeloupe", extent: [-62.018039, 15.644411, -60.7919656, 16.714]},
    {label: "Guyane", extent: [-54.6026945, 2.1109401, -51.5693643, 5.978]},
    {label: "Mayotte", extent: [44.7436676, -13.2732554, 45.5070347, -12.379027]},
    {label: "Réunion", extent: [55.0032808, -21.5904458, 56.0507905, -20.672764]}
  ];

  public static languages = [
    { value: 'eng', label: 'English', flag:'🇬🇧'},
    { value: 'fre', label: 'Français', flag:'🇫🇷'}
  ];

  public static DATA_FILE_SIZE_LIMIT = 2000;
  public static EXECUTION_FILE_SIZE_LIMIT = 10;
  public static OPALE_KEYWORD_NAME = "projetopale";
  public static TYPOLOGIE_KEYWORD_NAME = "typologie";
  public static EXECUTION_TIME_KEYWORD_NAME = "executionTime";
  public static THEMATICS_KEYWORD_NAME = "thematic";
  public static CODE_VERSION_KEYWORD_NAME = "version";
  public static WORKFLOW_QUALITY_KEYWORD_NAME = "workflowQuality";
  public static METADATA_QUALITY_KEYWORD_NAME = "metadataQuality";
  public static PROJECT_CATEGORY_KEYWORD_NAME = "studyStatus";
  public static KEYWORD_KEYWORD_NAME = "keyword";
  public static FILE_RESOURCE_CODE = "download";
  public static FILE_RESOURCE_PROTOCOL = "WWW:LINK-1.0-http--link";

  public static DEFAULT_MAP_CONFIG = {
    "name": "defaultMap",
    "projection": "EPSG:4326",
    "projections": [
      {
        "name": "EPSG:4326",
        "label": "Longitude/latitude"
      }
    ],
    "startZoom": 7,
    "minZoom": null,
    "maxZoom": null,
    "startCenter": [2.4609, 46.6061],
    "startExtent": null,
    "backgroundLayers": [
      {
        "url": "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        "label": "Fond geographique",
        "defaultLayer": true,
        "params": {
          "LAYERS": "MONDE_MOD1_FR"
        }
      }
    ],
    "defaultLayers": [],
    "destinations": [],
    "controls": {
      "layerSwitcher": false,
      "legend": false,
      "srsSelector": false,
      "backgroundSelector": false,
      "goToDestination": false,
      "measureTools": false,
      "exportable": false,
      "territoriesDestinations": false
    }
  };

  public static DESCRIBED_EXTENT_MAP_CONFIG = {
    "name": "describedExtentMap",
    "projection": "EPSG:4326",
    "projections": [
      {
        "name": "EPSG:4326",
        "label": "Longitude/latitude"
      }
    ],
    "startZoom": 7,
    "minZoom": null,
    "maxZoom": null,
    "startCenter": [2.4609, 46.6061],
    "startExtent": null,
    "backgroundLayers": [
      {
        "url": "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        "label": "Fond geographique",
        "defaultLayer": true,
        "params": {
          "LAYERS": "MONDE_MOD1_FR"
        }
      },
      {
        "url": "http://mapsref.brgm.fr/wxs/cartocom/monde",
        "label": "Fond geo et villes",
        "defaultLayer": false,
        "params": {
          "LAYERS": "Monde",
          "FORMAT": "jpeg"
        }
      }
    ],
    "defaultLayers": [],
    "destinations": [],
    "controls": {
      "layerSwitcher": true,
      "legend": false,
      "srsSelector": false,
      "backgroundSelector": true,
      "goToDestination": true,
      "measureTools": false,
      "exportable": false,
      "territoriesDestinations": false
    }
  };

  public static REGISTRY_CATEGORY = 'categories';
  public static REGISTRY_KEYWORD = 'keywords';
}
