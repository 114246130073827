import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, Subject, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { transformExtent as transformExtent, transform as transform } from 'ol/proj';

/**
 * Metadata service /!\ à ne pas confondre avec les metadatas de geonetwork
 * Le terme "metadata" est ici utilisé pour définir les champs automatiquement gérés des boites de process d'un workflow
 */
@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor() { }

  /**
   * Convertit une liste de metadatas en paramètres d'exécution
   * @param metadatas - Liste des metadatas à convertir
   */
  public convertMetadataToParams(metadatas: any[]): any {
    let params = {};

    _.each(metadatas, metadata => {
      let metadataName = metadata.execParamName || metadata.name;
      switch (metadata.type) {
        case "bbox":
          let bboxParam = [
            metadata.value.minX,
            metadata.value.minY,
            metadata.value.maxX,
            metadata.value.maxY
          ];
          params[metadataName] = bboxParam.join(',');
          break;
        case "coord":
          params[metadataName] = metadata.value.x + "," + metadata.value.y;
          break;
        case "coord_mult":
          let coords = [];
          _.each(metadata.values, val => {
            coords.push(val.x + "," + val.y);
          });
          params[metadataName] = coords.join(';');
          break;
        default:
          params[metadataName] = metadata.value;
      }
    });

    return params;
  }

  /**
   * Applique les données de paramètres d'exécution dans les metadatas
   * @param params - paramètres d'exécutions
   * @param metadatas - metadatas à modifier
   */
  public convertParamsToMetadatas(params: any, metadatas: any[]) {
    _.each(_.keys(params), key => {
      if (params.hasOwnProperty(key)) {
        let metadata = _.find(metadatas, m => m.execParamName === key || m.name === key);
        if (metadata) {
          switch (metadata.type) {
            case "bbox":
              let bbox = params[key].split(',').map(Number);
              if (bbox.length === 4) {
                metadata.value.minX = bbox[0];
                metadata.value.minY = bbox[1];
                metadata.value.maxX = bbox[2];
                metadata.value.maxY = bbox[3];
              }
              break;
            case "coord":
              let coords = params[key].split(',').map(Number);
              if (coords.length === 2) {
                metadata.value.x = coords[0];
                metadata.value.y = coords[1];
              }
              break;
            case "coord_mult":
              let coordsMult = params[key].split(';');
              metadata.values = [];
              _.each(coordsMult, c => {
                let coords = c.split(',').map(Number);
                if (coords.length === 2) {
                  metadata.values.push({ x: coords[0], y: coords[1] });
                }
              });
              break;
            default:
              metadata.value = params[key];
              break;
          }
        }
      }
    });
  }

  public updateDependentFields(changedField, fields) {
    if (changedField.dependingFields) {
      _.each(changedField.dependingFields, dependingField => {
        let fieldToUpdate = _.find(fields, { name: dependingField.fieldName });
        if (fieldToUpdate) {
          if (dependingField.updateMethod === "hideOnValue") {
            this._updateFieldHidden(fieldToUpdate, changedField.value, dependingField.hiddenValueList);
          } else {
            if (this[dependingField.updateMethod]) {
              let params = this.generateParams(dependingField, fields);
              this[dependingField.updateMethod](fieldToUpdate, params);
            }
          }
        }
      });
    }
  }

  public clone(metadatas) {
    let clone;
    if (_.isArray(metadatas)) {
      clone = [];
      _.each(metadatas, metadata => {
        clone.push(this.clone(metadata));
      });
    } else if (_.isObject(metadatas)) {
      clone = {};
      _.each(_.keys(metadatas), key => {
        clone[key] = this.clone(metadatas[key]);
      });
    } else {
      clone = metadatas;
    }
    return clone;
  }

  private generateParams(fieldToUpdate, fields) {
    let obj = {};
    _.each(fieldToUpdate.params, param => {
      let paramField = _.find(fields, { name: param });
      if (paramField) {
        obj[param] = paramField.value;
      }
    });
    return obj;
  }

  // dynamics methods

  private _updateFieldHidden(fieldToUpdate: any, currentValue: any, matchList?: any[]) {
    if (!matchList) {
      return;
    }
    fieldToUpdate.hidden = (matchList.indexOf(currentValue) >= 0);
  }

  private toggleSrsCible(fieldToUpdate, params) {
    fieldToUpdate.readOnly = !params.hasProjection;
    fieldToUpdate.value = fieldToUpdate.readOnly ? 'none' : "EPSG:4326";
  }

  private toggleResolutionCible(fieldToUpdate, params) {
    fieldToUpdate.readOnly = !params.execInterpolation;
    if (fieldToUpdate.readOnly) {
      fieldToUpdate.baseMin = fieldToUpdate.min;
      fieldToUpdate.min = null;
    } else {
      fieldToUpdate.min = fieldToUpdate.baseMin || 1;
      delete fieldToUpdate.baseMin;
    }
    fieldToUpdate.value = fieldToUpdate.readOnly ? 0 : fieldToUpdate.min + 1;
  }

  private shakemapToggleField(fieldToUpdate, params) {
    let activationBool = false;
    if (params.mode_alea === 'exemple') {
      activationBool = true;
    }

    if (['shakemap_exemple', 'info_exemple'].indexOf(fieldToUpdate.name) >= 0) {
      fieldToUpdate.hidden = !activationBool;
      if (fieldToUpdate.type != 'info') {
        fieldToUpdate.execParam = activationBool;
      }
    }
    if (['uuid_geoazur', 'resolution_geoazur', 'info_geoazur'].indexOf(fieldToUpdate.name) >= 0) {
      fieldToUpdate.hidden = activationBool;
      if (fieldToUpdate.type != 'info') {
        fieldToUpdate.execParam = !activationBool;
      }
    }
  }
}
