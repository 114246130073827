import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RightPanelAnimation, DataService, ProjectService, SessionService, LoaderService, UtilsService } from 'src/app/services';
import { Project, Data } from 'src/app/models';
import { Constants } from 'src/app/constants';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';


@Component({
  templateUrl: './data-detail.component.html',
  animations: RightPanelAnimation
})
export class DataDetailComponent implements OnInit, OnDestroy {
  public FILE_RESOURCE_CODE = Constants.FILE_RESOURCE_CODE;

  /**
   * Type d'objet project
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Donnée affichée
   */
  public data: Data;

  /**
   * Étude à laquelle appartient la donnée
   */
  public project: Project;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Base d'url vers l'étude
   */
  public projectPath: string;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Est-on dans le contexte d'une étude ?
   */
  private _isInProject: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _projectService: ProjectService,
    public session: SessionService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _toastr: ToastrService,
    private _modalService: NgbModal,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._dataService.data$.subscribe(data => this._initData(data)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initDataFromParams(params)));
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprimme la donnée
   */
  public deleteData(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'une donnée`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette donnée : '${this.data.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._dataService.deleteData(this.data)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de donnée",
            properties: {
              category: this.data.name
            }
          });
          this._toastr.success($localize`La donnée '${this.data.name}' a été supprimée avec succès`);
          let returnRoute = '../../';
          if (this._isInProject) {
            returnRoute += '../../../';
          }
          this._router.navigate([returnRoute], { relativeTo: this._route });
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initDataFromParams(params: any) {
    this._loader.show();
    let dataId = params.datasetId;
    if (params.projectId) {
      this._isInProject = true;
    }
    this._dataService.getData(dataId);
  }

  /**
   * Affiche le lien reçu
   * @param data - Lien issu du serveur
   */
  private _initData(data: Data): void {
    this.data = data;
    this._projectService.getProject(data.projectId, false);
    this._generateContacts();
  }

  /**
   * Affiche l'étude du lien
   * @param project - Projet du lien, issu du serveur
   */
  private _initProject(project: Project): void {
    this.project = project;
    if (this.session.hasRight(this.project.id, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
      this.projectPath = '/my-projects';
    } else {
      this.projectPath = '/projects';
    }
    this._loader.hide();
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.data.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.data.contacts, c => c.role !== 'pointOfContact');
    this.data.contacts = firstContacts.concat(othersContacts);

    _.each(Constants.contactTypes, role => {
      this.contactsRolesNames[role.value] = role.label;
    });
  }

}
