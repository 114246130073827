<section id="user-search-admin" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Utilisateurs</h1>
    <button type="button" class="btn btn-primary" (click)="addUser()">
      <i class="fa fa-plus"></i>
      <ng-container i18n>Ajouter un utilisateur à Vigirisks</ng-container>
    </button>
  </div>
  <div class="page-content">
    <div class="row group-content">
      <div class="col-6">
        <h2 i18n>Rechercher un utilisateur</h2>
        <p-autoComplete class="w-100" [(ngModel)]="selectedUser" [suggestions]="emails" appendTo="body"
          inputStyleClass="form-control" styleClass="w-100" [forceSelection]="true"
          (completeMethod)="autocompleteUsers($event)" (onSelect)="loadGroups($event)">
        </p-autoComplete>
      </div>
      <div class="col-6" *ngIf="selectedUser">
        <h2 i18n>Groupes de l'utilisateur</h2>
        <button class="btn btn-primary btn-sm nowrap" (click)="addGroup()">
          <i class="fa fa-plus"></i>
          <ng-container i18n>Ajouter un groupe</ng-container>
        </button>
        <p class="alert-info p-3" *ngIf="groups.length === 0">
          <i class="fa fa-info-circle mr-1"></i>
          <ng-container i18n>Cet utilisateur n'appartient à aucun groupe.</ng-container>
        </p>
        <div>
          <ul class="user-list" *ngIf="groups.length > 0">
            <li class="d-flex align-items-center" *ngFor="let group of groups; trackBy: trackById">
              <span class="mr-3">{{group.name}}</span>
              <button type="button" class="btn btn-icon ml-auto" (click)="removeGroup(group)"><i>&times;</i></button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>