import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';

import Map from 'ol/Map';
import { transformExtent } from 'ol/proj';
import { MapService } from 'src/app/services';



@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  public searchText: string = "";
  public searchResults: any[] = [];

  private searchTimeout = null;
  private sub: Subscription = new Subscription();
  @Input() private map: Map;

  constructor(private mapService: MapService) { }

  public ngOnInit() {
    this.sub.add(this.mapService.geonames$.subscribe(result => this.searchResults = result));
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public goToDestination(destination) {
    if (this.map) {
      let view = this.map.getView();
      let extent = transformExtent(destination.extent, 'EPSG:4326', view.getProjection());

      view.fit(extent, { padding: [20, 20, 20, 20] });

      this.searchResults = [];
    }
  }

  public updateSearchList() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.mapService.getSearchList(this.searchText);
      this.searchTimeout = null;
    }, 500);
  }

  public resetSearch() {
    this.searchText = "";
    this.searchResults = [];
  }
}
