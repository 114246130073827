<form #groupForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': groupForm.submitted}"
  (ngSubmit)="save(groupForm)">
  <div class="modal-header">
    <h2 class="modal-title" i18n>Ajouter un utilisateur au groupe</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div class="d-flex align-items-center">
      <label for="group-choice" class="mb-0 mr-3 nowrap" i18n>Utilisateur</label>
      <select name="group-choice" id="group-choice" class="form-control" [(ngModel)]="selectedGroup" required>
        <option [ngValue]="null" i18n>-- Choisir un groupe --</option>
        <option *ngFor="let group of groups; trackBy: trackById" [ngValue]="group">{{group.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
  </div>
</form>