import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  RightPanelAnimation, WorkflowService, ProjectService, MetadataService,
  LoaderService, SessionService, UtilsService, ResourceService, DataService
} from 'src/app/services';
import { Workflow, Process, Execution, Permission, Data, Project, OnlineResource, Destination, FileToUpload } from 'src/app/models';
import { WorkflowMapModalComponent, WorkflowLinkageModalComponent, ExecutionNameModalComponent, ConfirmModalComponent, ResultsTableModalComponent } from '../../modals';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constants';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Angulartics2 } from 'angulartics2';

@Component({
  templateUrl: './workflow-detail.component.html',
  animations: RightPanelAnimation
})
export class WorkflowDetailComponent implements OnInit, OnDestroy {
  /**
   * Modals de confirmation -> TODO : à passer en composant
   */
  @ViewChild('alertProcessModal') alertProcessModal: TemplateRef<any>;

  /**
   * Est-on dans le contexte d'un projet ?
   */
  public isInProject: boolean = false;

  /**
   * Est-on éditeur de l'étude ?
   */
  public isProjectEditor: boolean = false;

  /**
   * Est-on propriétaire de l'étude ?
   */
  public isProjectOwner: boolean = false;

  /**
   * Est-on propriétaire du workflow ?
   */
  public isWorkflowOwner: boolean = false;

  /**
   * A-t-on le rôle d'intégrateur de workflow ?
   */
  public isWorkflowManager: boolean = false;

  /**
   * A-t-on le rôle d'admin ?
   */
  public isAdmin: boolean = false;

  /**
   * Id du projet courant
   */
  public currentProjectId: string;

  /**
   * Workflow à afficher
   */
  public workflow: Workflow;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Liste des contacts principaux
   */
  public mainContacts: string = "";

  /**
   * Process actuellement affiché
   */
  public selectedProcess: Process;

  /**
   * Copie des metadatas du process affiché
   */
  public selectedProcessMetadatas;

  /**
   * Données de résultat d'exécution
   */
  public data: any;

  /**
   * Y a-t-il des modifications de metadata non enregistrées ?
   */
  public metadataHasChanged: boolean = false;

  /**
   * Des process ont-ils été modifiés ?
   */
  public processHasChanged: boolean = false;

  /**
   * Exécution actuellement affichée
   */
  public currentExecution: Execution = null;

  /**
   * Y a-t-il une exécution en cours ?
   */
  public hasRunningExecution: boolean = false;

  /**
   * Liste des statuts
   */
  public executionStatus: any = Constants.executionStatus;

  /**
   * Le workflow a-t-il eu des exécutions ?
   */
  public hasExecutions: boolean = false;

  /**
   * Afficher les logs ?
   */
  public displayLogs: boolean = true;

  /**
   * Afficher le bouton de visualisation des résultats sur la carte ?
   */
  public displayMapButton: boolean = false;

  /**
   * Afficher le bouton de visualisation des résutlats en tableau ?
   */
  public displayTableButton: boolean = false;

  /**
   * Afficher les exécutions ?
   */
  public displayExecutions: boolean = false;

  /**
   * Afficher le lien vers la liste d'exécutions ?
   */
  public displayExecutionsLink: boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Contexte actuel de l'étude
   */
  private _currentProject: Project;

  constructor(
    private _session: SessionService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _workflowService: WorkflowService,
    private _projectService: ProjectService,
    private _dataService: DataService,
    private _resourceService: ResourceService,
    private _metadataService: MetadataService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _utils: UtilsService,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  ngOnInit() {
    this._loader.show();

    this._subs.add(this._workflowService.workflow$.subscribe(workflow => this._initWorkflow(workflow)));
    this._subs.add(this._workflowService.executionLogs$.subscribe(data => this._updateLogsAndStatus(data)));
    this._subs.add(this._workflowService.executionResults$.subscribe(data => this._updateResults(data)));
    this._subs.add(this._resourceService.editRights$.subscribe(newRights => this._updateNewRights(newRights)));
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initWorkflowFromParams(params)));

    this._dataService.getAllDatas(true);
    this.isWorkflowManager = this._session.hasRole(Constants.userRoles.workflowManager);
    this.isAdmin = this._session.hasRole(Constants.userRoles.admin);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Ouvre/ferme le panel de configuration du process
   * @param process - process à afficher
   */
  public openProcessConfig(process: Process) {
    if (this.selectedProcess && this.selectedProcess.id === process.id) {
      this.closeProcessConfig();
    } else {
      if (this.metadataHasChanged) {
        this._modalService.open(this.alertProcessModal, {
          backdrop: "static",
          size: "lg"
        }).result.then(result => {
          if (result != 'cancel') {
            if (result == 'save') {
              this.saveProcessMetadata(this.selectedProcessMetadatas);
            }
            this.metadataHasChanged = false;
            this.selectedProcess = process;
            this.selectedProcessMetadatas = _.cloneDeep(process.metadata);
          }
        });
      } else {
        this.selectedProcess = process;
        this.selectedProcessMetadatas = _.cloneDeep(process.metadata);
      }
    }
  }

  /**
   * Ferme le panel de configuration des process
   */
  closeProcessConfig() {
    this.metadataHasChanged = false;
    this.selectedProcess = null;
    this.selectedProcessMetadatas = null;
  }

  /**
   * Enregistre et ferme la configuration du process
   * @param metadatas - liste des metadatas du process à enregistrer
   */
  saveProcessMetadata(metadatas: any[]) {
    this.selectedProcess.metadata = _.cloneDeep(metadatas);
    if (this.selectedProcess.id === 1) {
      let srcMetadata = _.find(metadatas, { name: "resolutionInit" });
      if (srcMetadata) {
        let process = _.find(this.workflow.processes, { id: 18 });
        if (process) {
          let metadata = _.find(process.metadata, { name: "resolutionCible" });
          if (metadata) {
            if (metadata.readOnly) {
              metadata.baseMin = srcMetadata.value;
            } else {
              metadata.min = srcMetadata.value;
              if (metadata.value < metadata.min + 1) {
                metadata.value = metadata.min + 1;
              }
            }
          }
        }
      }
    }
    this.currentExecution.inputs = this._getExecuteParams();
    this.selectedProcess.edited = true;
    this.processHasChanged = true;
    this.closeProcessConfig();
  }

  /**
   * Ouvre la carte du workflow en affichant les résultats
   */
  public openResultsMap() {
    this._loader.show();
    this._workflowService.getResultsData(this.data.results, "display_map").subscribe(results => {
      this._loader.hide();
      let destinations: Destination[] = [];
      if (this.isInProject && this._currentProject.extents && this._currentProject.extents.length > 0) {
        destinations.push(new Destination().deserialize({
          label: $localize`Emprise de l'étude`,
          extent: this._currentProject.getExtent()
        }));
      }
      if (this.workflow.extents && this.workflow.extents.length > 0) {
        destinations.push(new Destination().deserialize({
          label: $localize`Emprise du workflow`,
          extent: this.workflow.getExtent()
        }));
      }

      const modalRef = this._modalService.open(WorkflowMapModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "map-modal" });
      modalRef.componentInstance.mapConfig = this.workflow.mapConfig;
      modalRef.componentInstance.options = {
        features: results.length > 0 ? results : null,
        destinations: destinations.length > 0 ? destinations : null,
        execId: this.currentExecution.id
      };
    });
  }

  /**
   * Ouvre la modal de récapitulatif des données de résultats
   */
  public openResultsTable() {
    this._loader.show();
    this._workflowService.getResultsData(this.data.results, "display_table").subscribe(results => {
      this._loader.hide();
      const modalRef = this._modalService.open(ResultsTableModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "confirm-modal results-table-modal" });
      modalRef.componentInstance.mapConfig = this.workflow.mapConfig;
      modalRef.componentInstance.results = results;
    });
  }

  /**
   * Exécute les process du workflow
   */
  public execWorkflow() {
    this._loader.show();
    this.currentExecution.inputs = this._getExecuteParams();
    this.hasRunningExecution = true;
    this.data = null;

    this.hasExecutions = true;

    // création des données s'il y a des uploads de fichiers
    let calls = [];
    const protocolDomain = "https://" + window.location.host;
    _.each(_.keys(this.currentExecution.inputs), k => {
      if (this.currentExecution.inputs[k] instanceof File) {
        let fileToUpload: FileToUpload = {
          label: this.currentExecution.inputs[k].name,
          file: this.currentExecution.inputs[k]
        }
        let datePipe = new DatePipe(this._utils.getLocaleFromNavigator());
        let newData = new Data();
        newData.name = this.currentExecution.inputs[k].name;
        newData.ownerName = this._session.currentUser.email;
        newData.ownerOrganisation = "BRGM";
        newData.projectId = this.currentProjectId;

        newData.addClassifiedKeywords(Constants.TYPOLOGIE_KEYWORD_NAME, ["Donnée d'étude VigiRisks"], true);
        newData.addClassifiedKeywords(Constants.METADATA_QUALITY_KEYWORD_NAME, ["Incomplet"], true);

        newData.comment = $localize`Donnée utilisée dans le cadre de l'étude '${this._currentProject.name}', pour l'exécution '${this.currentExecution.name}' du workflow '${this.workflow.name}', le ${datePipe.transform(new Date(), 'dd/MM/yyyy')} à ${datePipe.transform(new Date(), 'HH:mm')} par ${this._session.currentUser.email}`;

        calls.push(this._dataService.saveData(newData, [fileToUpload], true)
          .pipe(
            tap((data: Data) => {
              if (data.file.url.indexOf(protocolDomain) < 0) {
                this.currentExecution.inputs[k] = protocolDomain + '/back' + data.file.url;
              }
              this._tracker.eventTrack.next({
                action: "Ajout d'une donnée",
                properties: {
                  category: "Donnée:" + newData.name + "ajoutée lors de l'exécution (" + this.currentExecution.name + ") du workflow " + this.workflow.name
                }
              });
            }),
          ));
      } else if (this.currentExecution.inputs[k] instanceof Data && this.currentExecution.inputs[k].file) {
        let existentData: Data = this.currentExecution.inputs[k];
        // Si on ne trouve pas le domain dans l'url alors on l'ajoute
        if (existentData.file.url.indexOf(protocolDomain) < 0) {
          this.currentExecution.inputs[k] = protocolDomain + '/back' + existentData.file.url;
        }
      } else if (this.currentExecution.inputs[k].toString().indexOf("/api") > -1) {
        // Non typé Data, mais il s'agit bien d'une Data
        if (this.currentExecution.inputs[k].indexOf(protocolDomain) < 0) {
          this.currentExecution.inputs[k] = protocolDomain + '/back' + this.currentExecution.inputs[k];
        }
      }
    });

    let bulkCall = calls.length > 0 ? forkJoin(calls) : of(null);

    bulkCall
      .subscribe(() => {
        this._workflowService.executeWorkflow(this.currentExecution)
          .subscribe((newExecution: Execution) => {
            this._tracker.eventTrack.next({
              action: "Exécution de workflow",
              properties: {
                category: "Exécution: " + this.currentExecution.name + " créée pour le workflow " + this.workflow.name
              }
            });
            this.workflow.executions = _.filter(this.workflow.executions, { status: Constants.executionStatus.saved });
            this.workflow.executions.unshift(newExecution);
            this.currentExecution = newExecution;
            this._workflowService.getExecutionLogs(newExecution.id);
            this._loader.hide();
          }, error => console.error(error));
      }, () => {
        this._toastr.error($localize`Un ou plusieurs fichiers n'ont pas pu être correctement convertis en données.`);
      });

  }

  /**
   * Exécute unitairement un process
   * @param process - process à exécuter
   */
  public execProcess(process: Process) {
    console.info('execProcess');
    console.info(process.execUrl);
  }

  /**
   * Ajoute un des fichiers résultat au projet actuel
   * @param result - résultat à ajouter
   */
  public addResultToProject(result: any) {
    if (this._currentProject) {
      this._loader.show();
      let newData = new Data();
      newData.name = result.name + ' (' + this.currentExecution.name + ')';
      newData.ownerName = this._session.currentUser.email;
      newData.ownerOrganisation = "BRGM";
      newData.projectId = this.currentProjectId;
      newData.creationDate = this.currentExecution.executionDate;

      let datePipe = new DatePipe(this._utils.getLocaleFromNavigator());

      newData.lineage = $localize`Donnée produite dans le cadre de l'étude '${this._currentProject.name}'.\r\n\nRésultat de l'exécution '${this.currentExecution.name}' du workflow '${this.workflow.name}', exécutée le ${datePipe.transform(this.currentExecution.executionDate, 'dd/MM/yyyy')} à ${datePipe.transform(this.currentExecution.executionDate, 'HH:mm')} par ${this.currentExecution.userId}.\r\n\nAjouté à l'étude par ${this._session.currentUser.email} le ${datePipe.transform(new Date(), 'dd/MM/yyyy')} à ${datePipe.transform(new Date(), 'HH:mm')}`;

      newData.description = result.description;

      newData.addClassifiedKeywords(Constants.TYPOLOGIE_KEYWORD_NAME, ["Donnée calculée par VigiRisks"], true);
      newData.addClassifiedKeywords(Constants.METADATA_QUALITY_KEYWORD_NAME, ["Complet"], true);

      // Ajout du fichier comme données de l'étude
      // On récupère le nom mais aussi l'extension
      const fileName = result.url.split("/").pop();
      const fileToUpload: FileToUpload = {
        label: fileName,
        file: null
      };
      // Récupération du fichier pour l'ajouter sur la donnée
      this._dataService.getFile(result.url, fileName).subscribe((file: File) => {
        fileToUpload.file = file;
        this._dataService.saveData(newData, [fileToUpload], true)
          .subscribe((createdData: Data) => {
            this._tracker.eventTrack.next({
              action: "Ajout d'une donnée",
              properties: {
                category: "Donnée:" + newData.name + "ajoutée depuis un résultat du workflow " + this.workflow.name + ", exécution " + this.currentExecution.name
              }
            });
            this._toastr.success($localize`Le résultat '${result.name}' a été ajouté aux données de l'étude avec succès`);
            this._currentProject.datas.push(createdData);
            this._loader.hide();
          }, error => console.error(error));
        });
    }
  }

  /**
   * Ouvre la popin pour lier le workflow à une étude
   */
  public linkToProject() {
    const modalRef = this._modalService.open(WorkflowLinkageModalComponent, { windowClass: "confirm-modal workflow-linkage-modal" });
    modalRef.componentInstance.workflow = _.cloneDeep(this.workflow);

    modalRef.result.then(result => {
      if (result) {
        this._loader.show();
        result.subscribe(editedObj => {
          this._tracker.eventTrack.next({
            action: "Lier un worfklow à une étude",
            properties: {
              category: editedObj.name + ' - ' + this.workflow.name
            }
          });
          this.workflow.projectUris.push(editedObj.id);
          this._toastr.success($localize`Le workflow a été lié à l'étude '${editedObj.name}' avec succès.`);
          this._loader.hide();
        }, error => console.error(error))
      }
    }, () => null);
  }

  /**
   * Délie un workflow de l'étude
   */
  public unlinkOfProject() {
    if (!this.isInProject) {
      return;
    }
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Retirer le workflow de l'étude`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment retirer le workflow '${this.workflow.name}' de l'étude '${this._currentProject.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Retirer`;

    modalRef.result.then(() => {
      this._loader.show();
      this.workflow.projectUris.splice(this.workflow.projectUris.indexOf(this._currentProject.id), 1);
      this._workflowService.removeWorkflowRelation(this.workflow, this._currentProject)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Délier un worfklow d'une étude",
            properties: {
              category: this._currentProject.name + ' - ' + this.workflow.name
            }
          });
          this._toastr.success($localize`Le workflow '${this.workflow.name}' a été délié avec succès`);
          this._router.navigate(['/my-projects', this.currentProjectId]);
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Empêche de quitter la page si quelque chose n'est pas enregistré. Utilisé par need-save.guard
   */
  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.isInProject || !this.workflow) {
      return true;
    }

    let nonSavedExecution = _.find(this.workflow.executions, { status: Constants.executionStatus.completed });

    if (nonSavedExecution) {
      let subject: Subject<boolean> = new Subject<boolean>();

      const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal", size: "lg", backdrop: "static" });
      modalRef.componentInstance.title = $localize`Enregistrer votre exécution ?`;
      modalRef.componentInstance.message = $localize`Votre dernière exécution ne sera pas conservée et est susceptible de disparaître, souhaitez-vous l'enregistrer ?`;
      modalRef.componentInstance.confirmText = $localize`Enregistrer et quitter`;
      modalRef.componentInstance.cancelAndQuitText = $localize`Quitter sans enregistrer`;

      modalRef.result.then(result => {
        if (result === 'cancelAndQuit') {
          subject.next(true);
          subject.complete();
        } else if (result === 'confirm') {
          this._loader.show();
          this._workflowService.saveExecution(this.currentExecution.id)
            .subscribe(() => {
              this._toastr.success($localize`L'exécution a été enregistrée avec succès`);
              this._loader.hide();
              subject.next(true);
              subject.complete();
            }, error => {
              console.error(error);
              subject.next(false);
              subject.complete();
            });

        }
      }, () => {
        subject.next(false);
        subject.complete();
      });

      return subject.asObservable();
    } else {
      return true;
    }
  }

  /**
   * Ouvre la popup d'édition des droits
   */
  public openEditRightsModal() {
    this._resourceService.editResourceRights(this.workflow, Constants.OBJECT_TYPE_WORKFLOW);
  }

  /**
   * Modifie les valeurs des metadatas de process en fonction de l'exécution choisie et lance la récupération des logs
   */
  public applyExecutionInputs() {
    if (!this.currentExecution) {
      return;
    }
    let exectudedMetadatas = [];
    _.each(this.workflow.processes, process => {
      exectudedMetadatas = exectudedMetadatas.concat(_.filter(process.metadata, { execParam: true }));
    });
    this._metadataService.convertParamsToMetadatas(this.currentExecution.inputs, exectudedMetadatas);

    this.data = null;

    if (this.currentExecution.id) {
      this._workflowService.getExecutionLogs(this.currentExecution.id);
    }
  }

  /**
   * Demande la duplication d'une exécution
   */
  public addExecution() {
    const modalRef = this._modalService.open(ExecutionNameModalComponent, { windowClass: "confirm-modal", backdrop: "static" })
    modalRef.componentInstance.executionName = "";
    modalRef.componentInstance.title = $localize`Créer/dupliquer une exécution`;

    modalRef.result.then(formInfo => {
      if (formInfo.name) {
        let newExecution = new Execution();
        newExecution.name = formInfo.name;
        newExecution.draft = formInfo.draft;
        newExecution.projectId = this.currentProjectId;
        newExecution.workflowId = this.workflow.id;
        newExecution.userId = this._session.currentUser.email;
        newExecution.inputs = this._getExecuteParams();

        // On supprime tout autre nouvelle exécution
        let oldNewIndex = _.findIndex(this.workflow.executions, { id: null });
        this.workflow.executions.splice(oldNewIndex, 1);

        // On ajoute la nouvelle exécution
        this.workflow.executions.unshift(newExecution);
        this.currentExecution = newExecution;
        _.each(this.workflow.processes, process => {
          process.edited = false;
        });
        this.processHasChanged = false;
        this.applyExecutionInputs();
      }
    }, () => null);
  }

  /**
   * Demande la modification d'une exécution
   */
  public editExecution() {
    const modalRef = this._modalService.open(ExecutionNameModalComponent, { windowClass: "confirm-modal", backdrop: "static" })
    modalRef.componentInstance.executionName = this.currentExecution.name;
    modalRef.componentInstance.title = $localize`Modifier l'exécution ${this.currentExecution.name}`;

    modalRef.result.then(formInfo => {
      if (formInfo.name) {
        let oldName = this.currentExecution.name;
        let oldDraft = this.currentExecution.draft;
        this.currentExecution.name = formInfo.name;
        this.currentExecution.draft = formInfo.draft;
        if (this.currentExecution.id) {
          this._loader.show();
          this._workflowService.editExecution(this.currentExecution)
            .subscribe(() => {
              this._tracker.eventTrack.next({
                action: "Exécution modifiée",
                properties: {
                  category: "Workflow: " + this.workflow.name + ", Exécution: " + oldName + " => " + formInfo.name + " / " + oldDraft + " => " + formInfo.draft
                }
              });
              this._toastr.success($localize`L'exécution a été modifiée avec succès`);
              this._loader.hide();
            }, error => console.error(error));
        } else {
          this._toastr.success($localize`L'exécution a été modifiée avec succès`);
        }
      }
    }, () => null);
  }

  /**
   * Demande la sauvegarde de l'exécution
   */
  public saveExecution() {
    this._loader.show();
    this._workflowService.saveExecution(this.currentExecution.id)
      .subscribe(() => {
        this._tracker.eventTrack.next({
          action: "Enregistrement d'exécution",
          properties: {
            category: "Workflow: " + this.workflow.name + ", Exécution: " + this.currentExecution.name
          }
        });
        this.currentExecution.status = Constants.executionStatus.saved;
        this._toastr.success($localize`L'exécution a été enregistrée avec succès`);
        this._loader.hide();
      }, error => console.error(error));
  }

  /**
   * Demande la suppression de l'exécution
   */
  public deleteExecution() {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'une exécution`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette exécution : ${this.currentExecution.name} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._workflowService.deleteExecution(this.currentExecution.id)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression d'exécution",
            properties: {
              category: "Workflow: " + this.workflow.name + ", Exécution: " + this.currentExecution.name
            }
          });
          this
          let index = _.findIndex(this.workflow.executions, this.currentExecution);
          if (index >= 0) {
            this.workflow.executions.splice(index, 1);
            if (this.workflow.executions.length === 0) {
              let newExecution = new Execution();
              newExecution.name = "Nouvelle exécution";
              newExecution.projectId = this.currentProjectId;
              newExecution.workflowId = this.workflow.id;
              newExecution.inputs = this._getExecuteParams();
              this.workflow.executions.unshift(newExecution);
            }
            this.currentExecution = this.workflow.executions[0];
            this.applyExecutionInputs();
          }
          this._toastr.success($localize`L'exécution a été enregistrée avec succès`);
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Supprimme le workflow
   */
  public deleteWorkflow(): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un workflow`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce workflow : '${this.workflow.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._workflowService.deleteWorkflow(this.workflow)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de workflow",
            properties: {
              category: this.workflow.name
            }
          });
          this._toastr.success($localize`Le workflow '${this.workflow.name}' a été supprimé avec succès`);
          this._router.navigate(['../../'], { relativeTo: this._route });
          this._loader.hide();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Met à jour les logs et le statut d'exécution
   * @param result - résultat du serveur d'exécution
   */
  private _updateLogsAndStatus(result: { execId: string, status: string, logs: string }) {
    if (result.execId === this.currentExecution.id) {
      if (!this.data) {
        this.data = {};
      }
      if (this.currentExecution.status !== Constants.executionStatus.saved) {
        this.currentExecution.status = result.status;
      }
      this.data.logs = result.logs || "";
      this.data.error = "";
      switch (result.status) {
        case Constants.executionStatus.submitted:
        case Constants.executionStatus.running:
          setTimeout(
            () => this._workflowService.getExecutionLogs(this.currentExecution.id),
            1000
          );
          break;
        case Constants.executionStatus.completed:
          this.hasRunningExecution = false;
        case Constants.executionStatus.saved:
          this._workflowService.getExecutionResults(this.currentExecution.id);
          break;
        case Constants.executionStatus.failed:
          this.hasRunningExecution = false;
          this.data.error = $localize`Le serveur a rencontré une erreur, votre requête n'a pas pu être exécutée.`;
          break;
      }
    }
  }

  /**
   * Met à jour la liste des résultats
   * @param result 
   */
  private _updateResults(result: { execId: string, results: any[] }) {
    if (result.execId === this.currentExecution.id) {
      this.data.results = result.results;
      _.each(this.data.results, r => {
        if (_.find(r.functions, { action: 'display_map' })) {
          this.displayMapButton = true;
        }
        if (_.find(r.functions, { action: 'display_table' })) {
          this.displayTableButton = true;
        }
      });
    }
  }

  /**
   * Initialise le workflow
   * @param workflow - workflow reçu du serveur
   */
  private _initWorkflow(workflow: Workflow) {
    this.workflow = workflow;
    this._generateContacts();
    this.displayExecutionsLink = this._session.hasRight(this.workflow.id, Constants.OBJECT_TYPE_WORKFLOW, 'owner') || this._session.hasRole(Constants.userRoles.admin);


    if (this.isInProject) {
      let initParams = this._session.getInitPageParams();
      let execId = initParams && initParams.executionId ? initParams.executionId : null;
      if (this.workflow.executions.length === 0 && this._session.hasRight(this.currentProjectId, Constants.OBJECT_TYPE_PROJECT, 'editor')) {
        let newExecution = new Execution();
        newExecution.name = "Nouvelle exécution";
        newExecution.projectId = this.currentProjectId;
        newExecution.workflowId = this.workflow.id;
        newExecution.userId = this._session.currentUser.email;
        newExecution.inputs = this._getExecuteParams();
        this.workflow.executions.unshift(newExecution);
      }

      this.currentExecution = _.find(this.workflow.executions, { id: execId }) || this.workflow.executions[0];
      this._checkRunningExecutions();
      this._projectService.getProject(this.currentProjectId);
    } else {
      this._loader.hide();
    }

  }

  /**
   * Initialise le projet parent
   * @param project - Projet reçu du serveur
   */
  private _initProject(project: Project) {
    this._currentProject = project;
    this.applyExecutionInputs();
    this._loader.hide();
  }

  /**
   * Demande la récupération du workflow à partir des paramètres d'url
   * @param params - paramètres d'url
   */
  private _initWorkflowFromParams(params: any) {
    let workflowId = params.workflowId;
    this.isWorkflowOwner = this._session.hasRight(workflowId, Constants.OBJECT_TYPE_WORKFLOW, 'owner');

    let projectId = null;
    if (params.projectId) {
      projectId = params.projectId;
      this.isInProject = true;
      this.displayExecutions = true;
      this._workflowService.getWorkflow(workflowId, projectId, true);
      this._session.saveWorkflowContext(projectId, workflowId);
      this.currentProjectId = projectId;
      this.isProjectEditor = this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'editor');
      this.isProjectOwner = this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'owner');
    } else {
      this._workflowService.getWorkflow(workflowId, null, true);
    }
  }

  /**
   * Met à jour les permissions après leur modification
   * @param newRights - nouvelles permissions
   */
  private _updateNewRights(newRights: { individualPermissions: Permission[], groupPermissions: Permission[] }) {
    if (newRights) {
      this.workflow.individualPermissions = newRights.individualPermissions;
      this.workflow.groupPermissions = newRights.groupPermissions;
      this.workflow.generateOwners();

      if (!this._session.hasRight(this.workflow.id, Constants.OBJECT_TYPE_WORKFLOW, 'owner') && !this._session.hasRole(Constants.userRoles.admin)) {
        this._router.navigate(['my-workflows']);
      }
    }
  }

  /**
   * Formate les données des process en paramètres d'exécution
   */
  private _getExecuteParams(): any {
    let exectudedMetadatas = [];
    _.each(this.workflow.processes, process => {
      exectudedMetadatas = exectudedMetadatas.concat(_.filter(process.metadata, { execParam: true }));
    });
    return this._metadataService.convertMetadataToParams(exectudedMetadatas);
  }

  /**
   * Vérifie s'il y a des exécutions en cours
   */
  private _checkRunningExecutions() {
    this.hasRunningExecution = _.find(this.workflow.executions, e => e.status === Constants.executionStatus.running || e.status === Constants.executionStatus.submitted);
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.workflow.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.workflow.contacts, c => c.role !== "pointOfContact");
    this.workflow.contacts = firstContacts.concat(othersContacts);

    let mainContacts = [];
    _.each(firstContacts, contact => {
      mainContacts.push(contact.individualName + ' (' + contact.organisationName + ')');
    });
    this.mainContacts = mainContacts.join(', ');

    _.each(Constants.contactTypes, role => {
      this.contactsRolesNames[role.value] = role.label;
    });
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(item) {
    return item.id;
  }

}
