import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();

  public visible: boolean = false;
  public menuCollapsed: boolean = false;

  constructor(private loaderService: LoaderService) {}

  public ngOnInit() {
    this.sub.add(this.loaderService.loader$.subscribe(visible => this.visible = visible));
    this.sub.add(this.loaderService.menuWidthChange$.subscribe(menuCollapsed => this.menuCollapsed = menuCollapsed));
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
