import * as _ from 'lodash';

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Uris } from "../constants";
import { User, Permission, SearchData, Group } from '../models';

/**
 * Service contenant les données conservées en session
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  /**
   * Étude dans laquelle on navigue
   */
  private _currentProjectId: string = null;

  /**
   * Workflow actuellement affiché
   */
  private _currentWorkflowId: string = null;

  /**
   * Cache des paramètres de carte par étude, workflow et type de carte
   * Exemple : { "uri_project": { "map_name": { "extent": [-4.264, 0.32, 2.17958, 6.3394] } } }
   * Exemple 2 : { "uri_project": { "uri_workflow": { "map_name": { "extent": [-4.264, 0.32, 2.17958, 6.3394] } } } }
   */
  private _maps: any = {};

  /**
   * Paramètres à utiliser à l'initialisation d'une page, utilisés lors des retours d'édition
   */
  private _initPageParams: any = null;

  /**
   * Paramètres de la dernière recherche effectuée
   */
  public searchData: SearchData = null;

  /**
   * Source d'événement pour la récupération de l'utilisateur connecté
   */
  private _currentUserSource = new Subject<User>();

  /**
   * Observable d'événement pour la récupération de l'utilisateur connecté
   */
  public currentUser$ = this._currentUserSource.asObservable();

  /**
   * Utilisateur connecté
   */
  public currentUser: User = null;

  /**
   * Liste numérotée des permissions pour gérer la hiérarchie verticale
   */
  private _permissions: any;

  constructor(
    private _http: HttpClient
  ) {
    this._permissions = {
      owner: 1,
      editor: 2,
      readonly: 3
    };
  }

  // ===============================================
  // ============= Utilisateur actuel ==============
  // ===============================================

  /**
   * Observable de récupération de l'utilisateur connecté
   */
  public getCurrentUserObs(): Observable<User> {
    return this._http.get<User>(Uris.USERS + 'current')
      .pipe(
        switchMap(user => {
          return this._http.get<any[]>(Uris.USERS + 'current/permissions')
            .pipe(
              map(permissions => {
                user.permissions = permissions.map(p => {
                  p.userId = user.email;
                  p.permissionCode = p.code; //correction de coquille dans le retour de l'API
                  return p;
                });
                return user;
              })
            )
        }),
        map(user => new User().deserialize(user)),
        switchMap((user: User) => {
          return this._http.get<Group[]>(Uris.USERS + user.id + '/groups')
            .pipe(
              map(groups => {
                user.groups = groups.map(g => new Group().deserialize(g));
                return user;
              })
            );
        })
      );
  }

  /**
   * Récupère l'utilisateur connecté et le renvoie à l'Observable currentUser$
   */
  public getCurrentUser(): void {
    this.getCurrentUserObs()
      .subscribe(user => {
        this.currentUser = user;
        this._currentUserSource.next(user);
      });
  }

  /**
   * Appel de récupération des permissions à mettre dans les process de création/sauvegarde (switchmap)
   * @param responseToPass - réponse originelle à transmettre
   */
  public getUserPermissionsObs(responseToPass: any): Observable<any> {
    return this._http.get<any[]>(Uris.USERS + 'current/permissions')
      .pipe(
        map(permissions => {
          this.currentUser.permissions = permissions.map(p => {
            p.userId = this.currentUser.email;
            p.permissionCode = p.code; //correction de coquille dans le retour de l'API
            return new Permission().deserialize(p);
          });;
          return responseToPass;
        })
      );
  }

  /**
   * Redirige vers la déconnexion de l'utilisateur
   */
  public logout(): void {
    window.location.href = window.location.origin + Uris.LOGOUT;
  }

  /**
   * Vérifie si l'utilisateur actuel a un droit donné sur l'élément
   * @param objectId - Id unique de l'élément
   * @param objectType - Type de l'élément
   * @param right - Droit à tester
   */
  public hasRight(objectId: string, objectType: string, right: string): boolean {
    if (!this.currentUser) {
      return false;
    }
    let askedPriority = this._permissions[right];
    if (!askedPriority) {
      return false;
    }
    let item = _.find(this.currentUser.permissions, p => p.objectType.indexOf(objectType) >= 0 && p.objectId == objectId && this._permissions[p.code] <= askedPriority);
    return item ? true : false;
  }

  /**
   * Vérifie si l'utilisateur actuel a le rôle donné
   * @param role - Role à tester
   */
  public hasRole(role: string): boolean {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.roles.indexOf(role) >= 0;
  }

  // ===============================================
  // ================== Contexte ===================
  // ===============================================

  /**
   * Met en cache l'id de l'étude actuel et supprimer celui du workflow actuel
   * @param projectId - Uri unique de l'étude actuel
   */
  public saveProjectContext(projectId: string): void {
    this._currentProjectId = projectId;
    this._currentWorkflowId = null;
  }

  /**
   * Met en cache les ids de l'étude et du workflow actuels
   * @param projectId - Uri unique de l'étude actuel
   * @param workflowId - Uri unique du workflow actuel
   */
  public saveWorkflowContext(projectId: string, workflowId: string): void {
    this._currentProjectId = projectId;
    this._currentWorkflowId = workflowId;
  }

  /**
   * Renvoie les IDs présents en contexte
   */
  public getWorkflowContext(): any {
    return {
      projectId: this._currentProjectId,
      workflowId: this._currentWorkflowId
    };
  }

  /**
   * Renvoie les paramètres de carte enregistrés dans le cache
   * @param mapNameOrExecId - Nom du type de configuration de carte à récupérer ou ID de l'exécution
   */
  public getMapContext(mapNameOrExecId: string): any {
    if (!mapNameOrExecId || !this._currentProjectId) {
      return null;
    }
    if (
      this._maps[this._currentProjectId]
    ) {
      if (
        this._currentWorkflowId !== null &&
        this._maps[this._currentProjectId][this._currentWorkflowId] &&
        this._maps[this._currentProjectId][this._currentWorkflowId][mapNameOrExecId]
      ) {
        return this._maps[this._currentProjectId][this._currentWorkflowId][mapNameOrExecId];
      } else if (this._currentWorkflowId === null && this._maps[this._currentProjectId][mapNameOrExecId]) {
        return this._maps[this._currentProjectId][mapNameOrExecId];
      }
    }
    return null;
  }

  /**
   * Met en cache les paramètres de carte
   * @param mapName - Nom du type de configuration de carte à mettre en cache
   * @param context - Json de configuration à mettre en cache
   * @param execId - (optionnel) ID d'exécution
   */
  public saveMapContext(mapName: string, context: any = null, execId: string = null) {
    if (!mapName || !this._currentProjectId) {
      return;
    }
    this._constructContextIfNecessary(this._currentProjectId, this._currentWorkflowId);
    if (this._currentWorkflowId !== null) {
      if(execId) {
        this._maps[this._currentProjectId][this._currentWorkflowId][execId] = context;
      } else {
        this._maps[this._currentProjectId][this._currentWorkflowId][mapName] = context;
      }
    } else {
      this._maps[this._currentProjectId][mapName] = context;
    }
  }

  /**
   * Définit des paramètres d'initialisation à conserver
   * @param params - Paramètres à enregistrer
   */
  public setInitPageParams(params: any) {
    this._initPageParams = params;
  }

  /**
   * Renvoie les paramètres d'initialisation conservés et les supprime du cache
   */
  public getInitPageParams(): any {
    let params = this._initPageParams;
    this._initPageParams = null;
    return params;
  }

  /**
   * Construit le cache en fonction des données
   * @param mapName - Nom du type de configuration de carte à mettre en cache
   * @param projectId - Uri unique de l'étude actuel
   * @param workflowId - Uri unique du workflow actuel
   */
  private _constructContextIfNecessary(projectId: string, workflowId: string): void {
    if (!this._maps[projectId]) {
      this._maps[projectId] = {};
    }
    if (workflowId !== null && !this._maps[projectId][workflowId]) {
      this._maps[projectId][workflowId] = {};
    }
  }

}