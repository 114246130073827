import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapConfig } from 'src/app/models';

@Component({
  templateUrl: './workflow-map-modal.component.html'
})
export class WorkflowMapModalComponent implements OnInit {
  @Input() mapConfig: MapConfig;
  @Input() options: any;
  
  constructor(
    public modal: NgbActiveModal
  ) {}


  ngOnInit() {

  }
}
