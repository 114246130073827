/** olGeoTiff class
https://github.com/santilland/plotty

https://eox.at/2018/01/visualizing-geotiff-tiles-with-openlayers/
https://github.com/geotiffjs/geotiff.js/issues/79
https://github.com/stuartmatthews/leaflet-geotiff
https://github.com/IHCantabria/Leaflet.CanvasLayer.Field

https://gis.stackexchange.com/questions/293191/openlayers-interpolating-a-raster-source

WCS et OL :
https://github.com/openlayers/openlayers/issues/8965#issuecomment-441061646
TODO : 
- query
- gerer une autre projection que du 4326
- dessiner des fleches pour les directions

**/

import * as plotty from 'plotty';

declare const require;

let GeoTIFF = require('geotiff');
import ImageStatic from 'ol/source/ImageStatic';


/**
 * base class for openlayers geotiff support
 * @param {*} map  OL Map
 * @param {*} layer OL Image layer
 * @param {*} plotOptions 
*/
export class OlGeoTiff {
  layer: any;
  map: any;
  plotOptions: any;
  plot: any;
  rasterData: any;
  imageInitial: any;
  currentLimits: any;
  rasterBoundsSouthWestLong: any;
  latSpan: number;
  lngSpan: number;
  rasterBoundsSouthWestLat: any;
  rasterBoundsNorthEastLong: any;
  rasterBoundsNorthEastLat: any;
  rasterHeight: number;
  rasterWidth: number;
  northWest: any[];
  southEast: any[];



  constructor(map, layer, plotOptions) {
    // layer of the OL map that holds the tiff image
    this.layer = layer;
    this.map = map;

    // options object for plotty plot
    this.plotOptions = plotOptions;

    // plotty instance for this layer
    this.plot = new plotty.plot({
      clampLow: this.plotOptions.clampLow,
      clampHigh: this.plotOptions.clampHigh,
      useWebGL: false
    });
    
    this.map.on("moveend", () => this.drawImage());
  }

  /**
   * Update the raster layer with new raster data
   * @param {TypedArray} data raster array (flot32)
   */
  updateRasterData(data) {
    this.rasterData = data;
    this.drawImage();
  }

  drawImage() {
    if (this.imageInitial) {
      var northWestPixel = this.map.getPixelFromCoordinate(this.northWest);
      if (northWestPixel == null) return; //map not fully load

      // taille de la div qui contient la map en pixel
      var mapSize = this.map.getSize();
      var size = {
        'x': mapSize[0], 'y': mapSize[1]
      }

      var southEastPixel = this.map.getPixelFromCoordinate(this.southEast);
      var rasterPixelBoundsMinX = Math.min(northWestPixel[0], southEastPixel[0]);
      var rasterPixelBoundsMinY = Math.min(northWestPixel[1], southEastPixel[1]);
      var rasterPixelBoundsMaxX = Math.max(northWestPixel[0], southEastPixel[0]);
      var rasterPixelBoundsMaxY = Math.max(northWestPixel[1], southEastPixel[1]);

      var xStart = (rasterPixelBoundsMinX > 0 ? rasterPixelBoundsMinX : 0);
      var yStart = (rasterPixelBoundsMinY > 0 ? rasterPixelBoundsMinY : 0);

      var xFinish = (rasterPixelBoundsMaxX < size.x ? rasterPixelBoundsMaxX : size.x);
      var yFinish = (rasterPixelBoundsMaxY < size.y ? rasterPixelBoundsMaxY : size.y);

      // my geotiff size in pixel that will be display on the map
      var plotWidth = Math.round(xFinish - xStart);
      var plotHeight = Math.round(yFinish - yStart);

      // when geotiff is not on the map
      if ((plotWidth <= 0) || (plotHeight <= 0)) {
        var plotCanvas = document.createElement("canvas");
        plotCanvas.width = size.x;
        plotCanvas.height = size.y;
        var ctx = plotCanvas.getContext("2d");
        ctx.clearRect(0, 0, plotCanvas.width, plotCanvas.height);
        this.setLayerSource(plotCanvas);
        this.currentLimits = null;
        return;
      }

      //Draw image data to canvas and pass to image element
      var plotCanvas = document.createElement("canvas");
      plotCanvas.width = size.x;
      plotCanvas.height = size.y;
      var ctx = plotCanvas.getContext("2d");
      ctx.clearRect(0, 0, plotCanvas.width, plotCanvas.height);

      // set plot values
      var plot = this.plot;
      plot.setDomain(this.plotOptions.domain);

      plot.setData(
        this.rasterData,
        this.rasterWidth,
        this.rasterHeight
      );
      if (this.plotOptions.palette) {
        plot.setColorScale(this.plotOptions.palette);
      }
      if (this.plotOptions.noDataValue !== false) {
        plot.setNoDataValue(this.plotOptions.noDataValue);
      }
      plot.setClamp(this.plotOptions.clampLow, this.plotOptions.clampHigh);
      plot.render();

      plot.colorScaleCanvas.toDataURL();
      var plottyCanvas = plot.getCanvas();

      let rasterImageData = plottyCanvas.getContext("2d").getImageData(0, 0, plottyCanvas.width, plottyCanvas.height);

      //Create image data and Uint32 views of data to speed up copying
      var imageData = new ImageData(plotWidth, plotHeight);
      var outData = imageData.data;
      var outPixelsU32 = new Uint32Array(outData.buffer);
      var inData = rasterImageData.data;
      var inPixelsU32 = new Uint32Array(inData.buffer);

      let currentData = [];
      // hauteur et largeur du raster geotiff en cours sur la carte, plus on zoom plus il augmente
      for (var y = 0; y < plotHeight; y++) {
        for (var x = 0; x < plotWidth; x++) {
          //Calculate lat-lng of (x,y)
          var currentCoordinates = this.map.getCoordinateFromPixel([x + xStart, y + yStart]);

          var rasterX = Math.floor((currentCoordinates[0] - this.rasterBoundsSouthWestLong) / this.lngSpan);
          var rasterY = this.rasterHeight - Math.ceil((currentCoordinates[1] - this.rasterBoundsSouthWestLong) / this.latSpan);

          //Location to draw to
          var index = y * plotWidth + x;
          var rasterIndex = rasterY * this.rasterWidth + rasterX;

          outPixelsU32[index] = inPixelsU32[rasterIndex];
          currentData.push(this.rasterData[rasterIndex]);
        }
      }
      ctx.putImageData(imageData, xStart, yStart);

      this.currentLimits = this.getMinMax(currentData);

      this.setLayerSource(plotCanvas);
    }
  }

  /**
   * https://stackoverflow.com/questions/1669190/find-the-min-max-element-of-an-array-in-javascript
   * @param {Array} data 
   */
  getMinMax(data) {
    let nb = data.length;

    // find the second biggest value (the frist one is supposed to be the nodata)
    // https://stackoverflow.com/a/17040125
    let biggest = -Infinity;
    let nextBiggest = -Infinity;
    let min = data[0];

    for (var i = 0, n = nb; i < n; ++i) {
      if (data[i] < min) {
        min = data[i];
      }

      var nr = +data[i]; // convert to number first

      if (nr > biggest) {
        nextBiggest = biggest; // save previous biggest value
        biggest = nr;
      } else if (nr < biggest && nr > nextBiggest) {
        nextBiggest = nr; // new second biggest value
      }
    }

    return { min: min, max: nextBiggest }
  }

  /**
   * fetch tiff and set callbacks
   * @param {*} url url of the geotiff file
   */
  async fetchTiff(url) {
    let response = await fetch(url, { mode: 'cors' }); //, { credentials: 'include', mode: 'cors' }
    let buffer = await response.arrayBuffer();
    const tiff = await GeoTIFF.fromArrayBuffer(buffer);
    const image = await tiff.getImage(); // by default, the first image is read.

    this.imageInitial = image;
    var rasterBounds = image.getBoundingBox();
    this.rasterWidth = image.getWidth();
    this.rasterHeight = image.getHeight();

    const rasters = await image.readRasters({ window: [0, 0, this.rasterWidth, this.rasterHeight], samples: [0] });

    this.rasterBoundsSouthWestLong = rasterBounds[0];//xmin
    this.rasterBoundsSouthWestLat = rasterBounds[1]; // ymin
    this.rasterBoundsNorthEastLong = rasterBounds[2]; // xmax
    this.rasterBoundsNorthEastLat = rasterBounds[3]; //ymax

    this.lngSpan = (this.rasterBoundsNorthEastLong - this.rasterBoundsSouthWestLong) / this.rasterWidth;
    this.latSpan = (this.rasterBoundsNorthEastLat - this.rasterBoundsSouthWestLat) / this.rasterHeight;
    this.northWest = [rasterBounds[0], rasterBounds[3]]; // xmin, ymax
    this.southEast = [rasterBounds[2], rasterBounds[1]]; //xmax ymin

    return rasters[0];
  }

  setLayerSource(canvas) {
    var mapExtent = this.map.getView().calculateExtent(this.map.getSize());

    //can't update directly the URL: https://github.com/openlayers/openlayers/issues/4300
    var staticSource = new ImageStatic({
      url: canvas.toDataURL("image/png"),
      imageExtent: mapExtent
    })
    this.layer.setSource(staticSource);
  }


  /**
   * Get raster value at a latitude/longitude
   * TODO a tester
   * ou cette methode http://santilland.github.io/plotty/module-plotty.plot.html#atPoint__anchor
   * @param {*} lat 
   * @param {*} lng 
   */
  getValueAtLatLng(lat, lng) {
    try {
      var x = Math.floor(this.rasterWidth * (lng - this.rasterBoundsSouthWestLong) / (this.rasterBoundsNorthEastLong - this.rasterBoundsSouthWestLong));
      var y = this.rasterHeight - Math.ceil(this.rasterHeight * (lat - this.rasterBoundsSouthWestLat) / (this.rasterBoundsNorthEastLat - this.rasterBoundsSouthWestLat));
      var i = y * this.rasterWidth + x;
      return this.rasterData[i];
    }
    catch (err) {
      return undefined;
    }
  }

}