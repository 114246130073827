<section id="edit-link" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1>
      <ng-container *ngIf="isNew" i18n>Référencer un nouveau lien</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier le lien</ng-container>
    </h1>
    <p class="alert alert-info" *ngIf="nonEditableInfos">
      <i class="fa fa-info-circle"></i>
      <ng-container i18n>Ce lien est actuellement modifié par {{nonEditableInfos.userId}}</ng-container>
    </p>
  </div>
  <div class="page-content">
    <form *ngIf="link" class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group row no-gutters align-items-center">
            <label for="link-language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue</ng-container> *
            </label>
            <div class="col">
              <select id="link-language" class="form-control" name="link-language"
                      #languageField="ngModel" [(ngModel)]="link.language" [disabled]="nonEditableInfos" required>
                <option *ngFor="let language of languages" [ngValue]="language.value">
                  {{language.flag}}&nbsp;{{language.label}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-name" class="col-4 mr-3 mb-0">
              <ng-container i18n>Titre</ng-container> *
            </label>
            <div class="col">
              <input type="text" id="link-name" class="form-control" name="link-name" [(ngModel)]="link.name"
                #nameField="ngModel" [disabled]="nonEditableInfos" required>
              <div class="alert alert-danger" *ngIf="editForm.submitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="link-description" class="col-4 mr-3 mb-0 mt-1" i18n>Description</label>
            <div class="col">
              <textarea name="link-description" class="form-control" id="link-description"
                [(ngModel)]="link.description" [disabled]="nonEditableInfos" #descField="ngModel"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-keywords" class="col-4 mr-3 mb-0">
              <ng-container i18n>Mots-clés</ng-container>
            </label>
            <div class="col">
              <p-autoComplete [(ngModel)]="link.keywords" [multiple]="true" inputId="link-keywords"
                              name="link-keywords" [suggestions]="registryKeywords" field="label" styleClass="form-control w-100"
                              [disabled]="nonEditableInfos" (completeMethod)="keywordsAutoComplete($event)">
              </p-autoComplete>
              <input type="text" id="link-keywords-old" class="form-control" name="link-keywords-old" [(ngModel)]="keywords"
                [disabled]="true">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="link-thematics" class="col-4 mr-3 mb-0 mt-2" i18n>Thématiques</label>
            <div class="col">
              <p-autoComplete [(ngModel)]="link.thematics" [multiple]="true" inputId="link-thematics"
                name="link-thematics" [suggestions]="categories" field="label" styleClass="form-control w-100"
                [disabled]="nonEditableInfos" (completeMethod)="categoriesAutoComplete($event)">
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-type" class="col-4 mr-3 mb-0">
              <ng-container i18n>Type de lien</ng-container> *
            </label>
            <div class="col">
              <select id="link-type" class="form-control" name="link-type" [(ngModel)]="link.typeName"
                #subTypeField="ngModel" [disabled]="nonEditableInfos" required>
                <option [ngValue]="null" i18n>-- Choisir un type de lien --</option>
                <option *ngFor="let subType of subTypes" [ngValue]="subType">{{subType}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && subTypeField.invalid">
                <p *ngIf="subTypeField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-project" class="col-4 mr-3 mb-0">
              <ng-container i18n>Étude</ng-container> *
            </label>
            <div class="col">
              <select id="link-project" class="form-control" name="link-project" [(ngModel)]="link.projectId"
                #projectField="ngModel" [disabled]="isInProject || nonEditableInfos" required>
                <option [ngValue]="null" i18n>-- Choisir une étude --</option>
                <option *ngFor="let project of projects; trackBy: trackById" [ngValue]="project.id">{{project.name}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && projectField.invalid">
                <p *ngIf="projectField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="link-url" class="col-4 mr-3 mb-0">
              <ng-container i18n>Url</ng-container> *
            </label>
            <div class="col">
              <input type="text" id="link-url" class="form-control" name="link-url" [(ngModel)]="link.url"
                #urlField="ngModel" [disabled]="nonEditableInfos" required>
              <div class="alert alert-danger" *ngIf="editForm.submitted && urlField.invalid">
                <p *ngIf="urlField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-6">
          <fieldset class="form-group">
            <legend i18n>Emprises</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="link-extent-description" class="col-4 mb-0" i18n>Description</label>
              <div class="col">
                <p-autoComplete [(ngModel)]="chosenGeoname" id="link-extent-description" name="link-extent-description"
                  [suggestions]="geonames" styleClass="form-control w-100" field="label"
                  [disabled]="nonEditableInfos" (onSelect)="updateExtent()" (onClear)="updateExtent()"
                  (completeMethod)="searchGeonames($event)">
                </p-autoComplete>
              </div>
            </div>
            <div class="row no-gutters align-items-start">
              <label for="link-extent" class="col-4 mb-0 mt-1" i18n>Emprise</label>
              <div class="col">
                <described-extent name="link-extent" id="link-extent" [isEditable]="!nonEditableInfos"
                  [(ngModel)]="link.extents"></described-extent>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default" i18n>Annuler</a>
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="nonEditableInfos" i18n>Enregistrer</button>
      </div>
    </form>
  </div>
</section>
