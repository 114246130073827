import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Workflow, Execution, Project } from 'src/app/models';
import { SessionService, WorkflowService, TableFilterService, LoaderService, ProjectService, UtilsService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';

@Component({
  templateUrl: './workflow-executions.component.html'
})
export class WorkflowExecutionsComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Liste des worflows
   */
  public workflow: Workflow;

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  private _dataTable: FiltrableTableComponent;

  @ViewChildren(FiltrableTableComponent) dataTables: QueryList<FiltrableTableComponent>;

  public executionStatus = Constants.executionStatus;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _sub: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _workflowService: WorkflowService,
    private _projectService: ProjectService,
    private _filterService: TableFilterService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loader: LoaderService,
    private _utils: UtilsService
  ) { }

  public ngOnInit() {
    this._sub.add(this._workflowService.workflow$.subscribe(workflow => this._initWorkflow(workflow)));
    this._sub.add(this._projectService.projects$.subscribe(projects => this._initProjects(projects)));
    this._sub.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initFromParams(params)))

    this._loader.show();

  }

  public ngOnDestroy() {
    this._sub.unsubscribe();
  }

  ngAfterViewInit() {
    this._sub.add(this.dataTables.changes.subscribe((dataTables: QueryList<FiltrableTableComponent>) => {
      if(dataTables.first) {
        this._dataTable = dataTables.first;
        this._dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
      }
    }));
  }

  /**
   * Navigue vers l'exécution
   * @param execution Exécution vers laquelle naviguer
   */
  public goToExecution(execution: Execution) {
    let routerLinkParam = [];
    if (this.session.hasRight(execution.projectId, Constants.OBJECT_TYPE_PROJECT, 'editor')) {
      routerLinkParam.push('/my-projects');
    } else {
      routerLinkParam.push('/projects');
    }
    routerLinkParam.push(execution.projectId);
    routerLinkParam.push('workflows');
    routerLinkParam.push(this.workflow.id);

    this.session.setInitPageParams({ executionId: execution.id });
    this._router.navigate(routerLinkParam);
  }

  /**
   * Initialise la page avec les paramètres d'url
   * @param params paramètres d'url
   */
  private _initFromParams(params: any) {
    if (params && params.workflowId) {
      this._workflowService.getWorkflow(params.workflowId, null, true);
    }
  }

  /**
   * Initialise la liste des workflows
   * @param workflows -  workflows reçus du serveur
   */
  private _initWorkflow(workflow: Workflow) {
    this.workflow = workflow;
    this._projectService.getAllProjects();
  }

  private _initProjects(projects: Project[]) {
    _.each(this.workflow.executions, (execution: Execution) => {
      execution.project = _.find(projects, { id: execution.projectId });
    });

    this._loader.hide();
  }
}
