import { EntityMapper } from "./entity-mapper.model";

/**
 * Groupe de droits d'accès aux métadonnées
 */
export class Group extends EntityMapper {
  /**
   * ID du groupe
   */
  id: number = null;

  /**
   * Nom
   */
  name: string = "";

  /**
   * Description
   */
  description: string = "";

  /**
   * Statut d'activation du groupe (alive ou closed)
   */
  status: string = "alive";

  /**
   * Utilisateurs faisant partie du groupe
   */
  users: string[] = [];
}