<section id="workflows" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto">
      <ng-container *ngIf="isOnlyMine" i18n>Mes workflows</ng-container>
      <ng-container *ngIf="!isOnlyMine" i18n>Tous les workflows</ng-container>
    </h1>
    <div class="title-button-container">
      <a [routerLink]="['./new/edit']" class="btn btn-primary" *ngIf="isWorkflowManager">
        <i class="fa fa-plus"></i>
        <ng-container i18n>Créer un workflow</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucun workflow</p>
          <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de
            {{totalResults}} workflows</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control"
          (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="workflows" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
            <th class="owner" sortable-column="ownerName"><span i18n>Contact</span></th>
            <th class="typeName" sortable-column="typeName"><span i18n>Type</span></th>
            <th class="date text-center" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-workflow>
          <tr>
            <td><a [routerLink]="['./', workflow.id]">{{workflow.name}}</a></td>
            <td>{{workflow.ownerName}} ({{workflow.ownerOrganisation}})</td>
            <td>{{workflow.typeName}}</td>
            <td class="text-center">{{workflow.lastUpdate | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
              <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                <a class="dropdown-item" [routerLink]="['./', workflow.id]" i18n>Consulter</a>
                <a *ngIf="session.hasRight(workflow.id, objectTypeWorkflow, 'owner')" class="dropdown-item"
                  [routerLink]="['./', workflow.id, 'edit']" i18n>Modifier</a>
                <a *ngIf="isWorkflowManager && session.hasRight(workflow.id, objectTypeWorkflow, 'owner')" class="dropdown-item item-danger"
                  (click)="deleteWorkflow(workflow)" i18n>Supprimer</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>