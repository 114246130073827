<header id="header" class="container-fluid">
  <nav class="navbar navbar-expand no-gutters">
    <a *ngIf="logoName" class="navbar-brand text-center" routerLink="/">
      <img [src]="'/assets/images/' + logoName" [alt]="logoTitle">
    </a>
    <form class="form-inline col-6 mr-auto no-gutters" (ngSubmit)="doSearch()" #searchForm="ngForm">
      <div class="input-group mr-4 col search-group">
        <input class="form-control search" type="search" i18n-placeholder placeholder="Rechercher"
          [(ngModel)]="search.text" #searchText name="searchText" aria-label="Rechercher">
        <div class="input-group-append" ngbDropdown>
          <button class="btn type-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            ngbDropdownToggle>{{search.typeLabel}}</button>
          <div ngbDropdownMenu>
            <a class="dropdown-item" (click)="setSearchType('', 'Tout')" i18n>Tout</a>
            <a class="dropdown-item" (click)="setSearchType('project', 'Études')" i18n>Études</a>
            <a class="dropdown-item" (click)="setSearchType('workflow', 'Workflow')" i18n>Workflows</a>
            <a class="dropdown-item" (click)="setSearchType('data', 'Données')" i18n>Données</a>
            <a class="dropdown-item" (click)="setSearchType('link', 'Liens')" i18n>Liens</a>
          </div>
        </div>
      </div>
      <button class="btn search-btn" type="submit" i18n>Rechercher</button>
    </form>
    <ul class="navbar-nav right-navbar ml-auto">
      <li class="nav-item" *ngIf="isAdmin">
        <a class="btn btn-icon nav-link" routerLink="/admin">
          <i class="fa fa-key"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="btn btn-icon nav-link" routerLink="/help">
          <i class="fa fa-question-circle"></i>
        </a>
      </li>
      <li class="nav-item calls-item" ngbDropdown placement="bottom-right">
        <a class="btn btn-icon nav-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          ngbDropdownToggle>
          <i class="fa fa-bell"
            [ngClass]="{alerts: executions.length > completedExecutions, completed: executions.length > 0 && executions.length == completedExecutions}"></i>
        </a>
        <div aria-labelledby="calls-dropdown" ngbDropdownMenu>
          <p *ngIf="executions.length === 0" class="mb-0" i18n>Aucune exécution en cours</p>
          <p *ngIf="executions.length > 0" class="mb-1" i18n>
            Exécutions en cours : {{completedExecutions}}/{{executions.length}}
          </p>
          <a *ngFor="let execution of executions; trackBy: trackById" class="dropdown-item" [routerLink]="urls[execution.id]">
            <i class="status" [ngClass]="execution.status"></i>
            {{execution.name}} (lancée le {{execution.executionDate|date:'dd/MM/yyyy à HH:mm'}})
          </a>
        </div>
      </li>
      <li class="nav-item separator" ngbDropdown placement="bottom-right">
        <a class="nav-link account" id="user-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" ngbDropdownToggle>
          {{session.currentUser.email}}
          <i class="fa fa-user"></i>
        </a>
        <div aria-labelledby="user-dropdown" ngbDropdownMenu>
          <dl class="groups-container">
            <dt i18n>Mes groupes</dt>
            <dd>
              <ul>
                <li *ngFor="let group of session.currentUser.groups; trackBy: trackById">
                  {{group.name}}
                </li>
              </ul>
            </dd>
          </dl>
          <a class="dropdown-item" [routerLink]="['/account']">
            <i class="fa fa-user mr-2"></i>
            <ng-container i18n>Mon compte</ng-container>
          </a>
          <a class="dropdown-item" (click)="logout()">
            <i class="fa fa-power-off mr-2"></i>
            <ng-container i18n>Se déconnecter</ng-container>
          </a>
        </div>
      </li>
    </ul>
  </nav>
</header>