import * as _ from 'lodash';

import { EntityBusinessObject } from './entity-business-object.model';
import { Constants } from '../constants/app.constants';
import { Project } from './project.model';

export class Link extends EntityBusinessObject {
  /**
   * Identifiant de l'étude lié
   */
  projectId: string = null;

  /**
   * Identifiant de l'étude liée
   */
  project: Project = null;

  /**
   * URL du lien
   */
  url: string = "";

  /**
   * Nom du type de lien
   */
  typeName: string = null;

  /**
   * Langue
   */
  language: string = "fre";

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "projectId", back: "parentIdentifier" },
      { front: "url", back: "onlineResources.0.link" }
    ]);
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.typeName = this.getUniqueClassifiedKeyword(Constants.TYPOLOGIE_KEYWORD_NAME);

    return this;
  }
}
