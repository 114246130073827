import * as _ from 'lodash';

import { Workflow } from './workflow.model';
import { Data } from './data.model';
import { Link } from './link.model';
import { Constants } from '../constants/app.constants';
import { EntityBusinessObject } from './entity-business-object.model';

/**
 * Entité des études
 */
export class Project extends EntityBusinessObject {

  /**
   * Commentaires / informations supplémentaires
   */
  comment: string = "";

  /**
   * Contraintes d'utilisation
   */
  legalConstraints: string = "";

  /**
   * Liste des workflows liés à l'étude
   */
  workflows: Workflow[] = [];

  /**
   * Liste des données de l'étude
   */
  datas: Data[] = [];

  /**
   * Liste des liens de l'étude
   */
  links: Link[] = [];

  /**
   * Traçabilité
   */
  lineage: string = "";

  /**
   * Projets Opale
   */
  opaleKeywords: string[] = [];

  /**
   * Catégorie
   */
  category: string = "En cours";

  /**
   * Langue
   */
  language: string = "fre";

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "comment", back: "supplementalInformation" },
      { front: "lineage", back: "dataQualityLineage" },
      { front: "links", class: Link },
      { front: "datas", class: Data },
      { front: "workflows", class: Workflow }
    ]);
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.opaleKeywords = this.getClassifiedKeywords(Constants.OPALE_KEYWORD_NAME) || [];

    const category = this.getUniqueClassifiedKeyword(Constants.PROJECT_CATEGORY_KEYWORD_NAME);
    if (category) {
      this.category = category;
    }

    return this;
  }

}
