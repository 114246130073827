<section id="execution-detail" class="meta-detail d-flex flex-column h-100" *ngIf="execution">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto">{{execution.name}}</h1>
  </div>
  <div class="top-button">
    <button *ngIf="execution.draft" class="btn btn-default" type="button" (click)="switchDraftOff()">
      <i class="fa fa-save"></i>
      <ng-container i18n>Conserver cette exécution</ng-container>
    </button>
    <button *ngIf="!execution.draft" class="btn btn-default" type="button" (click)="switchDraftOn()">
      <i class="fa fa-pen"></i>
      <ng-container i18n>Mettre cette exécution en brouillon</ng-container>
    </button>
  </div>
<div class="page-content d-flex flex-column">
    <div class="subcontent">
      <div class="row">
        <div class="col-xs-12 col-md-4">
          <h2 i18n>Informations</h2>
          <dl class="mb-0">
            <dt i18n>Date d'exécution</dt>
            <dd>{{execution.executionDate | date:'dd/MM/yy à HH:mm'}}</dd>
            <dt i18n>Brouillon</dt>
            <dd *ngIf="execution.draft" i18n>Oui</dd>
            <dd *ngIf="!execution.draft" i18n>Non</dd>
            <dt i18n>Statut</dt>
            <dd>{{currentStatus}}</dd>
            <dt i18n>Utilisateur</dt>
            <dd>{{execution.userId}}</dd>
            <ng-container *ngIf="execution.project">
              <dt i18n>Étude</dt>
              <dd>{{execution.project.name}}</dd>
            </ng-container>
          </dl>
          <hr class="my-3">
          <h2 i18n>Paramètres d'exécution</h2>
          <dl class="mn-0">
            <ng-container *ngFor="let key of inputsKeys">
              <dt>{{key}}</dt>
              <dd>{{execution.inputs[key]}}</dd>
            </ng-container>
          </dl>
        </div>

        <div class="col-xs-12 col-md-4">
          <h2 i18n>Logs</h2>
          <ng-container *ngIf="data.logs">
            <pre class="method-content">{{data.logs}}</pre>
          </ng-container>
        </div>

        <div class="col-xs-12 col-md-4">
          <h2 i18n>Fichiers résultats</h2>
          <ng-container class="mb-0" *ngIf="data.results && data.results.length > 0">
            <button type="button" class="btn btn-default btn-block mb-3" *ngIf="displayMapButton"
              (click)="openResultsMap()">
              <i class="fa fa-map-marker-alt"></i>
              <ng-container i18n>Voir les résultats sur la carte</ng-container>
            </button>
            <button type="button" class="btn btn-default btn-block mb-3" *ngIf="displayTableButton"
              (click)="openResultsTable()">
              <i class="fa fa-table"></i>
              <ng-container i18n>Voir le récapitulatif des résultats</ng-container>
            </button>

            <dl class="mb-0">
              <ng-container *ngFor="let result of data.results">
                <dt>
                  <a [href]="result.url" target="_blank">
                    {{result.name}} <i class="fa fa-external-link-alt"></i>
                  </a>
                </dt>
                <dd>{{result.description}}</dd>
              </ng-container>
            </dl>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>