import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
