import * as _ from 'lodash';

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectService, WorkflowService, LoaderService } from "src/app/services";
import { Project, Workflow } from "src/app/models";

@Component({
  templateUrl: './workflow-linkage-modal.component.html'
})
export class WorkflowLinkageModalComponent implements OnInit, OnDestroy {
  @Input() workflow: Workflow = null;
  @Input() project: Project = null;

  public projects: Project[] = [];
  public workflows: Workflow[] = [];

  public chosenProject: Project = null;
  public chosenWorkflow: Workflow = null;

  private _subs: Subscription = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _projectService: ProjectService,
    private _workflowService: WorkflowService
  ) { }

  ngOnInit() {
    if (this.workflow) {
      this._subs.add(this._projectService.projects$.subscribe(projects => this._initProjects(projects)));
      this._projectService.getUserProjects('owner');
    } else if (this.project) {
      this._subs.add(this._workflowService.workflows$.subscribe(workflows => this._initWorkflows(workflows)));
      this._workflowService.getAllWorkflows();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public save() {
    let obs;
    if(this.workflow) {
      this.workflow.projectUris.push(this.chosenProject.id);
      obs = this._workflowService.addWorkflowRelation(this.workflow, this.chosenProject);
    } else if(this.project) {
      this.chosenWorkflow.projectUris.push(this.project.id);
      obs = this._workflowService.addWorkflowRelation(this.chosenWorkflow, this.project);
    }
    this.modal.close(obs.pipe(
      map(() => this.chosenWorkflow || this.chosenProject || null)
    ));
  }

  private _initProjects(projects: Project[]) {
    this.projects = _.filter(projects, p => {
      return this.workflow.projectUris.indexOf(p.id.toString()) < 0;
    });
  }

  private _initWorkflows(workflows: Workflow[]) {
    this.workflows = _.filter(workflows, w => {
      return _.findIndex(this.project.workflows, { id: w.id }) < 0;
    });
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(i, item) {
    return item.id;
  }
}