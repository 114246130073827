import * as _ from 'lodash';

import { Component, Input, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';
import { Data } from 'src/app/models';
import { SelectItemGroup, SelectItem } from 'primeng/api';
import { Constants } from 'src/app/constants';


@Component({
  selector: 'metadata-file',
  templateUrl: './file.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: FileComponent, multi: true }
  ]
})
export class FileComponent implements ControlValueAccessor, Validator, OnInit {
  /**
   * Metadata
   */
  @Input() metadata;

  /**
   * Liste des données dont l'utilisateur est propriétaire
   */
  @Input() datasList: { currentProject: Data[], otherProjects: Data[] };

  /**
   * Est-on en lecture seule ?
   */
  @Input('readonly') isReadOnly;

  /**
   * Liste groupée pour p-dropdown
   */
  public finalDatasList: SelectItemGroup[] = [];

  /**
   * Fichier choisi
   */
  public chosenFile: File = null;

  /**
   * Donnée choisie
   */
  public chosenData: Data = null;

  /**
   * Ajoute-t-on une donnée ?
   */
  public isNewFile: boolean = false;

  /**
   * Limite de taille en Mo
   */
  public fileSizeLimit = Constants.EXECUTION_FILE_SIZE_LIMIT;

  /**
   * La taille est-elle dépassée ?
   */
  public isFileError: boolean = false;

  /**
   * Cache de l'éventuelle url fournie à l'initialisation
   */
  private _oldValueUrl: string = null;

  /**
   * Objet pUpload primeng
   */
  @ViewChild('fileField') fileField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor() { }

  ngOnInit() {
    this.finalDatasList = [];

    let currentProjectItems = this._getSelectItemsFromDataList(this.datasList.currentProject);
    if (currentProjectItems.length > 0) {
      this.finalDatasList.push({
        label: $localize`Étude actuelle`,
        items: currentProjectItems
      });
    }

    let othersProjectItems = this._getSelectItemsFromDataList(this.datasList.otherProjects);
    if (othersProjectItems.length > 0) {
      this.finalDatasList.push({
        label: $localize`Autres études`,
        items: othersProjectItems
      });
    }

    this.isNewFile = this.finalDatasList.length === 0;
  }

  private _getSelectItemsFromDataList(dataList: Data[]): SelectItem[] {
    let list = [];

    _.each(dataList, (data: Data) => {
      if (this.metadata.restrictions) {
        if (data.file) {
          let allowedExtensions = this.metadata.restrictions.split(',');
          for (let i = 0; i < allowedExtensions.length; i++) {
            if (_.endsWith(data.file.url, allowedExtensions[i])) {
              list.push({
                label: data.name,
                value: data
              });
              break;
            }
          }
        }
      } else if (data.file) {
        list.push({
          label: data.name,
          value: data
        });
      }
    });

    return list;
  }

  /**
   * Gère la modification de fichier
   * @param event - événement primeng
   */
  public changeFile(event) {
    if (event && event.files && event.files.length > 0) {
      this.chosenFile = event.files[0];
    } else {
      this.chosenFile = null;
    }
    this.onChangeCb(this.chosenFile);
    this.onMetadataChange.emit(this.metadata);
  }

  /**
   * Gère le changement de donnée choisie
   */
  public changeData() {
    this.onChangeCb(this.chosenData);
    this.onMetadataChange.emit(this.metadata);
  }

  /**
   * Applique le changement d'onglet
   * @param isNewFile - Est-ce l'onglet "nouveau fichier" ?
   */
  public changeTab(isNewFile) {
    if (isNewFile) {
      this.onChangeCb(this.chosenFile);
    } else {
      this.onChangeCb(this.chosenData);
    }
    this.onMetadataChange.emit(this.metadata);
  }

  // control methods
  writeValue(value) {
    if (value instanceof File) {
      this.chosenFile = value;
      this.fileField.files = [value];
      this.isNewFile = true;
    } else if (value instanceof Data) {
      this.chosenData = _.find(this.datasList.currentProject, { id: value.id });
      if (!this.chosenData) {
        this.chosenData = _.find(this.datasList.otherProjects, { id: value.id }) || null;
      }
    } else if (_.isString(value)) {
      this._oldValueUrl = value;
      this.chosenData = _.find(this.datasList.currentProject, d => d.file && d.file.url === value);
      if (!this.chosenData) {
        this.chosenData = _.find(this.datasList.otherProjects, d => d.file && d.file.url === value) || null;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations: any = {};

    //built-in validators
    if (this.metadata.required && !this._oldValueUrl) {
      validations = _.defaults(Validators.required(control), validations);
    }

    // Vérification des extensions
    if (this.isNewFile && this.chosenFile && this.metadata.restrictions) {
      let split = this.chosenFile.name.split('.');
      let types = this.metadata.restrictions.split(',');
      let found = _.find(types, t => {
        return t.indexOf('.' + split[split.length - 1]) >= 0;
      });
      if (!found) {
        validations.type = true;
      }
    }

    // Vérification de la taille de fichier
    if (this.isNewFile && this.chosenFile) {
      let sizeMB = this.chosenFile.size / 1024 / 1024; // Mo
      this.isFileError = (sizeMB >= this.fileSizeLimit);
      if (this.isFileError) {
        validations.size = true;
      }
    }

    if (_.keys(validations).length > 0) {
      return validations;
    }

    return null;
  }
}
