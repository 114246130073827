import { EntityMapper } from "./entity-mapper.model";
import { Project } from "./project.model";

/**
 * Exécution d'un workflow
 */
export class Execution extends EntityMapper {
  /**
   * Id cromwell de l'exécution
   */
  id: string = null;

  /**
   * Nom
   */
  name: string = "";

  /**
   * Date d'exécution
   */
  executionDate: Date = null;

  /**
   * Statut d'exécution (Submited, Completed, Failed, Viewed ou Saved)
   */
  status: string = null;

  /**
   * Utilisateur ayant fait l'exécution
   */
  userId: string = null;

  /**
   * Id de l'étude associée
   */
  projectId: string = null;

  /**
   * Étude associée
   */
  project: Project = null;

  /**
   * Id du workflow associé
   */
  workflowId: string = null;

  /**
   * Paramètres utilisés pour l'exécution
   */
  inputs: any = {};

  /**
   * Brouillon ?
   */
  draft: boolean = false;

  constructor() {
    super();

    this._mapperDefs = [
      { front: "executionDate", class: Date },
      { front: "projectId", back: "uriStudy" },
      { front: "workflowId", back: "uriWorkflow" }
    ];
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    if(this._jsonModel.inputsJson) {
      try {
        this.inputs = JSON.parse(this._jsonModel.inputsJson);
      } catch(e) {
        this.inputs = {};
      }
    }

    return this;
  }

  public serialize(): any {
    super.serialize();

    this._jsonModel.inputsJson = JSON.stringify(this.inputs);

    return this._jsonModel;
  }
}