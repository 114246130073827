import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { Subscription } from 'rxjs';
import { Project, Workflow } from 'src/app/models';
import { ProjectService, SessionService, LoaderService, DataService, WorkflowService, LinkService, ResourceService } from 'src/app/services';
import { ConfirmModalComponent, WorkflowLinkageModalComponent } from '../../modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from 'src/app/constants';



@Component({
  templateUrl: './project-detail.component.html'
})
export class ProjectDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Onglet initialement ouvert
   */
  public activeTab: string = "workflow-tab";

  /**
   * Étude à afficher
   */
  public project: Project;

  /**
   * Nom des rôles de contacts
   */
  public contactsRolesNames: { [key: string]: string } = {};

  /**
   * Afficher la description complète de l'étude ?
   */
  public showFullDescription: boolean = false;

  /**
   * Est-on propriétaire de l'étude ?
   */
  public isOwner: boolean = false;

  /**
   * Est-on éditeur de l'étude ?
   */
  public isEditor: boolean = false;

  /**
   * Est-on administrateur ?
   */
  public isAdmin: boolean = false;

  /**
   * Version actuellement visible de la description de l'étude
   */
  public currentDescription: string;

  private _targetId: string;

  private _dataTable: FiltrableTableComponent;

  @ViewChildren(FiltrableTableComponent) dataTables: QueryList<FiltrableTableComponent>;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _projectService: ProjectService,
    private _dataService: DataService,
    private _linkService: LinkService,
    private _resourceService: ResourceService,
    private _session: SessionService,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._loader.show();
    this._subs.add(this._projectService.project$.subscribe(project => this._initProject(project)));
    this._subs.add(this._route.paramMap.subscribe(params => this._initProjectFromParams(params)));
    this._subs.add(this._resourceService.editRights$.subscribe(newRights => this._updateNewRights(newRights)));

    this.isAdmin = this._session.hasRole(Constants.userRoles.admin);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    this._subs.add(this.dataTables.changes.subscribe((dataTables: QueryList<FiltrableTableComponent>) => {
      if (dataTables.first) {
        this._dataTable = dataTables.first;
        if (this._targetId) {
          setTimeout(() => {
            this._dataTable.filter({ target: { value: this._targetId, field: 'id' } });
            this._targetId = null;
          }, 0);
        }
      }
    }));
  }

  /**
   * Active/désactive la vue complète de la description de l'étude
   */
  public toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
    if (this.showFullDescription) {
      this.currentDescription = this.project.description;
    } else {
      this.currentDescription = this.project.description.substring(0, 260);
    }
  }

  /**
   * Supprimme une metadata
   * @param metadata - metadata à supprimer
   */
  public deleteMetadata(metadata: any, metadataType: string): void {
    let title, text, successText, obs;
    switch (metadataType) {
      case 'data':
        title = $localize`Suppression d'une donnée`;
        text = $localize`Voulez-vous vraiment supprimer cette donnée : '${metadata.name}' ?`;
        successText = $localize`La donnée '${metadata.name}' a été supprimée avec succès`;
        obs = this._dataService.deleteData(metadata);
        break;
      case 'link':
        title = $localize`Suppression d'un lien`;
        text = $localize`Voulez-vous vraiment supprimer ce lien : '${metadata.name}' ?`;
        successText = $localize`Le lien '${metadata.name}' a été supprimée avec succès`;
        obs = this._linkService.deleteLink(metadata);
        break;
    }

    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = text;


    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      obs.subscribe(() => {
        this._toastr.success(successText);
        this._projectService.getProject(this.project.id);
      }, error => console.error(error));
    }, () => null);
  }

  /**
   * Ouvre une popin pour lier l'étude à un workflow
   */
  public linkToWorkflow() {
    const modalRef = this._modalService.open(WorkflowLinkageModalComponent, { windowClass: "confirm-modal workflow-linkage-modal" });
    modalRef.componentInstance.project = _.cloneDeep(this.project);

    modalRef.result.then(result => {
      if (result) {
        this._loader.show();
        result.subscribe(editedObj => {
          this._tracker.eventTrack.next({
            action: "Lier un worfklow à une étude",
            properties: {
              category: this.project.name + ' - ' + editedObj.name
            }
          });
          this._toastr.success($localize`L'étude a été lié au workflow '${editedObj.name}' avec succès.`);
          this._projectService.getProject(this.project.id);
        }, error => {
          console.error(error);
          this._loader.hide();
        })
      }
    }, () => null);
  }

  /**
   * Ouvre la popup d'édition des droits
   */
  public openEditRightsModal() {
    this._resourceService.editResourceRights(this.project, Constants.OBJECT_TYPE_PROJECT);
  }

  /**
   * Supprimme l'étude
   */
  public deleteProject(): void {
    if (this.project.datas.length === 0 && this.project.links.length === 0 && this.project.workflows.length === 0) {
      const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
      modalRef.componentInstance.title = $localize`Suppression d'une étude`;
      modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette étude : '${this.project.name}' ?`;
      modalRef.componentInstance.confirmClass = "btn-danger";
      modalRef.componentInstance.confirmText = $localize`Supprimer`;

      modalRef.result.then(() => {
        this._loader.show();
        this._projectService.deleteProject(this.project)
          .subscribe(() => {
            this._tracker.eventTrack.next({
              action: "Suppression d'étude",
              properties: {
                category: this.project.name
              }
            });
            this._toastr.success($localize`L'étude '${this.project.name}' a été supprimée avec succès`);
            this._router.navigate(['../../'], { relativeTo: this._route });
            this._loader.hide();
          }, error => console.error(error));
      }, () => null);
    } else {
      this._toastr.error($localize`Votre étude doit être totalement vide pour pouvoir être supprimée`);
    }
  }

  /**
   * Initialise l'étude venu du serveur
   * @param project - Projet reçu
   */
  private _initProject(project) {
    this.project = project;
    this._generateContacts();
    this.currentDescription = this.showFullDescription ? this.project.description : this.project.description.substring(0, 260);

    let params = this._session.getInitPageParams();
    if (params) {
      if (params.type) {
        this.activeTab = params.type + '-tab';
        if (params.id) {
          this._targetId = params.id
        }
      }
    }

    this._loader.hide();
  }

  /**
   * Demande la récupération de l'étude à partir de l'ID reçu
   * @param params 
   */
  private _initProjectFromParams(params) {
    const projectId = params.get('projectId');
    this.isOwner = this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'owner');
    this.isEditor = this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'editor');

    this._projectService.getProject(projectId);
  }

  /**
   * Met à jour les permissions après leur modification
   * @param newRights - nouvelles permissions
   */
  private _updateNewRights(newRights: any) {
    if (newRights) {
      this.project.individualPermissions = newRights.individualPermissions;
      this.project.groupPermissions = newRights.groupPermissions;
      this.project.generateOwners();

      if (!this._session.hasRight(this.project.id, Constants.OBJECT_TYPE_PROJECT, 'owner') && !this._session.hasRole(Constants.userRoles.admin)) {
        this._router.navigate(['my-projects']);
      }
    }
  }

  /**
   * Ordonne les contacts et génère la liste de textes de contacts
   */
  private _generateContacts() {
    let firstContacts = _.filter(this.project.contacts, { role: "pointOfContact" });
    let othersContacts = _.filter(this.project.contacts, c => c.role !== "pointOfContact");
    this.project.contacts = firstContacts.concat(othersContacts);

    _.each(Constants.contactTypes, role => {
      this.contactsRolesNames[role.value] = role.label;
    });
  }

  /**
   * Quand l'utilisateur change d'onglet
   */
  public onTabChange(value: number) {
    //console.log(value);
  }
}
