import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

@Component({
  selector: 'metadata-number',
  templateUrl: './number.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: NumberComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: NumberComponent, multi: true }
  ]
})
export class NumberComponent implements ControlValueAccessor, Validator {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  value: number;

  @ViewChild('numberField', { static: true }) numberField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor() {}

  onChange() {
    this.onChangeCb(this.value);
    this.onMetadataChange.emit(this.metadata);
  }

  // control methods
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): {[key: string]: any} | null {
    let validations = {};


    //built-in validators
    if(this.metadata.required) {
      validations = _.defaults(Validators.required(control), validations);
    }

    //custom validators
    this.minValidator(validations, control);
    this.maxValidator(validations, control);
    this.typeValidator(validations, control);

    if(_.keys(validations).length > 0) {
      this.numberField.control.setErrors(validations);
      return validations;
    }
    this.numberField.control.setErrors(null);
    return null;
  }

  minValidator(validations, control) {
    if(this.metadata.min !== undefined && this.metadata.min !== null) {
      let valid = true;
      if(this.metadata.strictMin) {
        valid = (control.value > this.metadata.min);
      } else {
        valid = (control.value >= this.metadata.min);
      }
      if(!valid) {
        validations.min = { value: control.value };
      }
    }
  }

  maxValidator(validations, control) {
    if(this.metadata.max !== undefined && this.metadata.max !== null) {
      let valid = true;
      if(this.metadata.strictMax) {
        valid = (control.value < this.metadata.max);
      } else {
        valid = (control.value <= this.metadata.max);
      }
      if(!valid) {
        validations.max = { value: control.value };
      }
    }
  }

  typeValidator(validations, control) {
    if(this.metadata.numberType === "int" && control.value && control.value.toString().indexOf('.') >= 0) {
      validations.numberType = { value: control.value };
    }
  }
}
