import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Group } from '../models';
import { Uris } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * Source de la liste des emails
   */
  private _emailsSource = new Subject<string[]>();

  /**
   * Source de la liste des groupes
   */
  private _groupsSource = new Subject<Group[]>();

  /**
   * Source d'un groupe
   */
  private _groupSource = new Subject<Group>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste d'emails
   */
  public emails$ = this._emailsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de groupes
   */
  public groups$ = this._groupsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un groupe
   */
  public group$ = this._groupSource.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Recherche des emails de users
   * @param searchText - email à chercher (peut être partiel)
   */
  public searchUsers(searchText: string = ""): void {
    this.searchUsersObs(searchText)
      .subscribe(users => this._emailsSource.next(users));
  }

  /**
   * Observable de recherche des emails de users
   * @param searchText - email à chercher (peut être partiel) 
   */
  public searchUsersObs(searchText: string = ""): Observable<string[]> {
    return this._http.post<string[]>(Uris.USERS + 'search', { email: searchText });
  }

  public getUserGroups(userId: string): Observable<Group[]> {
    return this._http.get<Group[]>(Uris.USERS + userId + '/groups')
      .pipe(
        map(groups => groups.map(g => new Group().deserialize(g)))
      );
  }

  /**
   * Récupère la liste des groupes de droits
   */
  public getGroups() {
    this._http.get<Group[]>(Uris.GROUPS)
      .pipe(
        map(groups => groups.map(g => new Group().deserialize(g)))
      )
      .subscribe(groups => this._groupsSource.next(groups));
  }

  /**
   * Récupère un groupe
   * @param id - ID du groupe
   */
  public getGroup(id: number | string) {
    if (id === 'new') {
      let newGroup = new Group();
      newGroup.name = $localize`Nouveau groupe`;
      this._groupSource.next(newGroup);
    } else {
      this._http.get<Group>(Uris.GROUPS + id)
        .pipe(
          map(group => new Group().deserialize(group))
        )
        .subscribe(group => this._groupSource.next(group));
    }
  }

  /**
   * Enregistre un groupe
   * @param group - groupe à enregistrer
   */
  public saveGroup(group: Group): Observable<any> {
    let obs;
    if (group.id) {
      obs = this._http.put<any>(Uris.GROUPS + group.id, group.serialize());
    } else {
      obs = this._http.post<any>(Uris.GROUPS, group.serialize());
    }
    return obs;
  }

  /**
   * Supprimer un groupe
   * @param group - groupe à supprimer
   */
  public deleteGroup(group: Group): Observable<any> {
    return this._http.delete<any>(Uris.GROUPS + group.id);
  }

  /**
   * Retire un utilisateur d'un groupe
   * @param group Groupe duquel retirer l'utilisateur
   * @param user Email de l'utilisateur
   */
  public removeUserFromGroup(group: Group, user: string): Observable<any> {
    return this._http.delete<any>(Uris.GROUPS + group.id + '/users/' + user);
  }

  /**
   * Ajoute un utilisateur à un groupe
   * @param group Groupe auquel ajouter l'utilisateur
   * @param user Email de l'utilisateur
   */
  public addUserToGroup(group: Group, user: string): Observable<any> {
    return this._http.post<any>(Uris.GROUPS + group.id + '/users/' + user, {});
  }

  /**
   * Ajoute un utilisateur à Vigirisks
   * @param user Email de l'utilisateur
   */
  public addAppUser(user: string): Observable<any> {
    return this._http.post<any>(Uris.USERS, user);
  }
}
