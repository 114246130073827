import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { Map, View, Feature } from 'ol';
import { Image as ImageLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, ImageWMS } from 'ol/source';
import { defaults as defaultControls, MousePosition, ScaleLine } from 'ol/control';
import { DragBox } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { format } from 'ol/coordinate';
import { platformModifierKeyOnly as platformModifierKeyOnly } from 'ol/events/condition';
import { extend as extendExtent } from 'ol/extent';

import { Resource, SearchData, Destination, EntityBusinessObject } from 'src/app/models';
import { ResourceService, TableFilterService, SessionService, LoaderService, UserService, CustomActionButtonControl } from 'src/app/services';
import { Constants } from 'src/app/constants';
import { Angulartics2 } from 'angulartics2';

@Component({
  templateUrl: './resources.component.html'
})
export class ResourcesComponent implements OnInit, OnDestroy {
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;

  /**
   * Liste initiale des résultats de la recherche
   */
  public resources: Resource[] = [];

  /**
   * Liste ordonnée et paginée des résultats de la recherche
   */
  public filteredResources: Resource[] = [];

  /**
   * Nombre de résultats par page
   */
  public itemsPerPage: number = 10;

  /**
   * Page de résultats actuelle
   */
  public currentPage: number = 1;

  /**
   * Est-on en train de sélectionner une emprise ?
   */
  public currentlyExtentChoose: boolean = false;

  /**
   * Liste des utilisateurs
   */
  public users: string[] = [];

  /**
   * Liste des types de données
   */
  public dataTypes = Constants.dataTypes;

  /**
   * Liste des types de liens
   */
  public linkTypes = Constants.linkTypes;

  /**
   * Liste des types de liens
   */
  public workflowTypes = Constants.workflowTypes;

  /**
   * Liste des thématiques
   */
  public thematics = Constants.thematics;

  /**
   * Objet formulaire
   */
  public searchData: SearchData = new SearchData();

  /**
   * Afficher les résultats ?
   */
  public displayTable = false;

  /**
   * Agrandir le panel de résultats ?
   */
  public displayTableWide = false;

  /**
   * Afficher le panel de recherche ?
   */
  public displayFilters = true;

  /**
   * Liste des destinations du goto
   */
  public destinations: Destination[] = [];

  /**
   * Emprise de la destination actuellement choisie
   */
  public currentDestinationExtent: [number, number, number, number] = null;

  /**
   * Type d'objet projet
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Type d'objet workflow
   */
  public objectTypeWorkflow = Constants.OBJECT_TYPE_WORKFLOW;

  /**
   * Toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Carte openlayers
   */
  public map: Map;

  private _resultsLayer: VectorLayer;

  private _drawLayer: VectorLayer;

  private _drawInteraction: DragBox;

  constructor(
    private _resourceService: ResourceService,
    private _filterService: TableFilterService,
    public session: SessionService,
    private _userService: UserService,
    private _loader: LoaderService,
    private _tracker: Angulartics2
  ) { }

  ngOnInit() {
    this._subs.add(this._resourceService.resources$.subscribe(resources => this._setResourcesListAndCounts(resources)));
    this._subs.add(this._userService.emails$.subscribe(users => this._setUserList(users)));
    this._subs.add(this._resourceService.externalSearch$.subscribe(searchData => this.search(searchData)));
    this._subs.add(this._loader.menuWidthChange$.subscribe(() => setTimeout(() => this._redrawMap(), 300)));

    this.destinations = Constants.searchDestinations.map(d => new Destination().deserialize(d));

    if (this.session.searchData) {
      this.search(this.session.searchData);
    }
    this._initMap();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Met à jour les données de filtre des résultats
   * @param event - Event du filtre de table
   */
  public filterTable(event: any): void {
    this.currentPage = event.page;
    event.itemsPerPage = this.itemsPerPage;
    this.filteredResources = this._filterService.filterTable(event, this.resources);
    this._updateResultsLayer();
  }

  /**
   * Lance la recherche
   */
  public search(searchData: SearchData = null): void {
    if (searchData) {
      this.searchData = _.cloneDeep(searchData);
    } else {
      this.session.searchData = _.cloneDeep(this.searchData);
    }
    this._loader.show();
    this._tracker.eventTrack.next({
      action: "Recherche",
      properties: {
        category: "Mot-clé: " + this.searchData.text
      }
    });
    this._resourceService.searchResources(this.searchData);
    this.toggleDisplayTable(true);
  }

  public autocompleteUsers(event: any): void {
    this._userService.searchUsers(event.query);
  }

  /**
   * Réinitialise la recherche
   */
  public resetSearch(): void {
    this.searchData = new SearchData();
  }

  public toggleDisplayTable(isDisplayed: boolean) {
    if (this._resultsLayer) {
      this._resultsLayer.setVisible(isDisplayed);
    }
    this.displayTable = isDisplayed;
  }

  /**
   * Définit et trie la liste des utilisateurs
   * @param users - Liste des utilisateurs
   */
  private _setUserList(users: string[]): void {
    this.users = users.sort();

    this._loader.hide();
  }

  /**
   * Met à jour les comptes des différents types de résultats
   * @param resources - Résultats de la recherche
   */
  private _setResourcesListAndCounts(resources: Resource[]): void {
    this.resources = resources;
    this.currentPage = 1;
    this.filterTable({
      page: 1,
      sortColumn: 'name'
    });
    this._loader.hide();
    this.toggleDisplayTable(true);
  }

  // =============================================
  // ================== Carte ====================
  // =============================================

  /**
   * Zoome sur l'emprise d'un résultat
   * @param resource Objet d'origine du résultat
   */
  public goToExtent(resource: EntityBusinessObject): void {
    if (resource.extents.length > 0) {
      let finalExtent;
      _.each(resource.extents, extent => {
        if (!finalExtent) {
          finalExtent = extent.slice();
        } else {
          extendExtent(finalExtent, extent);
        }
      });

      this.map.getView().fit(finalExtent, { padding: [15, 515, 15, 15] });
    }
  }

  /**
   * Active la sélection d'une emprise sur la carte
   */
  public chooseExtent(): void {
    if (this.map) {
      this.currentlyExtentChoose = true;
      this.map.addInteraction(this._drawInteraction);
    }
  }

  /**
   * Réinitialise l'emprise de recherche
   */
  public resetExtent(): void {
    this.searchData.extent = null;
    this._drawLayer.getSource().clear();
  }

  /**
   * Met en valeur une resource survolée dans le tableau des résultats
   * @param resource - Resource survolée
   * @param isHovered - A mettre en valeur ou non
   */
  public highlightResourceFeatures(resource: Resource, isHovered: boolean): void {
    resource.hovered = isHovered;
    this._resultsLayer.changed();
  }

  /**
   * Centre la carte sur la destination choisie dans le gazetteer
   * @param extent - emprise sur laquelle zoomer
   */
  public chooseDestination(extent: [number, number, number, number]): void {
    if (extent) {
      this.map.getView().fit(extent, { padding: [15, 515, 15, 15] });
      this.currentDestinationExtent = null;
    }
  }

  /**
   * Initialise la carte de la recherche
   */
  private _initMap(): void {
    this.map = new Map({
      target: this.mapContainer.nativeElement,
      controls: this._getControls(),
      layers: this._getInitialLayers(),
      view: new View({
        projection: 'EPSG:4326',
        center: [6.853376452701834, 46.159415444095494],
        zoom: 6
      })
    });
    this._initChooseExtentInteraction();
    this.map.on('pointermove', e => this._highlightHoveredFeaturesResources(e));

    setTimeout(() => this._redrawMap(), 100);
    setTimeout(() => this._redrawMap(), 500);
  }

  /**
   * Initialise les contrôles de la carte
   */
  private _getControls(): any[] {
    return defaultControls({
      zoomOptions: {
        zoomInTipLabel: 'Zoomer',
        zoomOutTipLabel: 'Dézoomer'
      },
      rotateOptions: {
        tipLabel: 'Réinitialiser la rotation'
      }
    }).extend([
      new ScaleLine(),
      new MousePosition({
        coordinateFormat: coordinate => format(coordinate, 'X:&nbsp;{x}&nbsp;Y:&nbsp;{y}', 4),
        projection: 'EPSG:4326',
        undefinedHTML: 'X:&nbsp;0&nbsp;Y:&nbsp;0'
      }),
      new CustomActionButtonControl({
        class: 'ol-zoom-extent',
        label: '<i class="fa fa-expand"></i>',
        tipLabel: $localize`Revenir à l'emprise initiale`,
        onClick: () => this.chooseDestination([-4.965820, 42.261049, 8.305664, 51.645294])
      })
    ])
  }

  /**
   * Initialise les couches de la carte
   */
  private _getInitialLayers(): any[] {
    let layers = [];

    layers.push(new ImageLayer({
      source: new ImageWMS({
        ratio: 1,
        url: "https://mapsref.brgm.fr/wxs/refcom-brgm/refign",
        params: {
          "LAYERS": "MONDE_MOD1_FR"
        }
      })
    }));

    this._resultsLayer = new VectorLayer({
      source: new VectorSource(),
      style: feat => {
        let resource = _.find(this.filteredResources, r => r.features.indexOf(feat) >= 0);
        let color = '#E36C04';
        let fill = 'rgba(255,255,255,0.3)';
        if (resource && resource.hovered) {
          color = 'blue';
          fill = 'rgba(255,255,255,0.6)';
        }

        return new Style({
          stroke: new Stroke({
            width: 2,
            color: color
          }),
          fill: new Fill({
            color: fill
          })
        });
      }
    });

    layers.push(this._resultsLayer);

    this._drawLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          width: 2,
          color: 'red'
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.3)'
        })
      })
    });

    layers.push(this._drawLayer);

    return layers;
  }

  /**
   * Initialise l'interaction de séleciton d'une emprise
   */
  private _initChooseExtentInteraction(): void {
    this._drawInteraction = new DragBox({
      condition: platformModifierKeyOnly
    });

    this._drawInteraction.on('boxend', e => this._updateChosenExtent(e.target));
  }

  /**
   * Met à jour la carte et l'emprise choisis sur la carte
   * @param feature - Feature openlayers envoyée par l'événement
   */
  private _updateChosenExtent(feature: any): void {
    let extent = feature.getGeometry().getExtent();

    this._drawLayer.getSource().clear();
    this._drawLayer.getSource().addFeature(new Feature({
      geometry: polygonFromExtent(extent)
    }));

    for (let i = 0; i < extent.length; i++) {
      extent[i] = Math.round(extent[i] * 1000000) / 1000000;
    }

    this.searchData.extent = extent;

    this.map.removeInteraction(this._drawInteraction);
    this.currentlyExtentChoose = false;
    this.displayFilters = true;
  }

  /**
   * Met à jour les emprises visibles sur la carte en fonction des résultats de la page courante
   */
  private _updateResultsLayer() {
    this._resultsLayer.getSource().clear();
    let finalExtent;
    _.each(this.filteredResources, resource => {
      resource.features = [];
      _.each(resource.extents, extent => {
        if (finalExtent) {
          extendExtent(finalExtent, extent);
        } else {
          finalExtent = extent.slice();
        }

        let feature = new Feature({
          geometry: polygonFromExtent(extent)
        });
        resource.features.push(feature);
        this._resultsLayer.getSource().addFeature(feature);
      });
    });

    if (finalExtent) {
      this.map.getView().fit(finalExtent, { padding: [15, 515, 15, 15] });
    }
  }

  /**
   * Met en valeur les resources survolées sur la carte
   * @param event - Event de mouvement de souris de la carte
   */
  private _highlightHoveredFeaturesResources(event) {
    let hoveredResources = [];

    this.map.forEachFeatureAtPixel(event.pixel, (f, layer) => {
      if (this._resultsLayer === layer) {
        let resource = _.find(this.filteredResources, el => el.features.indexOf(f) >= 0);
        if (resource) {
          hoveredResources.push(resource);
        }
      }
    });

    let changed = false;
    _.each(this.filteredResources, r => {
      if (r.features.length === 0) return;

      if (hoveredResources.indexOf(r) >= 0 && !r.hovered) {
        r.hovered = true;
        changed = true;
      } else if (hoveredResources.indexOf(r) < 0 && r.hovered) {
        r.hovered = false;
        changed = true;
      }
    });

    if (changed) {
      this._resultsLayer.changed();
    }
  }

  /**
   * Redessine la carte
   */
  private _redrawMap() {
    if (this.map) {
      this.map.updateSize();
    }
  }

}
