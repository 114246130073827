<div class="modal-header">
  <h2 class="modal-title">{{title}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" id="confirm-modal">
  <p class="mb-0 nl2br">{{message}}</p>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-default" 
    (click)="modal.dismiss('cancel')" i18n>Annuler</button>
  <button *ngIf="cancelAndQuitText"
    type="button" class="btn ml-auto mr-3" [ngClass]="cancelAndQuitClass" 
    (click)="modal.close('cancelAndQuit')">{{cancelAndQuitText}}</button>
  <button type="button" class="btn {{confirmClass}}" [ngClass]="{'ml-auto': !cancelAndQuitText}" 
    (click)="modal.close('confirm')">{{confirmText}}</button>
</div>