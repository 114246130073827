import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from '@angular/forms';
import { Observable, of, Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { equals as equalsExtent } from 'ol/extent';

import { Data, Destination, Project, OnlineResource, Contact, FileToUpload, MetadataEditing } from 'src/app/models';
import { DataService, MapService, LoaderService, ProjectService, UtilsService, SessionService, ResourceService } from 'src/app/services';
import { Constants } from 'src/app/constants';
import { Angulartics2 } from 'angulartics2';
import { FormListEvent, RegistryService } from '../../../services';
import { SelectItem } from 'primeng/api';

@Component({
  templateUrl: './data-edit.component.html'
})
export class DataEditComponent implements OnInit, OnDestroy {
  @ViewChild('timeExtentStartField') timeExtentStartField;
  @ViewChild('timeExtentEndField') timeExtentEndField;

  public FILE_RESOURCE_CODE = Constants.FILE_RESOURCE_CODE;

  /**
   * Donnée en cours d'édition
   */
  public data: Data;

  /**
   * Est-ce une nouvelle donnée ?
   */
  public isNew: boolean = true;

  /**
   * Est-on dans le contexte d'une étude ?
   */
  public isInProject: boolean = true;

  /**
   * ID de l'étude courante
   */
  public currentProjectId: string = null;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Objet geoname actuellement sélectionné
   */
  public chosenGeoname: Destination;

  /**
   * Liste des mots-clés au format string
   */
  public keywords: string = "";

  /**
   * Types de données
   */
  public subTypes: string[] = [];

  /**
   * Liste des qualités de métadonnée
   */
  public metadataQualities: string[] = [];

  /**
   * Liste de mes études
   */
  public projects: Project[] = [];

  /**
   * Liste des protocoles pour les liens et services
   */
  public protocols: string[] = [];

  /**
   * Liste des types de représentation spatiale
   */
  public spatialTypes: string[] = [];

  /**
   * Liste des types de contact
   */
  public contactTypes: { label: string, value: string }[] = [];

  /**
   * Route de retour
   */
  public cancelRoute: string[] = ['../'];

  /**
   * Langue actuelle
   */
  public locale: string = "fr";

  /**
   * Configuration des langues
   */
  public localeCalendarConfig = Constants.localeCalendarConfig;

  /**
   * Limite (en Mo) de taille d'un fichier
   */
  public fileSizeLimit = Constants.DATA_FILE_SIZE_LIMIT;

  /**
   * Est-il possible de supprimer un contact "pointOfContact" ?
   */
  public canDeletePointOfContact: boolean = false;

  public filesToUpload: FileToUpload[] = [];

  /**
   * Informations de l'étude en cours d'édition
   */
  public nonEditableInfos: MetadataEditing;

  /**
   * Liste des âges de début pour les dropdowns
   */
  public categories: SelectItem[] = [];

  /**
   * Liste des mots clés pour les dropdowns
   */
  public registryKeywords: SelectItem[] = [];

  /**
   * Liste des langues
   */
  public languages: ({ label: string; value: string, flag: any })[] = [];

  /**
   * Donnée avant modification
   */
  private _initialData: Data;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _projectService: ProjectService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _session: SessionService,
    private _tracker: Angulartics2,
    private _resourceService: ResourceService,
    private _registryService: RegistryService
  ) { }

  ngOnInit() {
    this.locale = this._utils.getLocaleFromNavigator();

    this._subs.add(this._dataService.data$.subscribe(data => this._initData(data)));
    this._subs.add(this._projectService.projects$.subscribe(projects => this._setProjectsList(projects)));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this.geonames = geonames));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initDataFromParams(params)));

    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY);
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD);

    this.subTypes = Constants.dataTypes;
    this.protocols = Constants.dataOnlineResourceProtocols;
    this.spatialTypes = Constants.spatialRepresentationTypes;
    this.metadataQualities = Constants.metadataQualities;
    this.contactTypes = Constants.contactTypes;
    this.languages = Constants.languages;

    this._projectService.getUserProjects();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Lance la requête pour l'autocomplétion des geonames
   * @param event - event primeng
   */
  public searchGeonames(event: any) {
    this.data.extentDescription = event.query || "";
    this._mapService.getSearchList(event.query);
  }

  /**
   * Mise à jour de l'emprise quand un geoname est sélectionné
   */
  public updateExtent() {
    if (this.chosenGeoname) {
      this.data.extentDescription = this.chosenGeoname.label;
      const existentExtent = _.find(this.data.extents, e => equalsExtent(e, this.chosenGeoname.extent));
      if (!existentExtent) {
        // trick pour forcer la maj de la carte
        this.data.extents = [_.clone(this.chosenGeoname.extent)].concat(this.data.extents);
      }
    } else {
      this.data.extentDescription = "";
    }
  }

  /**
   * Met à jour la traçabilité, si elle est vide, en fonction de l'étude choisie
   */
  public updateLineageWithProject() {
    if (this.isNew && !this.data.lineage) {
      this._setLineageFromProject(_.find(this.projects, { id: this.data.projectId }));
    }
  }

  public checkTemporalExtentDates() {
    this.timeExtentStartField.control.setErrors(null);
    this.timeExtentEndField.control.setErrors(null);

    if (this.data.temporalExtentStart || this.data.temporalExtentEnd) {
      if (!this.data.temporalExtentStart) {
        this.timeExtentStartField.control.setErrors({ missing: true });
      } else if (!this.data.temporalExtentEnd) {
        this.timeExtentEndField.control.setErrors({ missing: true });
      } else if (this.data.temporalExtentStart >= this.data.temporalExtentEnd) {
        this.timeExtentStartField.control.setErrors({ range: true });
      }
    }
  }

  /**
   * Demande la donnée à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initDataFromParams(params: any) {
    this._loader.show();
    let dataId = params.datasetId;
    this._dataService.getData(dataId);
    this.isNew = (dataId === 'new');
    if (params.projectId) {
      let projectId = params.projectId;
      this.currentProjectId = projectId;
      if (this.isNew) {
        this.data.projectId = projectId;
      }
      this._projectService.getProject(projectId, false);
    } else {
      this.isInProject = false;
    }
    if (this.isNew) {
      this.cancelRoute[0] += '../';
      if (this.isInProject) {
        this.cancelRoute[0] += '../';
      }
    }
  }

  /**
   * Clone la donnée reçue du serveur pour en faire un objet éditable
   * @param data - Donnée issue du serveur
   */
  private _initData(data: Data) {
    this._initialData = _.cloneDeep(data);
    let isEditableObs: Observable<MetadataEditing>;
    if (this.isNew) {
      this._initialData.name = "";
      // === à retirer après multi-contact
      this._initialData.ownerName = this._session.currentUser.email;
      this._initialData.ownerOrganisation = "BRGM";
      // === fin à retirer
      this._initialData.typeName = "Donnée d'étude VigiRisks";

      let firstContact = new Contact();
      firstContact.individualName = this._session.currentUser.email;
      firstContact.organisationName = "BRGM";
      firstContact.role = "pointOfContact";
      this._initialData.contacts.push(firstContact);
      isEditableObs = of(null);
    } else {
      isEditableObs = this._resourceService.getResourceEditingStatus(data.id, Constants.OBJECT_TYPE_DATASET);
    }
    isEditableObs.subscribe(me => {
      if (me) {
        this.nonEditableInfos = me;
      } else if (!this.isNew) {
        this._resourceService.setResourceAsEditing(data.id, Constants.OBJECT_TYPE_DATASET, true);
      }
      this.data = _.cloneDeep(this._initialData);

      this.filesToUpload = [];
      _.each(this.data.files, (file: OnlineResource) => {
        this.filesToUpload.push({
          label: file.name,
          existent: true,
          url: file.url
        });
      });

      this.chosenGeoname = new Destination().deserialize({
        label: this.data.extentDescription,
        extent: this.data.extents.length > 0 ? this.data.extents[0] : null
      });
      this.keywords = this.data.freeKeywords.join(', ');
      this.checkPointsOfContacts();
      this._loader.hide();
    });
  }

  /**
   * Met à jour la liste des études et éventuellement la traçabilité
   * @param projects Liste des études
   */
  private _setProjectsList(projects: Project[]) {
    this.projects = projects;

    if (this.isNew && this.isInProject && !this.data.lineage) {
      this._setLineageFromProject(_.find(this.projects, { id: this.currentProjectId }));
    }
  }

  /**
   * Définit le texte de traçabilité en fonction de l'étude donnée
   * @param project Étude
   */
  private _setLineageFromProject(project: Project) {
    if (project) {
      this.data.lineage = $localize`Donnée produite ou utilisée dans le cadre de l'étude '${project.name}'`;
    }
  }

  /**
   * Enregistre la donnée et reviens à la liste des données
   */
  public save(editForm: NgForm) {
    if (editForm.invalid) {
      return;
    }
    this._loader.show();
    if (this.keywords) {
      this.data.freeKeywords = this.keywords.split(',').map(k => k.trim());
    } else {
      this.data.freeKeywords = [];
    }
    this.data.addClassifiedKeywords(Constants.TYPOLOGIE_KEYWORD_NAME, [this.data.typeName], true);
    this.data.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.data.thematics, true);
    this.data.addClassifiedKeywords(Constants.METADATA_QUALITY_KEYWORD_NAME, [this.data.metadataQuality], true);
    this.data.addClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME, this.data.keywords, true);

    this._dataService.saveData(this.data, this.filesToUpload)
      .subscribe(result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'une donnée" : "Modification d'une donnée",
          properties: {
            category: this.data.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew) {
            this._toastr.success($localize`La donnée ${this.data.name} a été créée avec succès`);
            this._session.setInitPageParams({ id: result.id, type: 'data' });
          } else {
            this._toastr.success($localize`La donnée ${this.data.name} a été modifiée avec succès`);
          }
          this._router.navigate(this.cancelRoute, { relativeTo: this._route });
        }
      }, error => console.error(error));
  }

  /**
   * Ajoute un lien ou service
   */
  public addOnlineResource() {
    this.data.onlineResources.push(new OnlineResource());
  }

  /**
   * Supprime un lien ou service
   * @param index emplacement de l'objet
   */
  public removeOnlineResource(index: number) {
    this.data.onlineResources.splice(index, 1);
  }

  /**
   * Ajoute un contact
   */
  public addContact() {
    this.data.contacts.push(new Contact());
  }

  /**
   * Supprime un contact
   * @param index emplacement de l'objet
   */
  public removeContact(index: number) {
    this.data.contacts.splice(index, 1);
    this.checkPointsOfContacts();
  }

  /**
   * Vérifie si on peut encore supprimer des "pointOfContact"
   */
  public checkPointsOfContacts() {
    let pointOfContacts = _.filter(this.data.contacts, { role: "pointOfContact" });
    this.canDeletePointOfContact = (pointOfContacts.length > 1);
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param group
   */
  public trackById(group) {
    return group.id;
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByValue(item) {
    return item.value;
  }

  /**
   * Action en quittant la page
   */
  public canDeactivate(): boolean {
    if (!this.nonEditableInfos && !this.isNew) {
      this._resourceService.setResourceAsEditing(this.data.id, Constants.OBJECT_TYPE_DATASET, false);
    }

    return true;
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public categoriesAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY, event.query, {});
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public keywordsAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD, event.query, {});
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case Constants.REGISTRY_CATEGORY:
        this.categories = event.datas;
        break;
      case Constants.REGISTRY_KEYWORD:
        this.registryKeywords = event.datas;
        break;
    }
  }

}
