<nav id="menu" class="h-100 d-flex flex-column" [ngClass]="{collapsed: collapsed}">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-projects" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes études"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-folder-open"></i>
        <span i18n>Mes études</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-workflows" routerLinkActive="active" i18n-ngbTooltip
        ngbTooltip="Mes workflows" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip"
        container="body">
        <i class="fa fa-sitemap"></i>
        <span i18n>Mes workflows</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-datas" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes données"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-file-alt"></i>
        <span i18n>Mes données</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/my-links" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Mes liens"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-link"></i>
        <span i18n>Mes liens</span>
      </a>
    </li>
  </ul>
  <hr>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/projects" routerLinkActive="active" i18n-ngbTooltip
        ngbTooltip="Toutes les études" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip"
        container="body">
        <i class="fa fa-folder-open"></i>
        <span i18n>Toutes les études</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/workflows" routerLinkActive="active" i18n-ngbTooltip
        ngbTooltip="Tous les workflows" placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip"
        container="body">
        <i class="fa fa-sitemap"></i>
        <span i18n>Tous les workflows</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/resources" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Recherche"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-search"></i>
        <span i18n>Recherche</span>
      </a>
    </li>
  </ul>
  <hr>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/help" routerLinkActive="active" i18n-ngbTooltip ngbTooltip="Aide"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-question-circle"></i>
        <span i18n>Aide</span>
      </a>
    </li>
  </ul>
  <ul class="nav flex-column mt-auto">
    <li class="nav-item">
      <button type="button" class="nav-link" (click)="toggleMenu()" i18n-ngbTooltip ngbTooltip="Déplier le menu"
        placement="right" [disableTooltip]="!collapsed" tooltipClass="menu-tooltip" container="body">
        <i class="fa fa-chevron-left"></i>
        <span i18n>Replier le menu</span>
      </button>
    </li>
  </ul>
  <hr class="my-0">
  <div class="d-flex align-items-center app-version flex-wrap">
    <p class="mb-0 px-2 py-1">
      <ng-container i18n>Version : </ng-container> {{appVersion}}
    </p>
    <a href="https://www.brgm.fr" target="_blank" class="d-inline-block ml-auto px-2 py-1">www.brgm.fr</a>
  </div>
</nav>