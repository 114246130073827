import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, of, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';

import { Data, Project, OnlineResource, FileToUpload } from '../models';
import { Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Constants } from '../constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  /**
   * Source de la liste des données
   */
  private datasSource = new Subject<Data[]>();

  /**
   * Source d'une donnée unique
   */
  private dataSource = new Subject<Data>();

  /**
   * Cache des données pour les inputs file
   */
  private _dataCache: Data[] = [];

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de données
   */
  public datas$ = this.datasSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'une donnée unique
   */
  public data$ = this.dataSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router
  ) { }

  /**
   * Demande la récupération des données dont le user est propriétaire
   */
  public getUserDatas(): void {
    this._http.get<Data[]>(Uris.DATAS + '?permission=editor')
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l))),
        switchMap(datas => {
          return this._http.get<Project[]>(Uris.PROJECTS)
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(datas, data => {
                  data.project = _.find(projects, { id: data.projectId });
                });
                return datas;
              })
            )
        })
      ).subscribe(
        datas => this.datasSource.next(datas),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des données, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Demande la récupération de toutes les données auxquelles le user a accès
   * @param needCache Faut-il mettre le résultat en cache ?
   */
  public getAllDatas(needCache: boolean = false) {
    this._http.get<Data[]>(Uris.DATAS)
      .pipe(
        map(datas => datas.map(l => new Data().deserialize(l))),
        switchMap(datas => {
          return this._http.get<Project[]>(Uris.PROJECTS)
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(datas, data => {
                  data.project = _.find(projects, { id: data.projectId }) || null;
                });
                return datas;
              })
            )
        }),
        tap(datas => {
          if (needCache) {
            this._dataCache = _.cloneDeep(datas);
          }
        })
      ).subscribe(
        datas => this.datasSource.next(datas),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des données, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Renvoie les données conservées en cache
   */
  public getCachedData(): Data[] {
    return _.cloneDeep(this._dataCache);
  }

  /**
   * Demande la récupération d'une donnée précis
   * @param id - identifiant de la donnée
   */
  public getData(id: string): void {
    if (id === 'new') {
      let newData = new Data();
      newData.name = $localize`Nouvelle donnée`;
      this.dataSource.next(newData);
    } else {
      this._http.get<Data>(Uris.DATAS + id)
        .pipe(
          map(data => new Data().deserialize(data)),
          map((data: Data) => {
            // Réordonner les online resources pour mettre les liens bibliographiques en premier
            let alphaResources: OnlineResource[] = [];
            let otherResources: OnlineResource[] = [];

            _.each(data.onlineResources, (ol: OnlineResource) => {
              if (Constants.dataOnlineResourceProtocols.indexOf(ol.protocol) < 0) {
                ol.protocol = "Lien bibliographique"; // Rétrocompatibilité 1.0.X
              }
              if (ol.protocol === "Lien bibliographique") {
                alphaResources.push(ol);
              } else {
                otherResources.push(ol);
              }
            });

            data.onlineResources = alphaResources.concat(otherResources);

            return data;
          }),
        )
        .subscribe(
          data => this.dataSource.next(data),
          error => {
            console.error(error);
            this._loader.hide();
            if (error.status === 403) {
              this._toastr.error($localize`Vous n'êtes pas autorisé à consulter cette donnée.`);
              this._router.navigate(['/my-datas']);
            } else if (error.status === 404) {
              this._toastr.error($localize`Cette donnée n'existe pas ou plus.`);
              this._router.navigate(['/my-datas']);
            } else {
              this._toastr.error($localize`Une erreur est survenue pendant la récupération de la donnée, veuillez réessayer plus tard.`);
            }
          }
        );
    }
  }

  /**
   * Enregistre une donnée
   * @param data - donnée à enregistrer
   */
  public saveData(data: Data, files?: FileToUpload[], getNewData: boolean = false): Observable<any> {
    data = _.cloneDeep(data);
    let obs, isNew = false;
    if (data.id) {
      obs = this._http.put<any>(Uris.DATAS + data.id, data.serialize());
    } else {
      isNew = true;
      obs = this._http.post<any>(Uris.DATAS, data.serialize());
    }
    return obs
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à modifier cette donnée.`);
            this._router.navigate(['/my-datas']);
          } else if (error === 404) {
            this._toastr.error($localize`Cette donnée n'existe pas ou plus.`);
            this._router.navigate(['/my-datas']);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement de la donnée, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        }),
        tap((result: any) => data.id = result.id),
        switchMap((result: any) => {
          if (files) {
            const formData: FormData = new FormData();
            let addedFiles: any = [];
            let deletedFiles: string[] = [];
            _.each(files, (file: FileToUpload) => {
              if (!file.existent && file.file) {
                addedFiles.push(file.file);
                formData.append('file', file.file);
              }
              if (file.existent && file.deleted) {
                deletedFiles.push(file.label);
              }
            });

            let deleteCall: Observable<any> = of(null);
            let addCall: Observable<any> = of(null);

            if (deletedFiles.length > 0) {
              deleteCall = this._http.post<any>(Uris.DATAS + result.id + '/deletefiles', deletedFiles)
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue lors de la suppression de fichiers associés à la donnée, veuillez réessayer plus tard.`);
                    if (error && isNew) {
                      return this.deleteData(data)
                        .pipe(
                          map(() => throwError(error))
                        );
                    }
                    return throwError(error);
                  })
                );
            }

            if (addedFiles.length > 0) {
              addCall = this._http.post<any>(Uris.DATAS + result.id + '/files', formData)
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement des fichiers associés à la donnée, veuillez réessayer plus tard.`);
                    if (error && isNew) {
                      return this.deleteData(data)
                        .pipe(
                          map(() => throwError(error))
                        );
                    }
                    return throwError(error);
                  })
                );
            }

            return deleteCall.pipe(
              tap(deleteResult => {
                if (deleteResult && _.isArray(deleteResult)) {
                  let notDeleted = [];
                  _.each(deleteResult, r => {
                    if (!r.deleted) {
                      notDeleted.push(r.fileName);
                    }
                  })
                  if (notDeleted.length > 0) {
                    this._toastr.warning(
                      $localize`Suite à une erreur, les fichiers suivants n'ont pas été correctement supprimés : ${notDeleted.join(', ')}`
                    );
                  }
                }
              }),
              switchMap(() => addCall.pipe(
                tap(addResult => {
                  if (addResult && _.isArray(addResult)) {
                    let notAdded = [];
                    let renamed = [];
                    _.each(addResult, r => {
                      if (!r.uploaded) {
                        notAdded.push(r.originalFileName);
                      } else if (r.fileName !== r.originalFileName) {
                        renamed.push(r.originalFileName + ' -> ' + r.fileName);
                      }
                    });
                    if (notAdded.length > 0) {
                      this._toastr.warning(
                        $localize`Suite à une erreur, les fichiers suivants n'ont pas été correctement ajoutés : ${notAdded.join(', ')}`
                      );
                    }
                    if (renamed.length > 0) {
                      this._toastr.info(
                        $localize`En raison de doublons de noms, les fichiers suivants ont été renommés : ${renamed.join(', ')}`
                      );
                    }
                  }
                })
              )),
              map(() => result)
            );
          }
          return of(result);
        }),
        switchMap(result => this._session.getUserPermissionsObs(result)),
        switchMap((result: any) => {
          if (getNewData) {
            return this._http.get<Data>(Uris.DATAS + result.id)
              .pipe(
                catchError(error => {
                  this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement de la donnée enregistrée.`);
                  return throwError(error);
                }),
                map(data => new Data().deserialize(data))
              )
          }
          return of(result);
        }),
        catchError(error => {
          this._loader.hide();
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer une donnée
   * @param data - donnée à supprimer
   */
  public deleteData(data: Data): Observable<any> {
    return this._http.delete<any>(Uris.DATAS + data.id)
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à supprimer cette donnée.`);
          } else if (error === 404) {
            this._toastr.error($localize`Cette donnée n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement de la donnée, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }

  /**
   * https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
   * @param uri 
   * @param fileName 
   * @returns 
   */
  public getFile(uri: string, fileName: string): Observable<File> {
    return this._http.get<File>(uri, { responseType: 'blob' as 'json' })
    .pipe(
      map(blob => {
        return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type })
      })
    );
  }
}
