import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Link } from 'src/app/models';
import { SessionService, TableFilterService, LinkService, LoaderService } from 'src/app/services';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from '../../../constants';

@Component({
  templateUrl: './links.component.html'
})
export class LinksComponent implements OnInit, OnDestroy {
  /**
   * Liste des liens
   */
  public links: Link[] = [];

  /**
   * Type d'objet projet
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _linkService: LinkService,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._linkService.links$.subscribe(links => this._initLinks(links)));

    this._loader.show();
    if (!this.session.currentUser) {
      this._router.navigate(['/projects']);
      return;
    }
    this._linkService.getUserLinks();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprimme une donnée
   * @param link - donnée à supprimer
   */
  public deleteLink(link: Link): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = `Suppression d'une donnée`;
    modalRef.componentInstance.message = `Voulez-vous vraiment supprimer ce lien : '${link.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = `Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._linkService.deleteLink(link)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de lien",
            properties: {
              category: link.name
            }
          });
          this._toastr.success(`Le lien '${link.name}' a été supprimé avec succès`);
          this._linkService.getUserLinks();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Initialise la liste des liens
   * @param links - liens reçus du serveur
   */
  private _initLinks(links) {
    this.links = links;

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'link') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }

    this._loader.hide();
  }
}
