import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import { Project, Permission } from '../models';
import { Constants, Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  /**
   * Source de la liste des études
   */
  private _projectsSource = new Subject<Project[]>();

  /**
   * Source d'un étude unique
   */
  private _projectSource = new Subject<Project>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de études
   */
  public projects$ = this._projectsSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un étude unique
   */
  public project$ = this._projectSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router
  ) { }

  /**
   * Récupère tous les études auxquels le user a accès
   */
  public getAllProjects(): void {
    this._http.get<Project[]>(Uris.PROJECTS)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des études, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Récupère tous les études dont l'utilisateur courant est propriétaire
   */
  public getUserProjects(role: string = 'editor'): void {
    this._http.get<Project[]>(Uris.PROJECTS + '?permission=' + role)
      .pipe(
        map(projects => projects.map(p => new Project().deserialize(p)))
      )
      .subscribe(
        projects => this._projectsSource.next(projects),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des études, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Récupère le étude actuel ou en renvoie un nouveau à l'observable
   * @param id - ID du étude
   * @param getChildren - (optionnel) récupérer les enfants du projet ? vrai par défaut
   */
  public getProject(id: string, getChildren: boolean = true): void {
    if (id === 'new') {
      let newProject = new Project();
      newProject.name = $localize`Nouvelle étude`;
      this._projectSource.next(newProject);
    } else {
      this._http.get<any>(Uris.PROJECTS + id)
        .pipe(
          catchError(error => {
            if (error.status === 403) {
              this._toastr.error($localize`Vous n'êtes pas autorisé à consulter cette étude.`);
              this._router.navigate(['/my-projects']);
            } else if (error.status === 404) {
              this._toastr.error($localize`Cette étude n'existe pas ou plus.`);
              this._router.navigate(['/my-projects']);
            } else {
              this._toastr.error($localize`Une erreur est survenue pendant la récupération de l'étude, veuillez réessayer plus tard.`);
            }
            return throwError(error);
          }),
          switchMap(project => {
            if (getChildren) {
              return this._http.get<any>(Uris.PROJECTS + id + '/children')
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue pendant la récupération des données et des liens de l'étude.`);
                    return throwError(error);
                  }),
                  map(children => {
                    project.links = children.metadataLinks;
                    project.datas = children.metadataDatas;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          switchMap(project => {
            if (getChildren) {
              return this._http.get<any>(Uris.PROJECTS + id + '/workflows')
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue pendant la récupération des workflows de l'étude.`);
                    return throwError(error);
                  }),
                  map(children => {
                    project.workflows = children.metadataWorkflows;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          switchMap(project => {
            const params = new HttpParams().set('objectType', Constants.OBJECT_TYPE_PROJECT);
            return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/users', { params })
              .pipe(
                catchError(error => {
                  this._toastr.error($localize`Une erreur est survenue pendant la récupération des droits de l'étude.`);
                  return throwError(error);
                }),
                map(rights => {
                  project.individualPermissions = rights;
                  return project;
                })
              );
          }),
          switchMap(project => {
            if (getChildren && (this._session.hasRight(id, Constants.OBJECT_TYPE_PROJECT, 'owner') || this._session.hasRole(Constants.userRoles.admin))) {
              const params = new HttpParams().set('objectType', Constants.OBJECT_TYPE_PROJECT);
              return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/groups', { params })
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue pendant la récupération des droits de l'étude.`);
                    return throwError(error);
                  }),
                  map(rights => {
                    project.groupPermissions = rights;
                    return project;
                  })
                );
            }
            return of(project);
          }),
          map(project => new Project().deserialize(project))
        )
        .subscribe(
          project => this._projectSource.next(project),
          error => {
            console.error(error);
            this._loader.hide();
          }
        );
    }
  }

  /**
   * Compte le nombre d'enfants d'une étude (workflows, données et liens)
   * @param id - ID de l'étude
   */
  public getProjectChildrenCount(id: string): Observable<number> {
    return this._http.get<any>(Uris.PROJECTS + id + '/children')
      .pipe(
        map(children => {
          let count = 0;
          count += children.metadataLinks.length;
          count += children.metadataDatas.length;
          return count;
        }),
        switchMap(count => {
          return this._http.get<any>(Uris.PROJECTS + id + '/workflows')
            .pipe(
              map(children => {
                count += children.metadataWorkflows.length;
                return count;
              })
            );
        })
      );
  }

  /**
   * Enregistre une étude
   * @param project - Étude à enregistrer
   */
  public saveProject(project: Project): Observable<any> {
    let obs;
    if (project.id) {
      obs = this._http.put<any>(Uris.PROJECTS + project.id, project.serialize());
    } else {
      obs = this._http.post<any>(Uris.PROJECTS, project.serialize());
    }
    return obs
      .pipe(
        switchMap(result => this._session.getUserPermissionsObs(result)),
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à modifier cette étude.`);
            this._router.navigate(['/my-projects']);
          } else if (error.status === 404) {
            this._toastr.error($localize`Cette étude n'existe pas ou plus.`);
            this._router.navigate(['/my-projects']);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement de l'étude, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer une étude
   * @param project - étude à supprimer
   */
  public deleteProject(project: Project): Observable<any> {
    return this._http.delete<any>(Uris.PROJECTS + project.id)
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à supprimer cette étude.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`Cette étude n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de la suppression de l'étude, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }
}
