import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SessionService, TableFilterService, LoaderService, DataService } from 'src/app/services';
import { Data } from 'src/app/models';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from '../../../constants';

@Component({
  templateUrl: './datas.component.html'
})
export class DatasComponent implements OnInit, OnDestroy {
  /**
   * Liste des données
   */
  public datas: Data[] = [];

  /**
   * Type d'objet projet
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _filterService: TableFilterService,
    private _dataService: DataService,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._dataService.datas$.subscribe(datas => this._initDatas(datas)));

    this._loader.show();
    if (!this.session.currentUser) {
      this._router.navigate(['/projects']);
      return;
    }
    this._dataService.getUserDatas();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprimme une donnée
   * @param data - donnée à supprimer
   */
  public deleteData(data: Data): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'une donnée`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette donnée : '${data.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._dataService.deleteData(data)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de donnée",
            properties: {
              category: data.name
            }
          });
          this._toastr.success($localize`La donnée '${data.name}' a été supprimée avec succès`);
          this._dataService.getUserDatas();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Initialise la liste des données
   * @param datas - données reçues du serveur
   */
  private _initDatas(datas) {
    this.datas = datas;

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'data') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }

    this._loader.hide();
  }
}
