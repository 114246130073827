import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BboxMapModalComponent } from 'src/app/components/modals';
import { transform } from 'ol/proj';

@Component({
  selector: 'metadata-mult-coord',
  templateUrl: './multiple-coord.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MultipleCoordComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: MultipleCoordComponent, multi: true }
  ]
})
export class MultipleCoordComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  values: any[] = [];
  private _targetProj: string;
  private _displayProj: string;

  @ViewChild('coordX') xField;
  @ViewChild('coordY') yField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this._targetProj = this.metadata.targetProj || 'EPSG:4326';
    this._displayProj = this.metadata.mapConfig.projection || 'EPSG:4326';
  }

  public onChange() {
    this.onChangeCb(this.values);
    this.onMetadataChange.emit(this.metadata);
  }

  public addCoords() {
    this.values.push({ x: 0, y: 0 });
    this.onChange();
  }

  public deleteCoords(index: number) {
    if (this.values.length > index) {
      this.values.splice(index, 1);
      this.onChange();
    }
  }

  public openMap() {
    const modalRef = this.modalService.open(BboxMapModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "map-modal" })

    let editedMetadata = _.cloneDeep(this.metadata);
    let coords = [];
    _.each(this.values, value => {
      coords.push(this._getFormatedCoordValue(value, 'display'));
    });
    editedMetadata.values = coords;

    modalRef.componentInstance.metadata = editedMetadata;

    modalRef.result.then(result => {
      this.writeValue(result);
      this.onChangeCb(result);
      this.onMetadataChange.emit(this.metadata);
    }, () => {

    });
  }

  public onPaste($event) {
    let split = $event.event.clipboardData.getData('Text').split("\n");

    let data: [number, number][] = [];
    _.each(split, s => {
      let split2 = s.split("\t");
      if (split2.length === 2) {
        data.push([parseFloat(split2[0]), parseFloat(split2[1])]);
      }
    });
    let isValid = true;
    _.each(data, d => {
      if (!_.isFinite(d[0]) || !_.isFinite(d[1])) {
        isValid = false;
        return;
      }
    });
    if (isValid) {
      let index = parseInt($event.id.replace('coord', ''), 10);
      if (_.isFinite(index)) {
        _.each(data, d => {
          if (this.values.length > index) {
            this.values[index].x = d[0];
            this.values[index].y = d[1];
          } else {
            this.values.push({ x: d[0], y: d[0] });
          }
          index++;
        });
      }
    }
  }

  private _getFormatedCoordValue(value: any, purpose: string): { x: number, y: number } {
    let x = _.isFinite(value.x) ? value.x : null;
    let y = _.isFinite(value.y) ? value.y : null;

    let formatedValue = { x: x, y: y };

    if (x !== null && y !== null) {
      let coord;
      if (purpose === 'display') {
        coord = transform([x, y], this._targetProj, this._displayProj);
      } else {
        coord = transform([x, y], this._displayProj, this._targetProj);
      }
      formatedValue = { x: coord[0], y: coord[1] };
    }

    return formatedValue;
  }

  // control methods
  writeValue(values) {
    this.values = [];
    _.each(values, value => {
      this.values.push(this._getFormatedCoordValue(value, 'display'));
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations = {};

    this._requiredValidator(validations, control)

    if (_.keys(validations).length > 0) {
      return validations;
    }
    return null;
  }

  private _requiredValidator(validations: any, control: AbstractControl) {
    if (this.metadata.required && (!control.value || control.value.length === 0)) {
      validations.required = true;
    }
  }

}
