import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[app-autofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  /**
   * Délai avant l'autofocus (en ms)
   */
  @Input('autofocusDelay') delay: number = 0;

  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => this._el.nativeElement.focus(), this.delay);
  }

}