import { Component } from "@angular/core";
import { SessionService } from "src/app/services";

@Component({
  templateUrl: './account.component.html'
})
export class AccountComponent {
  constructor(
    public session: SessionService
  ) {}

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(i, item) {
    return item.id;
  }
}