import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { Workflow } from 'src/app/models';
import { SessionService, WorkflowService, TableFilterService, LoaderService } from 'src/app/services';
import { ConfirmModalComponent } from '../../modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from 'src/app/constants';

@Component({
  templateUrl: './workflows.component.html'
})
export class WorkflowsComponent implements OnInit, OnDestroy {
  /**
   * Liste des worflows
   */
  public workflows: Workflow[] = [];

  /**
   * Type d'objet workflow
   */
  public objectTypeWorkflow = Constants.OBJECT_TYPE_WORKFLOW;

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name', 'project.name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * N'afficher que nos propres workflows ?
   */
  public isOnlyMine: boolean = false;

  /**
   * Est-on intégrateur de workflows ?
   */
  public isWorkflowManager: boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _workflowService: WorkflowService,
    private _filterService: TableFilterService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._workflowService.workflows$.subscribe(workflows => this._initWorkflows(workflows)));

    this.isOnlyMine = (this._route.parent.routeConfig.path === 'my-workflows');
    this.isWorkflowManager = this.session.hasRole(Constants.userRoles.workflowManager);

    if (this.isOnlyMine && !this.session.currentUser) {
      this._router.navigate(['/workflows']);
    }

    this._getWorkflows();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprime un workflow
   * @param workflow - workflow à supprimer
   */
  public deleteWorkflow(workflow: Workflow): void {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Suppression d'un workflow`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer ce workflow : '${workflow.name}' ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Supprimer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._workflowService.deleteWorkflow(workflow)
        .subscribe(() => {
          this._tracker.eventTrack.next({
            action: "Suppression de workflow",
            properties: {
              category: workflow.name
            }
          });
          this._toastr.success($localize`Le workflow '${workflow.name}' a été supprimé avec succès`);
          this._getWorkflows();
        }, error => console.error(error));
    }, () => null);
  }

  /**
   * Initialise la liste des workflows
   * @param workflows -  workflows reçus du serveur
   */
  private _initWorkflows(workflows) {
    this.workflows = workflows;

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'workflow') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }

    this._loader.hide();
  }

  /**
   * Demande la liste des workflows
   */
  private _getWorkflows() {
    this._loader.show();
    if (this.isOnlyMine) {
      this._workflowService.getUserWorkflows();
    } else {
      this._workflowService.getAllWorkflows();
    }
  }


}
