<label for="{{metadata.name}}">
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<select id="{{metadata.name}}" class="form-control"
  [name]="metadata.name"
  [(ngModel)]="value"
  [required]="metadata.required"
  [disabled]="isReadOnly || metadata.readOnly"
  (ngModelChange)="onChange()"
  #listField="ngModel">
  <option *ngFor="let option of metadata.list; trackBy: trackByValue" [ngValue]="option.value" [disabled]="option.disabled" [ngClass]="{hidden: option.hidden}">{{option.label}}</option>
</select>
<div class="alert alert-danger" *ngIf="listField.invalid">
  <p *ngIf="listField.errors.required" i18n>Champ requis</p>
  <p *ngIf="listField.errors.min" i18n>La valeur ne doit pas être inférieure à {{metadata.min}}</p>
  <p *ngIf="listField.errors.max" i18n>La valeur ne doit pas être supérieure à {{metadata.max}}</p>
  <p *ngIf="listField.errors.numberType" i18n>La valeur doit être un entier</p>
</div>
