import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService, LoaderService } from "src/app/services";
import { Subscription } from "rxjs";
import { Group } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GroupChoiceModalAdminComponent, AddUserModalAdminComponent } from "../../modals";
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from 'src/app/components/modals';

@Component({
  templateUrl: './user-search.component.html'
})
export class UserSearchAdminComponent implements OnInit, OnDestroy {
  /**
   * Utilisateur sélectionné
   */
  public selectedUser: string;

  /**
   * Liste d'autocomplétion des emails d'utilisateurs
   */
  public emails: string[] = [];

  /**
   * Liste des groupes auxquels appartient l'utilisateur sélectionné
   */
  public groups: Group[] = [];

  /**
   * Liste des utilisateurs de l'application
   */
  private _appUsers: string[] = [];

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _userService: UserService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _loader: LoaderService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.emails$.subscribe(emails => this.emails = emails));

    this._userService.searchUsersObs().subscribe(emails => this._appUsers = emails);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Demande l'autocomplétion des emails d'utilisateurs
   * @param event - événement primeng
   */
  public autocompleteUsers(event: any) {
    this._userService.searchUsers(event.query);
  }

  /**
   * Charge les groupes de l'utilisateur
   * @param email Email de l'utilisateur sélectionné
   */
  public loadGroups(email: string) {
    if (email) {
      this._userService.getUserGroups(email)
        .subscribe(groups => this.groups = _.sortBy(groups, ['name']));
    } else {
      this.groups = [];
    }
  }

  /**
   * Ajoute l'utilisateur à un groupe
   */
  public addGroup() {
    const modalRef = this._modalService.open(GroupChoiceModalAdminComponent, { windowClass: "confirm-modal" });
    modalRef.componentInstance.currentGroups = this.groups;

    modalRef.result.then((group: Group) => {
      if (group) {
        this._loader.show();
        this._userService.addUserToGroup(group, this.selectedUser)
          .subscribe(result => {
            this.groups.push(group);
            this.groups = _.sortBy(this.groups, ['name']);
            this._toastr.success($localize`L'utilisateur ${this.selectedUser} a été ajouté au groupe ${group.name} avec succès.`);
            this._loader.hide();
          }, error => {
            this._toastr.error($localize`Une erreur est survenue lors de l'ajout, veuillez réessayer plus tard.`);
            this._loader.hide();
          });

      }
    }, () => null);
  }

  /**
   * Retire l'utilisateur d'un groupe
   * @param group Groupe duquel retirer l'utilisateur
   */
  public removeGroup(group: Group) {
    const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
    modalRef.componentInstance.title = $localize`Retirer l'utilisateur du groupe`;
    modalRef.componentInstance.message = $localize`Voulez-vous vraiment retirer l'utilisateur ${this.selectedUser} du groupe ${group.name} ?`;
    modalRef.componentInstance.confirmClass = "btn-danger";
    modalRef.componentInstance.confirmText = $localize`Retirer`;

    modalRef.result.then(() => {
      this._loader.show();
      this._userService.removeUserFromGroup(group, this.selectedUser)
        .subscribe(() => {
          this._toastr.success($localize`L'utilisateur ${this.selectedUser} a été retiré du groupe ${group.name} avec succès`);
          this.groups.splice(this.groups.indexOf(group), 1);
          this._loader.hide();
        }, error => {
          console.error(error);
          this._toastr.error($localize`Une erreur est survenue lors de la suppression, veuillez réessayer plus tard.`);
          this._loader.hide();
        });
    }, () => null);
  }

  /**
   * Ajoute un utilisateur à l'application
   */
  public addUser() {
    const modalRef = this._modalService.open(AddUserModalAdminComponent, { windowClass: "confirm-modal" });

    modalRef.result.then(email => {
      if (this._appUsers.indexOf(email) < 0) {
        this._loader.show();
        this._userService.addAppUser(email).subscribe(() => {
          this._toastr.success($localize`L'utilisateur ${email} a été ajouté à Vigirisks avec succès.`);
          this._appUsers.push(email);
          this.selectedUser = email;
          this.loadGroups(email);
          this._loader.hide();
        }, error => {
          console.error(error);
          this._toastr.error($localize`Une erreur est survenue lors de l'ajout, veuillez réessayer plus tard.`);
          this._loader.hide();
        });
      } else {
        this._toastr.warning($localize`L'utilisateur ${email} existe déjà dans Vigirisks.`);
      }
    }, () => null);
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param group 
   */
  public trackById(i, group) {
    return group.id;
  }
}