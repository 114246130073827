import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { UserService } from "src/app/services";
import { Subscription } from "rxjs";

@Component({
  templateUrl: './user-name-modal.component.html'
})
export class UserNameModalAdminComponent implements OnInit, OnDestroy {
  public user: string = "";
  public emails: string[] = [];
  @Input() title: string = "";

  private _subs: Subscription = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.emails$.subscribe(emails => this.emails = emails));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public save(renameForm: NgForm) {
    if(renameForm.valid) {
      this.modal.close(this.user);
    }
  }

  /**
   * Demande l'autocomplétion des emails d'utilisateurs
   * @param event - événement primeng
   */
  public autocompleteUsers(event: any) {
    this._userService.searchUsers(event.query);
  }
}