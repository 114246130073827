<section id="data-edit" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1>
      <ng-container *ngIf="isNew" i18n>Déposer une nouvelle donnée</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier la donnée</ng-container>
    </h1>
    <p class="alert alert-info" *ngIf="nonEditableInfos">
      <i class="fa fa-info-circle"></i>
      <ng-container i18n>Cette donnée est actuellement modifiée par {{nonEditableInfos.userId}}</ng-container>
    </p>
  </div>
  <div class="page-content">
    <form *ngIf="data" class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group row no-gutters align-items-center">
            <label for="data-language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue</ng-container> *
            </label>
            <div class="col">
              <select id="data-language" class="form-control" name="data-language"
                      #languageField="ngModel" [(ngModel)]="data.language" [disabled]="nonEditableInfos" required>
                <option *ngFor="let language of languages" [ngValue]="language.value">
                  {{language.flag}}&nbsp;{{language.label}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-name" class="col-4 mr-3 mb-0">
              <ng-container i18n>Titre</ng-container> *
            </label>
            <div class="col">
              <input type="text" id="data-name" class="form-control" name="data-name" [(ngModel)]="data.name"
                #nameField="ngModel" [disabled]="nonEditableInfos" required>
              <div class="alert alert-danger" *ngIf="editForm.submitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-description" class="col-4 mr-3 mb-0 mt-1" i18n>Description</label>
            <div class="col">
              <textarea name="data-description" class="form-control" id="data-description"
                [(ngModel)]="data.description" #descField="ngModel" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-keywords" class="col-4 mr-3 mb-0">
              <ng-container i18n>Mots-clés</ng-container>
            </label>
            <div class="col">
              <p-autoComplete [(ngModel)]="data.keywords" [multiple]="true" inputId="data-keywords"
                              name="data-keywords" [suggestions]="registryKeywords" field="label" styleClass="form-control w-100"
                              [disabled]="nonEditableInfos" (completeMethod)="keywordsAutoComplete($event)">
              </p-autoComplete>
              <input type="text" id="data-keywords-old" class="form-control" name="data-keywords-old" [(ngModel)]="keywords"
                [disabled]="true">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-thematics" class="col-4 mr-3 mb-0 mt-2" i18n>Thématiques</label>
            <div class="col">
              <p-autoComplete [(ngModel)]="data.thematics" [multiple]="true" inputId="project-thematics"
                name="data-thematics" [suggestions]="categories" field="label" styleClass="form-control w-100"
                [disabled]="nonEditableInfos" (completeMethod)="categoriesAutoComplete($event)">
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-type" class="col-4 mr-3 mb-0">
              <ng-container i18n>Type de donnée</ng-container> *
            </label>
            <div class="col">
              <select id="data-type" class="form-control" name="data-type" [(ngModel)]="data.typeName"
                #subTypeField="ngModel" [disabled]="nonEditableInfos" required>
                <option [ngValue]="null" i18n>-- Choisir un type de donnée --</option>
                <option *ngFor="let subType of subTypes" [ngValue]="subType">{{subType}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && subTypeField.invalid">
                <p *ngIf="subTypeField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-project" class="col-4 mr-3 mb-0">
              <ng-container i18n>Étude</ng-container> *
            </label>
            <div class="col">
              <select id="data-project" class="form-control" name="data-project" [(ngModel)]="data.projectId"
                #projectField="ngModel" [disabled]="isInProject || nonEditableInfos"
                (change)="updateLineageWithProject()" required>
                <option [ngValue]="null" i18n>-- Choisir une étude --</option>
                <option *ngFor="let project of projects; trackBy: trackById" [ngValue]="project.id">{{project.name}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && projectField.invalid">
                <p *ngIf="projectField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters">
            <label for="data-files" class="col-4 mr-3 mb-0 mt-1" i18n>Fichiers</label>
            <div class="col">
              <drop-files inputId="data-files" [(ngModel)]="filesToUpload" #dataFilesFied="ngModel" name="data-files"
                [multiple]="true" [disabled]="nonEditableInfos" [sizeLimit]="fileSizeLimit">
              </drop-files>
              <div class="alert alert-danger" *ngIf="editForm.submitted && dataFilesFied.invalid">
                <p *ngIf="dataFilesFied.errors.size" i18n>Un ou plusieurs fichiers sont trop lourds</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-creation-date" class="col-4 mr-3 mb-0">
              <i class="fa fa-question-circle" i18n-ngbTooltip ngbTooltip="Date de création du jeu de données"
                placement="top" container="body"></i>
              <ng-container i18n>Date de création</ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-creation-date" name="data-creation-date" styleClass="d-flex align-items-stretch"
                inputStyleClass="form-control" [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body"
                [(ngModel)]="data.creationDate" [locale]="localeCalendarConfig[locale]" [showIcon]="true"
                [disabled]="nonEditableInfos"></p-calendar>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-revision-date" class="col-4 mr-3 mb-0">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Date de dernière modification du jeu de données" placement="top" container="body"></i>
              <ng-container i18n>Date de révision</ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-revision-date" name="data-revision-date" styleClass="d-flex align-items-stretch"
                inputStyleClass="form-control" [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body"
                [(ngModel)]="data.revisionDate" [locale]="localeCalendarConfig[locale]" [showIcon]="true"
                [disabled]="nonEditableInfos"></p-calendar>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-publication-date" class="col-4 mr-3 mb-0">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Date à laquelle la métadonnée est validée, publiée" placement="top" container="body"></i>
              <ng-container i18n>Date de publication</ng-container>
            </label>
            <div class="col">
              <p-calendar id="data-publication-date" name="data-publication-date"
                styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body"
                [(ngModel)]="data.publicationDate" [locale]="localeCalendarConfig[locale]" [showIcon]="true"
                [disabled]="nonEditableInfos">
              </p-calendar>
            </div>
          </div>

          <fieldset class="form-group">
            <legend i18n>Étendue temporelle</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="data-time-extent-start" class="col-4 mb-0">
                <ng-container i18n>Début</ng-container>
              </label>
              <div class="col">
                <p-calendar id="data-time-extent-start" name="data-time-extent-start"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentStartField="ngModel"
                  [(ngModel)]="data.temporalExtentStart" [locale]="localeCalendarConfig[locale]" [showIcon]="true"
                  [disabled]="nonEditableInfos" (ngModelChange)="checkTemporalExtentDates()">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="editForm.submitted && timeExtentStartField.invalid">
                  <p *ngIf="timeExtentStartField.errors.missing" i18n>Veuillez fournir les 2 dates ou aucune</p>
                  <p *ngIf="timeExtentStartField.errors.range" i18n>La date de début doit être inférieure à la date de
                    fin</p>
                </div>
              </div>
            </div>
            <div class="row no-gutters align-items-center">
              <label for="data-time-extent-end" class="col-4 mb-0">
                <ng-container i18n>Fin</ng-container>
              </label>
              <div class="col">
                <p-calendar id="data-time-extent-end" name="data-time-extent-end"
                  styleClass="d-flex align-items-stretch" inputStyleClass="form-control"
                  [dateFormat]="localeCalendarConfig[locale].dateFormat" appendTo="body" #timeExtentEndField="ngModel"
                  [(ngModel)]="data.temporalExtentEnd" [locale]="localeCalendarConfig[locale]" [showIcon]="true"
                  [disabled]="nonEditableInfos" (ngModelChange)="checkTemporalExtentDates()">
                </p-calendar>
                <div class="alert alert-danger" *ngIf="editForm.submitted && timeExtentEndField.invalid">
                  <p *ngIf="timeExtentEndField.errors.missing" i18n>Veuillez fournir les 2 dates ou aucune</p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-12 col-xl-6">
          <fieldset class="form-group">
            <legend i18n>Emprises</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="data-extent-description" class="col-4 mb-0" i18n>Description</label>
              <div class="col">
                <p-autoComplete [(ngModel)]="chosenGeoname" id="data-extent-description" name="data-extent-description"
                  [suggestions]="geonames" styleClass="form-control w-100" field="label"
                  [disabled]="nonEditableInfos" (onSelect)="updateExtent()" (onClear)="updateExtent()"
                  (completeMethod)="searchGeonames($event)">
                </p-autoComplete>
              </div>
            </div>
            <div class="row no-gutters align-items-start">
              <label for="data-extent" class="col-4 mb-0 mt-1" i18n>Emprise</label>
              <div class="col">
                <described-extent name="data-extent" id="data-extent" [isEditable]="!nonEditableInfos"
                  [(ngModel)]="data.extents"></described-extent>
              </div>
            </div>
          </fieldset>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-spatial-type" class="col-4 mr-3 mb-0">
              <ng-container i18n>Type de représentation spatiale</ng-container>
            </label>
            <div class="col">
              <select id="data-spatial-type" class="form-control" name="data-spatial-type" [disabled]="nonEditableInfos"
                [(ngModel)]="data.spatialRepresentationType">
                <option [ngValue]="null" i18n>-- Choisir une représentation --</option>
                <option *ngFor="let spatialType of spatialTypes" [ngValue]="spatialType">{{spatialType}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="data-resolution" class="col-4 mr-3 mb-0">
              <ng-container i18n>Résolution spatiale</ng-container>
            </label>
            <div class="col">
              <input type="number" id="data-resolution" class="form-control" name="data-resolution"
                [(ngModel)]="data.spatialResolution" [disabled]="nonEditableInfos">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="metadata-quality" class="col-4 mr-3 mb-0">
              <ng-container i18n>Qualité de la méta-donnée</ng-container> *
            </label>
            <div class="col">
              <select id="metadata-quality" class="form-control" name="metadata-quality" #metadataQualityField="ngModel"
                [(ngModel)]="data.metadataQuality" [disabled]="nonEditableInfos" required>
                <option *ngFor="let metadataQuality of metadataQualities" [ngValue]="metadataQuality">
                  {{metadataQuality}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && metadataQualityField.invalid">
                <p *ngIf="metadataQualityField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-comment" class="col-4 mr-3 mb-0 mt-1" i18n>Commentaire</label>
            <div class="col">
              <textarea name="data-comment" class="form-control" id="data-comment" [(ngModel)]="data.comment"
                [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-lineage" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Toute information utile permettant de préciser la création de la donnée et son cycle de vie"
                placement="top" container="body"></i>
              <ng-container i18n>Traçabilité (généalogie)</ng-container>
            </label>
            <div class="col">
              <textarea name="data-lineage" class="form-control" id="data-lineage" [(ngModel)]="data.lineage"
                [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="data-constraints" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Les contraintes d'utilisation sont définies en fonction de la communicabilité et de la réutilisation des données. Par défaut, les données du BRGM sont publiques et sous licence Etalab. La communicabilité peut être immédiate, décalée (préciser le délai), différée, ou réservée."
                placement="top" container="body"></i>
              <ng-container i18n>Contraintes d'utilisation</ng-container>
            </label>
            <div class="col">
              <textarea name="data-constraints" class="form-control" id="data-constraints"
                [(ngModel)]="data.legalConstraints" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
        </div>
      </div>

      <hr class="form-separator">
      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>Contacts</h2>
        <button *ngIf="!nonEditableInfos" type="button" class="btn btn-default ml-5" (click)="addContact()">
          <i class="fa fa-plus mr-2"></i>
          <ng-container i18n>Ajouter un contact</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let contact of data.contacts; let index = index">
          <div class="col-12 col-xl-6">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Contact</legend>
              <button type="button"
                *ngIf="!nonEditableInfos && (contact.role !== 'pointOfContact' || canDeletePointOfContact)"
                class="btn btn-close" (click)="removeContact(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-name-' + index" class="col-4 mb-0">
                  <ng-container i18n>Nom</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-name-' + index" class="form-control"
                    [name]="'contact-name-' + index" [(ngModel)]="contact.individualName" #contactNameField="ngModel"
                    [disabled]="nonEditableInfos" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactNameField.invalid">
                    <p *ngIf="contactNameField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-email-' + index" class="col-4 mb-0">
                  <ng-container i18n>Email</ng-container>
                </label>
                <div class="col">
                  <input type="email" [id]="'contact-email-' + index" class="form-control"
                    [name]="'contact-email-' + index" [(ngModel)]="contact.email" #contactEmailField="ngModel"
                    [disabled]="nonEditableInfos">
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-organisation-' + index" class="col-4 mb-0">
                  <ng-container i18n>Organisation</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-organisation-' + index" class="form-control"
                    [name]="'contact-organisation-' + index" [(ngModel)]="contact.organisationName"
                    #contactOrganisationField="ngModel" [disabled]="nonEditableInfos" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactOrganisationField.invalid">
                    <p *ngIf="contactOrganisationField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-center">
                <label [for]="'contact-role-' + index" class="col-4 mb-0">
                  <ng-container i18n>Rôle</ng-container> *
                </label>
                <div class="col">
                  <select [id]="'contact-role-' + index" class="form-control" [name]="'contact-role-' + index"
                    [(ngModel)]="contact.role" #contactRoleField="ngModel" (change)="checkPointsOfContacts()"
                    [disabled]="nonEditableInfos || (contact.role === 'pointOfContact' && !canDeletePointOfContact)"
                    required>
                    <option *ngFor="let role of contactTypes; trackBy: trackByValue" [ngValue]="role.value">
                      {{role.label}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactRoleField.invalid">
                    <p *ngIf="contactRoleField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>Liens et services associés</h2>
        <button *ngIf="!nonEditableInfos" type="button" class="btn btn-default ml-5" (click)="addOnlineResource()">
          <i class="fa fa-plus mr-2"></i>
          <ng-container i18n>Ajouter un lien ou un service</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let resource of data.onlineResources; let index = index">
          <div class="col-12 col-xl-6" *ngIf="resource.type !== FILE_RESOURCE_CODE">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Lien ou service</legend>
              <button *ngIf="!nonEditableInfos" type="button" class="btn btn-close"
                (click)="removeOnlineResource(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-url-' + index" class="col-4 mb-0">
                  <ng-container i18n>Url</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'data-or-url-' + index" class="form-control" [name]="'data-or-url-' + index"
                    [(ngModel)]="resource.url" [disabled]="nonEditableInfos" #dataUrlField="ngModel" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && dataUrlField.invalid">
                    <p *ngIf="dataUrlField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-protocol-' + index" class="col-4 mb-0">
                  <ng-container i18n>Type de lien</ng-container> *
                </label>
                <div class="col">
                  <select [id]="'data-or-protocol-' + index" class="form-control" [name]="'data-or-protocol-' + index"
                    [(ngModel)]="resource.protocol" #dataUrlProtocolField="ngModel" [disabled]="nonEditableInfos"
                    required>
                    <option [ngValue]="''" i18n>-- Choisir un type de lien --</option>
                    <option *ngFor="let protocol of protocols" [ngValue]="protocol">{{protocol}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && dataUrlProtocolField.invalid">
                    <p *ngIf="dataUrlProtocolField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'data-or-name-' + index" class="col-4 mb-0" i18n>Nom</label>
                <div class="col">
                  <input type="text" [id]="'data-or-name-' + index" class="form-control"
                    [name]="'data-or-name-' + index" [disabled]="nonEditableInfos" [(ngModel)]="resource.name">
                </div>
              </div>
              <div class="row no-gutters align-items-start">
                <label [for]="'data-or-desc-' + index" class="col-4 mb-0 mt-1" i18n>Description</label>
                <div class="col">
                  <textarea [name]="'data-or-desc-' + index" class="form-control" [id]="'data-or-desc-' + index"
                    [(ngModel)]="resource.description" [disabled]="nonEditableInfos"></textarea>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default" i18n>Annuler</a>
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="nonEditableInfos" i18n>Enregistrer</button>
      </div>
    </form>
  </div>
</section>
