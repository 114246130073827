<i *ngIf="isVisible" class="fa fa-question-circle" [ngbTooltip]="tipContent" placement="top" container="body"></i>

<ng-template #tipContent>
  <p class="mb-0 text-left" *ngIf="metadata.description">{{metadata.description}}</p>
  <div class="row" [ngClass]="{'mt-1': metadata.description}" *ngIf="minValue !== null || maxValue !== null">
    <p class="col mb-0 text-center" *ngIf="minValue !== null">
      <strong i18n>Min:</strong>
      <ng-container *ngIf="metadata.strictMin"> &gt; </ng-container>{{minValue}}
    </p>
    <p class="col mb-0 text-center" *ngIf="maxValue !== null">
      <strong i18n>Max:</strong>
      <ng-container *ngIf="metadata.strictMax"> &lt; </ng-container>{{maxValue}}
    </p>
  </div>
  <p class="mb-0 text-left" *ngIf="restrictions">
    <strong i18n>Fichiers autorisés:</strong>
    {{restrictions}}
  </p>
</ng-template>