import * as _ from 'lodash';

export class SearchData {
  /**
   * Texte à rechercher
   */
  text: string = "";

  /**
   * Rechercher dans les études ?
   */
  searchProjects: boolean = true;

  /**
   * Rechercher dans les workflows ?
   */
  searchWorkflows: boolean = true;

  /**
   * Rechercher dans les données ?
   */
  searchDatas: boolean = true;

  /**
   * Rechercher dans les liens ?
   */
  searchLinks: boolean = true;

  /**
   * Type de workflow à chercher
   */
  workflowType: string = null;

  /**
   * Type de donnée à chercher
   */
  dataType: string = null;

  /**
   * Type de lien à chercher
   */
  linkType: string = null;

  /**
   * Id du user propriétaire des ressources recherchées
   */
  ownerId: string = null;

  /**
   * Emprise dans laquelle chercher
   */
  extent: [number, number, number, number] = null;

  /**
   * Thématique
   */
  thematic: string = null;

  /**
   * Modèle à envoyer au back
   */
  private _jsonModel: any;

  /**
   * Sérialise l'objet pour la requête serveur
   */
  public serialize(): any {
    let metadataTypes = []
    
    if(this.searchProjects) {
      metadataTypes.push({
        type: 'PROJECT',
        subType: null
      });
    }
    if(this.searchWorkflows) {
      metadataTypes.push({
        type: 'WORKFLOW',
        subType: this.workflowType
      });
    }
    if(this.searchLinks) {
      metadataTypes.push({
        type: 'LINK',
        subType: this.linkType
      });
    }
    if(this.searchDatas) {
      metadataTypes.push({
        type: 'DATASET',
        subType: this.dataType
      });
    }

    let extent = null;
    if(this.extent) {
      extent = {
        eastBoundLongitude: this.extent[0],
        northBoundLatitude: this.extent[1],
        westBoundLongitude: this.extent[2],
        southBoundLatitude: this.extent[3]
      };
    }

    this._jsonModel = {
      extent: extent,
      freeText: this.text,
      metadataType: metadataTypes,
      owner: this.ownerId,
      maxRecords: 100,
      startPosition: 0,
      thematic: this.thematic
    };

    return this._jsonModel;
  }
}
