import * as _ from 'lodash';

import { EntityBusinessObject } from './entity-business-object.model';
import { OnlineResource } from './online-resource.model';
import { Constants } from '../constants/app.constants';
import { Project } from './project.model';

export class Data extends EntityBusinessObject {
  /**
   * Identifiant de l'étude liée
   */
  projectId: string = null;

  /**
   * Identifiant de l'étude liée
   */
  project: Project = null;

  /**
   * Date de création de la donnée d'origine
   */
  creationDate: Date = null;

  /**
   * Date de publication de la donnée d'origine
   */
  publicationDate: Date = null;

  /**
   * Date de dernière révision de la donnée d'origine
   */

  revisionDate: Date = null;

  /**
   * Commentaires / informations supplémentaires
   */
  comment: string = "";

  /**
   * Contraintes d'utilisation
   */
  legalConstraints: string = "";

  /**
   * Type de représentation spatiale
   */
  spatialRepresentationType: string = null;

  /**
   * Résolution spatiale
   */
  spatialResolution: number = null;

  /**
   * Traçabilité
   */
  lineage: string = "";

  /**
   * Liste des resources de la donnée
   */
  onlineResources: OnlineResource[] = [];

  /**
   * Lien vers le fichier
   */
  file: OnlineResource = null;

  /**
   * Lien vers les fichiers
   */
  files: OnlineResource[] = [];

  /**
   * Nom du type de donnée
   */
  typeName: string = null;

  /**
   * Début de l'étendue temporelle
   */
  temporalExtentStart: Date = null;

  /**
   * Fin de l'étendue temporelle
   */
  temporalExtentEnd: Date = null;

  /**
   * Qualité de la métadonnée
   */
  metadataQuality: string = "Incomplet";

  /**
   * Langue
   */
  language: string = "fre";

  constructor() {
    super();

    this._mapperDefs = this._mapperDefs.concat([
      { front: "projectId", back: "parentIdentifier" },
      { front: "creationDate", class: Date },
      { front: "publicationDate", class: Date },
      { front: "revisionDate", class: Date },
      { front: "comment", back: "supplementalInformation" },
      { front: "spatialRepresentationType", back: "spatialRepresentationType.codeListValue" },
      { front: "lineage", back: "dataQualityLineage" },
      { front: "onlineResources", class: OnlineResource },
      { front: 'temporalExtentStart', back: "extent.start", class: Date },
      { front: 'temporalExtentEnd', back: "extent.end", class: Date }
    ]);
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    this.typeName = this.getUniqueClassifiedKeyword(Constants.TYPOLOGIE_KEYWORD_NAME);

    const metadataQuality = this.getUniqueClassifiedKeyword(Constants.METADATA_QUALITY_KEYWORD_NAME);
    if (metadataQuality) {
      this.metadataQuality = metadataQuality;
    }

    let downloadResource = _.filter(this.onlineResources, { type: Constants.FILE_RESOURCE_CODE });
    if (downloadResource.length > 0) {
      this.file = downloadResource[downloadResource.length - 1];
      this.files = downloadResource;
    }

    return this;
  }

}
