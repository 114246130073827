import { Component, ContentChild, TemplateRef, OnInit, OnDestroy } from "@angular/core";
import { CounterContentTemplateDirective } from "../../directives";
import { Subscription } from "rxjs";
import { FilterEvent, FilterService } from "../../services";

@Component({
  selector: 'vigirisks-table-counter',
  templateUrl: './counter.component.html'
})
export class CounterComponent implements OnInit, OnDestroy {
  /**
   * Directives et templates pour le composant
   */
  @ContentChild(CounterContentTemplateDirective, /* TODO: add static flag */ {}) public counterDir: CounterContentTemplateDirective;

  get counterTpl(): TemplateRef<any> {
    return this.counterDir && this.counterDir.tpl;
  }

  /**
   * Numéro de début et de fin des éléments affichés
   */
  public pageRange: [number, number] = [0, 0];

  /**
   * Nombre de résultats trouvés
   */
  public totalResults: number = 0;

  /**
   * Souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(private _filterService: FilterService) { }

  ngOnInit() {
    this._subs.add(this._filterService.tableFiltered$.subscribe(event => this._updateFromSettings(event)));

    this._filterService.updateFilters({});
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Met à jour les nombres à afficher en fonction des paramètres de filtre
   * @param event Paramètres de filtre
   */
  private _updateFromSettings(event: FilterEvent) {
    this.totalResults = event.collectionSize;

    this.pageRange[0] = ((event.page - 1) * event.itemsPerPage) + 1;
    this.pageRange[1] = this.pageRange[0] + event.itemsPerPage - 1;
    if(this.pageRange[1] > event.collectionSize) {
      this.pageRange[1] = event.collectionSize;
    }
  }
}