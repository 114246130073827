import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";

@Component({
  templateUrl: './execution-name-modal.component.html'
})
export class ExecutionNameModalComponent implements OnInit {
  @Input() executionName: string = "";
  @Input() executionDraft: boolean = false;
  @Input() title: string = "";

  public isNew: boolean;

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.isNew = !this.executionName;
  }

  public save(renameForm: NgForm) {
    if(renameForm.valid) {
      this.modal.close({
        name: this.executionName,
        draft: this.executionDraft
      });
    }
  }
}