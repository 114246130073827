import { EntityMapper } from "./entity-mapper.model";
import { Permission } from "./permission.model";
import { Group } from "./group.model";

export class User extends EntityMapper {
  id: number = null;
  username: string = "";
  completeName: string = "";
  email: string = "";
  isExpert: boolean = false;
  roles: string[] = [];
  groups: Group[] = [];

  /**
   * Liste des permissions de l'utilisateur
   */
  permissions: Permission[] = [];
  
  constructor() {
    super();

    this._mapperDefs = [
      { front: 'id', back: 'email' },
      { front: 'roles', back: 'authorities' },
      { front: 'permissions', class: Permission }
    ];
  }
}
