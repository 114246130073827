import * as _ from 'lodash';
import { extend as extendExtent } from 'ol/extent';

import { EntityMapper } from "./entity-mapper.model";
import { ClassifiedKeyword } from "./classified-keyword.model";
import { Permission } from './permission.model';
import { Constants } from '../constants/app.constants';
import { Contact } from './contact.model';
import { Registry } from './registry.model';

export abstract class EntityBusinessObject extends EntityMapper {

  /**
   * Identifiant unique
   */
  id: string = "";

  /**
   * Nom
   */
  name: string = "";

  /**
   * Description
   */
  description: string = "";

  /**
   * Uri de l'entité
   */
  uri: string = "";

  /**
   * Propriétaires
   */
  owners: string[] = [];

  /**
   * Nom du propriétaire de l'entité
   */
  ownerName: string = "";

  /**
   * Nom de l'organisation du propriétaire de l'entité
   */
  ownerOrganisation: string = "";

  /**
   * Contacts de la ressource
   */
  contacts: Contact[] = [];

  /**
   * Id technique de la liste des emprises
   */
  extentId: number = null;

  /**
   * Description de la liste des emprises
   */
  extentDescription: string = "";

  /**
   * Liste des emprises traitées par l'entité
   */
  extents: Array<[number, number, number, number]> = [];

  /**
   * Liste des mots-clés libres associés
   */
  freeKeywords: string[] = [];

  /**
   * Liste des mots-clés libres associés
   */
  classifiedKeywords: ClassifiedKeyword[] = [];

  /**
   * Dernière mise à jour
   */
  lastUpdate: Date = null;

  /**
   * Permissions pour des groupes
   */
  groupPermissions: Permission[] = [];

  /**
   * Permissions individuelles
   */
  individualPermissions: Permission[] = [];

  /**
   * Liste des thématiques
   */
  thematics: Registry[] = [];

  /**
   * Liste des mots-clés
   */
  keywords: Registry[] = [];

  constructor() {
    super();

    this._mapperDefs = [
      { front: "name", back: "title" },
      { front: "description", back: "detailedAbstract" },
      { front: "classifiedKeywords", class: ClassifiedKeyword },
      { front: "extentId", back: "extent.id" },
      { front: "extentDescription", back: "extent.description" },
      { front: "uri", back: "ownUri" },
      { front: "ownerName", back: "owner.individualName" },
      { front: "ownerOrganisation", back: "owner.organisationName" },
      { front: "groupPermissions", class: Permission },
      { front: "individualPermissions", class: Permission },
      { front: "lastUpdate", back: "metadataRevisionDate", class: Date },
      { front: "contacts", class: Contact }
    ];
  }

  public deserialize(json: any): this {
    super.deserialize(json);

    if (this._jsonModel.extent && this._jsonModel.extent.geographicElements) {
      _.each(this._jsonModel.extent.geographicElements, extent => {
        this.extents.push([
          extent.westBoundLongitude,
          extent.northBoundLatitude,
          extent.eastBoundLongitude,
          extent.southBoundLatitude,
        ]);
      });
    }

    this.generateOwners();
    this.thematics = this.getClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME) || [];
    this.thematics.sort();
    this.keywords = this.getClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME) || [];
    this.keywords.sort();

    return this;
  }

  public serialize() {
    super.serialize();

    this._jsonModel.extent.geographicElements = [];
    _.each(this.extents, extent => {
      if (extent) {
        this._jsonModel.extent.geographicElements.push({
          "eastBoundLongitude": extent[2],
          "northBoundLatitude": extent[1],
          "southBoundLatitude": extent[3],
          "westBoundLongitude": extent[0]
        });
      }
    });

    return this._jsonModel;
  }

  /**
   * Génère la liste des propriétaires de la métadonnée à partir des permissions
   */
  public generateOwners() {
    this.owners = [];
    _.each(this.individualPermissions, permission => {
      if (permission.code === 'owner') {
        this.owners.push(permission.userId);
      }
    });
  }

  /**
   * Retrouve un type de mot-clé et y ajoute les nouveaux mot-clés
   * @param keywordType Type de mot-clé
   * @param keywords Liste des mot-clés à ajouter
   * @param cleanFirst (optionnel) Vider les mot-clés de ce type d'abord ? Faux par défaut
   */
  public addClassifiedKeywords(keywordType: string, keywords: string[], cleanFirst: boolean = false) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (!keywordObject) {
      keywordObject = new ClassifiedKeyword();
      keywordObject.typeCodeValue = keywordType;
      this.classifiedKeywords.push(keywordObject);
    }
    if (cleanFirst) {
      keywordObject.keywords = [];
    }
    _.each(keywords, keyword => {
      keywordObject.keywords.push(keyword);
    });
  }

  /**
   * Retrouve un type de mot-clé et y ajoute les nouveaux mot-clés
   * Cette méthode est utilisé pour le stockage des registres (id + label)
   * @param keywordType Type de mot-clé
   * @param keywords Liste des mot-clés à ajouter (id + label)
   * @param cleanFirst (optionnel) Vider les mot-clés de ce type d'abord ? Faux par défaut
   */
  public addClassifiedKeywordsWithLink(keywordType: string, keywords: Registry[], cleanFirst: boolean = false) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (!keywordObject) {
      keywordObject = new ClassifiedKeyword();
      keywordObject.typeCodeValue = keywordType;
      this.classifiedKeywords.push(keywordObject);
    }
    if (cleanFirst) {
      keywordObject.keywordsWithLink = [];
    }
    _.each(keywords, keyword => {
      keywordObject.keywordsWithLink.push(keyword);
    });
  }

  /**
   * Supprime des mots-clés d'un type donné. Supprime le type de mot-clé si la liste de mot-clés finale est vide
   * @param keywordType Type de mot-clé
   * @param keywords (optionnel) Liste des mots-clés à supprimer. Supprime le type de mot-clé si non fourni.
   */
  public removeClassifiedKeywords(keywordType: string, keywords?: string[]) {
    let keywordObject = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      if (keywords) {
        _.each(keywords, keyword => {
          const index = keywordObject.keywords.indexOf(keyword);
          if (index > -1) {
            keywordObject.keywords.splice(index, 1);
          }
        });
      } else {
        keywordObject.keywords = [];
      }
      if (keywordObject.keywords.length === 0) {
        this.classifiedKeywords.splice(this.classifiedKeywords.indexOf(keywordObject), 1);
      }
    }
  }

  /**
   * Récupère la liste des mot-clés d'un type donné
   * @param keywordType Type de mot-clés
   */
  public getClassifiedKeywords(keywordType: string): string[] {
    let keywordObject: ClassifiedKeyword = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      return keywordObject.keywords;
    }
    return null;
  }

  /**
   * Récupère la liste des mot-clés d'un type donné
   * @param keywordType Type de mot-clés
   */
  public getClassifiedKeywordsWithLink(keywordType: string): Registry[] {
    let keywordObject: ClassifiedKeyword = _.find(this.classifiedKeywords, { typeCodeValue: keywordType });
    if (keywordObject) {
      return keywordObject.keywordsWithLink;
    }
    return null;
  }

  /**
   * Récupère un mot-clé unique d'un type donné
   * @param keywordType Type de mot-clé
   */
  public getUniqueClassifiedKeyword(keywordType: string): string {
    let keywords = this.getClassifiedKeywords(keywordType);
    if (keywords && keywords.length > 0) {
      return keywords[0];
    }
    return null;
  }

  /**
   * Renvoie l'emprise globale de la métadonnée, null si aucune emprise
   */
  public getExtent(): [number, number, number, number] {
    let extent: [number, number, number, number] = null;
    _.each(this.extents, e => {
      if (!extent) {
        extent = _.clone(e);
      } else {
        extent = extendExtent(extent, e);
      }
    });
    return extent;
  }
}
