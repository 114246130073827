<form #metadataForm="ngForm" (ngSubmit)="submit()" novalidate>
  <ng-container *ngFor="let metadata of metadatas; trackBy: trackByName">
    <div class="form-group metadata-field"
      *ngIf="!metadata.hidden"
      [ngClass]="{'form-check': metadata.type === 'checkbox'}"
      [ngSwitch]="metadata.type">
      <metadata-number         *ngSwitchCase="'number'"     [(ngModel)]="metadata.value"  [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-number>
      <metadata-list           *ngSwitchCase="'list'"       [(ngModel)]="metadata.value"  [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-list>
      <metadata-checkbox       *ngSwitchCase="'checkbox'"   [(ngModel)]="metadata.value"  [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-checkbox>
      <metadata-bbox           *ngSwitchCase="'bbox'"       [(ngModel)]="metadata.value"  [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-bbox>
      <metadata-coord          *ngSwitchCase="'coord'"      [(ngModel)]="metadata.value"  [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)"></metadata-coord>
      <metadata-mult-coord     *ngSwitchCase="'coord_mult'" [(ngModel)]="metadata.values" [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)"></metadata-mult-coord>
      <metadata-info           *ngSwitchCase="'info'"       [metadata]="metadata"></metadata-info>
      <ng-container *ngSwitchCase="'text'">
        <metadata-text           *ngIf="!metadata.multiline" [(ngModel)]="metadata.value" [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-text>
        <metadata-text-multiline *ngIf="metadata.multiline"  [(ngModel)]="metadata.value" [metadata]="metadata" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-text-multiline>
      </ng-container>
      <ng-container *ngSwitchCase="'file'">
        <metadata-file *ngIf="datasList" [(ngModel)]="metadata.value"  [metadata]="metadata" [datasList]="datasList" name="{{metadata.name}}" (onMetadataChange)="onChange($event)" [readonly]="!isEditable"></metadata-file>
      </ng-container>
    </div>
  </ng-container>
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-default btn-block" (click)="cancel()" i18n>Annuler</button>
    </div>
    <div class="col" *ngIf="isEditable">
      <button type="submit" class="btn btn-primary btn-block" [disabled]="metadataForm.invalid" i18n>Appliquer</button>
    </div>
  </div>
</form>
