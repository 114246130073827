<div class="autocomplete ol-control">
  <div>
    <button type="button" class="btn btn-icon search-btn" (click)="updateSearchList()"> <i class="fa fa-search"></i> </button>
    <input type="text" [(ngModel)]="searchText" class="form-control" i18n-placeholder placeholder="Destination" (ngModelChange)="updateSearchList()" />
    <button *ngIf="searchText" type="button" class="btn btn-icon reset-btn" (click)="resetSearch()"> &times; </button>
    <div class="search-list" *ngIf="searchResults.length > 0">
      <ul>
        <li *ngFor="let destination of searchResults">
          <a (click)="goToDestination(destination)">{{destination.label}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
