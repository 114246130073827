import { EntityMapper } from "./entity-mapper.model";

export class Contact extends EntityMapper {

  /**
   * Identifiant technique du contact
   */
  id: number = null;

  /**
   * Nom de la personne
   */
  individualName: string = "";

  /**
   * Nom de l'organisation
   */
  organisationName: string = "";

  /**
   * Mail de contact
   */
  email: string = "";

  /**
   * Rôle pour la ressource
   */
  role: string = "user";

  constructor() {
    super();

    this._mapperDefs = [
      { front: "individualName", back: "individualName" },
      { front: "organisationName", back: "organisationName" },
      { front: "email", back: "email" },
      { front: "role", back: "role" }
    ];
  }
}