import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Project } from 'src/app/models';
import { SessionService, ProjectService, TableFilterService, LoaderService } from 'src/app/services';
import { ConfirmModalComponent } from '../../modals';
import { Angulartics2 } from 'angulartics2';
import { FiltrableTableComponent } from 'src/app/modules/filtrableTable/components';
import { Constants } from '../../../constants';

@Component({
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements OnInit, OnDestroy {
  /**
   * Liste des études
   */
  public projects: Project[] = [];

  /**
   * Type d'objet projet
   */
  public objectTypeProject = Constants.OBJECT_TYPE_PROJECT;

  /**
   * Filtres de la table
   */
  public filters: { [key: string]: { fields: string[], filter: string } } = {
    general: { fields: ['name'], filter: "" }
  };

  /**
   * Composant table filtrable
   */
  @ViewChild('dataTable', { static: true }) dataTable: FiltrableTableComponent;

  /**
   * Est-on dans "mes études" ?
   */
  public isOnlyMine: boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    public session: SessionService,
    private _projectService: ProjectService,
    private _filterService: TableFilterService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _loader: LoaderService,
    private _modalService: NgbModal,
    private _toastr: ToastrService,
    private _tracker: Angulartics2
  ) { }

  public ngOnInit() {
    this._subs.add(this._projectService.projects$.subscribe(projects => this._initProjects(projects)));

    this.isOnlyMine = (this._route.parent.routeConfig.path === 'my-projects');

    if (this.isOnlyMine && !this.session.currentUser) {
      this._router.navigate(['/projects']);
    }

    this._getProjects();
    this.dataTable.customFilter = rows => this._filterService.filterRows(rows, this.filters);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Supprime une étude
   * @param project - étude à supprimer
   */
  public deleteProject(project: Project): void {
    this._projectService.getProjectChildrenCount(project.id)
      .subscribe((count: number) => {
        if (count === 0) {
          const modalRef = this._modalService.open(ConfirmModalComponent, { windowClass: "confirm-modal" })
          modalRef.componentInstance.title = $localize`Suppression d'une étude`;
          modalRef.componentInstance.message = $localize`Voulez-vous vraiment supprimer cette étude : '${project.name}' ?`;
          modalRef.componentInstance.confirmClass = "btn-danger";
          modalRef.componentInstance.confirmText = $localize`Supprimer`;

          modalRef.result.then(() => {
            this._loader.show();
            this._projectService.deleteProject(project)
              .subscribe(() => {
                this._tracker.eventTrack.next({
                  action: "Suppression d'étude",
                  properties: {
                    category: project.name
                  }
                });
                this._toastr.success($localize`L'étude '${project.name}' a été supprimée avec succès`);
                this._getProjects();
              }, error => console.error(error));
          }, () => null);
        } else {
          this._toastr.error($localize`Votre étude doit être totalement vide pour pouvoir être supprimée`);
        }
      }, () => null);

  }

  /**
   * Initialise la liste des études
   * @param projects - études reçues du serveur
   */
  private _initProjects(projects) {
    this.projects = projects;

    let params = this.session.getInitPageParams();
    if (params) {
      if (params.type === 'project') {
        if (params.id) {
          this.dataTable.filter({ target: { value: params.id, field: 'id' } });
        }
      }
    }

    this._loader.hide();
  }

  /**
   * Demande la liste des études
   */
  private _getProjects() {
    this._loader.show();
    if (this.isOnlyMine) {
      this._projectService.getUserProjects();
    } else {
      this._projectService.getAllProjects();
    }
  }
}
