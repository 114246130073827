import * as _ from 'lodash';
import { EntityMapper } from "./entity-mapper.model";

export class Process extends EntityMapper {
  id: number = null;
  name: string = "";
  type: string = "";
  execUrl: string = "";
  metadata: any[] = [];
  edited: boolean = false;
}
