<div class="row no-gutters" *ngIf="value">
  <div class="col pr-2 d-flex align-items-center">
    <label [for]="id + 'x'" class="mb-0 mr-2" i18n>X</label>
    <div class="position-relative">
      <input [id]="id + 'x'" type="number" [name]="id + 'x'" [(ngModel)]="value.x" class="form-control"
        [readonly]="isReadOnly" #coordX="ngModel" (change)="onChange()" (paste)="onPaste($event)">
      <div class="alert alert-danger w-100" *ngIf="coordX.invalid">
        <p *ngIf="coordX.errors.required" i18n>Champ requis</p>
        <p *ngIf="coordX.errors.invalidCoord" i18n>Coordonnée incomplète</p>
      </div>
    </div>
  </div>
  <div class="col pl-2 d-flex align-items-center">
    <label [for]="id + 'y'" class="mb-0 mr-2" i18n>Y</label>
    <div class="position-relative">
      <input [id]="id + 'y'" type="number" [name]="id + 'y'" [(ngModel)]="value.y" class="form-control"
        [readonly]="isReadOnly" #coordY="ngModel" (change)="onChange()" (paste)="onPaste($event)">
      <div class="alert alert-danger w-100" *ngIf="coordY.invalid">
        <p *ngIf="coordY.errors.required" i18n>Champ requis</p>
        <p *ngIf="coordY.errors.invalidCoord" i18n>Coordonnée incomplète</p>
      </div>
    </div>
  </div>
</div>