<div class="edit-fields-form">
  <fieldset>
    <legend>
      <label-info [metadata]="metadata"></label-info>
      {{metadata.label}}
    </legend>
    <ng-container *ngIf="!isReadOnly && !metadata.readOnly">
      <p class="mb-2 p-3 alert-info">
        <i class="fa fa-info-circle mr-2"></i>
        <ng-container i18n>Choisissez une donnée existante ou créez-en une nouvelle en déposant un fichier qui sera ajouté à l'étude courante.</ng-container>
      </p>
      <div class="file-tabs-container">
        <ng-container *ngIf="finalDatasList.length > 0">
          <input type="radio" class="d-none" [(ngModel)]="isNewFile" [value]="false" [name]="metadata.name + '-existing'"
            [id]="metadata.name + '-existing'" (ngModelChange)="changeTab(false)">
          <label class="mb-0 mr-0 py-2 px-3 tab-like" [ngClass]="{active: !isNewFile}"
            [for]="metadata.name + '-existing'">
            Donnée existante
          </label>
        </ng-container>
        <input type="radio" class="d-none" [(ngModel)]="isNewFile" [value]="true" [name]="metadata.name + '-new'"
          [id]="metadata.name + '-new'" (ngModelChange)="changeTab(true)">
        <label class="mb-0 py-2 px-3 tab-like" [ngClass]="{active: isNewFile}" [for]="metadata.name + '-new'">
          Nouvelle donnée
        </label>
      </div>

      <div class="file-content mt-3">
        <div [hidden]="isNewFile">
          <p-dropdown #listField="ngModel" placeholder="-- Choisir une donnée --" i18n-placeholder class="w-100"
            appendTo="body" styleClass="w-100" [name]="metadata.name + '-data'" [options]="finalDatasList" filter="true"
            [group]="true" [(ngModel)]="chosenData" (onChange)="changeData()"
            [disabled]="isReadOnly || metadata.readOnly" [required]="metadata.required">
          </p-dropdown>
        </div>

        <div [hidden]="!isNewFile" [ngClass]="{'file-size-error': isFileError}">
          <p class="mb-0">
            <small>
              <ng-container *ngIf="isFileError">
                <ng-container i18n>Le fichier fournit dépasse la taille limite de</ng-container>&nbsp;{{fileSizeLimit}}<ng-container i18n>Mo, veuillez ajouter la donnée en tant que donnée de l'étude.</ng-container>
              </ng-container>
              <ng-container *ngIf="!isFileError">
                <ng-container i18n>{{fileSizeLimit}}Mo maximum</ng-container>
              </ng-container>
            </small>
          </p>
          <p-fileUpload [name]="metadata.name + '[]'" styleClass="d-flex align-items-center" chooseLabel="Choisir"
            i18n-chooseLabel #fileField [customUpload]="true" (uploadHandler)="changeFile($event)" [auto]="true"
            (onRemove)="changeFile(null)" (onSelect)="changeFile(null)" [disabled]="isReadOnly || metadata.readOnly"
            [accept]="metadata.restrictions || null" [files]="chosenFile ? [chosenFile] : []">
            <ng-template let-file pTemplate="file">
              <div>{{file.name}} ({{(file.size | fileSize)}})</div>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
    </ng-container>
    <p class="mb-0" *ngIf="isReadOnly || metadata.readOnly">
      <a [href]="chosenData.file.url" target="_blank" *ngIf="chosenData">{{chosenData.name}}</a>
      <ng-container *ngIf="!chosenData && chosenFile">{{chosenFile.name}}</ng-container>
      <ng-container *ngIf="!chosenData && !chosenFile" i18n>Aucun fichier choisi</ng-container>
    </p>
  </fieldset>
</div>
