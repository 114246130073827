<form #linkForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': linkForm.submitted}" (ngSubmit)="save()">
  <div class="modal-header">
    <h2 class="modal-title">
      <ng-container *ngIf="project" i18n>Lier un workflow à cette étude</ng-container>
      <ng-container *ngIf="workflow" i18n>Lier ce workflow à une étude</ng-container>
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div *ngIf="workflow">
      <div class="form-group d-flex align-items-center">
        <label for="project-to-link" class="mr-3 mb-0" i18n>Étude à lier</label>
        <select name="project-to-link" id="project-to-link" class="form-control" [(ngModel)]="chosenProject" required>
          <option [ngValue]="null" i18n>-- Choisir une étude à lier --</option>
          <option *ngFor="let project of projects; trackBy: trackById" [ngValue]="project">{{project.name}}</option>
        </select>
      </div>
      <p class="alert alert-info mb-0" i18n>Si votre étude n'apparaît pas dans la liste, cela signifie que ce workflow y est déjà lié.</p>
    </div>  
    <div *ngIf="project">
      <div class="form-group d-flex align-items-center">
        <label for="workflow-to-link" class="mr-3 mb-0" i18n>Workflow à lier</label>
        <select name="workflow-to-link" id="workflow-to-link" class="form-control" [(ngModel)]="chosenWorkflow" required>
          <option [ngValue]="null" i18n>-- Choisir un workflow à lier --</option>
          <option *ngFor="let workflow of workflows; trackBy: trackById" [ngValue]="workflow">{{workflow.name}}</option>
        </select>
      </div>
      <p class="alert alert-info mb-0" i18n>Si le workflow recherché n'apparaît pas dans la liste, cela signifie qu'il est déjà lié à cette étude.</p>
    </div>  
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" 
      (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto" i18n>Lier</button>
  </div>
</form>