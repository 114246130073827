import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

@Component({
  selector: 'metadata-text',
  templateUrl: './text.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TextComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TextComponent, multi: true }
  ]
})
export class TextComponent implements ControlValueAccessor, Validator {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  value: string;

  @ViewChild('textField', { static: true }) textField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor() {}

  onChange() {
    this.onChangeCb(this.value);
    this.onMetadataChange.emit(this.metadata);
  }

  // control methods
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): {[key: string]: any} | null {
    let validations = {};

    //built-in validators
    if(this.metadata.required) {
      validations = _.defaults(Validators.required(control), validations);
    }

    if(_.keys(validations).length > 0) {
      this.textField.control.setErrors(validations);
      return validations;
    }
    this.textField.control.setErrors(null);
    return null;
  }
}
