<label for="{{metadata.name}}">
  <label-info [metadata]="metadata"></label-info>
  {{metadata.label}}
</label>
<input type="text" id="{{metadata.name}}" class="form-control"
  [name]="metadata.name"
  [(ngModel)]="value"
  [required]="metadata.required"
  [readonly]="isReadOnly || metadata.readOnly"
  (ngModelChange)="onChange()"
  #textField="ngModel">
<div class="alert alert-danger" *ngIf="textField.invalid">
  <p *ngIf="textField.errors.required" i18n>Champ requis</p>
</div>
