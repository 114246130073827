import { EntityMapper } from "./entity-mapper.model";

/**
 * Configuration d'un client carto
 */
export class MapConfig extends EntityMapper {
  name: string = "";
  projection: string = "";
  projections: string[] = [];
  startZoom: number = null;
  minZoom: number = null;
  maxZoom: number = null;
  startCenter: [number, number] = null;
  startExtent: [number, number, number, number] = null;
  backgroundLayers: BackgroundLayer[] = [];
  defaultLayers: Layer[] = [];
  destinations: Destination[] = [];
  controls: any = {};

  constructor() {
    super();
    this._mapperDefs = [
      { front: 'backgroundLayers', class: BackgroundLayer },
      { front: 'defaultLayers', class: Layer },
      { front: 'destinations', class: Destination },
    ]
  }
}

/**
 * Couche de fond
 */
export class BackgroundLayer extends EntityMapper {
  url: string = "";
  label: string = "";
  params: any = {};
}

/**
 * Couche manipulable
 */
export class Layer extends EntityMapper {
  name: string = "";
  label: string = "";
  extent: [number, number, number, number] = null;
  opacity: number = 1;
  visible: boolean = true;
  url: string = "";
  params: any = {};
}

/**
 * Destination possible
 */
export class Destination extends EntityMapper {
  label: string = ""
  extent: [number, number, number, number] = [0, 0, 1, 1];
}