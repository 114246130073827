import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableFilterService {
  constructor() { }

  filterTable(filters, list) {
    if (!list || list.length === 0) {
      return [];
    }
    let filteredList = list.slice();
    // this.sortByColumn(filteredList, filters.sortColumn, filters.sortDirection);

    let chunkedList = _.chunk(filteredList, filters.itemsPerPage);

    return chunkedList[filters.page - 1];
  }

  /**
   * Filtre les lignes d'un tableau en fonction des paramètres
   * @param rows Lignes à filtrer
   * @param filters Filtres
   */
  public filterRows(rows: any[], filters: { [key: string]: { fields: string[], filter: string } }): any[] {
    if (filters.general && filters.general.filter) {
      const filter = this._stripCaseAndAccents(filters.general.filter);
      let filteredRows = _.filter(rows, row => {
        let valid = false;
        for (let i = 0; i < filters.general.fields.length; i++) {
          const field = filters.general.fields[i];
          const value = this._stripCaseAndAccents(_.get(row, field, null));
          if (_.isString(filter) && _.isString(value) && value.indexOf(filter) >= 0) {
            valid = true;
            break;
          } else if (filter === value) {
            valid = true;
            break;
          }
        }
        return valid;
      });
      return filteredRows;
    }
    return rows;
  }

  /**
   * Remplace les majuscules et les accents par leur équivalent simple
   * @param value Texte à modifier
   */
  private _stripCaseAndAccents(value) {
    if (_.isString(value)) {
      return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    return value;
  }

}
