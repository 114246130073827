import { User } from './user.model';
import { EntityMapper } from './entity-mapper.model';

export class Resource extends EntityMapper {
  id: string = null;
  type: string = "";
  typeLabel: string = "";
  name: string = "";
  owner: User = null;
  lastUpdate: Date = null;
  sourceObject: any = null;
  extents: any[] = [];
  features: any[] = [];
  hovered: boolean = false;
  url: string = "";
}
