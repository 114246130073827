import { Routes } from '@angular/router';
import { ProjectsComponent, WorkflowsComponent, DatasComponent, LinksComponent, WorkflowExecutionsComponent } from './components/lists';
import { ProjectDetailComponent, WorkflowDetailComponent, DataDetailComponent, LinkDetailComponent, ResourcesComponent, HelpComponent, ErrorPageComponent, ExecutionDetailComponent, AccountComponent } from './components/pages';
import { ProjectEditComponent, LinkEditComponent, DataEditComponent, WorkflowEditComponent } from './components/forms';
import { NeedSaveGuard, AuthGuard, AdminGuard } from './guards';
import { MainLayoutComponent } from './components/layout';
import { GroupsAdminComponent } from './components/admin/lists';
import { AdminLayoutComponent } from './components/admin/layout';
import { GroupManageAdminComponent, GroupEditAdminComponent } from './components/admin/forms';
import { UserSearchAdminComponent } from './components/admin/pages';

const LinkChildrenRoutes: Routes = [
  {
    path: ':linkId',
    data: { breadcrumb: '', replacementId: 'link_detail' },
    children: [
      { path: '', component: LinkDetailComponent },
      { path: 'edit', component: LinkEditComponent, data: { breadcrumb: "Modification" }, canDeactivate: [NeedSaveGuard] }
    ]
  }
];

const DataChildrenRoutes: Routes = [
  {
    path: ':datasetId',
    data: { breadcrumb: '', replacementId: 'data_detail' },
    children: [
      { path: '', component: DataDetailComponent },
      { path: 'edit', component: DataEditComponent, data: { breadcrumb: "Modification" }, canDeactivate: [NeedSaveGuard] }
    ]
  }
];

const WorkflowChildrenRoutes: Routes = [
  {
    path: ':workflowId',
    data: { breadcrumb: '', replacementId: 'workflow_detail' },
    children: [
      { path: '', component: WorkflowDetailComponent, canDeactivate: [NeedSaveGuard] },
      { path: 'edit', component: WorkflowEditComponent, data: { breadcrumb: "Modification" }, canDeactivate: [NeedSaveGuard] },
      {
        path: 'executions',
        data: { breadcrumb: "Exécutions" },
        children: [
          { path: '', component: WorkflowExecutionsComponent },
          { path: ':executionId', component: ExecutionDetailComponent, data: { breadcrumb: '', replacementId: "execution_detail" } }
        ]
      }
    ]
  }
];

const ProjectChildrenRoutes: Routes = [
  {
    path: ':projectId',
    data: { breadcrumb: '', replacementId: 'project_detail' },
    children: [
      { path: '', component: ProjectDetailComponent },
      { path: 'edit', component: ProjectEditComponent, data: { breadcrumb: "Modification" }, canDeactivate: [NeedSaveGuard] },
      {
        path: 'workflows',
        data: { breadcrumb: '', abstractBreadcrumb: true },
        children: [
          {
            path: ':workflowId',
            component: WorkflowDetailComponent,
            canDeactivate: [NeedSaveGuard],
            data: { breadcrumb: '', replacementId: 'workflow_detail' }
          }
        ]
      },
      { path: 'datas', children: DataChildrenRoutes, data: { breadcrumb: '', abstractBreadcrumb: true } },
      { path: 'links', children: LinkChildrenRoutes, data: { breadcrumb: '', abstractBreadcrumb: true } }
    ]
  }
];

export const AppRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'my-projects',
        children: [
          { path: '', component: ProjectsComponent },
          ...ProjectChildrenRoutes
        ],
        data: { breadcrumb: "Mes études" }
      },
      {
        path: 'my-workflows',
        children: [
          { path: '', component: WorkflowsComponent },
          ...WorkflowChildrenRoutes
        ],
        data: { breadcrumb: "Mes workflows" }
      },
      {
        path: 'my-datas',
        children: [
          { path: '', component: DatasComponent },
          ...DataChildrenRoutes
        ],
        data: { breadcrumb: "Mes données" }
      },
      {
        path: 'my-links',
        children: [
          { path: '', component: LinksComponent },
          ...LinkChildrenRoutes
        ],
        data: { breadcrumb: "Mes Liens" }
      },
      {
        path: 'projects',
        children: [
          { path: '', component: ProjectsComponent },
          ...ProjectChildrenRoutes
        ],
        data: { breadcrumb: "Toutes les études" }
      },
      {
        path: 'workflows',
        children: [
          { path: '', component: WorkflowsComponent },
          ...WorkflowChildrenRoutes
        ],
        data: { breadcrumb: "Tous les workflows" }
      },
      { path: 'resources', component: ResourcesComponent },
      { path: 'account', component: AccountComponent, data: { breadcrumb: "Mon compte" } },
      { path: 'help', component: HelpComponent, data: { breadcrumb: "Aide" } },
      {
        path: 'admin',
        component: AdminLayoutComponent,
        data: { breadcrumb: "Administration" },
        canActivate: [AdminGuard],
        children: [
          {
            path: 'groups',
            data: { breadcrumb: "Groupes" },
            children: [
              { path: '', component: GroupsAdminComponent },
              {
                path: ':groupId', children: [
                  { path: '', component: GroupManageAdminComponent },
                  { path: 'edit', component: GroupEditAdminComponent, data: { breadcrumb: "Modification" } }
                ],
                data: { breadcrumb: '', replacementId: 'group_detail' }
              }
            ]
          },
          { path: 'users', data: { breadcrumb: "Utilisateurs" }, component: UserSearchAdminComponent },
          { path: '', redirectTo: '/admin/groups', pathMatch: 'full' },
        ]
      },
      { path: '', redirectTo: '/my-projects', pathMatch: 'full' },
    ]
  },
  { path: '**', component: ErrorPageComponent }
];