<section id="account" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1>
      <i class="fa fa-user mr-2"></i>
      <ng-container i18n>Mon compte</ng-container>
    </h1>
  </div>
  <div class="page-content">
    <div class="subcontent">
      <h2>Mes informations</h2>
      <dl>
        <div class="def-line">
          <dt i18n>Email :</dt>&nbsp;<dd>{{session.currentUser.email}}</dd>
        </div>
      </dl>
      <h2>Mes groupes</h2>
      <ul class="group-list">
        <li *ngFor="let group of session.currentUser.groups; trackBy: trackById">
          <strong class="d-block">{{group.name}}</strong>
          <p>{{group.description}}</p>
        </li>
      </ul>
    </div>
  </div>
</section>