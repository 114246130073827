import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

export const RightPanelAnimation = [
  trigger('panelAnimation', [
    transition(':enter', [
      style({ right: '-465px' }),
      animate(150)
    ]),
    transition(':leave', [
      animate(150, style({ right: '-465px' }))
    ])
  ])
];

export const MapPanelsAnimation = [
  trigger('layerSwitcherAnimation', [
    transition(':enter', [
      style({ right: '-710px' }),
      animate(250)
    ]),
    transition(':leave', [
      animate(250, style({ right: '-710px' }))
    ])
  ]),
  trigger('mapToolsAnimation', [
    transition(':enter', [
      style({ right: '-260px' }),
      animate(150)
    ]),
    transition(':leave', [
      animate(150, style({ right: '-260px' }))
    ])
  ])
];
