import * as _ from 'lodash';

import { Component, OnInit, Input } from '@angular/core';
import {Constants} from "../../../constants";


@Component({
  selector: 'display-lang',
  templateUrl: './display-lang.component.html'
})
export class DisplayLangComponent implements OnInit {
  /**
   * Liste des langues
   */
  public languages: ({ label: string; value: string, flag: any })[] = [];

  /**
   * Langue à afficher
   */
  @Input() displayLang:string = "fre";

  constructor() { }

  public ngOnInit() {
    this.languages = Constants.languages;
  }

  public getFlag() {
    let languageObject = _.find(this.languages, {value: this.displayLang});
    if (languageObject) {
      return languageObject.flag;
    }
    return "";
  }

  public getLabel() {
    let languageObject = _.find(this.languages, {value: this.displayLang});
    if (languageObject) {
      return languageObject.label;
    }
    return "";
  }

}
