<section id="project-detail" class="d-flex flex-column h-100" *ngIf="project">
  <div class="page-header d-flex align-items-start mb-3">
    <div class="w-100 mr-5">
      <h1>
        <i *ngIf="isOwner && project.individualPermissions.length === 1 && project.groupPermissions.length === 0"
          i18n-ngbTooltip ngbTooltip="Privée : cette étude n'est visible que par moi" placement="top"
          class="fa fa-lock"></i>
        <i *ngIf="!isOwner || project.individualPermissions.length > 1 || project.groupPermissions.length > 0"
          i18n-ngbTooltip ngbTooltip="Partagée : cette étude est ouverte à plusieurs utilisateurs ou groupes" placement="top"
          class="fa fa-user-friends"></i>
        {{project.name}}
      </h1>
      <p class="w-50 description mb-3" *ngIf="project.owners && project.owners.length > 0">
        <strong i18n>Propriétaires :</strong>&nbsp;
        <ng-container *ngFor="let user of project.owners; let i = index;">
          <ng-container *ngIf="i > 0">, </ng-container>{{user}}
        </ng-container>
      </p>
      <p class="w-50 description nl2br">
        {{currentDescription}}
        {{currentDescription && currentDescription.length < project.description.length && !showFullDescription ? '...' : ''}}
        <a *ngIf="(currentDescription && currentDescription.length < project.description.length) || showFullDescription" (click)="toggleDescription()">
          <ng-container *ngIf="showFullDescription" i18n>moins</ng-container>
          <ng-container *ngIf="!showFullDescription" i18n>plus</ng-container>
        </a>
      </p>
    </div>
    <div class="title-button-container">
      <a [routerLink]="['./edit']" class="btn btn-default btn-block nowrap"
        *ngIf="isEditor">
        <i class="fa fa-edit"></i>
        <ng-container i18n>Modifier</ng-container>
      </a>
      <button type="button" class="btn btn-default btn-block nowrap mt-2"
        *ngIf="isAdmin || isOwner" (click)="openEditRightsModal()">
        <i class="fa fa-lock"></i>
        <ng-container i18n>Droits d'accès</ng-container>
      </button>
      <button class="btn btn-danger btn-block nowrap mt-2"
        (click)="deleteProject()" *ngIf="isOwner">
        <i class="fa fa-trash-alt"></i>
        <ng-container i18n>Supprimer</ng-container>
      </button>
    </div>
  </div>

  <div class="page-content d-flex flex-column">
    <div class="subcontent">
      <p-tabView styleClass="h-100 d-flex flex-column" (onChange)="onTabChange">
        <p-tabPanel i18n-header header="Workflows" [selected]="activeTab == 'workflow-tab'" [cache]="false">
          <ng-template pTemplate="content">
            <div class="p-3 d-flex flex-column h-100">
              <button type="button" (click)="linkToWorkflow()" *ngIf="isOwner" class="btn btn-primary add-btn">
                <i class="fa fa-plus"></i>
                <ng-container i18n>Lier un nouveau workflow</ng-container>
              </button>
              <div class="d-flex align-items-center">
                <vigirisks-table-counter>
                  <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
                    <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucun workflow</p>
                    <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} workflows</p>
                  </ng-template>
                </vigirisks-table-counter>
              </div>
              <div class="data-table h-100 px-3">
                <vigirisks-fitrable-table [rows]="project.workflows">
                  <ng-template head-content>
                    <tr>
                      <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
                      <th class="owner" sortable-column="ownerName"><span i18n>Contact</span></th>
                      <th class="typeName" sortable-column="typeName"><span i18n>Type</span></th>
                      <th class="date text-center" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
                      <th class="actions text-center">&nbsp;</th>
                    </tr>
                  </ng-template>
                  <ng-template body-content let-workflow>
                    <tr>
                      <td><a [routerLink]="['./workflows', workflow.id]">{{workflow.name}}</a></td>
                      <td>{{workflow.ownerName}} ({{workflow.ownerOrganisation}})</td>
                      <td>{{workflow.typeName}}</td>
                      <td class="text-center">{{workflow.lastUpdate | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                        <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                        <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                          <a class="dropdown-item" [routerLink]="['./workflows', workflow.id]" i18n>Consulter</a>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </vigirisks-fitrable-table>
              </div>
              <div class="d-flex">
                <vigirisks-pagination class="ml-auto mb-0" [maxSize]="5"></vigirisks-pagination>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel i18n-header header="Données" [selected]="activeTab == 'data-tab'" [cache]="false">
          <ng-template pTemplate="content">
            <div class="p-3 d-flex flex-column h-100">
              <a [routerLink]="['./datas/new/edit']" *ngIf="isEditor" class="btn btn-primary add-btn">
                <i class="fa fa-plus"></i>
                <ng-container i18n>Déposer une nouvelle donnée</ng-container>
              </a>
              <div class="d-flex align-items-center">
                <vigirisks-table-counter>
                  <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
                    <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucune donnée</p>
                    <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} données</p>
                  </ng-template>
                </vigirisks-table-counter>
              </div>
              <div class="data-table h-100 px-3">
                <vigirisks-fitrable-table [rows]="project.datas">
                  <ng-template head-content>
                    <tr>
                      <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
                      <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
                      <th class="extentDescription" sortable-column="extentDescription"><span i18n>Emprise</span></th>
                      <th class="date text-center" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
                      <th class="actions text-center">&nbsp;</th>
                    </tr>
                  </ng-template>
                  <ng-template body-content let-data>
                    <tr>
                      <td><a [routerLink]="['./datas', data.id]">{{data.name}}</a></td>
                      <td>{{data.typeName}}</td>
                      <td>{{data.extentDescription}}</td>
                      <td class="text-center">{{data.lastUpdate | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                        <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                        <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                          <a class="dropdown-item" [routerLink]="['./datas', data.id]" i18n>Consulter</a>
                          <a *ngIf="isEditor" class="dropdown-item" [routerLink]="['./datas', data.id, 'edit']" i18n>Modifier</a>
                          <a *ngIf="data.file && data.file.url" class="dropdown-item" target="_blank" [href]="data.file.url">
                            Télécharger
                            <i class="fa fa-external-link-alt ml-2"></i>
                          </a>
                          <a *ngIf="isEditor" class="dropdown-item item-danger" (click)="deleteMetadata(data, 'data')" i18n>Supprimer</a>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </vigirisks-fitrable-table>
              </div>
              <div class="d-flex">
                <vigirisks-pagination class="ml-auto mb-0" [maxSize]="5"></vigirisks-pagination>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel i18n-header header="Liens" [selected]="activeTab == 'link-tab'" [cache]="false">
          <ng-template pTemplate="content">
            <div class="p-3 d-flex flex-column h-100">
              <a [routerLink]="['./links/new/edit']" *ngIf="isEditor"
                 class="btn btn-primary add-btn">
                <i class="fa fa-plus"></i>
                <ng-container i18n>Référencer un nouveau lien</ng-container>
              </a>
              <div class="d-flex align-items-center">
                <vigirisks-table-counter>
                  <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
                    <p class="list-counter karamazov" *ngIf="totalResults === 0" i18n>Aucun lien</p>
                    <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} liens</p>
                  </ng-template>
                </vigirisks-table-counter>
              </div>
              <div class="data-table h-100 px-3">
                <vigirisks-fitrable-table [rows]="project.links">
                  <ng-template head-content>
                    <tr>
                      <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
                      <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
                      <th class="url" sortable-column="url"><span i18n>Url</span></th>
                      <th class="actions text-center">&nbsp;</th>
                    </tr>
                  </ng-template>
                  <ng-template body-content let-link>
                    <tr>
                      <td><a [routerLink]="['./links', link.id]">{{link.name}}</a></td>
                      <td>{{link.typeName}}</td>
                      <td class="url"><a [href]="link.url" [title]="link.url" target="_blank">{{link.url}}</a></td>
                      <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                        <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                        <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                          <a class="dropdown-item" [routerLink]="['./links', link.id]" i18n>Consulter</a>
                          <a *ngIf="isEditor" class="dropdown-item" [routerLink]="['./links', link.id, 'edit']" i18n>Modifier</a>
                          <a *ngIf="isEditor" class="dropdown-item item-danger" (click)="deleteMetadata(link, 'link')" i18n>Supprimer</a>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </vigirisks-fitrable-table>
              </div>
              <div class="d-flex">
                <vigirisks-pagination class="ml-auto mb-0" [maxSize]="5"></vigirisks-pagination>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel i18n-header header="Informations" [selected]="activeTab == 'info-tab'">
          <ng-template pTemplate="content">
            <div class="p-3 d-flex flex-column h-100 meta-detail">
              <dl class="mb-0 row">
                <div class="col-12 col-xl-8">
                  <h2 i18n>Informations</h2>
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Langue :</dt>&nbsp;
                        <dd class="d-inline"><display-lang [displayLang]="project.language"></display-lang></dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Mots-clés :</dt>&nbsp;
                        <dd class="d-inline">{{project.freeKeywords.join(', ')}}</dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Thématiques :</dt>&nbsp;
                        <dd class="d-inline">
                          <ng-container *ngFor="let thematic of project.thematics; let isLast=last">
                            {{thematic.label}}{{isLast ? '' : ', '}}
                          </ng-container>
                        </dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Projets Opale :</dt>&nbsp;
                        <dd class="d-inline">{{project.opaleKeywords.join(', ')}}</dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Catégorie :</dt>&nbsp;
                        <dd class="d-inline">{{project.category}}</dd>
                      </div>
                      <div class="mb-2">
                        <dt class="d-inline" i18n>Dernière modification :</dt>&nbsp;
                        <dd class="d-inline">{{project.lastUpdate | date: 'dd/MM/yyyy'}}</dd>
                      </div>
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideDescription = !hideDescription">
                          <span i18n>Description</span>
                          <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></i>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{project.description}}</dd>
                    </div>
                    <div class="col-6">
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideContacts = !hideContacts">
                          <span i18n>Contacts</span>
                          <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideContacts}"></i>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideContacts}">
                        <ul class="contacts">
                          <li *ngFor="let contact of project.contacts">
                            <em class="d-block">{{contactsRolesNames[contact.role]}}</em>
                            {{contact.individualName}} ({{contact.organisationName}})
                            <a *ngIf="contact.email" class="d-block" [href]="'mailto:' + contact.email">{{contact.email}}</a>
                          </li>
                        </ul>
                      </dd>
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideComment = !hideComment">
                          <span i18n>Commentaire</span>
                          <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideComment}"></i>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideComment}">{{project.comment}}</dd>
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                                (click)="hideLineage = !hideLineage">
                          <span i18n>Traçabilité (origine)</span>
                          <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLineage}"></i>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLineage}">{{project.lineage}}</dd>
                      <dt class="accordion-title">
                        <button type="button" class="btn btn-icon accordion-btn w-100 d-flex" (click)="hideLegals = !hideLegals">
                          <span i18n>Contraintes légales</span>
                          <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideLegals}"></i>
                        </button>
                      </dt>
                      <dd class="nl2br accordion-content mb-3" [ngClass]="{closed: hideLegals}">
                        {{project.legalConstraints}}
                      </dd>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <hr class="my-3 d-xl-none">
                  <h2>Emprises</h2>
                  <dt>
                    <ng-container *ngIf="project.extentDescription">{{project.extentDescription}}</ng-container>
                    <ng-container *ngIf="!project.extentDescription" i18n>(aucune description)</ng-container>
                  </dt>
                  <dd>
                    <simple-map [extents]="project.extents"></simple-map>
                  </dd>
                </div>
              </dl>
            </div>
          </ng-template>
        </p-tabPanel>

      </p-tabView>
    </div>
  </div>

</section>
