export class Uris {
  public static LOGOUT = "/back/api/user/logout";

  public static PROJECTS = "/back/api/projects/";

  public static LINKS = "/back/api/links/";

  public static DATAS = "/back/api/datasets/";

  public static WORKFLOWS = "/back/api/workflows/";

  public static RESOURCES = "/back/api/objects/";

  public static SEARCH = "/back/api/search/";

  public static METADATA_EDITITNG = "/back/api/objects/editing/";

  public static USERS = "/back/api/users/";

  public static GROUPS = "/back/api/groups/";

  public static EXECUTIONS = "/back/api/executions/";

  public static FILES = "/back/api/files/";

  public static PROXY = "/back/api/proxy/";

  public static REGISTRY_CATEGORIES = "/back/api/registries/{language}/categories";

  public static REGISTRY_KEYWORDS = "/back/api/registries/{language}/keywords";

  public static MAPS = "fakedata/maps.json";

  public static GET_GEONAMES = "https://nominatim.openstreetmap.org/search";
}
