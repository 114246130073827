import { Component, Input } from '@angular/core';

@Component({
  selector: 'metadata-info',
  templateUrl: './info.component.html'
})
export class InfoComponent {
  @Input() metadata;

  constructor() {}
}
