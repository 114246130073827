<div class="modal-header">
  <h2 class="modal-title" i18n>Résultats de l'exécution</h2>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body h-100" id="results-table-modal">
  <p-tabView styleClass="h-100 d-flex flex-column">
    <p-tabPanel *ngFor="let tab of tabs; trackBy: trackByName" [header]="tab.name">
      <ng-template pTemplate="content">
        <div class="data-table">
          <vigirisks-fitrable-table [rows]="tab.rows">
            <ng-template head-content>
              <tr>
                <ng-container *ngFor="let column of tab.columns; trackBy: trackByName">
                  <th *ngIf="column.ordonable" [sortable-column]="column.name"><span>{{column.label}}</span></th>
                  <th *ngIf="!column.ordonable"><span>{{column.label}}</span></th>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template body-content let-row>
              <tr>
                <td *ngFor="let column of tab.columns; trackBy: trackByName">{{row[column.name]}}</td>
              </tr>
            </ng-template>
          </vigirisks-fitrable-table>
        </div>
        <div class="d-flex">
          <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>

</div>
