import { Injectable } from "@angular/core";

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

import { SessionService } from "../services";

import { Constants } from "../constants/app.constants";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private _session: SessionService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this._session.hasRole(Constants.userRoles.admin)) {
      this._router.navigate(['/my-projects']);
      return false;
    }
    
    return true;
  }
}