<section id="workflow-edit" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1>
      <ng-container *ngIf="isNew" i18n>Créer un workflow</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier le workflow</ng-container>
    </h1>
    <p class="alert alert-info" *ngIf="nonEditableInfos">
      <i class="fa fa-info-circle"></i>
      <ng-container i18n>Ce workflow est actuellement modifié par {{nonEditableInfos.userId}}</ng-container>
    </p>
  </div>
  <div class="page-content">
    <form *ngIf="workflow" class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}"
      id="workflow-edit" #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-language" class="col-4 mr-3 mb-0">
              <ng-container i18n>Langue</ng-container> *
            </label>
            <div class="col">
              <select id="workflow-language" class="form-control" name="workflow-language"
                      #languageField="ngModel" [(ngModel)]="workflow.language" [disabled]="nonEditableInfos" required>
                <option *ngFor="let language of languages" [ngValue]="language.value">
                  {{language.flag}}&nbsp;{{language.label}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && languageField.invalid">
                <p *ngIf="languageField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-name" class="col-4 mr-3 mb-0">
              <ng-container i18n>Titre</ng-container> *
            </label>
            <div class="col">
              <input type="text" id="workflow-name" class="form-control" name="workflow-name"
                [(ngModel)]="workflow.name" #nameField="ngModel" [disabled]="nonEditableInfos" required>
              <div class="alert alert-danger" *ngIf="editForm.submitted && nameField.invalid">
                <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="workflow-description" class="col-4 mr-3 mb-0 mt-1" i18n>Description</label>
            <div class="col">
              <textarea name="workflow-description" class="form-control" id="workflow-description"
                [(ngModel)]="workflow.description" #descField="ngModel" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-keywords" class="col-4 mr-3 mb-0">
              <ng-container i18n>Mots-clés</ng-container>
            </label>
            <div class="col">
              <p-autoComplete [(ngModel)]="workflow.keywords" [multiple]="true" inputId="workflow-keywords"
                              name="workflow-keywords" [suggestions]="registryKeywords" field="label" styleClass="form-control w-100"
                              [disabled]="nonEditableInfos" (completeMethod)="keywordsAutoComplete($event)">
              </p-autoComplete>
              <input type="text" id="workflow-keywords-old" class="form-control" name="workflow-keywords-old"
                [(ngModel)]="keywords" [disabled]="true">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="workflow-thematics" class="col-4 mr-3 mb-0 mt-2" i18n>Thématiques</label>
            <div class="col">
              <p-autoComplete [(ngModel)]="workflow.thematics" [multiple]="true" inputId="workflow-thematics"
                name="workflow-thematics" [suggestions]="categories" field="label" styleClass="form-control w-100"
                [disabled]="nonEditableInfos" (completeMethod)="categoriesAutoComplete($event)">
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-type" class="col-4 mr-3 mb-0">
              <ng-container i18n>Type de workflow</ng-container> *
            </label>
            <div class="col">
              <select id="workflow-type" class="form-control" name="workflow-type" [(ngModel)]="workflow.typeName"
                #subTypeField="ngModel" [disabled]="nonEditableInfos" required>
                <option [ngValue]="null" i18n>-- Choisir un type de workflow --</option>
                <option *ngFor="let subType of subTypes" [ngValue]="subType">{{subType}}</option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && subTypeField.invalid">
                <p *ngIf="subTypeField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="exec-time" class="col-4 mr-3 mb-0">
              <ng-container i18n>Temps d'exécution</ng-container>
            </label>
            <div class="col">
              <select id="exec-time" class="form-control" name="exec-time" [(ngModel)]="workflow.executionTime"
                [disabled]="nonEditableInfos">
                <option [ngValue]="null" i18n>-- Choisir un temps d'exécution --</option>
                <option *ngFor="let executionTime of executionTimes" [ngValue]="executionTime">{{executionTime}}
                </option>
              </select>
            </div>
          </div>

          <fieldset class="form-group">
            <legend i18n>GIT</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="workflow-git-url" class="col-4 mb-0">
                <ng-container i18n>Url</ng-container> *
              </label>
              <div class="col">
                <input type="text" id="workflow-git-url" class="form-control" name="workflow-git-url"
                  [(ngModel)]="workflow.gitUrl" #workflowGitUrlField="ngModel" [disabled]="nonEditableInfos" required>
                <div class="alert alert-danger" *ngIf="editForm.submitted && workflowGitUrlField.invalid">
                  <p *ngIf="workflowGitUrlField.errors.required" i18n>Champ requis</p>
                </div>
              </div>
            </div>
            <div class="row no-gutters align-items-center">
              <label for="workflow-git-description" class="col-4 mb-0 mt-1" i18n>Description</label>
              <div class="col">
                <textarea name="workflow-git-description" class="form-control" id="workflow-git-description"
                  [disabled]="nonEditableInfos" [(ngModel)]="workflow.gitDescription"></textarea>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-12 col-xl-6">
          <fieldset class="form-group">
            <legend i18n>Emprises</legend>
            <div class="form-group row no-gutters align-items-center">
              <label for="workflow-extent-description" class="col-4 mb-0" i18n>Description</label>
              <div class="col">
                <p-autoComplete [(ngModel)]="chosenGeoname" id="workflow-extent-description"
                  name="workflow-extent-description" [suggestions]="geonames"
                  styleClass="form-control w-100" field="label" [disabled]="nonEditableInfos" (onSelect)="updateExtent()"
                  (onClear)="updateExtent()" (completeMethod)="searchGeonames($event)"></p-autoComplete>
              </div>
            </div>
            <div class="row no-gutters align-items-start">
              <label for="workflow-extent" class="col-4 mb-0 mt-1" i18n>Emprise</label>
              <div class="col">
                <described-extent name="workflow-extent" id="workflow-extent" [isEditable]="!nonEditableInfos"
                  [(ngModel)]="workflow.extents">
                </described-extent>
              </div>
            </div>
          </fieldset>
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-quality" class="col-4 mr-3 mb-0">
              <ng-container i18n>Validation scientifique</ng-container> *
            </label>
            <div class="col">
              <select id="workflow-quality" class="form-control" name="workflow-quality" #workflowQualityField="ngModel"
                [(ngModel)]="workflow.quality" [disabled]="nonEditableInfos" required>
                <option *ngFor="let workflowQuality of workflowQualities" [ngValue]="workflowQuality">
                  {{workflowQuality}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && workflowQualityField.invalid">
                <p *ngIf="workflowQualityField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="metadata-quality" class="col-4 mr-3 mb-0">
              <ng-container i18n>Qualité de la méta-donnée</ng-container> *
            </label>
            <div class="col">
              <select id="metadata-quality" class="form-control" name="metadata-quality" #metadataQualityField="ngModel"
                [(ngModel)]="workflow.metadataQuality" [disabled]="nonEditableInfos" required>
                <option *ngFor="let metadataQuality of metadataQualities" [ngValue]="metadataQuality">
                  {{metadataQuality}}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="editForm.submitted && metadataQualityField.invalid">
                <p *ngIf="metadataQualityField.errors.required" i18n>Champ requis</p>
              </div>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-center">
            <label for="workflow-code-version" class="col-4 mb-0 mr-3">
              <ng-container i18n>Version du code</ng-container>
            </label>
            <div class="col">
              <input type="text" id="workflow-code-version" class="form-control" name="workflow-code-version"
                [(ngModel)]="workflow.codeVersion" [disabled]="nonEditableInfos">
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="workflow-comment" class="col-4 mr-3 mb-0 mt-1" i18n>Commentaire</label>
            <div class="col">
              <textarea name="workflow-comment" class="form-control" id="workflow-comment"
                [(ngModel)]="workflow.comment" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="workflow-lineage" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Toute information utile permettant de préciser la création de la donnée et son cycle de vie"
                placement="top" container="body"></i>
              <ng-container i18n>Traçabilité (généalogie)</ng-container>
            </label>
            <div class="col">
              <textarea name="workflow-lineage" class="form-control" id="workflow-lineage"
                [(ngModel)]="workflow.lineage" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
          <div class="form-group row no-gutters align-items-start">
            <label for="workflow-constraints" class="col-4 mr-3 mb-0 mt-1">
              <i class="fa fa-question-circle" i18n-ngbTooltip
                ngbTooltip="Les contraintes d'utilisation sont définies en fonction de la communicabilité et de la réutilisation des données. Par défaut, les données du BRGM sont publiques et sous licence Etalab. La communicabilité peut être immédiate, décalée (préciser le délai), différée, ou réservée."
                placement="top" container="body"></i>
              <ng-container i18n>Contraintes d'utilisation</ng-container>
            </label>
            <div class="col">
              <textarea name="workflow-constraints" class="form-control" id="workflow-constraints"
                [(ngModel)]="workflow.legalConstraints" [disabled]="nonEditableInfos"></textarea>
            </div>
          </div>
        </div>
      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>Contacts</h2>
        <button *ngIf="!nonEditableInfos" type="button" class="btn btn-default ml-5" (click)="addContact()">
          <i class="fa fa-plus mr-2"></i>
          <ng-container i18n>Ajouter un contact</ng-container>
        </button>
      </div>
      <div class="row">
        <ng-container *ngFor="let contact of workflow.contacts; let index = index">
          <div class="col-12 col-xl-6">
            <fieldset class="form-group with-close-btn">
              <legend i18n>Contact</legend>
              <button type="button"
                *ngIf="!nonEditableInfos && (contact.role !== 'pointOfContact' || canDeletePointOfContact)"
                class="btn btn-close" (click)="removeContact(index)">
                <span>&times;</span>
              </button>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-name-' + index" class="col-4 mb-0">
                  <ng-container i18n>Nom</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-name-' + index" class="form-control"
                    [name]="'contact-name-' + index" [disabled]="nonEditableInfos" [(ngModel)]="contact.individualName"
                    #contactNameField="ngModel" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactNameField.invalid">
                    <p *ngIf="contactNameField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-email-' + index" class="col-4 mb-0">
                  <ng-container i18n>Email</ng-container>
                </label>
                <div class="col">
                  <input type="email" [id]="'contact-email-' + index" class="form-control"
                    [name]="'contact-email-' + index" [disabled]="nonEditableInfos" [(ngModel)]="contact.email"
                    #contactEmailField="ngModel">
                </div>
              </div>
              <div class="form-group row no-gutters align-items-center">
                <label [for]="'contact-organisation-' + index" class="col-4 mb-0">
                  <ng-container i18n>Organisation</ng-container> *
                </label>
                <div class="col">
                  <input type="text" [id]="'contact-organisation-' + index" class="form-control"
                    [name]="'contact-organisation-' + index" [disabled]="nonEditableInfos"
                    [(ngModel)]="contact.organisationName" #contactOrganisationField="ngModel" required>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactOrganisationField.invalid">
                    <p *ngIf="contactOrganisationField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters align-items-center">
                <label [for]="'contact-role-' + index" class="col-4 mb-0">
                  <ng-container i18n>Rôle</ng-container> *
                </label>
                <div class="col">
                  <select [id]="'contact-role-' + index" class="form-control" [name]="'contact-role-' + index"
                    [(ngModel)]="contact.role" [disabled]="nonEditableInfos" #contactRoleField="ngModel"
                    (change)="checkPointsOfContacts()"
                    [disabled]="contact.role === 'pointOfContact' && !canDeletePointOfContact" required>
                    <option *ngFor="let role of contactTypes; trackBy: trackByValue" [ngValue]="role.value">
                      {{role.label}}</option>
                  </select>
                  <div class="alert alert-danger" *ngIf="editForm.submitted && contactRoleField.invalid">
                    <p *ngIf="contactRoleField.errors.required" i18n>Champ requis</p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </ng-container>
      </div>

      <hr class="form-separator">

      <div class="d-flex mb-5 align-items-center">
        <h2 class="form-title my-0" i18n>Références associées</h2>
        <button *ngIf="!nonEditableInfos" type="button" class="btn btn-default ml-5" (click)="addOnlineResource()">
          <i class="fa fa-plus mr-2"></i>
          <ng-container i18n>Ajouter un lien</ng-container>
        </button>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6" *ngFor="let resource of workflow.onlineResources; let index = index">
          <fieldset class="form-group with-close-btn">
            <legend i18n>Lien bibliographique</legend>
            <button *ngIf="!nonEditableInfos" type="button" class="btn btn-close"
              (click)="removeOnlineResource(index)"><span>&times;</span></button>
            <div class="form-group row no-gutters align-items-center">
              <label [for]="'workflow-or-url-' + index" class="col-4 mb-0">
                <ng-container i18n>Url</ng-container> *
              </label>
              <div class="col">
                <input type="text" [id]="'workflow-or-url-' + index" class="form-control"
                  [name]="'workflow-or-url-' + index" [(ngModel)]="resource.url" #workflowUrlField="ngModel" required>
                <div class="alert alert-danger" *ngIf="editForm.submitted && workflowUrlField.invalid">
                  <p *ngIf="workflowUrlField.errors.required" i18n>Champ requis</p>
                </div>
              </div>
            </div>
            <div class="form-group row no-gutters align-items-center">
              <label [for]="'workflow-or-protocol-' + index" class="col-4 mb-0">
                <ng-container i18n>Type de lien</ng-container> *
              </label>
              <div class="col">
                <select [id]="'workflow-or-protocol-' + index" class="form-control"
                  [name]="'workflow-or-protocol-' + index" [(ngModel)]="resource.protocol" [disabled]="nonEditableInfos"
                  #workflowUrlProtocolField="ngModel" required>
                  <option [ngValue]="''" i18n>-- Choisir un type de lien --</option>
                  <option *ngFor="let protocol of protocols" [ngValue]="protocol">{{protocol}}</option>
                </select>
                <div class="alert alert-danger" *ngIf="editForm.submitted && workflowUrlProtocolField.invalid">
                  <p *ngIf="workflowUrlProtocolField.errors.required" i18n>Champ requis</p>
                </div>
              </div>
            </div>
            <div class="row no-gutters align-items-start">
              <label [for]="'workflow-or-desc-' + index" class="col-4 mb-0 mt-1" i18n>Description</label>
              <div class="col">
                <textarea [name]="'workflow-or-desc-' + index" class="form-control" [id]="'workflow-or-desc-' + index"
                  [disabled]="nonEditableInfos" [(ngModel)]="resource.description"></textarea>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default" i18n>Annuler</a>
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="nonEditableInfos" i18n>Enregistrer</button>
      </div>
    </form>
  </div>
</section>
