import * as _ from 'lodash';

import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TableFilterService } from 'src/app/services';

@Component({
  templateUrl: './results-table-modal.component.html'
})
export class ResultsTableModalComponent implements OnInit {
  @Input() results: any;

  public tabs: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private _filterService: TableFilterService
  ) { }

  ngOnInit() {
    _.each(this.results, result => {
      let action = _.find(result.functions, { action: "display_table" });
      if (action.columns) {
        let tab: any = {
          name: result.name,
          description: result.description,
          rows: result.data.rows,
          columns: action.columns,
          currentPage: 1,
          sortDirection: 'asc'
        };
        for (let i = 0; i < tab.columns.length; i++) {
          if (tab.columns[i].ordonable) {
            tab.sortColumn = tab.columns[i].name;
            break;
          }
        }
        this.tabs.push(tab);
      }
    });
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackByName(i, item) {
    return item.name;
  }
}