import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { transform } from 'ol/proj';

import { BboxMapModalComponent } from 'src/app/components/modals';

@Component({
  selector: 'metadata-coord',
  templateUrl: './coord.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CoordComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: CoordComponent, multi: true }
  ]
})
export class CoordComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  value: any = {};
  private _targetProj: string;
  private _displayProj: string;

  @ViewChild('coordX', { static: true }) xField;
  @ViewChild('coordY', { static: true }) yField;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this._targetProj = this.metadata.targetProj || 'EPSG:4326';
    this._displayProj = this.metadata.mapConfig.projection || 'EPSG:4326';
  }

  /**
   * Met à jour le ngModel quand les champs de coordonnées changent
   */
  public onChange() {
    let valueCoord = this._getFormatedCoordValue(this.value, 'target');
    this.onChangeCb(valueCoord);
    this.onMetadataChange.emit(this.metadata);
  }

  /**
   * Affiche la carte pour sélectionner une coordonnée
   */
  public openMap() {
    const modalRef = this.modalService.open(BboxMapModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "map-modal" })

    let editedMetadata = _.cloneDeep(this.metadata);
    editedMetadata.value = this._getFormatedCoordValue(this.value, 'target');

    modalRef.componentInstance.metadata = editedMetadata;

    modalRef.result.then(result => {
      this.writeValue(result);
      this.onChangeCb(result);
      this.onMetadataChange.emit(this.metadata);
    }, () => { });
  }

  private _getFormatedCoordValue(value: any, purpose: string): { x: number, y: number } {
    let x = _.isFinite(value.x) ? value.x : null;
    let y = _.isFinite(value.y) ? value.y : null;

    let formatedValue = { x: x, y: y };

    if (x !== null && y !== null) {
      let coord;
      if (purpose === 'display') {
        coord = transform([x, y], this._targetProj, this._displayProj);
      } else {
        coord = transform([x, y], this._displayProj, this._targetProj);
      }
      formatedValue = { x: coord[0], y: coord[1] };
    }

    return formatedValue;
  }

  // control methods
  writeValue(value) {
    if (!value) return;
    this.value = this._getFormatedCoordValue(value, 'display');
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): { [key: string]: any } | null {
    let validations = {};

    this.requiredValidator(validations, control);

    if (_.keys(validations).length > 0) {
      return validations;
    }
    return null;
  }

  /**
   * Validator vérifiant l'intégrité des coordonnées
   * @param validations 
   * @param control 
   */
  requiredValidator(validations, control) {
    if (!control.value) {
      return;
    }

    if (this.metadata.required) {
      if (control.value.x === null) {
        validations.x = { value: control.value.x };
        this.xField.control.setErrors({ required: true });
      }
      if (control.value.y === null) {
        validations.y = { value: control.value.y };
        this.yField.control.setErrors({ required: true });
      }
    } else if (control.value.x !== null || control.value.y !== null) {
      if (control.value.x === null) {
        validations.x = { value: control.value.x };
        this.xField.control.setErrors({ invalidCoord: true });
      }
      if (control.value.y === null) {
        validations.y = { value: control.value.y };
        this.yField.control.setErrors({ invalidCoord: true });
      }
    } else {
      this.xField.control.setErrors(null);
      this.yField.control.setErrors(null);
    }

  }

}
