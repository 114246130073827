import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SessionService, WorkflowService, ResourceService, AppConfigService } from 'src/app/services';
import { Execution, SearchData, Group } from 'src/app/models';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public search = {
    text: '',
    type: "",
    typeLabel: "Tout"
  };

  public completedExecutions: number = 0;

  public executions: Execution[] = [];
  public urls: any;
  public isAdmin: boolean = false;
  public groups: Group[] = [];
  public logoName: string;
  public logoTitle: string;

  private _subs: Subscription = new Subscription();
  private _timeout: any;

  constructor(
    public session: SessionService,
    private _workflowService: WorkflowService,
    private _resourceService: ResourceService,
    private _router: Router,
    private _appConfigService: AppConfigService
  ) {
    this.search.typeLabel = $localize`Tout`;
  }

  public ngOnInit() {
    this._subs.add(this._workflowService.executions$.subscribe(executions => this._updateExecutionsList(executions)));
    this._subs.add(this._workflowService.executionLogs$.subscribe(result => this._updateExecutionsFromLogs(result)));

    this._workflowService.getRunningExecutions();
    this.isAdmin = this.session.hasRole(Constants.userRoles.admin);
    let appConfig = this._appConfigService.getAppConfig();
    this.logoName = appConfig.logoName;
    this.logoTitle = appConfig.title;
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Définit le type de recherche à faire
   * @param type - Type de recherche à faire
   * @param typeLabel - Label du type de recherche
   */
  public setSearchType(type: string, typeLabel: string) {
    this.search.type = type;
    this.search.typeLabel = typeLabel;
  }

  /**
   * Redirige vers la page de recherche avec les paramètres donnés
   */
  public doSearch() {
    let searchData = new SearchData();
    searchData.text = this.search.text;
    searchData.searchProjects = false;
    searchData.searchDatas = false;
    searchData.searchLinks = false;
    searchData.searchWorkflows = false;
    switch (this.search.type) {
      case 'project': searchData.searchProjects = true;
        break;
      case 'workflow': searchData.searchWorkflows = true;
        break;
      case 'data': searchData.searchDatas = true;
        break;
      case 'link': searchData.searchLinks = true;
        break;
      default:
        searchData.searchProjects = true;
        searchData.searchDatas = true;
        searchData.searchLinks = true;
        searchData.searchWorkflows = true;
    }
    this.session.searchData = searchData;
    this._router.navigate(['/resources'])
      .then(r => {
        if (!r) {
          this._resourceService.doExternalSearch(searchData);
        }
      });
  }

  /**
   * Déconnecte l'utilisateur et redirige vers la page de connexion
   */
  public logout(): void {
    this.session.logout();
    this._router.navigate(['/login']);
  }

  /**
   * Met à jour la liste des exécutions et relance une demande 10s plus tard
   * @param executions - Liste des exécutions reçues
   */
  private _updateExecutionsList(executions: Execution[]) {
    this.urls = {};
    this.executions = executions;

    this.completedExecutions = 0;
    _.each(this.executions, (execution: Execution) => {
      if (execution.status === Constants.executionStatus.completed) {
        this.completedExecutions++;
      }

      let url = "/projects";
      if (this.session.hasRight(execution.projectId, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
        url = "/my-projects";
      }
      this.urls[execution.id] = url + '/' + execution.projectId + '/workflows/' + execution.workflowId
    });

    this._timeout = setTimeout(() => this._workflowService.getRunningExecutions(), 10000);
  }

  /**
   * Met à jour une exécution à partir d'un log si elle existe
   * @param result 
   */
  private _updateExecutionsFromLogs(result: { execId: string, status: string, logs: string }) {
    let loggedExecution = _.find(this.executions, { id: result.execId });
    if (loggedExecution) {
      loggedExecution.status = result.status;
    } else {
      clearTimeout(this._timeout);
      this._workflowService.getRunningExecutions();
    }

  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param item 
   */
  public trackById(item) {
    return item.id;
  }
}
