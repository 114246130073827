import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { UserService } from "src/app/services";
import { Group } from "src/app/models";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './group-choice-modal.component.html'
})
export class GroupChoiceModalAdminComponent implements OnInit, OnDestroy {
  @Input() currentGroups: Group[] = [];

  public selectedGroup: Group = null;

  public groups: Group[] = [];

  private _subs = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this._subs.add(this._userService.groups$.subscribe(groups => this._initGroups(groups)));

    this._userService.getGroups();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public save(groupForm: NgForm) {
    if (groupForm.valid) {
      this.modal.close(this.selectedGroup);
    }
  }

  /**
   * Optimisation pour le ngFor
   * @param i 
   * @param group 
   */
  public trackById(i, group) {
    return group.id;
  }

  private _initGroups(groups: Group[]) {
    this.groups = _.filter(groups, g => !_.find(this.currentGroups, { id: g.id }));
  }
}