<section id="link-detail" class="meta-detail d-flex flex-column h-100" *ngIf="link">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto">{{link.name}}</h1>
    <a [routerLink]="['./edit']" *ngIf="session.hasRight(link.projectId, objectTypeProject, 'editor')" class="btn btn-default">
      <i class="fa fa-edit"></i>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button class="btn btn-danger ml-3" (click)="deleteLink()" *ngIf="session.hasRight(link.projectId, objectTypeProject, 'editor')">
      <i class="fa fa-trash-alt"></i>
      <ng-container i18n>Supprimer</ng-container>
    </button>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="subcontent">
      <dl class="mb-0 row">
        <div class="col-12 col-xl-8">
          <h2 i18n>Informations</h2>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <dt class="d-inline" i18n>Langue :</dt>&nbsp;
                <dd class="d-inline"><display-lang [displayLang]="link.language"></display-lang></dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Mots-clés :</dt>&nbsp;
                <dd class="d-inline">{{link.freeKeywords.join(', ')}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Thématiques :</dt>&nbsp;
                <dd class="d-inline">
                  <ng-container *ngFor="let thematic of link.thematics; let isLast=last">
                    {{thematic.label}}{{isLast ? '' : ', '}}
                  </ng-container>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Type :</dt>&nbsp;
                <dd class="d-inline">{{link.typeName}}</dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Étude :</dt>&nbsp;
                <dd class="d-inline">
                  <a [routerLink]="[projectPath, link.projectId]">{{project ? project.name : ''}}</a>
                </dd>
              </div>
              <div class="mb-2">
                <dt class="d-inline" i18n>Url :</dt>&nbsp;
                <dd class="d-inline">
                  <a [href]="link.url" target="_blank" class="nowrap">
                    {{link.url}}
                    <i class="fa fa-external-link-alt"></i>
                  </a>
                </dd>
              </div>
              <dt class="accordion-title">
                <button type="button" class="btn btn-icon accordion-btn w-100 d-flex"
                  (click)="hideDescription = !hideDescription">
                  <span i18n>Description</span>
                  <i class="fa fa-chevron-up ml-auto" [ngClass]="{rotated: hideDescription}"></i>
                </button>
              </dt>
              <dd class="nl2br accordion-content" [ngClass]="{closed: hideDescription}">{{link.description}}</dd>
            </div>
            <div class="col-6"></div>
          </div>
        </div>

        <div class="col-12 col-xl-4">
          <hr class="my-3 d-xl-none">
          <h2>Emprises</h2>
          <dt>
            <ng-container *ngIf="link.extentDescription">{{link.extentDescription}}</ng-container>
            <ng-container *ngIf="!link.extentDescription" i18n>(aucune description)</ng-container>
          </dt>
          <dd>
            <simple-map [extents]="link.extents"></simple-map>
          </dd>
        </div>
        <div class="col">

        </div>
        <div class="col">

        </div>
      </dl>
    </div>
  </div>
</section>
