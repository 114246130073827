import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  constructor() { }

  transform(value: number, digits: number = 2, stage: number = 1) {
    let stageUnit = $localize`ko`;
    switch (stage) {
      case 0: stageUnit = $localize`o`;
        break;
      case 1: stageUnit = $localize`ko`;
        break;
      case 2: stageUnit = $localize`Mo`;
        break;
      case 3: stageUnit = $localize`Go`;
        break;
    }

    let showValue = value / Math.pow(1024, stage);
    showValue = Math.round(showValue * Math.pow(10, digits)) / Math.pow(10, digits);

    return showValue + ' ' + stageUnit;
  }
}