import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { LoaderService } from 'src/app/services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {
  public collapsed: boolean = false;

  public appVersion = environment.version;

  constructor(
    private _loaderService: LoaderService
  ) { }

  public toggleMenu() {
    this.collapsed = !this.collapsed;
    this._loaderService.changeMenuWidth(this.collapsed);
  }
}
