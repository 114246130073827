import { EntityMapper } from "./entity-mapper.model";

export class OnlineResource extends EntityMapper {

  /**
   * Id de la online resource
   */
  public id: number = null;

  /**
   * Nom de la resource
   */
  public name: string = "";

  /**
   * Description
   */
  public description: string = "";

  /**
   * Url
   */
  public url: string = "";

  /**
   * Protocole d'exécution de la ressource
   */
  public protocol: string = "";

  /**
   * Classification de la ressource
   */
  public type: string = "";

  constructor() {
    super();

    this._mapperDefs = [
      { front: "url", back: "link" },
      { front: "type", back: "function.codeListValue" }
    ];
  }

  public deserialize(json: any): this {
    super.deserialize(json);
    
    if(this.url) {
      this.url = this.url.replace('localhost:8080', window.location.host);
    }

    return this;
  }
}