import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

import { PaginationComponent, FiltrableTableComponent, CounterComponent } from './components';
import {
  SortableColumnDirective, HeadContentTemplateDirective,
  BodyContentTemplateDirective, CounterContentTemplateDirective
} from './directives';
import { FilterService } from './services';

@NgModule({
  declarations: [
    SortableColumnDirective,
    HeadContentTemplateDirective,
    BodyContentTemplateDirective,
    CounterContentTemplateDirective,
    PaginationComponent,
    FiltrableTableComponent,
    CounterComponent
  ],
  imports: [
    BrowserModule,
    NgbModule
  ],
  providers: [
    FilterService,
    { provide: LOCALE_ID, useValue: 'fr' }
  ],
  exports: [
    SortableColumnDirective,
    HeadContentTemplateDirective,
    BodyContentTemplateDirective,
    CounterContentTemplateDirective,
    PaginationComponent,
    FiltrableTableComponent,
    CounterComponent
  ]
})
export class FiltrableTableModule { }
