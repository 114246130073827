<section id="help" class="d-flex flex-column h-100">
  <div class="page-header">
    <h1 i18n>Aide</h1>
  </div>
  <div class="page-content">
    <div class="subcontent">
      <p>Le premier objectif de cette plateforme est de <strong>faciliter la mise à disposition, la traçabilité et l'exécution de calculs, traitements et modélisations.</strong> Ce portail web permet ainsi de travailler dans des <strong>espaces de travail collaboratifs ou privés (les études)</strong>, d'y <strong>déposer des fichiers de données</strong> et d'utiliser des traitements et calculs, avec une interface "clique-bouton" permettant de les paramétrer puis de les exécuter. Ces exécutions se font sur les serveurs distants et non en local sur les postes personnels.</p>
      <p><strong>Les principales notions sont les suivantes:</strong></p>
      <ul>
        <li class="mb-1"><strong>La notion de workflow:</strong> plutôt que de parler de traitements/process individuels, on utilise la notion de workflow qui représente une chaîne de traitements/de calculs (= de codes exécutables). Un workflow définit un ensemble de tâches, qui sont chacune des commandes appelant des programmes (quels qu'ils soient). Un workflow définit en plus les inputs, les outputs, et les transferts ouput -> input entre chaque tâche individuelle.</li>
        <li class="mb-1"><strong>Le catalogue de métadonnées: mettre à disposition, améliorer la visibilité d'un code et de données:</strong> les workflows et les données sont catalogués et peuvent être "découverts" facilement à travers un moteur de recherche offrant différents critères. Chaque workflow, chaque donnée, vient avec sa fiche de méta-données (selon norme ISO compatible INSPIRE) qui permet en particulier d'indiquer les auteurs et de référencer des liens bibliographiques.</li>
        <li class="mb-1"><strong>Les études: classement, archivage:</strong> tout se classe à travers des études, qui sont des contenants permettant d'exécuter des workflows, de stocker des fichiers de données et de référencer des liens (bibliographie, site web projet, etc).</li>
        <li class="mb-1"><strong>Référencement, archivage, traçabilité:</strong> grâce aux méta-données, sur le long terme, cette plateforme devra répondre à la question « qu'est-ce qui a déjà été fait sur cette région, pour cet aléa, ce scénario de risque ».</li>
        <li class="mb-1"><strong>Les exécutions: traçabilité:</strong> toutes les exécutions qu'un utilisateur souhaite mémoriser sont enregistrées avec tout ce qui permet de les ré-exécuter: le code (sa version installée), les inputs, les outputs. À tout moment, cet utilisateur, ou un autre qui a les droits, peut revenir et retrouver la trace complète (y compris logs) de cette exécution. Tous les fichiers qu'il a choisi d'utiliser en input sont classés et conservés. Les outputs sont conservés également et il peut choisir d'identifier en particulier certains fichiers de résultats qui sont alors enregistrés en tant que fichiers de données de l'étude, avec les informations permettant de retrouver exactement comment ils ont été calculés (par quel workflow, quelle exécution donc quels inputs et output).</li>
        <li class="mb-1"><strong>Ré-utilisabilité:</strong> lorsqu'un utilisateur souhaite effectuer un calcul, qu'il soit pré-traitement, analyse ou modélisation, il peut aller consulter le moteur de recherche et voir si une telle opération a déjà été effectuée. S'il trouve un workflow qui correspond à son besoin, il peut le lier dans son environnement de travail (son étude) et l'exécuter directement, avec son propre paramétrage et ses propres données.</li>
        <li class="mb-1"><strong>Travail collaboratif: users, groupes, sécurité:</strong> Une gestion des permissions par utilisateur et groupe permet de créer des études privées ou collaboratives en partageant avec d'autres collègues ou groupes de collègues. Les permissions se déclinent en "lecteur, éditeur, propriétaire".</li>
        <li><strong>Capitaliser les méthodologies développées dans les projets de recherche,</strong> en facilitant l'intégration et la mise à disposition des algorithmes scientifiques et traitement, à travers des librairies et par services web.</li>
      </ul>
      <div class="mw-50">
        <a class="d-block" href="https://gitlab.brgm.fr/brgm/vigirisks/communities/end-users/-/wikis/home" target="_blank">
          <ng-container i18n>Documentation utilisateur</ng-container>
          <i class="ml-1 fa fa-external-link-alt"></i> 
        </a>
        <a class="d-block" href="https://gitlab.brgm.fr/brgm/vigirisks/communities/communication/-/wikis/home" target="_blank">
          <ng-container i18n>Matériel de communication</ng-container>
          <i class="ml-1 fa fa-external-link-alt"></i> 
        </a>
      </div>
    </div>
  </div>
</section>
