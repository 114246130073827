<section id="links" class="d-flex flex-column h-100">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Mes liens</h1>
    <div class="title-button-container">
      <a [routerLink]="['./new/edit']" class="btn btn-primary"> 
        <i class="fa fa-plus"></i> 
        <ng-container i18n>Référencer un nouveau lien</ng-container>
      </a>
    </div>
  </div>
  <div class="page-content d-flex flex-column">
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter karamazov" *ngIf="totalResults === 0" i18n>Aucun lien</p>
          <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} liens</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="links" #dataTable>
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
            <th class="project" sortable-column="project.name"><span i18n>Étude</span></th>
            <th class="type" sortable-column="typeName"><span i18n>Type</span></th>
            <th class="url" sortable-column="source"><span i18n>Url</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-link>
          <tr>
            <td><a [routerLink]="['./', link.id]">{{link.name}}</a></td>
            <td>
              <a class="neutral-link"
                [routerLink]="session.hasRight(link.projectId, objectTypeProject, 'owner') ? ['/my-projects', link.projectId] : ['/projects', link.projectId]">
                {{link.project.name}}
              </a>
            </td>
            <td>{{link.typeName}}</td>
            <td class="url"><a [href]="link.url" [title]="link.url" target="_blank">{{link.url}}</a></td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
              <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                <a class="dropdown-item" [routerLink]="['./', link.id]" i18n>Consulter</a>
                <a *ngIf="session.hasRight(link.projectId, objectTypeProject, 'editor')" class="dropdown-item" [routerLink]="['./', link.id, 'edit']" i18n>Modifier</a>
                <a *ngIf="session.hasRight(link.projectId, objectTypeProject, 'editor')" class="dropdown-item item-danger" (click)="deleteLink(link)" i18n>Supprimer</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>
