import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { Subject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Constants, Uris } from '../constants';
import { Resource, Link, SearchData, Project, Workflow, Data, EntityBusinessObject, MetadataEditing } from '../models';
import { RightsModalComponent } from '../components/modals/rights/rights-modal.component';
import { LoaderService } from './loader.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  /**
   * Source utilisée lors de la récupération d'une liste de resources
   */
  private _resourcesSource = new Subject<Resource[]>();

  /**
   * Source utilisée au retour d'une édition des droits d'une resource
   */
  private _editRightsSource = new Subject<any>();

  /**
   * Source utilisée lors que le header effectue une recherche alors qu'on est dans la page recherche
   */
  private _externalSearch = new Subject<SearchData>();

  /**
   * Observable appelé à chaque récupération d'une liste de resource
   */
  public resources$ = this._resourcesSource.asObservable();

  /**
   * Observable appelé à chaque édition des droits d'une resource
   */
  public editRights$ = this._editRightsSource.asObservable();

  /**
   * Observable appelé à chaque recherche du header dans le cadre de la page recherche
   */
  public externalSearch$ = this._externalSearch.asObservable();

  constructor(
    private _http: HttpClient,
    private _toastr: ToastrService,
    private _modalService: NgbModal,
    private _loader: LoaderService,
    private _session: SessionService
  ) { }

  /**
   * Demande la recherche des resources metadata selon
   * @param searchData - critères de recherche
   */
  public searchResources(searchData: SearchData): void {
    this._http.post<any>(Uris.SEARCH, searchData.serialize())
      .pipe(
        map(result => {
          result = result || {};
          result.metadataProjects = result.metadataProjects || [];
          result.metadataWorkflows = result.metadataWorkflows || [];
          result.metadataDatas = result.metadataDatas || [];
          result.metadataLinks = result.metadataLinks || [];
          return result;
        }),
        map(result => {
          result.metadataProjects = result.metadataProjects.map(p => new Project().deserialize(p));
          result.metadataWorkflows = result.metadataWorkflows.map(p => new Workflow().deserialize(p));
          result.metadataDatas = result.metadataDatas.map(p => new Data().deserialize(p));
          result.metadataLinks = result.metadataLinks.map(p => new Link().deserialize(p));
          return result;
        }),
        switchMap(result => {
          return this._http.get<Project[]>(Uris.PROJECTS)
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(result.metadataDatas, data => {
                  data.project = _.find(projects, { id: data.projectId });
                });
                _.each(result.metadataLinks, link => {
                  link.project = _.find(projects, { id: link.projectId });
                });
                return result;
              })
            )
        })
      )
      .subscribe(result => {

        let resources: Resource[] = [];

        _.each(result.metadataProjects, (project: Project) => {
          let url = '/projects/' + project.id;
          if (this._session.hasRight(project.id, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
            url = '/my-projects/' + project.id;
          }
          resources.push(new Resource().deserialize({
            id: project.id,
            type: 'project',
            typeLabel: 'Étude',
            lastUpdate: project.lastUpdate,
            name: project.name,
            sourceObject: project,
            extents: project.extents || [],
            url: url
          }));
        });
        _.each(result.metadataWorkflows, (workflow: Workflow) => {
          let url = '/workflows/' + workflow.id;
          if (this._session.hasRight(workflow.id, Constants.OBJECT_TYPE_WORKFLOW, 'owner')) {
            url = '/my-workflows/' + workflow.id;
          }
          resources.push(new Resource().deserialize({
            id: workflow.id,
            type: 'workflow',
            typeLabel: 'Workflow',
            lastUpdate: workflow.lastUpdate,
            name: workflow.name,
            sourceObject: workflow,
            extents: workflow.extents || [],
            url: url
          }));
        });
        _.each(result.metadataDatas, (data: Data) => {
          let url = "/projects/" + data.projectId + "/datas/" + data.id;
          if (this._session.hasRight(data.projectId, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
            url = '/my-datas/' + data.id;
          }
          resources.push(new Resource().deserialize({
            id: data.id,
            type: 'data',
            typeLabel: 'Donnée',
            lastUpdate: data.lastUpdate,
            name: data.name,
            sourceObject: data,
            extents: data.extents || [],
            url: url
          }));
        });
        _.each(result.metadataLinks, (link: Link) => {
          let url = "/projects/" + link.projectId + "/links/" + link.id;
          if (this._session.hasRight(link.projectId, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
            url = '/my-links/' + link.id;
          }
          resources.push(new Resource().deserialize({
            id: link.id,
            type: 'link',
            typeLabel: 'Lien',
            lastUpdate: link.lastUpdate,
            name: link.name,
            sourceObject: link,
            extents: link.extents || [],
            url: url
          }));
        });

        if (result.numberOfRecordsMatched > resources.length) {
          this._toastr.info(
            $localize`Pour des raisons de performance, seules ${resources.length} ressources sont affichées sur ${result.numberOfRecordsMatched} trouvées. Veuillez affiner vos critères de recherche.`,
            null,
            { timeOut: 0, closeButton: true }
          )
        }

        this._resourcesSource.next(_.sortBy(resources, ['name']));
      }, error => {
        console.error(error);
        this._loader.hide();
        this._toastr.error($localize`Une erreur est survenue pendant la recherche, veuillez réessayer plus tard.`);
      });
  }

  /**
   * Ouvre la modale d'édition des droits d'une métadonnée
   * @param itemId - ID de la métadonnée (uri)
   * @param itemTitle - Nom de la métadonnée
   * @param itemType - Type de métadonnée (project, workflow, link ou data)
   */
  public editResourceRights(item: EntityBusinessObject, itemType: string) {
    let initialPermissions = {
      individualPermissions: _.cloneDeep(item.individualPermissions),
      groupPermissions: _.cloneDeep(item.groupPermissions)
    };
    const modalRef = this._modalService.open(RightsModalComponent, { backdrop: "static", size: "lg", windowClass: "confirm-modal rights-modal" });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.objectType = itemType;

    modalRef.result.then(result => {
      // save le résultat
      let calls = [];

      //// droits individuels

      // suppressions
      _.each(initialPermissions.individualPermissions, permission => {
        let currentPermission = _.find(result.individualPermissions, { objectId: permission.objectId, objectType: permission.objectType, userId: permission.userId });
        if (!currentPermission) { // suppression
          calls.push(this._http.delete<any>(Uris.USERS + permission.userId + `/permissions?objectId=${permission.objectId}&objectType=${permission.objectType}`));
        }
      });

      // ajouts et éditions
      _.each(result.individualPermissions, permission => {
        let initialPermission = _.find(initialPermissions.individualPermissions, { objectId: permission.objectId, objectType: permission.objectType, userId: permission.userId });
        if (initialPermission) {
          if (initialPermission.code !== permission.code) { // édition
            calls.push(this._http.put<any>(Uris.USERS + permission.userId +
              `/permissions?code=${permission.code}&objectId=${permission.objectId}&objectType=${permission.objectType}&id=${permission.id}`, {}));
          }
        } else { // ajout
          calls.push(this._http.post<any>(Uris.USERS + permission.userId +
            `/permissions?code=${permission.code}&objectId=${permission.objectId}&objectType=${permission.objectType}`, {}));
        }
      });

      //// droits de groupe

      // suppressions
      _.each(initialPermissions.groupPermissions, permission => {
        let currentPermission = _.find(result.groupPermissions, p => {
          return p.objectId === permission.objectId && p.objectType === permission.objectType && p.group.id === permission.group.id;
        });
        if (!currentPermission) { // suppression
          calls.push(this._http.delete<any>(Uris.GROUPS + permission.group.id + `/permissions?objectId=${permission.objectId}&objectType=${permission.objectType}`));
        }
      });

      // ajouts et éditions
      _.each(result.groupPermissions, permission => {
        let initialPermission = _.find(initialPermissions.groupPermissions, p => {
          return p.objectId === permission.objectId && p.objectType === permission.objectType && p.group.id === permission.group.id;
        });
        if (initialPermission) {
          if (initialPermission.code !== permission.code) { // édition
            calls.push(this._http.put<any>(Uris.GROUPS + permission.group.id +
              `/permissions?code=${permission.code}&objectId=${permission.objectId}&objectType=${permission.objectType}&id=${permission.id}`, {}));
          }
        } else { // ajout
          calls.push(this._http.post<any>(Uris.GROUPS + permission.group.id +
            `/permissions?code=${permission.code}&objectId=${permission.objectId}&objectType=${permission.objectType}`, {}));
        }
      });

      if (calls.length > 0) {
        this._loader.show();
        forkJoin(calls)
          .pipe(switchMap(() => this._session.getUserPermissionsObs(null)))
          .subscribe(() => {
            this._editRightsSource.next(result);
            this._toastr.success($localize`Les permissions de '${item.name}' ont été modifiées avec succès`);
            this._loader.hide();
          }, error => {
            console.error(error);
            this._editRightsSource.next(false);
            this._toastr.error($localize`Une erreur est survenue lors de la modification des permissions`);
            this._loader.hide();
          });
      } else {
        this._editRightsSource.next(false);
        this._toastr.success($localize`Les permissions de '${item.name}' ont été modifiées avec succès`);
      }

    }, () => null);
  }

  /**
   * Force une recherche pour le composant concerné
   * @param searchData Recherche à effectuer
   */
  public doExternalSearch(searchData: SearchData) {
    this._externalSearch.next(searchData);
  }

  /**
   * Récupère les informations d'édition si la metadata est en cours d'édition par un autre utilisateur
   * @param objectId Identifiant de l'objet
   * @param objectType Type de l'objet (étude, workflow, dataset ou lien)
   * @returns 
   */
  public getResourceEditingStatus(objectId: string, objectType: string): Observable<MetadataEditing> {
    const params = new HttpParams().set('objectType', objectType);
    return this._http.get<MetadataEditing>(`${Uris.METADATA_EDITITNG}${objectId}`, { params })
      .pipe(
        map(me => {
          if (me) {
            return new MetadataEditing().deserialize(me);
          }
          return null;
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      );
  }

  /**
   * Indique au serveur que la metadata est en cours d'édition ou terminée d'éditer
   * @param objectId Identifiant de l'objet
   * @param objectType Type de l'objet (étude, workflow, dataset ou lien)
   * @param isEditing Est-elle en cours d'édition ?
   */
  public setResourceAsEditing(objectId: string, objectType: string, isEditing: boolean) {
    var obs: Observable<any>;
    if (isEditing) {
      let me = new MetadataEditing();
      me.userId = this._session.currentUser.email;
      me.objectId = objectId;
      me.objectType = objectType;
      obs = this._http.post(`${Uris.METADATA_EDITITNG}`, me.serialize());
    } else {
      const params = new HttpParams().set('objectType', objectType);
      obs = this._http.delete(`${Uris.METADATA_EDITITNG}${objectId}`, { params });
    }
    obs.subscribe(() => { }, error => console.error(error));
  }
}
