import * as _ from 'lodash';

import { Component, TemplateRef, Input, ContentChild, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { HeadContentTemplateDirective, BodyContentTemplateDirective } from "../../directives";
import { FilterService, FilterEvent } from "../../services";
import { Subscription } from "rxjs";

@Component({
  selector: 'vigirisks-fitrable-table',
  templateUrl: './filtrable-table.component.html'
})
export class FiltrableTableComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Liste des données à afficher
   */
  @Input() rows: any[] = [];

  /**
   * Nombre de données par page
   */
  @Input() itemsPerPage: number = 10;

  /**
   * Directives et templates pour le composant
   */
  @ContentChild(HeadContentTemplateDirective, /* TODO: add static flag */ {}) public headDir: HeadContentTemplateDirective;
  @ContentChild(BodyContentTemplateDirective, /* TODO: add static flag */ {}) public bodyDir: BodyContentTemplateDirective;

  get headTpl(): TemplateRef<any> {
    return this.headDir && this.headDir.tpl;
  }

  get bodyTpl(): TemplateRef<any> {
    return this.bodyDir && this.bodyDir.tpl;
  }

  /**
   * Liste des données filtrées et ordonnées de la page courante
   */
  public filteredRows: any[];

  /**
   * Cache des paramètres de filtrage
   */
  public filterSettings: FilterEvent = {
    sortColumn: '',
    sortDirection: '',
    itemsPerPage: this.itemsPerPage,
    collectionSize: 0,
    page: 1,
    target: null
  };

  /**
   * Fonction filtrable, à remplacer par le parent
   */
  public customFilter: (rows: any[]) => any[] = rows => rows;

  /**
   * Souscriptions du composant
   */
  private _subs = new Subscription();

  constructor(private _filterService: FilterService) { }

  ngOnInit() {
    this._subs.add(this._filterService.updateFilters$.subscribe(event => this.filter(event)));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngOnChanges(changes) {
    if (changes.rows) {
      this.filterSettings.collectionSize = this.rows.length;
      this.filter();
    }
  }

  /**
   * Met à jour les paramètres et filtre les données
   * @param event Evénement de filtre partiel
   */
  public filter(event?: any) {
    if (event) {
      this.filterSettings = _.defaults(event, this.filterSettings);
    }

    let filteredRows = this.customFilter(this.rows);
    this.filterSettings.collectionSize = filteredRows.length;

    if(this.filterSettings.sortColumn) {
      filteredRows = _.sortBy(filteredRows, [r => this._stripCaseAndAccents(_.get(r, this.filterSettings.sortColumn, null))]);
  
      if (this.filterSettings.sortDirection === 'desc') {
        filteredRows.reverse();
      }
    }

    let chunkedList = _.chunk(filteredRows, this.itemsPerPage);
    if (chunkedList.length >= this.filterSettings.page) {
      this.filteredRows = chunkedList[this.filterSettings.page - 1];
    } else {
      this.filteredRows = chunkedList.length > 0 ? chunkedList[0] : [];
    }
    if (this.filterSettings.target) {
      let index = _.findIndex(filteredRows, r => _.get(r, this.filterSettings.target.field) === this.filterSettings.target.value);
      if (index >= 0) {
        let page = Math.floor(index / this.filterSettings.itemsPerPage);
        this.filteredRows = chunkedList[page];
        this.filterSettings.page = page + 1;
        this.filterSettings.target = null;
      }
    }
    
    this._filterService.filterTable(this.filterSettings);
  }

  /**
   * Remplace les majuscules et les accents par leur équivalent simple
   * @param value Texte à modifier
   */
  private _stripCaseAndAccents(value) {
    if (_.isString(value)) {
      return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    return value;
  }
}