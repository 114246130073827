import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import { OnInit, OnDestroy, Component } from "@angular/core";
import { WorkflowService, UtilsService, LoaderService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Workflow, Execution, Destination } from "src/app/models";
import { Constants } from 'src/app/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowMapModalComponent, ResultsTableModalComponent } from '../../modals';

@Component({
  templateUrl: './execution-detail.component.html'
})
export class ExecutionDetailComponent implements OnInit, OnDestroy {
  /**
   * Exécution courante
   */
  public execution: Execution;

  /**
   * Traduction du statut actuel de l'exécution
   */
  public currentStatus: string;

  /**
   * Données de logs et de résultats de l'exécution
   */
  public data: { logs: string, results: any[] } = { logs: null, results: null };

  /**
   * Liste des clés des inputs de l'exécution
   */
  public inputsKeys: string[];

  /**
   * Afficher le bouton de visualisation des résultats sur la carte ?
   */
  public displayMapButton: boolean = false;

  /**
   * Afficher le bouton de visualisation des résutlats en tableau ?
   */
  public displayTableButton: boolean = false;

  /**
   * Enum des statuts d'exécution
   */
  public executionStatus = Constants.executionStatus;

  /**
   * Contient toutes les soucriptions du composant
   */
  private _sub = new Subscription();

  /**
   * ID de l'exécution
   */
  private _executionId: string;

  /**
   * Workflow de l'exécution
   */
  private _workflow: Workflow;

  constructor(
    private _route: ActivatedRoute,
    private _workflowService: WorkflowService,
    private _utils: UtilsService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _modalService: NgbModal
  ) { }

  ngOnInit() {
    this._sub.add(this._workflowService.workflow$.subscribe(workflow => this._initExecutionFromWorkflow(workflow)));
    this._sub.add(this._workflowService.execution$.subscribe(execution => this._initExecution(execution)));
    this._sub.add(this._workflowService.executionLogs$.subscribe(data => this._updateLogsAndStatus(data)));
    this._sub.add(this._workflowService.executionResults$.subscribe(data => this._updateResults(data)));
    this._sub.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initFromParams(params)));

    this._loader.show();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  /**
   * Ouvre la carte du workflow en affichant les résultats
   */
  public openResultsMap() {
    this._loader.show();
    this._workflowService.getResultsData(this.data.results, "display_map").subscribe(results => {
      this._loader.hide();
      let destination: Destination;
      if (this._workflow.extents && this._workflow.extents.length > 0) {
        destination = new Destination().deserialize({
          label: $localize`Emprise du workflow`,
          extent: this._workflow.getExtent()
        });
      }

      const modalRef = this._modalService.open(WorkflowMapModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "map-modal" });
      modalRef.componentInstance.mapConfig = this._workflow.mapConfig;
      modalRef.componentInstance.options = {
        features: results.length > 0 ? results : null,
        destinations: destination ? [destination] : null,
        execId: this.execution.id
      };
    });
  }

  /**
   * Ouvre la modal de récapitulatif des données de résultats
   */
  public openResultsTable() {
    this._loader.show();
    this._workflowService.getResultsData(this.data.results, "display_table").subscribe(results => {
      this._loader.hide();
      const modalRef = this._modalService.open(ResultsTableModalComponent, { backdrop: "static", size: "lg", centered: true, windowClass: "confirm-modal results-table-modal" });
      modalRef.componentInstance.mapConfig = this._workflow.mapConfig;
      modalRef.componentInstance.results = results;
    });
  }

  /**
   * Initialise la page à partir des paramètres
   * @param params Paramètres d'url
   */
  private _initFromParams(params: any) {
    if (params) {
      this._executionId = params.executionId;
      this._workflowService.getWorkflow(params.workflowId, null, true);
    }
  }

  /**
   * Initialise l'exécution depuis le workflow reçu
   * @param workflow Workflow reçu du serveur
   */
  private _initExecutionFromWorkflow(workflow: Workflow) {
    this._workflow = workflow;
    this._workflowService.getExecution(this._executionId, workflow);
  }

  /**
   * Affiche l'exécution reçue
   * @param execution Exécution reçue du serveur
   */
  private _initExecution(execution: Execution) {
    this.execution = execution;
    this.inputsKeys = _.keys(this.execution.inputs);
    this._workflowService.getExecutionLogs(this.execution.id)
  }

  /**
   * Met à jour les logs et le statut d'exécution
   * @param result - résultat du serveur d'exécution
   */
  private _updateLogsAndStatus(result: { execId: string, status: string, logs: string }) {
    if (result.execId === this.execution.id) {
      this.data.logs = result.logs;
      if (this.execution.status !== Constants.executionStatus.saved) {
        this.execution.status = result.status;
      }
      switch (result.status) {
        case Constants.executionStatus.submitted:
        case Constants.executionStatus.running:
          this.currentStatus = $localize`En cours d'exécution`;
          this._loader.hide();
          break;
        case Constants.executionStatus.completed:
          this.currentStatus = $localize`Succès`;
          this._workflowService.getExecutionResults(this.execution.id);
          break;
        case Constants.executionStatus.saved:
          this.currentStatus = $localize`Enregistrée`;
          this._workflowService.getExecutionResults(this.execution.id);
          break;
        case Constants.executionStatus.failed:
          this.currentStatus = $localize`Échouée`;
          this.data.logs = $localize`Le serveur a rencontré une erreur, la requête n'a pas pu être exécutée.`;
          this._loader.hide();
          break;
      }
    }
  }

  /**
   * Met à jour la liste des résultats
   * @param result 
   */
  private _updateResults(result: { execId: string, results: any[] }) {
    if (result.execId === this.execution.id) {
      this.data.results = result.results;

      _.each(this.data.results, r => {
        if (_.find(r.functions, { action: 'display_map' })) {
          this.displayMapButton = true;
        }
        if (_.find(r.functions, { action: 'display_table' })) {
          this.displayTableButton = true;
        }
      });
      this._loader.hide();
    }
  }

  /**
   * Conserve l'exécution
   */
  public switchDraftOff() {
    this.updateDraft(false);    
  }

  /**
   * Met l'exécution en Brouillon
   */
  public switchDraftOn() {
    this.updateDraft(true);    
  }

  private updateDraft(newDraft: boolean) {
    this._loader.show();
    this.execution.draft=newDraft;

    this._workflowService.editExecution(this.execution)
    .subscribe(() => {
      this._toastr.success($localize`L'exécution a été modifiée avec succès`);
      this._loader.hide();
    }, error => console.error(error));

  }
}