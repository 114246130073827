<section id="resources" class="h-100 w-100">
  <div class="h-100 w-100" id="map" #mapContainer>
    <autocomplete *ngIf="map" [map]="map"></autocomplete>

    <div *ngIf="destinations && destinations.length > 0" class="ol-unselectable ol-control destination-selector map-control-selector">
      <div class="d-flex align-items-center">
        <label for="destination-selector"><ng-container i18n>Aller à</ng-container>: </label>
        <select id="destination-selector" class="form-control ml-2" 
          name="destination-selector" [(ngModel)]="currentDestinationExtent" 
          (ngModelChange)="chooseDestination(currentDestinationExtent)">
          <option [ngValue]="null" i18n>Aller directement à...</option>
          <option *ngFor="let dest of destinations" [ngValue]="dest.extent">{{dest.label}}</option>
        </select>
      </div>
    </div>
  </div>


  <div class="right-panel p-0" [ngClass]="{opened: displayFilters, wide: (displayFilters && displayTable && displayTableWide)}">
    <button type="button"
      class="toggle-btn btn btn-icon"
      i18n-title title="Afficher/cacher les filtres"
      (click)="displayFilters = !displayFilters">
      <i class="fa fa-caret-left"></i>
    </button>
    <form class="search-filters h-100 d-flex flex-column" (ngSubmit)="search()">
      <h2 i18n>Recherche de ressources</h2>
      <button type="button" name="close" class="btn btn-icon fa fa-times" i18n-title title="Fermer le panel de recherche" (click)="displayFilters = !displayFilters"></button>
      <div class="h-100 search-filters-content">
        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-text" i18n>Recherche</label>
          <div class="col">
            <input type="text" id="search-text" class="form-control search-text" name="searchText" [(ngModel)]="searchData.text">
          </div>
        </div>
  
        <div class="form-group row no-gutters align-items-start">
          <label class="col-4 mb-0" i18n>Types de ressource</label>
          <div class="col-4">
            <div class="form-check mb-3">
              <input type="checkbox" id="search-type-project" class="form-check-input"
                name="projectType"
                [(ngModel)]="searchData.searchProjects">
              <label class="form-check-label" for="search-type-project" i18n>Études</label>
            </div>
            <div class="form-check">
              <input type="checkbox" id="search-type-workflow" class="form-check-input"
                name="workflowType"
                [(ngModel)]="searchData.searchWorkflows">
              <label class="form-check-label" for="search-type-workflow" i18n>Workflows</label>
            </div>
          </div>
          <div class="col-4">
            <div class="form-check mb-3">
              <input type="checkbox" id="search-type-link" class="form-check-input"
                name="linkType"
                [(ngModel)]="searchData.searchLinks">
              <label class="form-check-label" for="search-type-link" i18n>Liens</label>
            </div>
            <div class="form-check">
              <input type="checkbox" id="search-type-data" class="form-check-input"
                name="dataType"
                [(ngModel)]="searchData.searchDatas">
              <label class="form-check-label" for="search-type-data" i18n>Données</label>
            </div>
          </div>
        </div>
  
        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-link-type" i18n>Type de liens</label>
          <div class="col">
            <select id="search-link-type" class="form-control search-link-type" name="search-link-type" 
              [(ngModel)]="searchData.linkType" [disabled]="!searchData.searchLinks">
              <option [ngValue]="null" i18n>Tous les types de liens</option>
              <option *ngFor="let linkType of linkTypes" [ngValue]="linkType">{{linkType}}</option>
            </select>
          </div>
        </div>
        
        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-data-type" i18n>Type de données</label>
          <div class="col">
            <select id="search-data-type" class="form-control search-data-type" name="searchDataType" 
              [(ngModel)]="searchData.dataType" [disabled]="!searchData.searchDatas">
                <option [ngValue]="null" i18n>Tous les types de données</option>
                <option *ngFor="let dataType of dataTypes" [ngValue]="dataType">{{dataType}}</option>
              </select>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-workflow-type">Type de workflows</label>
          <div class="col">
            <select id="search-workflow-type" class="form-control search-workflow-type" name="search-workflow-type" 
              [(ngModel)]="searchData.workflowType" [disabled]="!searchData.searchWorkflows">
                <option [ngValue]="null" i18n>Tous les types de workflows</option>
                <option *ngFor="let workflowType of workflowTypes" [ngValue]="workflowType">{{workflowType}}</option>
              </select>
          </div>
        </div>

        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 pr-3 mb-0" for="search-thematic">Thématique</label>
          <div class="col">
            <select id="search-thematic" class="form-control search-thematic" name="search-thematic" 
              [(ngModel)]="searchData.thematic">
                <option [ngValue]="null" i18n>Toutes les thématiques</option>
                <option *ngFor="let thematic of thematics" [ngValue]="thematic">{{thematic}}</option>
              </select>
          </div>
        </div>
  
        <div class="form-group row no-gutters align-items-center">
          <label class="col-4 mb-0 pr-3">
            <ng-container i18n>Emprise</ng-container>
            <button type="button" class="btn btn-primary btn-block mt-1 extent-btn" (click)="chooseExtent()"
              i18n-title title="Choisir l'emprise sur la carte" [disabled]="currentlyExtentChoose">
              <i class="fas fa-map-marked-alt mr-2"></i>
              <ng-container i18n>Choisir</ng-container>
            </button>
          </label>
          <div class="col">
            <div class="extent-backdrop" [ngClass]="{active: currentlyExtentChoose}">
              <p i18n>Ctrl + Cliquer-glisser pour tracer<br />votre emprise sur la carte</p>
            </div>
            <button *ngIf="searchData.extent" type="button" class="btn btn-clear-extent" (click)="resetExtent()"
              i18n-title title="Effacer l'emprise"  i18n-title> 
              <i>&times;</i> 
            </button>
            <div class="rosace">
              <div class="west" *ngIf="searchData.extent">{{searchData.extent[0]}}</div>
              <div class="north" *ngIf="searchData.extent">{{searchData.extent[1]}}</div>
              <div class="east" *ngIf="searchData.extent">{{searchData.extent[2]}}</div>
              <div class="south" *ngIf="searchData.extent">{{searchData.extent[3]}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-3">
        <button type="button" class="btn btn-default btn-block mr-2" (click)="resetSearch()" i18n>Réinitialiser</button>
        <button type="submit" class="btn btn-primary btn-block ml-2 mt-0" i18n>Rechercher</button>
      </div>
  
    </form>
    <div class="results-table d-flex flex-column" [ngClass]="{opened: displayTable}">
      <h2 i18n>Résultats de la recherche</h2>
      <button type="button" name="close" class="btn btn-icon fa fa-times" 
       i18n-title title="Fermer les résultats de la recherche" (click)="toggleDisplayTable(false)"></button>
      <div class="d-flex pl-4 align-items-end mb-3">
        <button type="button" class="btn btn-default btn-sm wide-btn" (click)="displayTableWide = !displayTableWide">
          <i class="fa fa-arrow-left"></i>
          <i class="fa fa-arrow-right"></i>
          <ng-container *ngIf="!displayTableWide" i18n>Agrandir le tableau</ng-container>
          <ng-container *ngIf="displayTableWide" i18n>Réduire le tableau</ng-container>
        </button>
        <vigirisks-table-counter>
          <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
            <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucun résultat</p>
            <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} résultats</p>
          </ng-template>
        </vigirisks-table-counter>
      </div>
      <div class="results-table-content h-100">
        <div class="data-table">
          <vigirisks-fitrable-table [rows]="resources" #dataTable>
            <ng-template head-content>
              <tr>
                <th class="name" sortable-column="name" sort-direction="asc"><span i18n>Titre</span></th>
                <th class="date" sortable-column="lastUpdate"><span i18n>Dernière modification</span></th>
                <th class="actions text-center">&nbsp;</th>
                <th class="actions text-center">&nbsp;</th>
              </tr>
            </ng-template>
            <ng-template body-content let-resource>
              <tr (mouseover)="highlightResourceFeatures(resource, true)" 
                (mouseout)="highlightResourceFeatures(resource, false)"
                [ngClass]="{hovered: resource.hovered}">
                <td class="name">
                  <i class="fa" title="{{resource.typeLabel}}"
                    [ngClass]="{
                      'fa-link': resource.type == 'link',
                      'fa-file-alt': resource.type == 'data',
                      'fa-sitemap': resource.type == 'workflow',
                      'fa-folder-open': resource.type == 'project'
                    }"></i>
                  <a [routerLink]="[resource.url]">
                    {{resource.name}}
                  </a>
                </td>
                <td class="text-center">{{resource.lastUpdate | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                  <button type="button" class="btn btn-icon far fa-eye" i18n-title title="Voir plus d'informations" ngbDropdownToggle></button>
                  <div class="resource-details" aria-labelledby="row-dropdown" ngbDropdownMenu>
                    <dl>
                      <dt i18n>Contact</dt>
                      <dd>{{resource.sourceObject.ownerName}} <ng-container *ngIf="resource.sourceObject.ownerOrganisation">({{resource.sourceObject.ownerOrganisation}})</ng-container> </dd>
                      <ng-container *ngIf="resource.sourceObject.project">
                        <dt i18n>Étude</dt>
                        <dd>
                          <a [routerLink]="session.hasRight(resource.sourceObject.project.id, objectTypeProject, 'owner') ? ['/my-projects', resource.sourceObject.project.id] : ['/projects', resource.sourceObject.project.id]">
                            {{resource.sourceObject.project.name}}
                          </a>
                        </dd>
                      </ng-container>
                      <ng-container *ngIf="resource.sourceObject.typeName">
                        <dt i18n>Type</dt>
                        <dd>{{resource.sourceObject.typeName}}</dd>
                      </ng-container>
                    </dl>
                    <ng-container *ngIf="resource.extents.length > 0">
                      <button type="button" class="btn btn-icon project-extent" i18n-title title="Centrer sur l'emprise" (click)="goToExtent(resource.sourceObject)"> <i class="fa fa-expand"></i> </button>
                    </ng-container>
                  </div>
                </td>
                <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
                  <button type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
                  <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                    <a class="dropdown-item" [routerLink]="[resource.url]" i18n>Consulter</a>
                    <a *ngIf="(resource.type === 'workflow' && session.hasRight(resource.id, objectTypeWorkflow, 'owner')) || 
                      ((resource.type === 'data' || resource.type === 'link') && session.hasRight(resource.sourceObject.projectId, objectTypeProject, 'editor')) ||
                      (resource.type === 'project' && session.hasRight(resource.id, objectTypeProject, 'editor'))" 
                      class="dropdown-item" [routerLink]="[resource.url, 'edit']" i18n>Modifier</a>
                  </div>
                </td>
              </tr>
            </ng-template>
          </vigirisks-fitrable-table>
        </div>
      </div>
      <div class="d-flex mb-3">
        <vigirisks-pagination class="mx-auto" [maxSize]="5"></vigirisks-pagination>
      </div>
    </div>
  </div>
</section>