<section id="workflow-executions" class="d-flex flex-column h-100" *ngIf="workflow">
  <div class="d-flex align-items-start page-header">
    <h1 class="mr-auto" i18n>Exécutions de {{workflow.name}}</h1>
  </div>
  <div class="page-content d-flex flex-column h-100">
    <p class="alert alert-info" *ngIf="!workflow.executions || workflow.executions.length === 0">
      <i class="fa fa-info-circle"></i>
      <ng-container i18n>Aucune exécution dans ce workflow</ng-container>
    </p>
    <div class="row no-gutters align-items-center">
      <vigirisks-table-counter>
        <ng-template counter-content let-pageRange="pageRange" let-totalResults="totalResults">
          <p class="list-counter" *ngIf="totalResults === 0" i18n>Aucune exécution</p>
          <p class="list-counter" *ngIf="totalResults > 0" i18n>Affichage {{pageRange[0]}}-{{pageRange[1]}} de {{totalResults}} exécutions</p>
        </ng-template>
      </vigirisks-table-counter>
      <div class="d-flex align-items-center justify-content-end col-12 col-md ml-3 filter-field-container">
        <label for="filter-field" class="mb-0 mr-2" i18n>Filtrer la liste</label>
        <input type="text" id="filter-field" [(ngModel)]="filters.general.filter" class="form-control" (ngModelChange)="dataTable.filter()">
      </div>
    </div>
    <div class="data-table subcontent">
      <vigirisks-fitrable-table [rows]="workflow.executions">
        <ng-template head-content>
          <tr>
            <th class="name" sortable-column="name"><span i18n>Nom</span></th>
            <th class="date" sortable-column="executionDate" sort-direction="desc"><span i18n>Date d'exécution</span></th>
            <th class="date" sortable-column="project.name"><span i18n>Étude</span></th>
            <th class="user" sortable-column="userId"><span i18n>Exécutant</span></th>
            <th class="actions text-center">&nbsp;</th>
          </tr>
        </ng-template>
        <ng-template body-content let-execution>
          <tr>
            <td>
              <a [routerLink]="['./', execution.id]">{{execution.name}}</a>
            </td>
            <td>
              {{execution.executionDate | date: 'dd/MM/yyyy'}}
              <ng-container i18n>à</ng-container>
              {{execution.executionDate | date: 'HH:mm'}}
            </td>
            <td>{{execution.project ? execution.project.name : ''}}</td>
            <td>{{execution.userId}}</td>
            <td class="text-center btn-icon-container" ngbDropdown container="body" placement="bottom-right">
              <button *ngIf="execution.status === executionStatus.saved" type="button" class="btn btn-icon fa fa-ellipsis-h" ngbDropdownToggle></button>
              <div aria-labelledby="row-dropdown" ngbDropdownMenu>
                <a class="dropdown-item" (click)="goToExecution(execution)" i18n>Consulter</a>
              </div>
            </td>
          </tr>
        </ng-template>
      </vigirisks-fitrable-table>
    </div>
    <div class="d-flex">
      <vigirisks-pagination class="ml-auto" [maxSize]="5"></vigirisks-pagination>
    </div>
  </div>
</section>