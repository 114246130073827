<form #renameForm="ngForm" class="edit-fields-form" [ngClass]="{'ng-submitted': renameForm.submitted}"
  (ngSubmit)="save(renameForm)">
  <div class="modal-header">
    <h2 class="modal-title">{{title}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="confirm-modal">
    <div class="d-flex align-items-center">
      <label for="rename-name" class="mb-0 mr-3 nowrap">Nom de l'exécution</label>
      <input type="text" id="rename-name" name="rename-name" class="form-control" app-autofocus [autofocusDelay]="300"
        [(ngModel)]="executionName" required />
    </div>
    <div class="d-flex align-items-center">
      <label for="draft-check" class="mb-0 mr-3 nowrap">Brouillon ?</label>
      <input type="checkbox" id="draft-check" name="draft-check" class="form-control" 
        [(ngModel)]="executionDraft" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-default" (click)="modal.dismiss('cancel')" i18n>Annuler</button>
    <button type="submit" class="btn btn-primary ml-auto">
      <ng-container *ngIf="isNew" i18n>Créer</ng-container>
      <ng-container *ngIf="!isNew" i18n>Enregistrer</ng-container>
    </button>
  </div>
</form>