<section id="admin-group-edit" class="d-flex flex-column h-100" *ngIf="group">
  <div class="page-header">
    <h1>
      <ng-container *ngIf="isNew" i18n>Créer un groupe</ng-container>
      <ng-container *ngIf="!isNew" i18n>Modifier un groupe</ng-container>
    </h1>
  </div>
  <div class="page-content">
    <form class="edit-form edit-fields-form" [ngClass]="{'ng-submitted': editForm.submitted}" id="group-edit"
      #editForm="ngForm" (ngSubmit)="save(editForm)" novalidate>
      <div class="form-group row no-gutters align-items-center">
        <label for="group-name" class="col-4 mr-3 mb-0">
          <ng-container i18n>Nom</ng-container> *
        </label>
        <div class="col">
          <input type="text" id="group-name" class="form-control" name="group-name" [(ngModel)]="group.name"
            #nameField="ngModel" required>
          <div class="alert alert-danger" *ngIf="editForm.submitted && nameField.invalid">
            <p *ngIf="nameField.errors.required" i18n>Champ requis</p>
          </div>
        </div>
      </div>
      <div class="form-group row no-gutters align-items-start">
        <label for="group-status" class="col-4 mr-3 mb-0 mt-1" i18n>Activer le groupe ?</label>
        <div class="col d-flex align-items-center">
          <p-inputSwitch inputId="group-status" name="group-status" [(ngModel)]="isActive" [disabled]="isNew"></p-inputSwitch>
          <span class="ml-2" *ngIf="isActive" i18n>Actif</span>
          <span class="ml-2" *ngIf="!isActive" i18n>Inactif</span>
        </div>
      </div>
      <div class="form-group row no-gutters align-items-start">
        <label for="group-description" class="col-4 mr-3 mb-0 mt-1" i18n>Description</label>
        <div class="col">
          <textarea name="group-description" class="form-control" id="group-description"
            [(ngModel)]="group.description"></textarea>
        </div>
      </div>
      <div class="d-flex">
        <a [routerLink]="cancelRoute" class="btn btn-default" i18n>Annuler</a>
        <button type="submit" class="btn btn-primary ml-auto" i18n>Enregistrer</button>
      </div>
    </form>
  </div>
</section>