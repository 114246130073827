import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FilterService {
  constructor() { }

  private tableFilteredSource = new Subject<FilterEvent>();

  public tableFiltered$ = this.tableFilteredSource.asObservable();

  private updateFiltersSource = new Subject<any>();

  public updateFilters$ = this.updateFiltersSource.asObservable();

  public filterTable(event: FilterEvent) {
    this.tableFilteredSource.next(event);
  }

  public updateFilters(event) {
    this.updateFiltersSource.next(event);
  }
}

export interface FilterEvent {
  /**
   * Colonne actuellement ordonnée
   */
  sortColumn: string;

  /**
   * Direction d'ordonnancement
   */
  sortDirection: string;

  /**
   * Nombre d'éléments par page
   */
  itemsPerPage: number;

  /**
   * Page courante
   */
  page: number;

  /**
   * Nombre d'éléments filtrés
   */
  collectionSize: number;

  /**
   * Informations d'un item à cibler
   */
  target?: {
    value: any;
    field: string;
  }

}
