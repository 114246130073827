import { EntityMapper } from './entity-mapper.model';

export class Registry extends EntityMapper {
    
  /**
   * ID en base
   */
  id: string = "";

  /**
   * Nom
   */
  label: string = "";

}
