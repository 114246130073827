import * as _ from 'lodash';

import { Component, Input, OnInit } from "@angular/core";
import { UtilsService } from "src/app/services";

@Component({
  selector: 'label-info',
  templateUrl: './label-info-metadata.component.html'
})
export class LabelInfoMetadataComponent implements OnInit {
  @Input() metadata: any;

  public isVisible: boolean = false;

  public minValue: number = null;
  public maxValue: number = null;

  public restrictions: string;

  constructor(
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    if (
      this.metadata.description ||
      (this.metadata.type === 'number' && (!this._utils.isNullOrUndefined(this.metadata.min) || !this._utils.isNullOrUndefined(this.metadata.min)))
    ) {
      this.isVisible = true;
    }
    if(this.metadata.type === 'number') {
      if(!this._utils.isNullOrUndefined(this.metadata.min)) {
        this.minValue = this.metadata.min;
      }
      if(!this._utils.isNullOrUndefined(this.metadata.max)) {
        this.maxValue = this.metadata.max;
      }
    }
    if(this.metadata.type === 'file' && this.metadata.restrictions) {
      this.restrictions = this.metadata.restrictions.replace(/\./g, "").replace(/,/g, ", ");
    }
  }

}