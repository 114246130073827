import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
