import { Component, OnInit, enableProdMode } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as proj4x from 'proj4';
import { register as proj4Register } from 'ol/proj/proj4';
import { Angulartics2Matomo } from 'angulartics2';

import { MapService } from './services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _mapService: MapService,
    private _angulartics2Matomo: Angulartics2Matomo
  ) {
    _angulartics2Matomo.startTracking();
  }

  ngOnInit() {
    if(environment.production) {
      enableProdMode();
    }
    this._mapService.initMaps();

    // custom OL projections
    let proj4 = (proj4x as any).default;
    proj4.defs([
      ["EPSG:2154", "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:4326", "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees"],
      ["EPSG:27563", "+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs"],
      ["EPSG:27582", "+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs"],
      ["EPSG:32740", "+proj=utm +zone=40 +south +datum=WGS84 +units=m +no_defs"],
      ["EPSG:32620", "+proj=utm +zone=20 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"],
      ["EPSG:27572", "+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs"],
      ["EPSG:4559", "+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:2972", "+proj=utm +zone=22 +ellps=GRS80 +towgs84=2,2,-2,0,0,0,0 +units=m +no_defs"],
      ["EPSG:4471", "+proj=utm +zone=38 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["EPSG:2975", "+proj=utm +zone=40 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"],
      ["IGNF:MART38UTM20", "+title=Martinique Fort-Desaix +proj=tmerc +towgs84=126.9260,547.9390,130.4090,-2.7867,5.1612,-0.8584,13.822650 +a=6378388.0000 +rf=297.0000000000000 +lat_0=0.000000000 +lon_0=-63.000000000 +k_0=0.99960000 +x_0=500000.000 +y_0=0.000 +units=m +no_defs"],
      ["IGNF:GUAD48UTM20", "+title=Guadeloupe Ste Anne +proj=tmerc +towgs84=-472.2900,-5.6300,-304.1200,0.4362,-0.8374,0.2563,1.898400 +a=6378388.0000 +rf=297.0000000000000 +lat_0=0.000000000 +lon_0=-63.000000000 +k_0=0.99960000 +x_0=500000.000 +y_0=0.000 +units=m +no_defs"],
      ["IGNF:RGFG95", "+title=Reseau geodesique francais de Guyane 1995 +proj=geocent +nadgrids=@null +wktext +towgs84=0.0000,0.0000,0.0000 +a=6378137.0000 +rf=298.2572221010000 +units=m +no_defs"],
      ["IGNF:RGM04", "+title=RGM04 (Reseau Geodesique de Mayotte 2004) +proj=geocent +nadgrids=@null +wktext +towgs84=0.0000,0.0000,0.0000 +a=6378137.0000 +rf=298.2572221010000 +units=m +no_defs"]
    ]);

    proj4Register(proj4);

    // return to top page when navigating
    this._router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    document.addEventListener('mouseout', e => {
      let target = e.target as HTMLTextAreaElement;
      if (target.nodeName) {
        let nodeName = target.nodeName.toLowerCase();
        if (nodeName === 'button' || nodeName === 'a') {
          target.blur();
        } else if (target.parentNode) {
          let parentNode = target.parentNode as HTMLTextAreaElement;
          nodeName = parentNode.nodeName.toLowerCase();
          if (nodeName === 'button' || nodeName === 'a') {
            parentNode.blur();
          }
        }
      }
    });
  }


}
