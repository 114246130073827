import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Link, Project } from '../models';
import { Uris } from '../constants';
import { SessionService } from './session.service';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  /**
   * Source de la liste des liens
   */
  private linksSource = new Subject<Link[]>();

  /**
   * Source pour un lien unique
   */
  private linkSource = new Subject<Link>();

  /**
   * Observable qui envoie un event à chaque récupération d'une liste de liens
   */
  public links$ = this.linksSource.asObservable();

  /**
   * Observable qui envoie un event à chaque récupération d'un lien unique
   */
  public link$ = this.linkSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router
  ) { }

  /**
   * Demande la récupération des liens dont le user est propriétaire
   */
  public getUserLinks(): void {
    this._http.get<Link[]>(Uris.LINKS + '?permission=editor')
      .pipe(
        map(links => links.map(l => new Link().deserialize(l))),
        switchMap(links => {
          return this._http.get<Project[]>(Uris.PROJECTS)
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(links, link => {
                  link.project = _.find(projects, { id: +link.projectId });
                });
                return links;
              })
            )
        })
      ).subscribe(
        links => this.linksSource.next(links),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des liens, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Demande la récupération d'un lien précis
   * @param id - identifiant du lien
   */
  public getLink(id: string): void {
    if (id === 'new') {
      let newLink = new Link();
      newLink.name = $localize`Nouveau lien`;
      this.linkSource.next(newLink);
    } else {
      this._http.get<Link>(Uris.LINKS + id)
        .pipe(
          map(link => new Link().deserialize(link))
        )
        .subscribe(
          link => this.linkSource.next(link),
          error => {
            console.error(error);
            this._loader.hide();
            if (error.status === 403) {
              this._toastr.error($localize`Vous n'êtes pas autorisé à consulter ce lien.`);
              this._router.navigate(['/my-links']);
            } else if (error.status === 404) {
              this._toastr.error($localize`Ce lien n'existe pas ou plus.`);
              this._router.navigate(['/my-links']);
            } else {
              this._toastr.error($localize`Une erreur est survenue pendant la récupération du lien, veuillez réessayer plus tard.`);
            }
          }
        );
    }
  }

  /**
   * Enregistre un lien
   * @param link - lien à enregistrer
   */
  public saveLink(link: Link): Observable<any> {
    let obs;
    if (link.id) {
      obs = this._http.put<any>(Uris.LINKS + link.id, link.serialize());
    } else {
      obs = this._http.post<any>(Uris.LINKS, link.serialize());
    }
    return obs
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à modifier ce lien.`);
            this._router.navigate(['/my-links']);
          } else if (error === 404) {
            this._toastr.error($localize`Ce lien n'existe pas ou plus.`);
            this._router.navigate(['/my-links']);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement du lien, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        }),
        switchMap(result => this._session.getUserPermissionsObs(result))
      );
  }

  /**
   * Supprimer un lien
   * @param link - lien à supprimer
   */
  public deleteLink(link: Link): Observable<any> {
    return this._http.delete<any>(Uris.LINKS + link.id)
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à supprimer ce lien.`);
          } else if (error === 404) {
            this._toastr.error($localize`Ce lien n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de l'enregistrement du lien, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }
}
