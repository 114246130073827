import * as _ from 'lodash';
import jsPDF from 'jspdf';
import * as saveAs from 'file-saver';

import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import View from 'ol/View';
import { Image as ImageLayer, Vector as VectorLayer, Tile as TileLayer } from 'ol/layer';
import { Cluster, Vector as VectorSource, ImageWMS, OSM } from 'ol/source';
import { defaults as defaultControls } from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import ScaleLine from 'ol/control/ScaleLine';
import OverviewMap from 'ol/control/OverviewMap';
import FullScreen from 'ol/control/FullScreen';
import { format } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { get as getProjection, transformExtent as transformExtent, transform as transform } from 'ol/proj';
import { platformModifierKeyOnly as platformModifierKeyOnly } from 'ol/events/condition';
import { defaults as defaultInteractions, Select } from 'ol/interaction';
import DragBox from 'ol/interaction/DragBox';
import Draw from 'ol/interaction/Draw';
import { Fill, Stroke, Style, Text, Circle } from 'ol/style';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { Point, LineString } from 'ol/geom';
import Feature from 'ol/Feature';
import { unByKey } from 'ol/Observable.js';
import { getArea, getLength } from 'ol/sphere.js';
import { extend as extendExtent } from 'ol/extent';

import { MapPanelsAnimation, MapService, SessionService, CustomActionButtonControl, ZoomToBBOXControl, UtilsService } from 'src/app/services';
import { MapConfig, Layer, Destination, BackgroundLayer } from 'src/app/models';
import { Constants } from 'src/app/constants';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'map-component',
  templateUrl: './map.component.html',
  animations: MapPanelsAnimation
})
export class MapComponent implements OnInit, OnDestroy {
  /**
   * Bloc HTML contenant la carte de la carte
   */
  @ViewChild('mapContainer', { static: true }) public mapContainer: ElementRef;

  /**
   * Conteneur du HTML de la popup
   */
  @ViewChild('resultPopup', { static: true }) public resultPopup: ElementRef;

  /**
   * Configuration courante de la carte
   */
  @Input() mapConfig?: MapConfig;

  /**
   * Paramètre entrant : options diverses
   */
  @Input('options') private _options: any;

  /**
   * Objet OL/Map
   */
  public map: Map;

  /**
   * Couches affichées dans le layerswitcher
   */
  public currentLayers: any[] = [];

  /**
   * Couches de résultats dans le layerswitcher
   */
  public resultsLayers: any[] = [];

  /**
   * Couches de fond de la carte
   */
  public currentBgLayers: any[] = [];

  /**
   * Couche de fond actuellement affichée
   */
  public currentBackground: any = null;

  /**
   * Projection actuelle de la carte
   */
  public currentProjection: string;

  /**
   * Emprise de la destination choisie dans la dropdown de destinations
   */
  public currentDestinationExtent: [number, number, number, number] = null;

  /**
   * Emprises actuellement choisies
   */
  public currentSelectedExtents: any[];

  /**
   * Dernier id de l'emprise sélectionnée
   */
  private _lastFeatureId: number = 0;

  /**
   * Liste des destinations
   */
  public destinations: Destination[] = [];

  /**
   * Afficher l'onglet du layerswitcher ?
   */
  public displayLayerSwitcher: boolean = false;

  /**
   * Afficher l'onglet des légendes ? ?
   */
  public displayLayerLegends: boolean = false;

  /**
   * Afficher l'onglet des outils de mesure ?
   */
  public displayMeasureTools: boolean = false;

  /**
   * Afficher l'onglet des exports de carte ?
   */
  public displayExport: boolean = false;

  /**
   * Résultat des mesures à afficher
   */
  public measureResult: string;

  /**
   * Mode de mesure actuelle ("length" pour une distance, "area" pour une surface)
   */
  public measureMode: string;

  /**
   * Feature (résultat) actuellement sélectionné pour l'affichage de la popup
   */
  public currentData: any;

  /**
   * Afficher le bouton de réinitialisation du dessin ?
   */
  public displayResetDataDraw: boolean = false;

  /**
   * Sélectionne-t-on une bbox ?
   */
  public isBboxSelection: boolean = false;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  /**
   * Couche utilisée pour les outils de mesure
   */
  private _measureLayer: VectorLayer;

  /**
   * Interaction utilisée par les outils de mesure
   */
  private _measureInteraction: Draw;

  /**
   * Event de mesure (utilisé pour annuler ce listener)
   */
  private _measureListener: any;

  /**
   * Interaction utilisée pour dessiner un point ou une bbox
   */
  private _dataDrawInteraction: Draw | DragBox;

  /**
   * Ol.Overlay utilisé pour l'affichage de la popup
   */
  private _popupOverlay: Overlay;

  /**
   * Emitter utilisé uniquement si le composant est issu d'un composant de formulaire
   */
  @Output() onDataChange = new EventEmitter();

  constructor(
    private _mapService: MapService,
    private _session: SessionService,
    private _utils: UtilsService
  ) { }

  ngOnInit() {
    if (!this._options) {
      this._options = {};
    }

    if (!this.mapConfig) {
      this.mapConfig = new MapConfig().deserialize(Constants.DEFAULT_MAP_CONFIG);
    }

    this.destinations = Constants.searchDestinations.map(d => new Destination().deserialize(d));
    if (this._options.destinations) {
      this.destinations = this._options.destinations.concat(this.destinations);
    }

    if (this._options.dataInteraction && this._options.dataInteraction.type == 'bbox') {
      this.isBboxSelection = true;
    }

    this._createMap();

    this._createBackgroundLayersList();

    this.currentProjection = this.map.getView().getProjection().getCode();

    this._subs.add(this._mapService.capabilities$.subscribe(layerInfos => {
      let layer = _.find(this.currentLayers, { idx: layerInfos.idx });
      if (layer) {
        layer.infos = layerInfos;
      }
    }));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByIdx(item) {
    return item.idx;
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackByName(item) {
    return item.name;
  }

  //========================================================
  //================ Navigation de la carte ================
  //========================================================

  /**
   * Centre la carte sur la destination choisie dans le gazetteer
   * @param extent - emprise sur laquelle zoomer
   */
  public chooseDestination(extent: [number, number, number, number]): void {
    if (extent) {
      extent = transformExtent(extent, 'EPSG:4326', this.map.getView().getProjection());
      this.goToExtent(extent);
      this.currentDestinationExtent = null;
    }
  }

  /**
   * Zoome sur une emprise donnée
   * @param extent - emprise sur laquelle zoomer
   */
  public goToExtent(extent: [number, number, number, number]): void {
    this.map.getView().fit(extent, { padding: [15, 15, 15, 15] });
  }

  //========================================================
  //==================== Layer switcher ====================
  //========================================================

  /**
   * Génère un layer du layerswitcher
   */
  private _createLayerSwitcherItem(layerData: Layer, idx: number, olLayer: ImageLayer): void {
    let layer = {
      idx: idx,
      type: "default",
      name: layerData.name,
      title: layerData.label,
      url: layerData.url,
      extent: layerData.extent,
      opacity: layerData.opacity,
      visible: layerData.visible,
      olLayer: olLayer
    };
    this.currentLayers.push(layer);
    this._mapService.getLayerCapabilities(layer);
  }

  /**
   * Affiche ou masque une couche
   * @param layer - data de la couche à afficher/masquer
   */
  public toggleLayer(layer: any): void {
    if (layer.olLayer) {
      layer.olLayer.setVisible(layer.visible);
      this._saveSessionMap();
    }
  }

  /**
   * Supprimer une couche du layerswitcher
   * @param layer - data de la couche à supprimer
   * @param list - liste dans laquelle se trouve la couche
   */
  public removeLayer(layer: any, list: any[]): void {
    if (layer.olLayer && list && list.indexOf(layer) >= 0) {
      this.map.removeLayer(layer.olLayer);
      list.splice(list.indexOf(layer), 1);
    }
  }

  /**
   * Modifie l'opacité d'une couche
   * @param layer - data de la couche à modifier
   */
  public onOpacityChange(layer: any): void {
    if (layer.olLayer) {
      layer.olLayer.setOpacity(layer.opacity);
      this._saveSessionMap();
    }
  }

  /**
   * Affiche les informations d'une couche et cache les autres
   * @param layer - data de la couche dont il faut afficher les informations
   */
  public showLayerInfo(layer: any): void {
    layer.displayInfo = !layer.displayInfo;
    if (layer.displayInfo) {
      _.each(this.currentLayers, l => {
        if (l.idx !== layer.idx) {
          l.displayInfo = false;
        }
      });
    }
  }

  /**
   * Gére la fin du Drag dans le layerswitcher
   */
  public reorderLayer(event: CdkDragDrop<any>, list: any[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    this._handleLayerDragEnd(event.item.data.idx, list);
  }

  /**
   * Réordonne les couches selon le dernier déplacement dans le layerswitcher
   * @param layerId - ID de la couche déplacée
   * @param layerId - liste dans laquelle se trouve la couche
   */
  private _handleLayerDragEnd(layerId: number, list: any[]): void {
    if (isNaN(layerId) || !list) {
      console.error("layerId is not a number");
      return;
    }
    var olLayers = this.map.getLayers();
    let layers = list.slice();
    layers.reverse(); // in map, last added layer is on top

    let newMapLayerIndex = _.findIndex(layers, { idx: layerId });
    let layer = _.find(list, { idx: layerId });
    newMapLayerIndex += this.mapConfig.backgroundLayers.length; // add base layers number

    if (list === this.resultsLayers) {
      newMapLayerIndex += this.currentLayers.length;
    }

    this.map.removeLayer(layer.olLayer);
    olLayers.insertAt(newMapLayerIndex, layer.olLayer);
  }

  //========================================================
  //================ Altération de la carte ================
  //========================================================

  /**
   * Change de couche de fond lors du choix de couche de fond
   */
  public updateBackgroundLayer(): void {
    let overviewMap = _.find(this.map.getControls().getArray(), control => control.constructor.name == 'OverviewMap');
    _.each(this.currentBgLayers, layer => {
      if (layer === this.currentBackground) {
        layer.visible = true;
      } else {
        layer.visible = false;
      }
      let mapLayer = this._getMapLayer(layer.idx);
      if (mapLayer) {
        mapLayer.setVisible(layer.visible);
      }
      let overviewMapLayer = this._getMapLayer(layer.idx, overviewMap.getOverviewMap());
      if (overviewMapLayer) {
        overviewMapLayer.setVisible(layer.visible);
      }
    });
  }

  /**
   * Effectue le changement de projection après un choix de projection
   */
  public updateProjection(): void {
    let oldView = this.map.getView();
    let oldProj = oldView.getProjection().getCode();
    let newProj = getProjection(this.currentProjection);

    let center = oldView.getCenter();
    center = transform(center, oldProj, newProj);

    _.each(this.currentLayers, layer => {
      layer.extent = transformExtent(layer.extent, oldProj, newProj);
      layer.olLayer.setExtent(layer.extent);
      layer.cantDisplay = (layer.infos && layer.infos.supportedProjections.indexOf(this.currentProjection) < 0);
      if (layer.cantDisplay) {
        layer.visible = false;
        layer.olLayer.setVisible(false);
      }
    });

    _.each(this.resultsLayers, layer => {
      layer.extent = transformExtent(layer.extent, oldProj, newProj);
    });

    let drawLayer = this._getMapLayer('dataDraw');
    if (drawLayer) {
      _.each(drawLayer.getSource().getFeatures(), feat => {
        let geom = feat.getGeometry();
        geom.transform(oldProj, newProj);
        if (geom.constructor.name == "Point" && !this._options.displayProjection) {
          let newCoords = geom.getCoordinates();
          drawLayer.getStyle().getText().setText('X: ' + newCoords[0] + '; Y: ' + newCoords[1]);
        }
      })
    }

    if (!this._options.displayProjection) {
      let mousePosition = _.find(this.map.getControls().getArray(), control => control.constructor.name == 'MousePosition');
      if (mousePosition) {
        mousePosition.setProjection(newProj);
      }
    }

    let newView = new View({
      projection: newProj,
      zoom: oldView.getZoom(),
      center: center
    });

    this.map.setView(newView);

    let overviewMap = _.find(this.map.getControls().getArray(), control => control.constructor.name == 'OverviewMap');
    if (overviewMap) {
      overviewMap.getOverviewMap().setView(
        new View({
          projection: newProj,
          zoom: 10,
          center: [0, 0]
        })
      );
    }
  }

  /**
   * Génère la liste des couches de fond disponibles
   */
  private _createBackgroundLayersList(): void {
    this.currentBgLayers = [];
    _.each(this.map.getLayers().getArray(), layer => {
      if (layer.get('type') === 'base') {
        let bgLayer = {
          idx: layer.get('idx'),
          title: layer.get('title'),
          visible: layer.getVisible()
        };
        this.currentBgLayers.push(bgLayer);
        if (bgLayer.visible) {
          this.currentBackground = bgLayer;
        }
      }
    });
  }

  //========================================================
  //=================== Outils de mesure ===================
  //========================================================

  /**
   * Change de mode de mesure de l'outil de mesure
   * @param mode - nouveau mode à appliquer
   */
  public changeMeasureMode(mode: string) {
    if (this.measureMode === mode) {
      return;
    }
    if (this._measureInteraction) {
      this.map.removeInteraction(this._measureInteraction);
    }
    this._measureLayer.getSource().clear();
    this.measureResult = null;
    let drawType;
    switch (mode) {
      case 'length': drawType = "LineString";
        break;
      case 'area': drawType = "Polygon";
        break;
    }
    if (drawType) {
      this.measureMode = mode;
      this._measureInteraction = new Draw({
        source: this._measureLayer.getSource(),
        type: drawType,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          }),
          stroke: new Stroke({
            color: '#E36C04',
            lineDash: [10, 10],
            width: 2
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: '#E36C04',
              width: 2
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)'
            })
          })
        })
      });

      this._measureInteraction.on('drawstart', evt => this._listenDrawUpdate(evt));
      this._measureInteraction.on('drawend', () => this._stopListenDrawUpdate());

      this.map.addInteraction(this._measureInteraction);
    }
  }

  /**
   * Désactive l'outil de mesures et son effet sur la carte
   */
  public disableMeasureTools(): void {
    if (this._dataDrawInteraction) {
      this.map.addInteraction(this._dataDrawInteraction);
    }
    if (this._measureLayer) {
      this.map.removeLayer(this._measureLayer);
    }
    if (this._measureInteraction) {
      this.map.removeInteraction(this._measureInteraction);
    }
    this.displayMeasureTools = false;
  }

  /**
   * Active l'outil de mesures et son effet sur la carte
   */
  private _enableMeasureTools(): void {
    if (this._dataDrawInteraction) {
      this.map.removeInteraction(this._dataDrawInteraction);
    }
    if (!this._measureLayer) {
      this._measureLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)'
          }),
          stroke: new Stroke({
            color: 'red',
            width: 2
          })
        })
      });
    }
    this.map.addLayer(this._measureLayer);

    if (!this._measureInteraction) {
      this.changeMeasureMode('length');
    } else {
      this.map.addInteraction(this._measureInteraction);
    }
    this.displayMeasureTools = true;
  }

  /**
   * Au début du dessin, écoute les modifications du dessin
   * @param evt - événement drawstart
   */
  private _listenDrawUpdate(evt: any): void {
    this._measureLayer.getSource().clear();
    this.measureResult = null;
    this._measureListener = evt.feature.getGeometry().on('change', evt => this._updateDisplayedMeasures(evt));
  }

  /**
   * Au changement du dessin, met à jour les mesures affichées
   * @param evt - événement drawchange
   */
  private _updateDisplayedMeasures(evt: any): void {
    if (this.measureMode == 'length') {
      this.measureResult = this._formatLength(evt.target);
    } else if (this.measureMode == 'area') {
      this.measureResult = this._formatArea(evt.target);
    }
  }

  /**
   * A la fin du dessin, cesse l'écoute de l'update
   */
  private _stopListenDrawUpdate(): void {
    unByKey(this._measureListener);
    this._measureListener = null;
  }

  /**
   * Formate la mesure d'une distance pour affichage
   * @param line
   */
  private _formatLength(line: any): string {
    var length = getLength(line, { projection: this.currentProjection });
    var output;
    if (length > 100) {
      output = (Math.round(length / 1000 * 100) / 100) +
        ' ' + 'km';
    } else {
      output = (Math.round(length * 100) / 100) +
        ' ' + 'm';
    }
    return output;
  }

  /**
   * Formate la mesure d'une surface pour affichage
   * @param polygon
   */
  private _formatArea(polygon: any): string {
    var area = getArea(polygon, { projection: this.currentProjection });
    var output;
    if (area > 10000) {
      output = (Math.round(area / 1000000 * 100) / 100) +
        ' ' + 'km';
    } else {
      output = (Math.round(area * 100) / 100) +
        ' ' + 'm';
    }
    return output;
  }


  //========================================================
  //=================== Outils d'export ====================
  //========================================================

  /**
   * Exporte la carte sous forme d'image png
   */
  public exportAsImage(): void {
    this.map.once('rendercomplete', event => {
      let canvas = event.context.canvas;
        canvas.toBlob(blob => {
          saveAs(blob, 'map.png');
        });
    });
    this.map.renderSync();
  }

  /**
   * Exporte la carte et sa légende au format PDF
   */
  public exportAsPdf(): void {
    let width = Math.round(297 * 150 / 25.4);
    let height = Math.round(210 * 150 / 25.4);
    let mapOriginalSize = this.map.getSize();
    let extent = this.map.getView().calculateExtent(mapOriginalSize);

    this.map.once('rendercomplete', event => {
      let canvas = event.context.canvas;
      let pdf = new jsPDF('landscape', undefined, 'a4');
      pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, 297, 210);
      this.map.setSize(mapOriginalSize);
      this.map.getView().fit(extent, { size: mapOriginalSize });

      let sub: Subscription = this._mapService.legendImages$.subscribe(images => {
        pdf.setFontSize(10);
        _.each(images, image => {
          pdf.addPage('a4', 'portrait');
          pdf.text(image.title, 5, 10);

          image.cmHeight = image.height * 25.4 / 100;
          image.cmWidth = image.width * 25.4 / 100;
          image.ratio = image.cmWidth / image.cmHeight;

          if (image.cmHeight > 280) {
            image.cmHeight = 280;
            image.cmWidth = image.cmHeight * image.ratio;
          }

          if (image.cmWidth > 203) {
            image.cmWidth = 203;
            image.cmHeight = image.cmWidth / image.ratio;
          }

          pdf.addImage(image.image, 'PNG', 5, 15, image.cmWidth, image.cmHeight);
        });


        pdf.save(this.mapConfig.name + '.pdf');
        sub.unsubscribe();
      });

      let legendUrls = [];
      _.each(this.currentLayers, layer => {
        if (layer.infos && layer.infos.legendUrl) {
          legendUrls.push(layer);
        }
      });

      this._mapService.getLegendImages(legendUrls);

    });

    this.map.setSize([width, height]);
    this.map.getView().fit(extent, { size: [width, height] });
  }

  //========================================================
  //================ Génération de la carte ================
  //========================================================

  /**
   * Initialise la carte
   */
  private _createMap(): Map {
    let layers = this._getGeneratedLayers();
    this.map = new Map({
      controls: this._getControls(),
      target: this.mapContainer.nativeElement,
      layers: layers,
      view: this._getView(),
      interactions: this._getInteractions(layers)
    });


    if (this._options.tifUrls) {
      this._activateGeotiffLayers();
    }

    let extent = this._options.startExtent || this.mapConfig.startExtent;

    if (this._options.features) {
      this._popupOverlay = new Overlay({
        element: this.resultPopup.nativeElement
      });
      this.map.addOverlay(this._popupOverlay);
      this.map.on('singleclick', event => this._toggleResultInfos(event));

      let sessionMap = this._session.getMapContext(this._options.execId || this.mapConfig.name);
      if (!sessionMap) {
        let extent;
        let resultLayers = _.filter(this.resultsLayers, l => ['cluster', 'geojson'].indexOf(l.type) >= 0 && l.visible);
        _.each(resultLayers, layerData => {
          let e = layerData.olLayer.getSource().getSource().getExtent();
          if (!extent) {
            extent = e;
          } else {
            extent = extendExtent(extent, e);
          }
        });
        setTimeout(() => this.map.getView().fit(extent, { padding: [15, 15, 15, 15] }), 500);

      }

      this.displayLayerSwitcher = true;
    } else if (extent) {
      let proj = this.mapConfig.projection || 'EPSG:4326';
      extent = transformExtent(extent, 'EPSG:4326', proj);
      setTimeout(() => this.map.getView().fit(extent, { padding: [15, 15, 15, 15] }), 500);
    }


    setTimeout(() => this.map.updateSize(), 100);
    setTimeout(() => this.map.updateSize(), 500);

    this.map.on('moveend', () => this._saveSessionMap());
  }

  /**
   * Génère la liste des controleurs de la carte
   */
  private _getControls(): any[] {
    let controls = defaultControls({
      zoomOptions: {
        zoomInTipLabel: 'Zoomer',
        zoomOutTipLabel: 'Dézoomer'
      },
      rotateOptions: {
        tipLabel: 'Réinitialiser la rotation'
      }
    }).extend([
      new ScaleLine(),
      new MousePosition({
        coordinateFormat: coordinate => format(coordinate, 'X:&nbsp;{x}&nbsp;Y:&nbsp;{y}', 4),
        projection: this._options.displayProjection || this.mapConfig.projection || 'EPSG:4326',
        undefinedHTML: 'X:&nbsp;0&nbsp;Y:&nbsp;0'
      }),
      new OverviewMap({
        tipLabel: 'Mini carte',
        layers: this._getBackgroundLayers(),
        view: new View({
          zoom: 10,
          projection: this.mapConfig.projection || 'EPSG:4326'
        })
      }),
      new FullScreen({
        tipLabel: 'Activer/désactiver plein écran',
        label: ''
      })
    ]);

    if (this.mapConfig.controls.layerSwitcher) {
      controls.push(new CustomActionButtonControl({
        tipLabel: $localize`Voir la liste des couches`,
        label: '<i class="fa fa-layer-group"></i>',
        class: 'ol-layer-switcher-control',
        onClick: () => {
          this.displayLayerSwitcher = true;
          this.displayLayerLegends = false;
          this.displayExport = false;
          this.disableMeasureTools();
        }
      }));
    }
    if (this.mapConfig.controls.legend) {
      controls.push(new CustomActionButtonControl({
        tipLabel: $localize`Voir la légende des couches`,
        label: '<i class="fa fa-list"></i>',
        class: 'ol-layer-legend-control',
        onClick: () => {
          this.displayLayerSwitcher = false;
          this.displayLayerLegends = true;
          this.displayExport = false;
          this.disableMeasureTools();
        },
      }));
    }

    if (this._options.dataInteraction) {
      controls.push(new ZoomToBBOXControl({
        tipLabel: $localize`Aller à l'extension maximale`,
        label: '<i class="fa fa-expand"></i>'
      }));
    }

    if (this.mapConfig.controls.measureTools) {
      controls.push(new CustomActionButtonControl({
        tipLabel: $localize`Outils de mesure`,
        label: '<i class="fas fa-drafting-compass"></i>',
        class: 'ol-layer-measure-control',
        onClick: () => {
          this.displayLayerSwitcher = false;
          this.displayLayerLegends = false;
          this.displayExport = false;
          this._enableMeasureTools();
        },
      }));
    }
    // A réactiver plus tard
    // if (this.mapConfig.controls.exportable) {
    //   controls.push(new CustomActionButtonControl({
    //     tipLabel: "Exports",
    //     label: '<i class="fa fa-download"></i>',
    //     class: 'ol-layer-export-control',
    //     onClick: () => {
    //       this.displayLayerSwitcher = false;
    //       this.displayLayerLegends = false;
    //       this.displayExport = true;
    //       this.disableMeasureTools();
    //     },
    //   }));
    // }

    return controls;
  }

  /**
   * Génère toutes les couches de la carte et les renvoie
   */
  private _getGeneratedLayers(): any[] {
    let sessionMap = this._session.getMapContext(this._options.execId || this.mapConfig.name);
    let olLayers = this._getBackgroundLayers(sessionMap);

    let defaultLayers = [];

    let clonedDefaultLayers = _.cloneDeep(this.mapConfig.defaultLayers);

    _.each(clonedDefaultLayers, (layer, index) => {
      if (sessionMap && sessionMap.layers) {
        let sessionLayer = _.find(sessionMap.layers, { idx: index + 1 });
        if (sessionLayer) {
          layer.visible = sessionLayer.visible;
          layer.opacity = sessionLayer.opacity;
        } else {
          layer.visible = false;
          layer.opacity = 1;
        }
      }
      let olLayer = new ImageLayer({
        idx: index + 1,
        opacity: layer.opacity,
        visible: layer.visible,
        extent: layer.extent,
        source: new ImageWMS({
          ratio: 1,
          url: layer.url,
          params: layer.params,
          imageLoadFunction: (image, src) => {
            this._utils.getImageDataFromProxy(src)
              .subscribe(imageData => image.getImage().src = imageData);
          }
        })
      });
      if (this.mapConfig.controls.layerSwitcher) {
        this._createLayerSwitcherItem(layer, (index + 1), olLayer);
      }
      defaultLayers.push(olLayer);
    });

    defaultLayers.reverse();
    olLayers = olLayers.concat(defaultLayers);

    let lastIdx = clonedDefaultLayers.length;
    if (this._options.features) {
      olLayers = olLayers.concat(this._generateResultsCluster(lastIdx));
      lastIdx += this._options.features.length;
    }

    if (this._options.tifUrls) {
      olLayers = olLayers.concat(this._generateGeotifLayers());
      lastIdx += this._options.tifUrls.length;
    }

    if (this._options.dataInteraction) {
      olLayers.push(this._generateDataLayer());
    }

    return olLayers;
  }

  /**
   * Génère toutes les couches de fond de la carte
   */
  private _getBackgroundLayers(sessionMap?: any): any[] {
    let bgLayers = [];
    let defaultBaseLayerIndex = _.findIndex(this.mapConfig.backgroundLayers, { defaultLayer: true });
    if (defaultBaseLayerIndex < 0) {
      defaultBaseLayerIndex = 0;
    }
    _.each(this.mapConfig.backgroundLayers, (layer: BackgroundLayer, index: number) => {
      let isVisible = index === defaultBaseLayerIndex;
      if (sessionMap && sessionMap.currentBgIdx) {
        isVisible = sessionMap.currentBgIdx === 'bg' + (index + 1);
      }
      if (layer.params.type === "OSM") {
        bgLayers.push(new TileLayer({
          type: 'base',
          idx: 'bg' + (index + 1),
          title: layer.label || "Fond " + index,
          visible: isVisible,
          source: new OSM({
            url: layer.url
          })
        }));
      } else {
        bgLayers.push(new ImageLayer({
          type: 'base',
          idx: 'bg' + (index + 1),
          title: layer.label || "Fond " + index,
          visible: isVisible,
          source: new ImageWMS({
            ratio: 1,
            url: layer.url,
            params: layer.params,
            imageLoadFunction: (image, src) => {
              this._utils.getImageDataFromProxy(src)
                .subscribe(imageData => image.getImage().src = imageData);
            }
          })
        }));
      }
    });
    return bgLayers;
  }

  /**
   * Génère toutes les interactions de la carte
   * @param layers
   */
  private _getInteractions(layers: any[]): any[] {
    let interactions = defaultInteractions();
    if (this._options.dataInteraction) {
      let dataInteraction = this._generateDataDrawInteraction(layers);
      if (dataInteraction) {
        interactions.push(dataInteraction);
      }
    }
    if (this._options.features) {
      interactions.push(this._generateClusterResultsInteraction());
    }

    return interactions;
  }

  /**
   * Génère la vue de la carte
   */
  private _getView(): View {
    let proj = this.mapConfig.projection || 'EPSG:4326';
    let center = this._options.startCenter || this.mapConfig.startCenter || [2.4609, 46.6061];
    let zoom = this._options.startZoom || this.mapConfig.startZoom || 5;
    let sessionMap = this._session.getMapContext(this._options.execId || this.mapConfig.name);
    if (sessionMap) {
      center = sessionMap.center;
      zoom = sessionMap.zoom;
    } else {
      let extent = this._options.startExtent || this.mapConfig.startExtent;
      if (extent) {
        center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
      }
    }

    center = transform(center, 'EPSG:4326', proj);

    let view = new View({
      zoom: zoom,
      minZoom: this.mapConfig.minZoom || 0,
      maxZoom: this.mapConfig.maxZoom || 28,
      projection: proj,
      center: center
    });

    return view;
  }

  //========================================================
  //================ Résultats sur la carte ================
  //========================================================

  /**
   * Génère l'interaction de survol des clusters
   */
  private _generateClusterResultsInteraction(): any {
    let clusterLayers = _.filter(this.resultsLayers, { type: "cluster" });
    let olClusterLayers = [];
    _.each(clusterLayers, l => {
      olClusterLayers.push(l.olLayer);
    });

    return new Select({
      condition: function (evt) {
        return evt.type == 'pointermove';
      },
      layers: olClusterLayers,
      style: (feature) => this._getClusterHoverStyles(feature)
    });
  }

  /**
   * Génère les couches de rasters
   * @param lastIdx - Dernier index utilisé pour les couches
   */
  private _generateGeotifLayers(): any[] {
    // TODO : réactiver quand on arrivera à faire fonctionner les rasters
    let layers = [];
    _.each(this._options.tifUrls, () => {

      // this.resultsLayers.push({
      //   idx: ++lastIdx,
      //   type: "raster",
      //   name: tifUrl.name,
      //   visible: true,
      //   opacity: 1,
      //   extent: null,
      //   url: tifUrl.url,
      //   olLayer: geotiffLayer
      // });
      // layers.push(geotiffLayer);
    });

    return layers;
  }

  private _activateGeotiffLayers() {
    // TODO : utiliser le code de Robin
  }

  /**
   * Génère la couche de résultats de la carte
   * @param lastIdx - Dernier index utilisé pour les couches
   */
  private _generateResultsCluster(lastIdx: number): VectorLayer[] {
    let layers = [];
    let sessionMap = this._session.getMapContext(this._options.execId || this.mapConfig.name);
    _.each(this._options.features, (feature, idx) => {
      let params = _.find(feature.functions, { action: "display_map" });
      if (params && params.legends && params.legends.length > 0) {
        params.selectedLegend = params.legends[0];

        let layerData;
        switch (feature.format) {
          case "csv": layerData = this._generateResultLayerFromCSV(feature, lastIdx);
            break;
          case "geojson": layerData = this._generateResultsFromGeoJSON(feature, lastIdx);
            break;
        }
        if (layerData) {
          lastIdx = layerData.idx;
          if (idx === 0 && (!sessionMap || !sessionMap.resultLayers)) {
            layerData.visible = true;
            layerData.olLayer.setVisible(true);
          } else if (sessionMap && sessionMap.resultLayers) {
            let contextLayer = _.find(sessionMap.resultLayers, { idx: layerData.idx });
            if (contextLayer) {
              layerData.visible = contextLayer.visible;
              layerData.opacity = contextLayer.opacity;
              layerData.olLayer.setVisible(contextLayer.visible);
              layerData.olLayer.setOpacity(contextLayer.opacity);
            } else {
              layerData.visible = false;
              layerData.opacity = 1;
              layerData.olLayer.setVisible(false);
              layerData.olLayer.setOpacity(1);
            }
          }
          this.resultsLayers.push(layerData);
          layers.push(layerData.olLayer);
        }

      }
    });

    layers.reverse();

    return layers;
  }

  private _generateResultLayerFromCSV(feature: any, lastIdx: number): any {
    let proj = this.mapConfig.projection || 'EPSG:4326';
    let olFeatures = [];
    let params = _.find(feature.functions, { action: "display_map" });
    _.each(feature.data.rows, row => {
      let coords = [
        Number(row[params.xCol]),
        Number(row[params.yCol])
      ];

      if (!isNaN(coords[0]) && !isNaN(coords[1])) {
        coords = transform(coords, params.srs_epsg, proj);
        let olFeature = new Feature(new Point(coords));
        row.olFeature = olFeature;
        olFeatures.push(olFeature);
      }
    });

    let source = new VectorSource({
      features: olFeatures
    });

    let cluster = new Cluster({
      source: source,
      distance: 40
    });

    let clusterLayer = new VectorLayer({
      source: cluster,
      visible: false,
      style: f => this._getClusterStyles(f)
    });

    return {
      idx: ++lastIdx,
      type: "cluster",
      name: feature.name,
      visible: false,
      opacity: 1,
      extent: clusterLayer.getSource().getSource().getExtent(),
      olLayer: clusterLayer
    };
  }

  private _generateResultsFromGeoJSON(feature: any, lastIdx: number): any {
    let proj = this.mapConfig.projection || 'EPSG:4326';
    let params = _.find(feature.functions, { action: "display_map" });
    params.selectedLegend = params.legends[0];

    let vectorSource = new VectorSource({
      features: (new GeoJSON()).readFeatures(feature.data.geojson)
    });

    if (params.srs_epsg) {
      let i = 0;
      vectorSource.forEachFeature(f => {
        feature.data.rows[i].olFeature = f;
        f.getGeometry().transform(params.srs_epsg, proj);
        let color = this._getResultColorFromValue(params.selectedLegend, feature.data.rows[i]);
        f.setStyle(new Style({
          fill: new Fill({ color: this._utils.fadeHexColor(color, 30) }),
          stroke: new Stroke({ color: color, width: 2 })
        }));
        i++;
      });
    }

    let vectorLayer = new VectorLayer({
      visible: false,
      source: vectorSource
    });

    return {
      idx: ++lastIdx,
      type: "geojson",
      name: feature.name,
      visible: false,
      opacity: 1,
      extent: vectorLayer.getSource().getExtent(),
      olLayer: vectorLayer
    };
  }

  /**
   * Génère les styles pour le survol d'un cluster de résultats
   * @param feature - feature-cluster survolée
   */
  private _getClusterHoverStyles(feature: Feature): Style[] {
    let styles: Style[] = [
      new Style({
        image: new Circle({
          radius: feature.get('radius'),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.01)'
          })
        })
      })
    ];

    let childFeatures = feature.get('features');
    _.each(childFeatures, childFeature => {
      styles.push(this._getResultFeatureStyle(childFeature));
    });

    return styles;
  }

  /**
   * Génère les styles d'un cluster de résultats
   * @param feature - feature-cluster concerné
   */
  private _getClusterStyles(feature: Feature): Style[] {
    let childFeatures = feature.get('features');

    if (childFeatures.length === 1) {
      return [this._getResultFeatureStyle(childFeatures[0])];
    }

    return [
      new Style({
        image: new Circle({
          radius: 20,
          fill: new Fill({ color: '#3A3952' }),
          stroke: new Stroke({
            width: 2,
            color: 'white'
          }),
        }),
        text: new Text({
          text: childFeatures.length.toString(),
          font: "14px Roboto, Arial, sans-serif",
          offsetY: 1,
          fill: new Fill({ color: 'white' })
        })
      })
    ];
  }

  /**
   * Génère le style individuel d'un résultat de CSV
   * @param olFeature - feature individuelle du résultat (non cluster)
   */
  private _getResultFeatureStyle(olFeature: Feature): Style {
    let data, feature;
    for (let i = 0; i < this._options.features.length; i++) {
      data = _.find(this._options.features[i].data.rows, { olFeature: olFeature });
      if (data) {
        feature = this._options.features[i];
        break;
      }
    }

    let params = _.find(feature.functions, { action: "display_map" });

    return new Style({
      geometry: olFeature.getGeometry(),
      image: new Circle({
        radius: 7,
        fill: new Fill({ color: this._getResultColorFromValue(params.selectedLegend, data) }),
        stroke: new Stroke({
          width: 2,
          color: 'white'
        }),
      })
    });

  }

  /**
   * Génère la couleur d'un résultat individuel en fonction de sa valeur
   * @param legend Paramètres pour la légende
   * @param data Données du point à afficher
   */
  private _getResultColorFromValue(legend: any, data: any): string {
    let color = 'black';
    if (data.hasOwnProperty(legend.valueCol)) {
      let value = data[legend.valueCol];
      if (legend.valueType === 'number') {
        value = Number(value);
        if (isNaN(value)) {
          return color;
        }
      }
      for (let i = 0; i < legend.colorScheme.length; i++) {
        const scheme = legend.colorScheme[i];
        if (scheme.hasOwnProperty('value') && value === scheme.value) {
          color = scheme.color;
          break;
        } else if (scheme.hasOwnProperty('minValue') || scheme.hasOwnProperty('maxValue')) {
          let checkMin = true, checkMax = true;
          if (scheme.minValue !== null && !isNaN(scheme.minValue) && value < scheme.minValue) {
            checkMin = false;
          }
          if (scheme.maxValue !== null && !isNaN(scheme.maxValue) && value > scheme.maxValue) {
            checkMax = false;
          }
          if (checkMin && checkMax) {
            color = scheme.color;
            break;
          }
        }
      }
    }

    return color;
  }

  /**
   * Affiche ou masque la popup de description d'un résultat cliqué
   * @param event - événement de clic de la carte
   */
  private _toggleResultInfos(event: any): void {
    let olFeature;

    this.map.forEachFeatureAtPixel(event.pixel, (f, layer) => {
      let resultLayer = _.find(this.resultsLayers, l => {
        return ['cluster', 'geojson'].indexOf(l.type) >= 0 && l.olLayer === layer;
      });
      if (resultLayer) {
        olFeature = f;
      }
    });

    if (olFeature) {
      let childFeature = olFeature;
      let childFeatures = olFeature.get('features');
      if (childFeatures && childFeatures.length === 1) {
        childFeature = childFeatures[0];
      }
      let data;
      for (let i = 0; i < this._options.features.length; i++) {
        data = _.find(this._options.features[i].data.rows, { olFeature: childFeature });
        if (data) {
          break;
        }
      }

      if (data) {
        this.currentData = _.cloneDeep(data);
        delete this.currentData.olFeature;
        let extent = data.olFeature.getGeometry().getExtent();
        let coords = [(extent[2] + extent[0]) / 2, (extent[3] + extent[1]) / 2];

        this._popupOverlay.setPosition(coords);
      }
    } else {
      this._popupOverlay.setPosition(undefined);
    }
  }

  //========================================================
  //========== Sélection de données sur la carte ===========
  //========================================================

  /**
   * Réinitialise le dessin de saisie de points multiples
   */
  public resetDataPoints() {
    let drawLayer = this._getMapLayer('dataDraw');
    if (drawLayer) {
      drawLayer.getSource().clear();
      this.displayResetDataDraw = false;
    }
  }

  public removeBBOXExtent(featureObject: any) {
    let drawLayer = this._getMapLayer('dataDraw');
    if (drawLayer) {
      drawLayer.getSource().removeFeature(featureObject.olFeature);
      this.currentSelectedExtents.splice(this.currentSelectedExtents.indexOf(featureObject), 1);
      this._generateMultipleBBOXExtentsResult();
    }
  }

  /**
   * Génère la couche sur laquelle seront faits les dessins de données de formulaire
   */
  private _generateDataLayer(): VectorLayer {
    let drawLayer = new VectorLayer({
      idx: 'dataDraw',
      source: new VectorSource({ wrapX: false }),
      style: this._getDataDrawStyle()
    });
    let geom = null;
    if (this._options.dataInteraction.type == 'bbox' && this._options.dataInteraction.extent) {
      // bbox
      if (this._options.dataInteraction.isMultiple) {
        geom = [];
        _.each(this._options.dataInteraction.extent, extent => {
          geom.push(polygonFromExtent(transformExtent(extent, 'EPSG:4326', this.mapConfig.projection)));
        });
      } else {
        geom = polygonFromExtent(transformExtent(this._options.dataInteraction.extent, 'EPSG:4326', this.mapConfig.projection));
      }
    } else if (this._options.dataInteraction.type == "coord" && this._options.dataInteraction.point) {
      // point
      geom = new Point(transform(this._options.dataInteraction.point, 'EPSG:4326', this.mapConfig.projection));
    } else if (this._options.dataInteraction.type == "coord_mult") {
      // points multiples ou ligne cassée
      let points = [];
      _.each(this._options.dataInteraction.points, point => {
        points.push(transform(point, 'EPSG:4326', this.mapConfig.projection));
      });
      if (this._options.dataInteraction.isLine) {
        geom = new LineString(points, 'XY');
      } else {
        _.each(points, point => {
          drawLayer.getSource().addFeature(new Feature({ geometry: new Point(point) }));
        });
        if (points.length > 0) {
          this.displayResetDataDraw = true;
        }
      }
    }

    if (geom) {
      if (_.isArray(geom)) {
        let features = [];
        this.currentSelectedExtents = [];
        _.each(geom, (g, i) => {
          let idx = i + 1;
          let feature = {
            idx: idx,
            extent: g.getExtent(),
            name: $localize`Emprise` + " " + idx,
            olFeature: new Feature({ geometry: g })
          };
          this.currentSelectedExtents.push(feature);
          features.push(feature.olFeature);
          this._lastFeatureId = idx;
        });
        drawLayer.getSource().addFeatures(features);
      } else {
        drawLayer.getSource().addFeature(new Feature({ geometry: geom }));
      }
    }

    return drawLayer;
  }

  /**
   * Génère l'interaction de dessin pour les cartes de données de formulaire
   * @param layers - Couches qui vont être créées avec la map
   */
  private _generateDataDrawInteraction(layers: any[]): any | null {
    let drawLayer = _.find(layers, l => l.get('idx') == 'dataDraw');
    if (this._options.dataInteraction.type == 'bbox') {
      this._dataDrawInteraction = new DragBox({ condition: platformModifierKeyOnly });
      this._dataDrawInteraction.on('boxend', () => this._setBBOX(this._dataDrawInteraction));

      return this._dataDrawInteraction
    } else if (
      this._options.dataInteraction.type === "coord" ||
      (this._options.dataInteraction.type === "coord_mult" && !this._options.dataInteraction.isLine)
    ) {
      if (drawLayer) {
        this._dataDrawInteraction = new Draw({
          source: drawLayer.getSource(),
          type: 'Point',
          style: new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({ color: '#E36C04' }),
              stroke: new Stroke({ width: 2, color: 'white' })
            })
          })
        });

        this._dataDrawInteraction.on('drawstart', () => {
          if (this._options.dataInteraction.type === "coord") {
            drawLayer.getSource().clear();
          }
        });

        this._dataDrawInteraction.on('drawend', e => {
          if (this._options.dataInteraction.type === "coord_mult") {
            this.displayResetDataDraw = true;
          }
          this._setCoords(e.feature);
        });

        return this._dataDrawInteraction;
      }
    } else if (this._options.dataInteraction.type === "coord_mult" && this._options.dataInteraction.isLine) {
      if (drawLayer) {
        this._dataDrawInteraction = new Draw({
          source: drawLayer.getSource(),
          type: 'LineString',
          freehandCondition: () => false,
          style: new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({ color: '#E36C04' }),
              stroke: new Stroke({ width: 2, color: 'white' })
            }),
            stroke: new Stroke({ width: 2, color: '#E36C04' })
          })
        });

        this._dataDrawInteraction.on('drawstart', () => drawLayer.getSource().clear());

        this._dataDrawInteraction.on('drawend', e => this._setCoords(e.feature));

        return this._dataDrawInteraction;
      }
    }
    return null;
  }

  /**
   * Génère le style pour la couche de dessin en fonction de ce qui doit être dessiné
   */
  private _getDataDrawStyle(): Style {
    if (this._options.dataInteraction.type === 'bbox') {
      return new Style({
        stroke: new Stroke({
          color: 'red',
          width: 3
        })
      });
    } else if (
      this._options.dataInteraction.type === "coord" ||
      (this._options.dataInteraction.type === "coord_mult" && !this._options.dataInteraction.isLine)
    ) {
      return (feature) => {
        let point = feature.getGeometry().getCoordinates();
        return new Style({
          stroke: new Stroke({
            color: 'red',
            width: 3
          }),
          image: new Circle({
            radius: 5,
            fill: new Fill({ color: 'red' }),
            stroke: new Stroke({ width: 2, color: 'white' })
          }),
          text: new Text({
            text: "X: " + point[0] + " ; Y: " + point[1],
            font: "14px Roboto, Arial, sans-serif",
            textBaseline: 'bottom',
            fill: new Fill({ color: '#444' }),
            backgroundFill: new Fill({ color: 'rgba(255,255,255,0.8)' }),
            offsetY: -13,
            padding: [5, 7, 5, 7]
          })
        });
      };
    } else if (this._options.dataInteraction.type === "coord_mult" && this._options.dataInteraction.isLine) {
      return new Style({
        stroke: new Stroke({
          color: 'red',
          width: 3
        })
      });
    }
    return null;
  }

  /**
   * Définit une nouvelle bbox et la renvoie au composant de formulaire
   * @param dragBox - objet ol de bbox
   */
  private _setBBOX(dragBox: Feature) {
    let extent = _.cloneDeep(dragBox.getGeometry().getExtent());

    let drawLayer = this._getMapLayer('dataDraw');
    if (drawLayer) {
      let olFeature = new Feature({
        geometry: polygonFromExtent(extent)
      });
      if (this._options.dataInteraction.isMultiple) {
        const idx = ++this._lastFeatureId;
        this.currentSelectedExtents.push({
          idx: idx,
          extent: extent,
          name: $localize`Emprise` + " " + idx,
          olFeature: olFeature
        });
      } else {
        drawLayer.getSource().clear();
      }
      drawLayer.getSource().addFeature(olFeature);
    }

    if (this._options.dataInteraction.isMultiple) {
      this._generateMultipleBBOXExtentsResult();
    } else {
      extent = transformExtent(extent, this.mapConfig.projection, 'EPSG:4326');
      this.onDataChange.emit(extent);
    }
  }

  /**
   * Génère les emprises choisies et les retourne au parent
   */
  private _generateMultipleBBOXExtentsResult() {
    let extents = [];
    _.each(this.currentSelectedExtents, feature => {
      extents.push(
        transformExtent(feature.extent, this.mapConfig.projection, 'EPSG:4326')
      );
    });
    this.onDataChange.emit(extents);
  }

  /**
   * Définit de nouvelles coordonnées et les renvoie au composant de formulaire
   */
  private _setCoords(newFeature: Feature) {
    let coords;
    if (this._options.dataInteraction.type === "coord") {
      coords = transform(newFeature.getGeometry().getCoordinates(), this.mapConfig.projection, 'EPSG:4326');
    } else if (this._options.dataInteraction.type === "coord_mult") {
      coords = [];
      if (this._options.dataInteraction.isLine) {
        coords = newFeature.getGeometry().getCoordinates();
      } else {
        let drawLayer = this._getMapLayer('dataDraw');
        if (drawLayer) {
          let features = drawLayer.getSource().getFeatures();

          coords = [];
          _.each(features, feature => {
            coords.push(feature.getGeometry().getCoordinates());
          });
          coords.push(newFeature.getGeometry().getCoordinates());
        }
      }
      _.each(coords, (c, i) => {
        coords[i] = transform(c, this.mapConfig.projection, 'EPSG:4326');
      });
    }

    if (coords) {
      this.onDataChange.emit(coords);
    }
  }

  //========================================================
  //===================== Utilitaires ======================
  //========================================================

  /**
   * Renvoie la couche OL correspondant à l'ID demandé
   * @param layerId - ID de la couche à retrouver
   * @param map - (optionnel) carte OL dans laquelle chercher la couche
   */
  private _getMapLayer(layerId: number | string, map: string = null): ImageLayer {
    if (!map) {
      map = this.map;
    }
    return _.find(this.map.getLayers().getArray(), layer => layer.get('idx') == layerId);
  }

  /**
   * Enregistre en session l'état actuel de la carte
   */
  private _saveSessionMap(): void {
    let view = this.map.getView();
    let center = transform(view.getCenter(), view.getProjection(), 'EPSG:4326');

    let context: any = {
      center: center,
      zoom: view.getZoom(),
      currentBgIdx: this.currentBackground.idx
    };

    if (this.currentLayers.length > 0) {
      context.layers = [];
      _.each(this.currentLayers, layer => {
        context.layers.push({
          idx: layer.idx,
          visible: layer.visible,
          opacity: layer.opacity
        });
      });
    }

    if (this.resultsLayers.length > 0) {
      context.resultLayers = [];
      _.each(this.resultsLayers, layer => {
        context.resultLayers.push({
          idx: layer.idx,
          visible: layer.visible,
          opacity: layer.opacity
        });
      });
    }

    this._session.saveMapContext(this.mapConfig.name, context, this._options.execId);
  }
}
