import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  Validator,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';

@Component({
  selector: 'metadata-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: CheckboxComponent, multi: true }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, Validator {
  @Input() metadata;
  @Input('readonly') isReadOnly;
  value: boolean;

  @Output() onMetadataChange = new EventEmitter();

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: () => void = () => { };

  constructor() {}

  onChange() {
    if(this.value && this.metadata.trueValue !== undefined) {
      this.onChangeCb(this.metadata.trueValue);
    } else if(!this.value && this.metadata.falseValue !== undefined){
      this.onChangeCb(this.metadata.falseValue);
    } else {
      this.onChangeCb(this.value);
    }
    this.onMetadataChange.emit(this.metadata);
  }

  // control methods
  writeValue(value) {
    if(this.metadata.trueValue === value) {
      this.value = true;
    } else if(this.metadata.falseValue === value) {
      this.value = false;
    } else {
      this.value = value ? true : false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  // validation methods
  validate(control: AbstractControl): {[key: string]: any} | null {
    return null;
  }
}
