import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from '@angular/forms';
import { Observable, of, Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { equals as equalsExtent } from 'ol/extent';

import { Link, Destination, Project, MetadataEditing } from 'src/app/models';
import { LinkService, MapService, LoaderService, ProjectService, UtilsService, SessionService, ResourceService } from 'src/app/services';
import { Constants } from 'src/app/constants';
import { Angulartics2 } from 'angulartics2';
import { FormListEvent, RegistryService } from '../../../services';
import { SelectItem } from 'primeng/api';

@Component({
  templateUrl: './link-edit.component.html'
})
export class LinkEditComponent implements OnInit, OnDestroy {
  /**
   * Lien en cours d'édition
   */
  public link: Link;

  /**
   * Est-ce un nouveau lien ?
   */
  public isNew: boolean = true;

  /**
   * Est-on dans le contexte d'une étude ?
   */
  public isInProject: boolean = true;

  /**
   * Liste des geonames de l'autocomplétion
   */
  public geonames: Destination[] = [];

  /**
   * Objet geoname actuellement sélectionné
   */
  public chosenGeoname: Destination;

  /**
   * Liste des mots-clés au format string
   */
  public keywords: string = "";

  /**
   * Types de liens
   */
  public subTypes: string[] = [];

  /**
   * Liste de mes études
   */
  public projects: Project[] = [];

  /**
   * Route de retour
   */
  public cancelRoute: string[] = ['../'];

  /**
   * Informations de l'étude en cours d'édition
   */
  public nonEditableInfos: MetadataEditing;

  /**
   * Liste des âges de début pour les dropdowns
   */
  public categories: SelectItem[] = [];

  /**
   * Liste des mots clés pour les dropdowns
   */
  public registryKeywords: SelectItem[] = [];

  /**
   * Liste des langues
   */
  public languages: ({ label: string; value: string, flag: any })[] = [];

  /**
   * Lien avant modification
   */
  private _initialLink: Link;

  /**
   * Contient toutes les souscriptions du composant
   */
  private _subs: Subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _linkService: LinkService,
    private _projectService: ProjectService,
    private _mapService: MapService,
    private _toastr: ToastrService,
    private _loader: LoaderService,
    private _utils: UtilsService,
    private _session: SessionService,
    private _tracker: Angulartics2,
    private _resourceService: ResourceService,
    private _registryService: RegistryService
  ) { }

  ngOnInit() {
    this._subs.add(this._linkService.link$.subscribe(link => this._initLink(link)));
    this._subs.add(this._projectService.projects$.subscribe(projects => this.projects = projects));
    this._subs.add(this._mapService.geonames$.subscribe(geonames => this.geonames = geonames));
    this._subs.add(this._registryService.registries$.subscribe(event => this._updateFormList(event)));
    this._subs.add(this._utils.getAllRouteParams(this._route).subscribe(params => this._initLinkFromParams(params)));

    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY);
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD);

    this.subTypes = _.clone(Constants.linkTypes);
    this._projectService.getUserProjects();
    this.languages = Constants.languages;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  /**
   * Lance la requête pour l'autocomplétion des geonames
   * @param event - event primeng
   */
  public searchGeonames(event: any) {
    this.link.extentDescription = event.query || "";
    this._mapService.getSearchList(event.query);
  }

  /**
   * Mise à jour de l'emprise quand un geoname est sélectionné
   */
  public updateExtent() {
    if (this.chosenGeoname) {
      this.link.extentDescription = this.chosenGeoname.label;
      const existentExtent = _.find(this.link.extents, e => equalsExtent(e, this.chosenGeoname.extent));
      if (!existentExtent) {
        // trick pour forcer la maj de la carte
        this.link.extents = [_.clone(this.chosenGeoname.extent)].concat(this.link.extents);
      }
    } else {
      this.link.extentDescription = "";
    }
  }

  /**
   * Demande le lien à partir des paramètres
   * @param params - paramètres d'url
   */
  private _initLinkFromParams(params: any) {
    this._loader.show();
    let linkId = params.linkId;
    this._linkService.getLink(linkId);
    this.isNew = (linkId === 'new');
    if (params.projectId) {
      let projectId = params.projectId;
      if (this.isNew) {
        this.link.projectId = projectId;
      }
      this._projectService.getProject(projectId, false);
    } else {
      this.isInProject = false;
    }
    if (this.isNew) {
      this.cancelRoute[0] += '../';
      if (this.isInProject) {
        this.cancelRoute[0] += '../';
      }
    }
  }

  /**
   * Clone le lien reçu du serveur pour en faire un objet éditable
   * @param link - Lien issu du serveur
   */
  private _initLink(link: Link) {
    this._initialLink = _.cloneDeep(link);
    let isEditableObs: Observable<MetadataEditing>;
    if (this.isNew) {
      this._initialLink.name = "";
      this._initialLink.ownerName = this._session.currentUser.email;
      this._initialLink.ownerOrganisation = "BRGM";
      isEditableObs = of(null);
    } else {
      isEditableObs = this._resourceService.getResourceEditingStatus(link.id, Constants.OBJECT_TYPE_LINK);
    }
    isEditableObs.subscribe(me => {
      if (me) {
        this.nonEditableInfos = me;
      } else if (!this.isNew) {
        this._resourceService.setResourceAsEditing(link.id, Constants.OBJECT_TYPE_LINK, true);
      }
      this.link = _.cloneDeep(this._initialLink);
      this.chosenGeoname = new Destination().deserialize({
        label: this.link.extentDescription,
        extent: this.link.extents.length > 0 ? this.link.extents[0] : null
      });
      this.keywords = this.link.freeKeywords.join(', ');
      this._loader.hide();
    });
  }

  /**
   * Enregistre le lien et reviens à la liste des liens
   */
  public save(editForm: NgForm) {
    if (editForm.invalid) {
      return;
    }
    this._loader.show();
    if (this.keywords) {
      this.link.freeKeywords = this.keywords.split(',').map(k => k.trim());
    } else {
      this.link.freeKeywords = [];
    }
    this.link.addClassifiedKeywords(Constants.TYPOLOGIE_KEYWORD_NAME, [this.link.typeName], true);
    this.link.addClassifiedKeywordsWithLink(Constants.THEMATICS_KEYWORD_NAME, this.link.thematics, true);
    this.link.addClassifiedKeywordsWithLink(Constants.KEYWORD_KEYWORD_NAME, this.link.keywords, true);

    this._linkService.saveLink(this.link)
      .subscribe(result => {
        this._tracker.eventTrack.next({
          action: this.isNew ? "Ajout d'un lien" : "Modification d'un lien",
          properties: {
            category: this.link.name
          }
        });
        this._loader.hide();
        if (result && result.id) {
          if (this.isNew) {
            this._toastr.success($localize`Le lien ${this.link.name} a été créé avec succès`);
            this._session.setInitPageParams({ id: result.id, type: 'link' });
          } else {
            this._toastr.success($localize`Le lien ${this.link.name} a été modifié avec succès`);
          }
          this._router.navigate(this.cancelRoute, { relativeTo: this._route });
        }
      }, error => console.error(error));
  }

  /**
   * Optimisation pour le ngFor
   * @param i
   * @param item
   */
  public trackById(item) {
    return item.id;
  }

  /**
   * Action en quittant la page
   */
  public canDeactivate(): boolean {
    if (!this.nonEditableInfos && !this.isNew) {
      this._resourceService.setResourceAsEditing(this.link.id, Constants.OBJECT_TYPE_LINK, false);
    }

    return true;
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public categoriesAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_CATEGORY, event.query, {});
  }

  /**
   * Appelle le service des listes pour l'autocomplétion
   * @param event - Événement primeng contenant la requête d'autocomplétion
   */
  public keywordsAutoComplete(event): void {
    this._registryService.getLexiques(Constants.REGISTRY_KEYWORD, event.query, {});
  }

  /**
   * Met à jour les listes des dropdowns/autocomplétions
   * @param event - Événement reçu du service FormListService
   */
  private _updateFormList(event: FormListEvent): void {
    switch (event.type) {
      case Constants.REGISTRY_CATEGORY:
        this.categories = event.datas;
        break;
      case Constants.REGISTRY_KEYWORD:
        this.registryKeywords = event.datas;
        break;
    }
  }

}
