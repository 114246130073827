<div class="container-fluid main d-flex flex-column">
  <app-header></app-header>
  <div class="d-flex h-100">
    <app-menu></app-menu>
    <div class="page w-100 h-100 d-flex flex-column">
      <breadcrumb></breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<loader></loader>
