<section id="admin-group-manage" class="d-flex flex-column h-100" *ngIf="group">
  <div class="d-flex align-items-start page-header">
    <div class="mr-auto">
      <h1>{{group.name}}</h1>
    </div>
    <a [routerLink]="['./edit']" class="btn btn-default nowrap">
      <i class="fa fa-edit"></i>
      <ng-container i18n>Modifier</ng-container>
    </a>
    <button class="btn btn-danger nowrap ml-3" (click)="deleteGroup()">
      <i class="fa fa-trash-alt"></i>
      <ng-container i18n>Supprimer</ng-container>
    </button>
  </div>
  <div class="page-content">
    <div class="row group-content">
      <div class="col-6">
        <h2 i18n>Informations</h2>
        <p class="mb-2">
          <strong i18n>Statut :</strong>&nbsp;
          <i class="mr-2 status-dot" [ngClass]="{active: group.status === 'alive'}"></i>
          <ng-container *ngIf="group.status === 'alive'" i18n>Actif</ng-container>
          <ng-container *ngIf="group.status !== 'alive'" i18n>Inactif</ng-container>
        </p>
        <p class="mb-2" *ngIf="group.description">
          <strong class="d-block" i18n>Description</strong>
          <span class="nl2br">{{group.description}}</span>
        </p>
      </div>
      <div class="col-6">
        <h2 i18n>Membres du groupe</h2>
        <button class="btn btn-primary btn-sm nowrap" (click)="addUser()">
          <i class="fa fa-plus"></i>
          <ng-container i18n>Ajouter un utilisateur</ng-container>
        </button>
        <p class="alert-info p-3" *ngIf="group.users.length === 0">
          <i class="fa fa-info-circle mr-1"></i>
          <ng-container i18n>Ce groupe ne contient aucun utilisateur.</ng-container>
        </p>
        <div>
          <ul class="user-list" *ngIf="group.users.length > 0">
            <li class="d-flex align-items-center" *ngFor="let user of group.users">
              <span class="mr-3">{{user}}</span>
              <button type="button" class="btn btn-icon ml-auto" (click)="removeUser(user)"><i>&times;</i></button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>