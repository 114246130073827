import * as _ from 'lodash';

/**
 * Entité de base pour la désérialisation de données
 * Tous les modèles doivent en hériter
 * Utilisation : 
 *    - Définissez tous les attributs de votre objet avec pour tous une valeur par défaut (même null, ça marche)
 *      c'est une obligation pour la boucle qui remplit l'objet, sinon le js ne détecte pas l'existence de l'attribut
 * 
 *    - Dans le constructeur de votre modèle, définissez le tableau "_mapperDefs", 
 *      il fait la relation entre le nom de la propriété côté back et celui côté front.
 *      Si "back" n'est pas fourni, le mapper considère que la version serveur a le même nom d'attribut
 *      Si "class" est fourni, la valeur de l'attribut indiqué est initialisé dans cette classe
 * 
 *    - Pour initialiser un objet à partir d'un json issu du serveur, faites un new puis utilisez immédiatement la fonction "deserialize"
 * 
 *    - Si vous avez des éléments d'initialisation particuliers, surchargez la méthode "deserialize" plutôt que le constructeur,
 *      comme ça vous pouvez placer l'appel au parent où vous le souhaitez dans la méthode (voir ne pas l'utiliser)
 * 
 *    - La méthode "serialize" restitue votre objet sous la forme d'un JSON semblable à celui qui vient du serveur
 * 
 *    - Ne pas utiliser la méthode "deserialize" dans le constructeur : les attributs de l'objet n'existent pas encore à ce stade
 */
export abstract class EntityMapper {
  /**
   * Contient le json issu des appels serveur, utilisé pour les updates
   */
  protected _jsonModel: any = {};

  /**
   * Définit quelle clé dans le json du serveur correspond à quel attribut de l'objet
   */
  protected _mapperDefs: EntityMapperModelKeys[] = [];

  /**
   * Définit les valeurs des attributs à partir du json entrant
   * @param json - Json de données issu du serveur
   */
  public deserialize(json: any): this {
    if (!json) {
      console.warn("Aucun json fourni à l'instance");
      return;
    }
    this._jsonModel = json;
    _.each(_.keys(this), key => {
      if (this.hasOwnProperty(key) && key.indexOf("_") !== 0) {
        let dataValue, dataClass;
        let mapDef = _.find(this._mapperDefs, { front: key });
        // La clé est référencée
        if (mapDef) {
          if (mapDef.back) {
            dataValue = this._getDataFromJson(this._jsonModel, mapDef.back);
          }
          if (mapDef.class) {
            dataClass = mapDef.class;
          }
        }

        // la clé n'est pas référencée ou la valeur "back" n'est pas définie
        if (dataValue === undefined && this._jsonModel.hasOwnProperty(key)) {
          dataValue = this._jsonModel[key];
        }

        this._setValue(key, dataValue, dataClass);
      }
    });

    return this;
  }

  /**
   * Met à jour le json du serveur à partir des données de l'objet et le renvoie
   */
  public serialize(): any {
    _.each(_.keys(this), key => {
      if (this.hasOwnProperty(key) && key.indexOf("_") !== 0) {
        let mapDef = _.find(this._mapperDefs, { front: key });
        let backName = key;
        if (mapDef && mapDef.back) {
          backName = mapDef.back;
        }
        this._updateJsonData(this._jsonModel, backName, this[key]);
      }
    });

    return this._jsonModel;
  }

  /**
   * (Récursive) Renvoie la valeur du champ demandé dans le json fourni
   * @param jsonModel - Json ou fraction du json issu du serveur
   * @param dataName - Nom de la clé à récupérer. Peut être sur le modèle 'key1.key2' en cas d'objets imbriqués
   */
  private _getDataFromJson(jsonModel: any, dataName: string): any {
    let split = dataName.split('.');

    if (jsonModel.hasOwnProperty(split[0])) {
      if (split.length > 1 && jsonModel[split[0]] !== null) {
        return this._getDataFromJson(jsonModel[split[0]], split.slice(1).join('.'));
      } else {
        return jsonModel[split[0]];
      }
    } else {
      return null;
    }
  }

  /**
   * Définit la valeur dans l'entité à partir du modèle json, en respectant le typage
   * @param key - Clé de la valeur dans l'entité
   * @param value - Valeur dans le json
   * @param ClassDef - classe à caster si nécessaire
   */
  private _setValue(key: string, value: any, ClassDef: any = null) {
    let finalValue;
    if (_.isArray(this[key]) || _.isArray(value)) {
      finalValue = _.cloneDeep(value) || [];
    } else if (_.isObject(this[key]) || _.isObject(value)) {
      finalValue = _.cloneDeep(value) || {};
    } else if (_.isBoolean(this[key]) || _.isBoolean(value)) {
      finalValue = _.isBoolean(value) ? value : this[key];
    } else {
      finalValue = value !== undefined ? value : this[key];
    }

    if (ClassDef && finalValue !== undefined && finalValue !== null) {
      if (ClassDef.prototype.deserialize) {
        if (_.isArray(finalValue)) {
          finalValue = finalValue.map(e => new ClassDef().deserialize(e));
        } else {
          finalValue = new ClassDef().deserialize(finalValue);
        }
      } else {
        if (_.isArray(finalValue)) {
          finalValue = finalValue.map(e => new ClassDef(e));
        } else {
          finalValue = new ClassDef(finalValue);
        }
      }
    }

    this[key] = finalValue;
  }

  /**
   * (Récursive) Met à jour la valeur du champ demandé dans le json fourni
   * @param jsonModel - Json ou fraction du json issu du serveur
   * @param dataName - Nom de la clé à récupérer. Peut être sur le modèle 'key1.key2' en cas d'objets imbriqués
   * @param value - La valeur à donner à la clé
   */
  private _updateJsonData(jsonModel: any, dataName: string, value: any): void {
    let split = dataName.split('.');
    let key = isNaN(Number(split[0])) ? split[0] : Number(split[0]);

    if (split.length > 1) {
      if (!jsonModel.hasOwnProperty(key) || jsonModel[key] === null) {
        if (isNaN(Number(split[1]))) {
          jsonModel[key] = {};
        } else {
          jsonModel[key] = [];
        }
      }
      this._updateJsonData(jsonModel[key], split.slice(1).join('.'), value);
    } else {
      if (_.isArray(value)) {
        let values = value.slice(0);
        jsonModel[key] = [];
        _.each(values, val => {
          if (_.isObject(val) && val.serialize) {
            jsonModel[key].push(val.serialize());
          } else if(val !== null && val!== undefined){
            jsonModel[key].push(val);
          }
        })
      } else {
        if(_.isObject(value) && value.serialize) {
          jsonModel[key] = value.serialize();
        } else {
          jsonModel[key] = value;
        }
      }
    }
  }
}

interface EntityMapperModelKeys {
  /**
   * Clé du json serveur
   */
  back?: string;

  /**
   * Clé de l'objet front
   */
  front: any;

  /**
   * Classe à utiliser pour le parsing
   */
  class?: any;
}