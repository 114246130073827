import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject, of, forkJoin, throwError } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';


import { Workflow, Project, Process, Permission, Execution, MapConfig, OnlineResource, BackgroundLayer } from '../models';
import { Uris } from '../constants';
import { SessionService } from './session.service';
import { Constants } from '../constants/app.constants';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  private _workflowsSource = new Subject<Workflow[]>();
  private _workflowSource = new Subject<Workflow>();
  private _executionLogsSource = new Subject<{ execId: string, status: string, logs: string }>();
  private _executionResultsSource = new Subject<{ execId: string, results: any[] }>();
  private _executionsSource = new Subject<Execution[]>();
  private _executionSource = new Subject<Execution>();

  public workflows$ = this._workflowsSource.asObservable();
  public workflow$ = this._workflowSource.asObservable();
  public executionLogs$ = this._executionLogsSource.asObservable();
  public executionResults$ = this._executionResultsSource.asObservable();
  public executions$ = this._executionsSource.asObservable();
  public execution$ = this._executionSource.asObservable();

  private _failedGetLogCounter: number = 0;

  private _fileCache: { [key: string]: Observable<any> } = {};

  constructor(
    private _http: HttpClient,
    private _session: SessionService,
    private _loader: LoaderService,
    private _toastr: ToastrService,
    private _router: Router,
  ) { }

  /**
   * Renvoie l'observable d'appel des workflows
   * @param urlQuery - Paramètres d'url à ajouter à l'appel
   */
  private _getWorkflowsObs(urlQuery: string = ""): Observable<Workflow[]> {
    return this._http.get<Workflow[]>(Uris.WORKFLOWS + urlQuery)
      .pipe(
        map(workflows => workflows.map(p => new Workflow().deserialize(p))),
        switchMap(workflows => {
          return this._http.get<Project[]>(Uris.PROJECTS)
            .pipe(
              map(projects => projects.map(p => new Project().deserialize(p))),
              map(projects => {
                _.each(workflows, workflow => {
                  _.each(workflow.projectUris, uri => {
                    workflow.projects.push(_.find(projects, { id: uri }));
                  });
                });
                return workflows;
              })
            )
        })
      )
  }

  /**
   * Demande la récupération de la liste des workflows que le user peut voir
   */
  public getAllWorkflows(): void {
    this._getWorkflowsObs()
      .subscribe(
        workflows => this._workflowsSource.next(workflows),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des workflows, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Demande la récupération des workflows dont le user est propriétaire
   */
  public getUserWorkflows(): void {
    this._getWorkflowsObs('?permission=owner')
      .subscribe(
        workflows => this._workflowsSource.next(workflows),
        error => {
          console.error(error);
          this._loader.hide();
          this._toastr.error($localize`Une erreur est survenue pendant la récupération des workflows, veuillez réessayer plus tard.`);
        }
      );
  }

  /**
   * Demande la récupération d'un workflow précis
   * @param id - identifiant du workflow
   * @param projectId - (optionnel) identifiant de l'étude en cours, null par défaut
   * @param getInputs - (optionnel) faut-il récupérer les inputs ? false par défaut
   */
  public getWorkflow(id: string, projectId: string = null, getInputs: boolean = false): void {
    if (id === 'new') {
      let newWorkflow = new Workflow();
      newWorkflow.name = $localize`Nouveau workflow`;
      this._workflowSource.next(newWorkflow);
    } else {
      let query = '';
      if (projectId) {
        query = '?projectId=' + projectId;
      }
      this._http.get<Workflow>(Uris.WORKFLOWS + id + query)
        .pipe(
          catchError(error => {
            if (error.status === 403) {
              this._toastr.error($localize`Vous n'êtes pas autorisé à consulter ce workflow.`);
              if (projectId) {
                if (this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
                  this._router.navigate(['/my-projects', projectId]);
                } else {
                  this._router.navigate(['/projects', projectId]);
                }
              } else {
                this._router.navigate(['/workflows']);
              }
            } else if (error.status === 404) {
              this._toastr.error($localize`Ce workflow n'existe pas ou plus.`);
              if (projectId) {
                if (this._session.hasRight(projectId, Constants.OBJECT_TYPE_PROJECT, 'owner')) {
                  this._router.navigate(['/my-projects', projectId]);
                } else {
                  this._router.navigate(['/projects', projectId]);
                }
              } else {
                this._router.navigate(['/workflows']);
              }
            } else {
              this._toastr.error($localize`Une erreur est survenue pendant la récupération des workflows, veuillez réessayer plus tard.`);
            }
            return throwError(error);
          }),
          switchMap(workflow => {
            const params = new HttpParams().set('objectType', Constants.OBJECT_TYPE_WORKFLOW);
            return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/users', { params })
              .pipe(
                catchError(error => {
                  this._toastr.error($localize`Une erreur est survenue pendant la récupération des droits du workflow.`);
                  return throwError(error);
                }),
                map(rights => {
                  workflow.individualPermissions = rights;
                  return workflow;
                })
              );
          }),
          switchMap(workflow => {
            if (getInputs && this._session.hasRight(id, Constants.OBJECT_TYPE_WORKFLOW, 'owner')) {
              const params = new HttpParams().set('objectType', Constants.OBJECT_TYPE_WORKFLOW);
              return this._http.get<Permission[]>(Uris.RESOURCES + id + '/rights/groups', { params })
                .pipe(
                  catchError(error => {
                    this._toastr.error($localize`Une erreur est survenue pendant la récupération des droits du workflow.`);
                    return throwError(error);
                  }),
                  map(rights => {
                    workflow.groupPermissions = rights;
                    return workflow;
                  })
                );
            }
            return of(workflow);
          }),
          map(workflow => new Workflow().deserialize(workflow)),
          map((workflow: Workflow) => {
            // Réordonner les online resources pour mettre les liens bibliographiques en premier
            let alphaResources: OnlineResource[] = [];
            let otherResources: OnlineResource[] = [];

            _.each(workflow.onlineResources, (ol: OnlineResource) => {
              if (Constants.workflowOnlineResourceProtocols.indexOf(ol.protocol) < 0) {
                ol.protocol = "Lien bibliographique"; // Rétrocompatibilité 1.0.X
              }
              if (ol.protocol === "Lien bibliographique") {
                alphaResources.push(ol);
              } else {
                otherResources.push(ol);
              }
            });

            workflow.onlineResources = alphaResources.concat(otherResources);

            return workflow;
          }),
          switchMap(workflow => {
            return this._http.get<Project[]>(Uris.PROJECTS)
              .pipe(
                map(projects => projects.map(p => new Project().deserialize(p))),
                map(projects => {
                  _.each(workflow.projectUris, uri => {
                    workflow.projects.push(_.find(projects, { id: uri }));
                  });
                  return workflow;
                })
              );
          })
        )
        .subscribe(workflow => {
          if (getInputs) { // mis dans le subscribe pour ne pas bloquer le flux si cromwell est planté
            this._http.get<Process[]>(Uris.WORKFLOWS + id + '/inputs')
              .pipe(
                catchError(error => {
                  if (error.status === 404) {
                    this._toastr.error($localize`Inputs du workflow introuvables.`);
                  } else {
                    this._toastr.error($localize`Une erreur est survenue pendant la récupération des inputs du workflow.`);
                  }
                  return throwError(error);
                }),
                map(result => {
                  if (result && _.isArray(result)) {
                    workflow.processes = result.map(r => new Process().deserialize(r));
                  }
                  return workflow;
                }),
                switchMap(workflow => {
                  return this._http.get<any>(Uris.WORKFLOWS + id + '/map')
                    .pipe(
                      catchError(() => of(Constants.DEFAULT_MAP_CONFIG)),
                      map(result => {
                        if (result) {
                          result.name = workflow.id;
                          workflow.mapConfig = new MapConfig().deserialize(result);
                        }
                        _.each(workflow.processes, process => {
                          _.each(process.metadata, metadata => {
                            metadata.mapConfig = workflow.mapConfig;
                          });
                        });
                        return workflow;
                      })
                    );
                }),
                switchMap(workflow => {
                  let call;
                  if (projectId) {
                    call = this._http.get<Execution[]>(Uris.EXECUTIONS + id + '/history/' + projectId);
                  } else if (getInputs && this._session.hasRight(id, Constants.OBJECT_TYPE_WORKFLOW, 'owner')) {
                    call = this._http.get<Execution[]>(Uris.WORKFLOWS + id + '/executions');
                  }
                  if (call) {
                    return call.pipe(
                      catchError(error => {
                        this._toastr.error($localize`Une erreur est survenue pendant la récupération des exécutions du workflow.`);
                        return throwError(error);
                      }),
                      map((executions: Execution[]) => {
                        if (executions && _.isArray(executions)) {
                          executions = executions.map(e => new Execution().deserialize(e));

                          if (projectId) {
                            workflow.executions = _.orderBy(_.filter(executions, { status: Constants.executionStatus.saved }), ['executionDate'], ['desc']);
                            let unsavedExecution = _.maxBy(
                              _.filter(
                                executions, e => e.status !== Constants.executionStatus.saved &&
                                  e.status !== Constants.executionStatus.deleted &&
                                  e.userId === this._session.currentUser.id
                              ),
                              'executionDate'
                            );

                            let mySavedExecutions = _.filter(workflow.executions, e => e.userId === this._session.currentUser.id);

                            if (
                              (mySavedExecutions.length === 0 && unsavedExecution) ||
                              (mySavedExecutions.length > 0 && unsavedExecution && unsavedExecution.executionDate > mySavedExecutions[0].executionDate)
                            ) {
                              workflow.executions.unshift(unsavedExecution);
                            }
                          } else {
                            workflow.executions = _.orderBy(executions, ['executionDate'], ['desc']);
                          }

                        }
                        return workflow;
                      })
                    );
                  }
                  return of(workflow);
                })
              )
              .subscribe((workflow: Workflow) => {
                this._workflowSource.next(workflow);
              }, () => {
                workflow.processes = [];
                workflow.executions = [];
                this._workflowSource.next(workflow);
              });
          } else {
            this._workflowSource.next(workflow);
          }
        }, error => {
          console.error(error);
          this._loader.hide();
        });
    }
  }

  /**
   * Récupère une exécution dans un workflow
   * @param execId 
   * @param workflow 
   */
  public getExecution(execId: string, workflow: Workflow) {
    let execution = _.find(workflow.executions, { id: execId });
    this._executionSource.next(execution);
  }

  /**
   * Enregistre un workflow
   * @param workflow - workflow à enregistrer
   */
  public saveWorkflow(workflow: Workflow): Observable<any> {
    let obs;
    console.log(workflow);
    if (workflow.id) {
      obs = this._http.put<any>(Uris.WORKFLOWS + workflow.id, workflow.serialize());
    } else {
      obs = this._http.post<any>(Uris.WORKFLOWS, workflow.serialize());
    }
    return obs
      .pipe(
        catchError(error => {
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas authorisé à modifier ce workflow.`);
            this._router.navigate(['/workflows']);
          } else if (error.status === 404) {
            this._toastr.error($localize`Ce workflow n'existe pas ou plus.`);
            this._router.navigate(['/workflows']);
          } else {
            this._toastr.error($localize`Une erreur est survenue pendant l'enregistrement du workflows, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        }),
        switchMap(result => this._session.getUserPermissionsObs(result))
      );
  }

  /**
   * Ajoute une étude à un workflow
   * @param workflow Workflow modifié
   * @param project Étude à ajouter
   */
  public addWorkflowRelation(workflow: Workflow, project: Project): Observable<any> {
    return this._http.post<any>(`${Uris.PROJECTS}${project.id}/workflows/${workflow.id}`, null)
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à lier cette étude et ce workflow.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`Le workflow et/ou l'étude n'existent pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de la liaison de l'étude et du workflow, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        })
      );
  }

  /**
   * Supprime une étude d'un workflow
   * @param workflow Workflow modifié
   * @param project Étude à supprimer
   */
  public removeWorkflowRelation(workflow: Workflow, project: Project): Observable<any> {
    return this._http.delete<any>(`${Uris.PROJECTS}${project.id}/workflows/${workflow.id}`)
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à délier cette étude et ce workflow.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`Le workflow et/ou l'étude n'existent pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de la suppression de la liaison entre l'étude et le workflow, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        })
      );
  }

  /**
   * Supprimer un workflow
   * @param workflow - workflow à supprimer
   */
  public deleteWorkflow(workflow: Workflow): Observable<any> {
    return this._http.delete<any>(Uris.WORKFLOWS + workflow.id)
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à supprimer ce workflow.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`Le workflow n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue lors de la suppression du workflow, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        })
      );
  }

  /**
   * Lance l'exécution d'un workflow
   * @param workflowId - Uri du workflow
   * @param projectId - Uri du projet associé
   * @param params - inputs pour l'exécution
   */
  public executeWorkflow(execution: Execution): Observable<Execution> {
    return this._http.post<any>(Uris.WORKFLOWS + execution.workflowId + '/execute', {
      inputsJson: JSON.stringify(execution.inputs),
      projectId: execution.projectId
    }).pipe(
      catchError(error => {
        console.error(error);
        if (error.status === 403) {
          this._toastr.error($localize`Vous n'êtes pas autorisé à exécuter workflow.`);
        } else if (error.status === 404) {
          this._toastr.error($localize`Le workflow n'existe pas ou plus.`);
        } else {
          this._toastr.error($localize`Une erreur est survenue lors de la suppression du workflow, veuillez réessayer plus tard.`);
        }
        return throwError(error);
      }),
      switchMap(result => {
        return this._http.get<Execution>(Uris.EXECUTIONS + result.id, execution.serialize())
          .pipe(
            catchError(error => {
              console.error(error);
              if (error.status === 404) {
                this._toastr.error($localize`L'exécution créée est introuvable.`);
              } else {
                this._toastr.error($localize`Une erreur est survenue lors de la récupération de la nouvelle exécution, veuillez réessayer plus tard.`);
              }
              return throwError(error);
            }),
            map(e => new Execution().deserialize(e))
          )
      }),
      switchMap(newExecution => {
        newExecution.name = execution.name;
        return this.editExecution(newExecution);
      }),
      catchError(error => {
        this._loader.hide();
        return throwError(error);
      })
    );
  }

  /**
   * Modifie une exécution
   * @param execution - exécution à modifier
   */
  public editExecution(execution: Execution): Observable<Execution> {
    return this._http.put<any>(Uris.EXECUTIONS + execution.id, execution.serialize())
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à modifier cette exécution.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`L'exécution n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue en tentant de modifier l'exécution, veuillez réessayer plus tard.`);
          }
          return throwError(error);
        }),
        map(() => execution)
      );
  }

  /**
   * Marque une exécution comme sauvegardée pour prévenir toute suppression automatique
   * @param execId - ID de l'exécution
   */
  public saveExecution(execId: string): Observable<any> {
    return this._http.put<any>(Uris.EXECUTIONS + execId + '/markassaved', {})
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à enregistrer cette exécution.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`L'exécution n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue en tentant d'enregistrer l'exécution, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }

  /**
   * Supprime une exécution
   * @param execId - ID de l'exécution
   */
  public deleteExecution(execId: string): Observable<any> {
    return this._http.delete<any>(Uris.EXECUTIONS + execId)
      .pipe(
        catchError(error => {
          console.error(error);
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à enregistrer cette exécution.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`L'exécution n'existe pas ou plus.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue en tentant de renommer l'exécution, veuillez réessayer plus tard.`);
          }
          this._loader.hide();
          return throwError(error);
        })
      );
  }

  /**
   * Lance la récupération des logs d'une exécution
   * @param id - id cromwell de l'exécution
   */
  public getExecutionLogs(id: string) {
    this._failedGetLogCounter = 0;
    this._doGetExecutionLogs(id);
  }

  /**
   * (Récursive) Lance la récupération des logs et boucle si erreur 404
   * @param id - id cromwell de l'exécution
   */
  private _doGetExecutionLogs(id: string) {
    this._http.get<any>(Uris.EXECUTIONS + id + '/logs')
      .subscribe(
        result => {
          this._failedGetLogCounter = 0;
          this._executionLogsSource.next({ execId: id, status: result.status, logs: result.logs });
        },
        error => {
          if (error.status === 404 && this._failedGetLogCounter < 10) {
            this._failedGetLogCounter++;
            setTimeout(() => this._doGetExecutionLogs(id), 1000);
          } else {
            if (error.status === 404) {
              this._toastr.error($localize`Les logs de l'exécution sont introuvables.`);
            } else {
              this._toastr.error($localize`Une erreur est survenue lors de la récupération des logs de l'exécution.`);
            }
            this._executionLogsSource.next({ execId: id, status: Constants.executionStatus.failed, logs: null });
          }
        }
      );
  }

  /**
   * Lance la récupération des résultats d'une exécution
   * @param id - id cromwell de l'exécution
   */
  public getExecutionResults(id: string) {
    this._http.get<any[]>(Uris.EXECUTIONS + id + '/outputs')
      .pipe(map(results => {
        _.each(results, (result) => {
          result.functions = result.funcions || result.function || result.functions; // anti erreurs
          delete result.funcions;
          delete result.function;
          if (result.functions) {
            if (!_.isArray(result.functions)) {
              result.functions = [result.functions];
            }
            _.each(result.functions, f => {
              if (f.srs_epsg && f.srs_epsg.indexOf("EPSG") < 0) {
                f.srs_epsg = "EPSG:" + f.srs_epsg;
              }
            });
          }

        })
        return results;
      }))
      .subscribe(
        results => this._executionResultsSource.next({ execId: id, results: results }),
        error => {
          console.error(error);
          this._loader.hide();
          if (error.status === 403) {
            this._toastr.error($localize`Vous n'êtes pas autorisé à consulter ces résultats.`);
          } else if (error.status === 404) {
            this._toastr.error($localize`Les résultats de l'exécution sont introuvables.`);
          } else {
            this._toastr.error($localize`Une erreur est survenue pendant la récupération des résultats, veuillez réessayer plus tard.`);
          }
        }
      );
  }

  /**
   * Lance la récupération de toutes les exécutions en cours de l'utilisateur
   */
  public getRunningExecutions() {
    this._http.get<Execution[]>(Uris.EXECUTIONS + 'submitted')
      .pipe(
        map(executions => executions.map(e => new Execution().deserialize(e)))
      )
      .subscribe(executions => this._executionsSource.next(executions));
  }

  /**
   * Lit les fichiers de résultats pour un type d'action donnée pour renvoyer des données exploitables
   * @param results Liste des résultats d'exécution
   * @param action Type d'action à utiliser pour ce résultat
   */
  public getResultsData(results: any[], action: string): Observable<any[]> {
    let calls = [];
    let filteredResults = [];

    _.each(results, result => {
      let actionParams = _.find(result.functions, { action: action });
      let call;
      if (actionParams) {
        switch (result.format) {
          case "csv": call = this._readResultsCsv(result);
            break;
          case "geojson": call = this._readResultsGeoJSON(result);
            break;
        }
        if (call) {
          calls.push(call.pipe(
            tap((data: any) => {
              if (!data) {
                return;
              }
              let finalResult = _.cloneDeep(result);
              finalResult.data = data;
              let params = _.find(finalResult.functions, { action: action });
              if (action === 'display_table' && (!params.columns || params.columns.length === 0)) {
                params.columns = [];
                _.each(data.columns, col => {
                  params.columns.push({ name: col, label: col, ordonable: false });
                })
              }
              filteredResults.push(finalResult);
            })
          ));
        }
      }
    });
    if (calls.length > 0) {
      return forkJoin(calls)
        .pipe(map(() => filteredResults));
    }
    return of([]);
  }

  /**
   * Lit un CSV et le convertit en JSON
   * @param csvList 
   */
  private _readResultsCsv(result: any): Observable<any> {
    if (this._fileCache[result.url]) {
      return of(this._fileCache[result.url]);
    }
    return this._http.get(Uris.PROXY + '?url=' + encodeURIComponent(result.url), { responseType: 'text' })
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            this._toastr.error($localize`Le fichier de résultats '${result.name}' est introuvable.`);
          } else {
            this._toastr.error(
              $localize`Une erreur est survenue lors de la récupération du fichier de résultats '${result.name}', veuillez réessayer plus tard.`
            );
          }
          return of("");
        }),
        map((csv: string) => {
          if (!csv) {
            return null;
          }
          let rows = [], columns = [];
          let lines = csv.split('\n');
          if (lines.length > 0) {
            columns = lines[0].split(';');
            for (let i = 0; i < columns.length; i++) {
              columns[i] = columns[i].trim();
            }
            _.each(csv.split('\n'), (line, lineIndex) => {
              if (lineIndex > 0 && line) {
                let item: any = {};
                let splitedLine = line.split(';');
                _.each(columns, (column, columnIndex) => {
                  if (column !== "") {
                    item[column] = splitedLine[columnIndex];
                  }
                });
                rows.push(item);
              }
            });
            for (let i = columns.length - 1; i >= 0; i--) {
              if (columns[i] === "") {
                columns.splice(i, 1);
              }
            }
          }
          return { rows: rows, columns: columns };
        }),
        tap(results => this._fileCache[result.url] = results)
      );
  }

  /**
   * Lit un GeoJSON
   * @param result 
   */
  private _readResultsGeoJSON(result: any): Observable<any> {
    if (this._fileCache[result.url]) {
      return of(this._fileCache[result.url]);
    }
    return this._http.get<any>(Uris.PROXY + '?url=' + encodeURIComponent(result.url))
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            this._toastr.error($localize`Le fichier de résultats '${result.name}' est introuvable.`);
          } else {
            this._toastr.error(
              $localize`Une erreur est survenue lors de la récupération du fichier de résultats '${result.name}', veuillez réessayer plus tard.`
            );
          }
          return of(null);
        }),
        map(geojson => {
          if (geojson && geojson.features && geojson.features.length > 0) {
            let columns = _.keys(geojson.features[0].properties);
            let rows = [];
            _.each(geojson.features, feature => {
              rows.push(feature.properties);
            });
            return { rows: rows, columns: columns, geojson: geojson };
          }
          return null;
        }),
        tap(results => this._fileCache[result.url] = results)
      );
  }
}
